import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
// import { IMGBASEAPIURL } from '../../config';
import { BASEAPIURL, UPLOADNODEURL } from '../../config';

function OurClientsBox() {
    const [Clients, setClients] = useState([]);
    const sliderRef = useRef();
    const clients = {
        autoplay: true,
        arrows: true,
        dots: true,
        slidesToShow: 5,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.5,
                },
            },
        ],
    };

    const FetchClients = async () => {
        try {
            const res = await fetch(`${BASEAPIURL}/admin/renownedclients/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    recordsPerPage: 30,
                    SortStatus: 1,
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                setClients(resJson.data);
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        FetchClients();
        // document.getElementById('blog_filter_frm').submit();
    }, []);
    return (
        <>
            <section className="clients-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Our Renowned Clients</h3>
                                <p>Teams that trust our passion</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 p-0">
                            <div className="clients-thumb FeaturedCategoriesSlider"  style={{ paddingLeft: '50px', paddingRight: '50px'}}>
                                <Slider ref={sliderRef} {...clients}>
                                    {Clients.map((singi) => (
                                        <div key={singi.renownedclientsid}>
                                            <div className="p-1">
                                                <img
                                                    src={`${UPLOADNODEURL}${singi.image}`}
                                                    alt={singi.title}
                                                    style={{
                                                        width: '150px',
                                                        height: '100px',
                                                        objectFit: 'contain',
                                                        border: '1px solid #f5f5f5',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default OurClientsBox;
