import React, { useEffect } from 'react';
import { BASEURL } from '../../config';
import { useParams } from 'react-router-dom';

function CourseDetailQBRed() {
    const { CourseSlug } = useParams();
    useEffect(() => {
        window.location.href = `${BASEURL}question-bank/${CourseSlug}`;
    }, [CourseSlug]);

    return <></>;
}

export default CourseDetailQBRed;
