import React from 'react';
import { Link } from 'react-router-dom';
import { UPLOADNODEURL } from '../../../../config';
import { useSelector } from 'react-redux'
import { Chart } from "react-google-charts";

// const data = [
//     ["Year", "asd"],
//     ["2014", 1000],
//     ["2015", 1170],
//     ["2016", 660],
//     ["2017", 1030],
//   ];
  const options = {
    chart: {
      title: "Course Performance",
      subtitle: "",
    },
  };

function ProfileMenu({ forpage }) {
    const instructorData = useSelector((state) => state.instructorData);
    // const [lfVisibility, setlfVisibility] = useState('block');
    return (
        <>
            <div className="ry_instr_shadow p-4" forpage={forpage}>
                <div className="row">
                    <div className="col-md-3">
                        <div className="instr_tot_cours">
                            <i className="fa fa-2x fa-books"></i>
                            <h4 className="text-light mt-3">{instructorData.totalCourse}</h4>
                            <b className="text-dark">Courses</b>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="instr_tot_std">
                            <i className="fa fa-2x fa-users"></i>
                            <h4 className="text-light mt-3">{instructorData.totalLearner}</h4>
                            <b className="text-dark">Students</b>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="instr_tot_webi">
                            <i className="fa fa-2x fa-question"></i>
                            <h4 className="text-light mt-3">{instructorData.totalQb}</h4>
                            <b className="text-dark">Question Banks</b>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="instr_tot_blog">
                            <i className="fa fa-2x fa-books"></i>
                            <h4 className="text-light mt-3">{instructorData.totalBlogs}</h4>
                            <b className="text-dark">Blogs</b>
                        </div>
                    </div>
                </div>
            </div>
            {/* {instructorData.ChartData.length} */}
            {instructorData.ChartData.length > 1 ? (
                <div className="ry_instr_shadow p-4 mt-4" forpage={forpage}>
                    <Chart
                        chartType="BarChart"
                        width="100%"
                        height="400px"
                        data={instructorData.ChartData}
                        options={options}
                    />
                </div>
            ) : (
                ''
            )}
            <div className="ry_instr_shadow p-4 mt-4" forpage={forpage}>
                <h4>
                    Selling Courses
                    {/* <Link
                        to="/instructor/dashboard/mycourses"
                        className="btn btn-docsta-instructor ry-pull-right"
                        style={{ width: 'auto', borderRadius: '10px' }}
                    >
                        View All
                    </Link> */}
                    <div style={{ clear: 'both' }}></div>
                </h4>
                <div className="mt-3 table-responsive">
                    <table className="table table-hover table-borddered table-stripped">
                        <thead className="ry-table-header">
                            <tr>
                                <th>Course Name</th>
                                <th>View</th>
                                <th>Amount</th>
                                <th>Students</th>
                            </tr>
                        </thead>
                        <tbody>
                            {instructorData.dataCourse && instructorData.dataCourse.map((s) => (
                                <tr key={`VC${s._id}`}>
                                    <td>
                                        <img
                                            src={`${UPLOADNODEURL}${s.image}`}
                                            alt={s.title}
                                            className="mr-2 ry-pull-left"
                                            style={{ width: '50px' }}
                                        />
                                        <div style={{ display: 'inline-block' }} className="">
                                            <b>{s.title}</b>
                                            <br />
                                            <small style={{ fontSize: '70%' }}>
                                                <>
                                                    <i className="fa fa-star"></i> {s.sessionCount} Sessions&nbsp;
                                                </>
                                            </small>
                                        </div>
                                    </td>
                                    <td>
                                        <Link target="_BLANK" to={`/course/${s.slug}`} className="btn btn-sm btn-outline-info">
                                            <i className="fa fa-eye"></i>
                                        </Link>
                                    </td>
                                    <td>{s.totalAmount ? s.totalAmount : 'NA'}</td>
                                    <td>{s.totalStudent ? s.totalStudent : 'NA'}</td>
                                </tr>
                            ))}
                            {instructorData.dataBanks && instructorData.dataBanks.map((s) => (
                                <tr key={`QB${s._id}`}>
                                    <td>
                                        <img
                                            src={`${UPLOADNODEURL}${s.image}`}
                                            alt={s.title}
                                            className="mr-2 ry-pull-left"
                                            style={{ width: '50px' }}
                                        />
                                        <div style={{ display: 'inline-block' }} className="">
                                            <b>{s.title}</b>
                                            <br />
                                            <small style={{ fontSize: '70%' }}>
                                                <>
                                                    <i className="fa fa-star"></i> {s.sessionCount} Sessions&nbsp;
                                                </>
                                            </small>
                                        </div>
                                    </td>
                                    <td>
                                        <Link target="_BLANK" to={`/questionBank/${s.slug}`} className="btn btn-sm btn-outline-info">
                                            <i className="fa fa-eye"></i>
                                        </Link>
                                    </td>
                                    <td>{s.totalAmount ? s.totalAmount : 'NA'}</td>
                                    <td>{s.totalStudent ? s.totalStudent : 'NA'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default ProfileMenu;
