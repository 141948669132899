import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function CourseCalendar() {
    const [date, setDate] = useState(new Date());
    const today = new Date();
    const [dateRange, setDateRange] = useState([
        new Date(today.setDate(today.getDate() - today.getDay())),
        new Date(today.setDate(today.getDate() - today.getDay() + 6)),
    ]);
    const [startDate, endDate] = dateRange;
    return (
        <>
            <section className="course-calendar">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="appie-section-title">
                                <h3 className="appie-title">Course Calendar</h3>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-section-title text-lg-right">
                                <h4>Pick your preferred course from the calendar</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 pr-lg-0">
                            <div className="leftBox">
                                <Calendar
                                    onChange={setDate}
                                    value={date}
                                    next2Label={null}
                                    prev2Label={null}
                                    calendarType="US"
                                    selectRange
                                />
                                <hr />
                                <div className="categoryBox">
                                    <b>Choose the Category</b>
                                    <select>
                                        <option value="0">Medical</option>
                                        <option value="0">Business</option>
                                        <option value="0">Software</option>
                                        <option value="0">Marketing</option>
                                        <option value="0">Finance</option>
                                    </select>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <p>
                                                <input type="checkbox" defaultChecked /> PALS
                                            </p>
                                            <p>
                                                <input type="checkbox" /> Hearts Saver
                                            </p>
                                            <p>
                                                <input type="checkbox" /> ABG
                                            </p>
                                        </div>
                                        <div className="col-lg-6">
                                            <p>
                                                <input type="checkbox" /> Hearts Saver
                                            </p>
                                            <p>
                                                <input type="checkbox" /> CT Scan
                                            </p>
                                            <p>
                                                <input type="checkbox" defaultChecked /> ACLS
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 pl-lg-0">
                            <div className="rightBox">
                                <div className="headSec">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <span>Weekly Calendar</span>
                                            <DatePicker
                                                selectsRange
                                                startDate={startDate}
                                                endDate={endDate}
                                                onChange={(update) => {
                                                    setDateRange(update);
                                                }}
                                                dateFormat="d MMMM yyyy"
                                                withPortal
                                            />
                                        </div>
                                        <div className="col-lg-4">
                                            <span>Location</span>
                                            <select>
                                                <option value="0">Abu Dhabi</option>
                                                <option value="0">Dubai</option>
                                                <option value="0">Sharjah</option>
                                                <option value="0">Ajman</option>
                                                <option value="0">Al Khaimah</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="contSec">
                                    <div className="dateBox disabled">
                                        <h3>
                                            10<span>Sun</span>
                                        </h3>
                                        <b>3 Courses</b>
                                        <div className="courseBox">
                                            <span>10:00am - 12:00am</span>
                                            <h5>Hearts Saver</h5>
                                            <p>(AHA)</p>
                                        </div>
                                        <div className="courseBox">
                                            <span>2:00pm - 4:00pm</span>
                                            <h5>CT Scan</h5>
                                            <p>Interpretation</p>
                                        </div>
                                        <div className="courseBox">
                                            <span>4:00pm - 6:00pm</span>
                                            <h5>ACLS</h5>
                                            <p>(AHA)</p>
                                        </div>
                                    </div>
                                    <div className="dateBox disabled">
                                        <h3>
                                            11<span>Mon</span>
                                        </h3>
                                        <b>3 Courses</b>
                                        <div className="courseBox">
                                            <span>10:00am - 12:00am</span>
                                            <h5>Hearts Saver</h5>
                                            <p>(AHA)</p>
                                        </div>
                                        <div className="courseBox">
                                            <span>2:00pm - 4:00pm</span>
                                            <h5>CT Scan</h5>
                                            <p>Interpretation</p>
                                        </div>
                                        <div className="courseBox">
                                            <span>4:00pm - 6:00pm</span>
                                            <h5>ACLS</h5>
                                            <p>(AHA)</p>
                                        </div>
                                    </div>
                                    <div className="dateBox active">
                                        <h3>
                                            12<span>Tue</span>
                                        </h3>
                                        <b>3 Courses</b>
                                        <div className="courseBox">
                                            <span>10:00am - 12:00am</span>
                                            <h5>Hearts Saver</h5>
                                            <p>(AHA)</p>
                                        </div>
                                        <div className="courseBox">
                                            <span>2:00pm - 4:00pm</span>
                                            <h5>CT Scan</h5>
                                            <p>Interpretation</p>
                                        </div>
                                        <div className="courseBox actOne">
                                            <span>4:00pm - 6:00pm</span>
                                            <h5>ACLS</h5>
                                            <p>(AHA)</p>
                                        </div>
                                    </div>
                                    <div className="dateBox">
                                        <h3>
                                            13<span>Wed</span>
                                        </h3>
                                        <b>3 Courses</b>
                                        <div className="courseBox">
                                            <span>10:00am - 12:00am</span>
                                            <h5>Hearts Saver</h5>
                                            <p>(AHA)</p>
                                        </div>
                                        <div className="courseBox actTwo">
                                            <span>2:00pm - 4:00pm</span>
                                            <h5>CT Scan</h5>
                                            <p>Interpretation</p>
                                        </div>
                                        <div className="courseBox">
                                            <span>4:00pm - 6:00pm</span>
                                            <h5>ACLS</h5>
                                            <p>(AHA)</p>
                                        </div>
                                    </div>
                                    <div className="dateBox">
                                        <h3>
                                            14<span>Thu</span>
                                        </h3>
                                        <b>3 Courses</b>
                                        <div className="courseBox">
                                            <span>10:00am - 12:00am</span>
                                            <h5>Hearts Saver</h5>
                                            <p>(AHA)</p>
                                        </div>
                                        <div className="courseBox">
                                            <span>2:00pm - 4:00pm</span>
                                            <h5>CT Scan</h5>
                                            <p>Interpretation</p>
                                        </div>
                                        <div className="courseBox">
                                            <span>4:00pm - 6:00pm</span>
                                            <h5>ACLS</h5>
                                            <p>(AHA)</p>
                                        </div>
                                    </div>
                                    <div className="dateBox">
                                        <h3>
                                            15<span>Fri</span>
                                        </h3>
                                        <b>3 Courses</b>
                                        <div className="courseBox actTwo">
                                            <span>10:00am - 12:00am</span>
                                            <h5>Hearts Saver</h5>
                                            <p>(AHA)</p>
                                        </div>
                                        <div className="courseBox">
                                            <span>2:00pm - 4:00pm</span>
                                            <h5>CT Scan</h5>
                                            <p>Interpretation</p>
                                        </div>
                                        <div className="courseBox actOne">
                                            <span>4:00pm - 6:00pm</span>
                                            <h5>ACLS</h5>
                                            <p>(AHA)</p>
                                        </div>
                                    </div>
                                    <div className="dateBox last">
                                        <h3>
                                            16<span>Sat</span>
                                        </h3>
                                        <b>3 Courses</b>
                                        <div className="courseBox">
                                            <span>10:00am - 12:00am</span>
                                            <h5>Hearts Saver</h5>
                                            <p>(AHA)</p>
                                        </div>
                                        <div className="courseBox actTwo">
                                            <span>2:00pm - 4:00pm</span>
                                            <h5>CT Scan</h5>
                                            <p>Interpretation</p>
                                        </div>
                                        <div className="courseBox">
                                            <span>4:00pm - 6:00pm</span>
                                            <h5>ACLS</h5>
                                            <p>(AHA)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CourseCalendar;
