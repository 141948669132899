import React, { useState, useEffect } from 'react';
import { BASEAPIURL, BASEURL, UPLOADNODEURL } from '../../../../config';

import CourseListBlock from '../../../Helper/CourseListBlock';
import CourseListBlockQB from '../../../Helper/CourseListBlockQB';

function MyFavouritePage() {
    const [isLoded, SetisLoded] = useState(false);
    const [showShare, setshowShare] = useState(false);
    const [ShowType, SetShowType] = useState('MainCourse');
    const [FavCourse, SetFavCourse] = useState([]);
    const [FavQBCourse, SetFavQBCourse] = useState([]);
    const GerFavorites = async (targetType) => {
        try {
            const res = await fetch(`${BASEAPIURL}/student/GerFavoritesList`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
                body: JSON.stringify({
                    targetType,
                    usertype: localStorage.getItem('usertype'),
                }),
            });
            const resJson = await res.json();
            SetisLoded(true);
            if (resJson.data) {
                switch (targetType) {
                    case 'MainCourse':
                        SetFavCourse(resJson.data);
                        break;
                    case 'questionBank':
                        SetFavQBCourse(resJson.data);
                        break;
                    default:
                        break;
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        GerFavorites('MainCourse');
    }, []);
    return (
        <>
            {/* {ShowType} */}
            <div className="ry_instr_shadow p-4" style={{ display: isLoded ? '' : 'none' }}>
                <h4 className="std_header_on_page">My Favourites</h4>
                <div className="row">
                    <div className="col-md-12">
                        <div
                            className="col-md-12 mt-4"
                            style={{
                                backgroundColor: 'lightgrey',
                                borderRadius: '10px',
                            }}
                        >
                            <ul className="nav" style={{ text: 'black' }}>
                                <li className="col-md-1"></li>
                                <li className="nav-item col-md-2 p-1 text-center">
                                    <a
                                        className={ShowType === 'MainCourse' ? '' : 'text-dark'}
                                        href="/"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            SetShowType('MainCourse');
                                        }}
                                    >
                                        <b>Courses</b>
                                    </a>
                                </li>
                                <li className="nav-item col-md-2 p-1 text-center">
                                    <a
                                        className={ShowType === 'questionBanks' ? '' : 'text-dark'}
                                        href="/"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            GerFavorites('questionBank');
                                            SetShowType('questionBanks');
                                        }}
                                    >
                                         <b>Question Banks</b>
                                    </a>
                                </li>
                                <li className="nav-item col-md-2 p-1 text-center">
                                    <a
                                        className={ShowType === 'instructor' ? '' : 'text-dark'}
                                        href="/"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            SetShowType('instructor');
                                        }}
                                    >
                                         <b>Instructor</b>
                                    </a>
                                </li>
                                <li className="nav-item col-md-2 p-1 text-center">
                                    <a
                                        className={ShowType === 'webinar' ? '' : 'text-dark'}
                                        href="/"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            SetShowType('webinar');
                                        }}
                                    >
                                         <b>Webinars</b>
                                    </a>
                                </li>
                                <li className="nav-item col-md-2 p-1 text-center">
                                    <a
                                        className={ShowType === 'lifetips' ? '' : 'text-dark'}
                                        href="/"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            SetShowType('lifetips');
                                        }}
                                    >
                                        <b>Life Tips</b>
                                    </a>
                                </li>
                                <li className="col-md-1"></li>
                            </ul>
                        </div>
                        {/* For Favarate product */}
                        <div style={{ display: ShowType === 'questionBanks' ? '' : 'none' }}>
                            <div
                                className="col-md-12 std_header_on_page"
                                style={{
                                    backgroundColor: '#f0eeee',
                                    marginTop: '50px',
                                    borderRadius: '5px',
                                    padding: '20px',
                                    textAlign: 'center',
                                    display: FavQBCourse && FavQBCourse.length === 0 ? '' : 'none',
                                }}
                            >
                                <p>You haven't created your favourites yet!</p>
                                <p>
                                    click&nbsp;
                                    <i className="far fa-heart text-danger"></i>
                                    &nbsp;& start building your list now
                                </p>
                            </div>
                            <div className="mt-3">
                                <div className="row">
                                    {FavQBCourse.map((s) => (
                                        <div className="col-md-4" key={s.image}>
                                            <CourseListBlockQB
                                                singi={s}
                                                UPLOADNODEURL={UPLOADNODEURL}
                                                showShare={showShare}
                                                setshowShare={setshowShare}
                                                setShareThisURL={BASEURL}
                                                BASEURL={BASEURL}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {/* For Favarate product */}
                        {/* For Favarate lifetips */}
                        <div style={{ display: ShowType === 'lifetips' ? '' : 'none' }}>
                            <div
                                className="col-md-12 std_header_on_page"
                                style={{
                                    backgroundColor: '#f0eeee',
                                    marginTop: '50px',
                                    borderRadius: '5px',
                                    padding: '20px',
                                    textAlign: 'center',
                                    // display: FavCourse && FavCourse.length === 0 ? '' : 'none',
                                }}
                            >
                                <p>You haven't created your favourites yet!</p>
                                <p>
                                    click&nbsp;
                                    <i className="far fa-heart text-danger"></i>
                                    &nbsp;& start building your list now
                                </p>
                            </div>
                        </div>
                        {/* For Favarate lifetips */}
                        {/* For Favarate webinar */}
                        <div style={{ display: ShowType === 'webinar' ? '' : 'none' }}>
                            <div
                                className="col-md-12 std_header_on_page"
                                style={{
                                    backgroundColor: '#f0eeee',
                                    marginTop: '50px',
                                    borderRadius: '5px',
                                    padding: '20px',
                                    textAlign: 'center',
                                    // display: FavCourse && FavCourse.length === 0 ? '' : 'none',
                                }}
                            >
                                <p>You haven't created your favourites yet!</p>
                                <p>
                                    click&nbsp;
                                    <i className="far fa-heart text-danger"></i>
                                    &nbsp;& start building your list now
                                </p>
                            </div>
                        </div>
                        {/* For Favarate webinar */}
                        {/* For Favarate instructor */}
                        <div style={{ display: ShowType === 'instructor' ? '' : 'none' }}>
                            <div
                                className="col-md-12 std_header_on_page"
                                style={{
                                    backgroundColor: '#f0eeee',
                                    marginTop: '50px',
                                    borderRadius: '5px',
                                    padding: '20px',
                                    textAlign: 'center',
                                    // display: FavCourse && FavCourse.length === 0 ? '' : 'none',
                                }}
                            >
                                <p>You haven't created your favourites yet!</p>
                                <p>
                                    click&nbsp;
                                    <i className="far fa-heart text-danger"></i>
                                    &nbsp;& start building your list now
                                </p>
                            </div>
                        </div>
                        {/* For Favarate instructor */}
                        {/* For Favarate course */}
                        <div style={{ display: ShowType === 'MainCourse' ? '' : 'none' }}>
                            <div
                                className="col-md-12 std_header_on_page"
                                style={{
                                    backgroundColor: '#f0eeee',
                                    marginTop: '50px',
                                    borderRadius: '5px',
                                    padding: '20px',
                                    textAlign: 'center',
                                    display: FavCourse && FavCourse.length === 0 ? '' : 'none',
                                }}
                            >
                                <p>You haven't created your favourites yet!</p>
                                <p>
                                    click&nbsp;
                                    <i className="far fa-heart text-danger"></i>
                                    &nbsp;& start building your list now
                                </p>
                            </div>

                            <div className="mt-3">
                                <div className="row">
                                    {FavCourse.map((s) => (
                                        <div className="col-md-4" key={s.image}>
                                            <CourseListBlock
                                                singi={s}
                                                UPLOADNODEURL={UPLOADNODEURL}
                                                showShare={showShare}
                                                setshowShare={setshowShare}
                                                setShareThisURL={BASEURL}
                                                BASEURL={BASEURL}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {/* For Favarate course */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MyFavouritePage;
