import React, { Suspense, useEffect } from 'react';
// import { Helmet } from 'react-helmet';
import useToggle from '../../../Hooks/useToggle';
import BackToTop from '../../BackToTop';
import Drawer from '../../Mobile/DrawerInstitute';
import Header from '../../Helper/HeaderInstitute';
import Footer from '../../Helper/FooterInstitute';
import CorporateLearningHead from './CorporateLearningHead';
import OurClientsBox from '../OurClientsBox';
import CorporateLearning from './CorporateLearning';
// import InstructorsBox from '../InstructorsBox';
import TrainingBox from '../TrainingBox';
import ApprovalsBox from '../ApprovalsBox';
import TestimonialsBox from '../TestimonialsBox';

// import PopularCoursesBox from '../PopularCoursesBox';
const PopularCoursesBox = React.lazy(() => import('../PopularCoursesBox'));

function CorpoLearn() {
    const [drawer, drawerAction] = useToggle(false);

    useEffect(() => {
        // document.title = 'Corporate Learning';
    }, []);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <CorporateLearningHead />
            <OurClientsBox />
            <CorporateLearning />
            {/* <InstructorsBox className="pb-50" /> */}
            <TrainingBox />
            <ApprovalsBox className="pt-50 pb-30" />
            <TestimonialsBox />
            <Suspense fallback={<div>Loading...</div>}>
                <PopularCoursesBox className="pt-70" />
            </Suspense>
            <Footer />
            <BackToTop />
        </>
    );
}

export default CorpoLearn;
