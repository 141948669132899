import React, { Suspense } from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/DrawerInstitute';
import Header from '../Helper/HeaderInstitute';
import Footer from '../Helper/FooterInstitute';
import CounterArea from './CounterArea';
import CalendarBox from './CalendarBox';
import DateVenueBox from './DateVenueBox';
// import WebinarsBox from './WebinarsBox';
import TrainingBox from './TrainingBox';
// import RecentCourseBox from './RecentCourseBox';
// import InstructorBox from './InstructorBox';
// import PersonalDevelopBox from './PersonalDevelopBox';
// import CareerCounselingBox from './CareerCounselingBox';
// import BigBannerBox from './BigBannerBox';
// import TopUniversitiesBox from './TopUniversitiesBox';
// import DownloadApp from './DownloadApp';
// import MediaPressBox from './MediaPressBox';

// const Sharethis = React.lazy(() => import('../Helper/Sharethis'));

const ApprovalsBox = React.lazy(() => import('./ApprovalsBox'));
const AdvisoryBoard = React.lazy(() => import('./AdvisoryBoard'));
const BannerBox = React.lazy(() => import('./BannerBox'));
const HomeAboutBox = React.lazy(() => import('./HomeAboutBox'));
const SubscribeBox = React.lazy(() => import('./SubscribeBox'));
const UpcomingCoursesBox = React.lazy(() => import('./UpcomingCoursesBox'));
const OurClientsBox = React.lazy(() => import('./OurClientsBox'));
const HomeSlider = React.lazy(() => import('./HomeSlider'));
const CategoryChoose = React.lazy(() => import('./CategoryChoose'));
const HighlyPopular = React.lazy(() => import('./HighlyPopularCourses'));
const TestimonialsBox = React.lazy(() => import('./TestimonialsBox'));

function DocInstitute() {
    const [drawer, drawerAction] = useToggle(false);
    // const [showShare, setshowShare] = useState(false);
    // function closeshowShare() {
    //     setshowShare(false);
    // }
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <Suspense fallback={<div>Loading...</div>}>
                <HomeSlider />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
                <HighlyPopular />
            </Suspense>
            <CounterArea />
            <Suspense fallback={<div>Loading...</div>}>
                <CategoryChoose />
            </Suspense>
            <CalendarBox />
            <div className="m-4">&nbsp;</div>
            <Suspense fallback={<div>Loading...</div>}>
                <ApprovalsBox />
            </Suspense>
            <DateVenueBox />
            {/* <WebinarsBox /> */}
            <TrainingBox />
            <Suspense fallback={<div>Loading...</div>}>
                <AdvisoryBoard className="pb-50" />
            </Suspense>
            {/* <RecentCourseBox /> */}
            <Suspense fallback={<div>Loading...</div>}>
                <BannerBox />
            </Suspense>
            {/* <InstructorBox /> */}
            {/* <PersonalDevelopBox className="pt-0" /> */}
            {/* <CareerCounselingBox /> */}
            <Suspense fallback={<div>Loading...</div>}>
                <UpcomingCoursesBox className="pt-0 pb-0" />
            </Suspense>
            {/* <BigBannerBox /> */}
            {/* <TopUniversitiesBox /> */}
            {/* <DownloadApp /> */}
            <Suspense fallback={<div>Loading...</div>}>
                <HomeAboutBox />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
                <TestimonialsBox />
            </Suspense>
            {/* <MediaPressBox /> */}
            <Suspense fallback={<div>Loading...</div>}>
                <OurClientsBox />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
                <SubscribeBox />
            </Suspense>
            <Footer />
            <BackToTop />
        </>
    );
}

export default DocInstitute;
