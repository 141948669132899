import React from 'react';
import HeaderInstitute from '../Helper/HeaderInstitute';

function Header({ action }) {
    return (
        <>
            <HeaderInstitute action={action} type="instructor" />
        </>
    );
}

export default Header;
