import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
// import Parser from 'html-react-parser';
import { BASEAPIURL, CShowFormatedTime, UPLOADNODEURL } from '../../../config';
import '../../Helper/rypopup.css';
import './FreeLifeTipsNew.css';
// import { FaRegHeart } from "react-icons/fa6";
import { IoIosPlay } from 'react-icons/io';
// import { IoMdHeart } from "react-icons/io";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import CourseListAudioTips from './CourseListAudioTips';

let keyword = '';
let freetipid = '';
let SortBy = '';

function FreeAudioTips2() {
  const [NoTips, SetNoTips] = useState(false);
  const [PlayAudio, SetPlayAudio] = useState('');
  const [LifeTipsCategory, setLifeTipsCategory] = useState([]);
  const [Instructures, setInstructures] = useState([]);
  const [Audios, setAudios] = useState([]);
  const [AllFreeLifeTips, setAllFreeLifeTips] = useState([]);

  // console.log("a : ", PlayAudio);

  const GetFreeLifeTips = async () => {
    // SetNoTips(false);
    try {
      const res = await fetch(`${BASEAPIURL}/admin/freetips/getAll`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          type: 'students',
          keyword,
          category: freetipid,
          sortby: SortBy,
          SortStatus: 1,
          recordsPerPage: 100,
        }),
      });
      const resJson = await res.json();

      if (resJson && resJson.data && resJson.data.length > 0) {
        setAllFreeLifeTips(resJson.data);
        SetNoTips(false);
      } else {
        SetNoTips(true);
        setAllFreeLifeTips([]);
      }

      if (resJson && resJson.instructure && resJson.instructure.length > 0) {
        setInstructures(resJson.instructure);
      }

      if (resJson && resJson.audios && resJson.audios.length > 0) {
        setAudios(resJson.audios);
      }
    } catch (err) {
      alert(err);
    }
  };

  const updateListner = async (index) => {
    try {
      const res = await fetch(`${BASEAPIURL}/admin/freetips/updateListner`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ index }),
      });
      await res.json();
    } catch (err) {
      console.error(err);
    }
  };

  const [isClicked, setIsClicked] = useState(false);
  const [stateIndx, setStateIndx] = useState(-1);

  // const [divSize , setDivSize] = useState({width :90%})
  // const handleSizeChange =() => {

  // }
  const GetFreeLifeTipsCategory = async () => {
    try {
      const res = await fetch(`${BASEAPIURL}/admin/freetipscategory/getAll`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          type: 'students',
          recordsPerPage: 100,
          sortby: 'a2z',
        }),
      });
      const resJson = await res.json();
      if (resJson && resJson.data && resJson.data.length > 0) {
        setLifeTipsCategory(resJson.data);
      }
    } catch (err) {
      alert(err);
    }
  };

  const [RunningTrack, SetRunningTrack] = useState([]);
  function FetchRunningTrack(iptcatid) {
    // console.log("d : ", iptcatid);
    const temp = [];

    if (Audios.length > 0) {
      Audios.map((s) => {
        if (Number(iptcatid) === Number(s.freetipautoip)) {
          temp.push(s);
        }
        return '';
      });
    }
    SetRunningTrack(temp);
  }

  function showInstructorPhoto(tid) {
    let returnValue = '';
    Instructures.map((s, indexI) => {
      if (Number(s.tid) === Number(tid)) {
        returnValue = (
          <div className="rowOfOne">
            <img
              src={`${BASEAPIURL}/admin/instructure/profileimage/${tid}`}
              alt=""
              className="photoId01"
            />
            {/* </Link> */}
          </div>
        );
      }
      return '';
    });
    return returnValue;
  }

  function showInstructorPhoto02(tid) {
    let returnValue = '';
    Instructures.map((s, indexI) => {
      if (Number(s.tid) === Number(tid)) {
        returnValue = (
          <div className="rowOfPLayer">
            <img
              src={`${BASEAPIURL}/admin/instructure/profileimage/${tid}`}
              alt=""
              className="photoId02"
            />
          </div>
        );
      }
      return '';
    });
    return returnValue;
  }

  function showInstructor(tid) {
    let returnValue = '';
    Instructures.map((s, indexI) => {
      if (Number(s.tid) === Number(tid)) {
        returnValue = (
          <div className="r">
            <div className="k">
              {/* <Link to={`/instructor/profile/${s.slug}`} target="_BLANK">
                <img
                  src={`${BASEAPIURL}/admin/instructure/profileimage/${tid}`}
                  alt=""
                  className="pt-2"
                />
              </Link> */}
            </div>
            <div className="k ">
              <Link
                to={`/instructor/profile/${s.slug}`}
                target="_BLANK"
              >
                <div
                  className="text-dark111"
                  // style={
                  //   isClicked
                  //     ? {
                  //         color: stateIndx === indexI ? "white" : "",
                  //       }
                  //     : {}
                  // }
                >
                  Instructor :
                  <b
                    className="text-infomk"
                    style={
                      isClicked
                        ? {
                            color: stateIndx === indexI ? 'white' : '',
                          }
                        : {}
                    }
                  >
                    {`  ${s.name}`}
                  </b>
                </div>
                {/* <span
                  className="te"
                  style={
                    isClicked
                      ? {
                          color: stateIndx === indexI ? "white" : "",
                        }
                      : {}
                  }
                >
                  {s.subTitle}
                </span> */}
              </Link>
            </div>
          </div>
        );
      }
      return '';
    });
    return returnValue;
  }
  // for playing next audio track
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  // const [isPlaying, setisPlaying] = useState(1);
  // const handlePlayPause = (index) => {
  //   if (currentTrackIndex === index) {
  //     const audioPlayer = document.getElementById(`audioPlayer${index}`);
  //     if (audioPlayer.paused) {
  //       audioPlayer.play();
  //       setisPlaying(1);
  //     } else {
  //       audioPlayer.pause();
  //       setisPlaying(0);
  //     }
  //   } else {
  //     if (currentTrackIndex !== null) {
  //       const previousAudio = document.getElementById(
  //         `audioPlayer${currentTrackIndex}`
  //       );
  //       if (previousAudio) {
  //         setisPlaying(0);
  //         previousAudio.pause();
  //       }
  //     }
  //     setCurrentTrackIndex(index);
  //     const audioPlayer = document.getElementById(`audioPlayer${index}`);
  //     if (audioPlayer) {
  //       setisPlaying(1);
  //       audioPlayer.play();
  //     }
  //   }
  // };
  // for playing next audio track
  useEffect(() => {
    keyword = '';
    freetipid = '';
    SortBy = 'Latest';
    GetFreeLifeTipsCategory();
    GetFreeLifeTips();
    // for playing next audio track
    const playNextTrack = () => {
      const nextTrackIndex = (currentTrackIndex + 1) % RunningTrack.length;
      setCurrentTrackIndex(nextTrackIndex);
    };
    console.log('errr:', RunningTrack[currentTrackIndex]);
    const audioPlayer = document.getElementById(
      `audioPlayer${RunningTrack[currentTrackIndex]}`
    );
    if (audioPlayer) {
      audioPlayer.load();
      audioPlayer.play();
      audioPlayer.addEventListener('ended', playNextTrack);
      return () => {
        audioPlayer.removeEventListener('ended', playNextTrack);
      };
    }
  }, []);

  const audioPlayerRef = useRef(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1220);
    };
    window.addEventListener('resize', handleResize);
    handleResize(); // Check initial screen size
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const scrollToAudioPlayer = () => {
    if (ScreenOrientation)
      if (audioPlayerRef.current) {
        audioPlayerRef.current.scrollIntoView({
          behavior: 'smooth',
          inline: 'center',
        });
      }
  };

  const player = useRef();
  const handleCloseButton = () => {
    console.log(' Player', player.current.audio);
    player.current.audio.current.pause();
    // player.current.audio.current.playbackRate()  ;
  };

  return (
    <>
      <section>
        <div className="appie-section-title text-center">
          <h4
            className="mt-4 mb-3 text-left"
            style={{
              borderBottom: '1px solid rgb(122, 54, 148)',
              display: 'inline-block',
              paddingBottom: '5px',
            }}
          >
            Free Audio Tips
          </h4>
        </div>
      </section>
      <section
        className="pt-3 pb-3"
        style={{ background: '#EEF3F9' }}
      >
        {/* <div className="container mb-4"> */}
        <div className="BiggContainer">
          <div className="rowLifeeat">
            {/* play audio option will be here option new */}

            <div className="appppp">
              <form
                id="blog_filter_frm"
                className="row mt-1"
              >
                <div className="col-md-4">
                  <input
                    name="keyword"
                    type="text"
                    className="form-control mb-1"
                    placeholder="Search Anything"
                    value={keyword}
                    onChange={(e) => {
                      keyword = e.target.value;
                      GetFreeLifeTips();
                    }}
                  />
                </div>
                <div className="col-md-4">
                  <select
                    name="SortStatus"
                    value={freetipid}
                    className="form-control mb-1"
                    onChangeCapture={(e) => {
                      freetipid = e.target.value;
                      GetFreeLifeTips();
                    }}
                  >
                    <option value="">Select Category</option>
                    {LifeTipsCategory.length > 0 &&
                      LifeTipsCategory.map((s) => (
                        <option
                          key={s.freetipid}
                          value={s.freetipid}
                        >
                          {s.title}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-4">
                  {isSmallScreen && <div ref={audioPlayerRef}></div>}
                  <select
                    name="sortby"
                    value={SortBy}
                    className="form-control mb-1"
                    onChangeCapture={(e) => {
                      SortBy = e.target.value;
                      GetFreeLifeTips();
                    }}
                  >
                    <option value="0">Sort By</option>
                    <option value="a2z">Title A2Z</option>
                    <option value="z2a">Title Z2A</option>
                    <option value="latest">Latest</option>
                    <option value="old">Oldest</option>
                  </select>
                </div>
              </form>
              <div
                className="row"
                style={{ flexWrap: 'nowrap' }}
              >
                {NoTips ? (
                  <div className="col-12 mb-3">
                    <div className="alert alert-info mt-4 mb-4">
                      There is no Audio Tips found with your search, Try with
                      search from other Parameters.
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className="audiocontentWrapper">
                  <div className={isClicked && `audioPlayercontainer`}>
                    <div
                      className="blackBOX"
                      style={isClicked ? { display: '' } : { display: 'none' }}
                    >
                      {AllFreeLifeTips.length > 0 &&
                        AllFreeLifeTips.map((s) => (
                          <div
                            key={s.freetipautoip}
                            className="boXDIv"
                          >
                            {Number(PlayAudio) === Number(s.freetipautoip) ? (
                              <>
                                {RunningTrack.length > 0 &&
                                  RunningTrack.map((s2, i) => (
                                    <div
                                      className="underBlackBox"
                                      key={s.index}
                                    >
                                      {/* {console.log("s2 length : ", s2.audio)} */}
                                      {/* {console.log("s2 : ", s2)} */}
                                      {Number(s.freetipautoip) ===
                                      Number(s2.freetipautoip) ? (
                                        <div
                                          className="playingContainermp3"
                                          style={{
                                            display:
                                              currentTrackIndex === i
                                                ? ''
                                                : 'none',
                                          }}
                                        >
                                          <div className="audioHeaderDiv">
                                            <b>{s2.title}</b>

                                            <Link
                                              href="#"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setCurrentTrackIndex('NA');
                                                setIsClicked(false);
                                                handleCloseButton();
                                              }}
                                            >
                                              <div className="crossIcon">
                                                <b>x</b>
                                              </div>
                                              {/* <div className="abcdef">
                                                <IoIosCloseCircleOutline />
                                              </div> */}
                                            </Link>
                                          </div>
                                          <div className="audioPlayerPhoto01">
                                            {s.image && s.image !== '' ? (
                                              <div className="rowOfPLayer">
                                                <img
                                                  src={`${UPLOADNODEURL}${s.image}`}
                                                  alt=""
                                                  className="photoId02"
                                                />
                                              </div>
                                            ) : (
                                              <>
                                                {s.createdID
                                                  ? showInstructorPhoto02(
                                                      s.createdID
                                                    )
                                                  : ''}
                                              </>
                                            )}
                                            {/* {showInstructorPhoto02(s.createdID)} */}
                                          </div>
                                          {/* <div style={{ clear: "both" }}></div> */}

                                          {/* <div className="audioPlayTrack"> */}
                                          <AudioPlayer
                                            autoPlay
                                            src={`${UPLOADNODEURL}${s2.audio}`}
                                            onPlay={() => {}}
                                            showFilledProgress={true}
                                            showFilledVolume={true}
                                            ref={player}
                                            style={{
                                              position: 'absolute',
                                              bottom: '0px',
                                              zIndex: '100',
                                              background:
                                                'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgb(1 1 1 / 97%) 100%)',
                                              borderRadius: '15px',
                                              boxShadow: 'none',
                                            }}
                                          />
                                          {/* <audio
                                              onContextMenu={(e) => {
                                                e.preventDefault();
                                              }}
                                              id={`audioPlayer${i}`}
                                              autoPlay={i === 0}
                                              src={`${UPLOADNODEURL}${s2.audio}`}
                                              controls
                                              controlsList="nodownload"
                                              style={{
                                                width: "100%",
                                                
                                              }}
                                              className="custom-audio"
                                              >
                                              <track
                                                kind="captions"
                                                src="/media/examples/friday.vtt"
                                                style={{backgroundColor:'red'}}
                                              />
                                              Your browser does not support the
                                              audio element.
                                            </audio> */}
                                          {/* <button
                                              type="button"
                                              // className="ry"
                                              onClick={() => {
                                                handlePlayPause(i);
                                              }}
                                            >
                                              {isPlaying === 1 ? (
                                                <i className="fa fa-pause"></i>
                                              ) : (
                                                <i className="fa fa-play"></i>
                                              )}
                                            </button> */}
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  ))}
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className={isClicked ? 'forflexingAC' : 'forflexing'}>
                    {AllFreeLifeTips.length > 0 &&
                      AllFreeLifeTips.map((s, indexI) => (
                        <div
                          className="forActualContent"
                          key={s.freetipautoip}
                          style={
                            isClicked
                              ? stateIndx === indexI
                                ? {
                                    background:
                                      'linear-gradient(90deg, rgba(33,54,145,1) 28%, rgba(12,20,54,1) 100%)',

                                    color: 'white',
                                  }
                                : {}
                              : {}
                          }
                        >
                          <div className="mainWrapper">
                            <div className="indxMediaWrapper">
                              <div className="ForNumbersIndexing">
                                {indexI + 1}
                              </div>

                              <div className="forMUltiMedia">
                                <div
                                  className="PlayButtonSsRR"
                                  style={
                                    isClicked
                                      ? {
                                          cursor: 'pointer',
                                          color:
                                            stateIndx === indexI ? 'black' : '',
                                        }
                                      : {
                                          cursor: 'pointer',
                                        }
                                  }
                                  onClick={() => {
                                    setCurrentTrackIndex(0);
                                    SetPlayAudio(s.freetipautoip);
                                    FetchRunningTrack(s.freetipautoip);
                                    setIsClicked(true);
                                    setStateIndx(indexI);
                                    updateListner(s._id);
                                    scrollToAudioPlayer();
                                  }}
                                >
                                  <IoIosPlay />
                                  {/* <i
                                      className="far fa-play"
                                      style={{ paddingLeft: 3 }}
                                    ></i> */}
                                </div>
                                {s.createdID !== '' ? (
                                  <div className="pv">
                                    {s.image && s.image !== '' ? (
                                      <div className="rowOfOne">
                                        <img
                                          src={`${UPLOADNODEURL}${s.image}`}
                                          alt=""
                                          className="photoId01"
                                        />
                                      </div>
                                    ) : (
                                      <>{showInstructorPhoto(s.createdID)}</>
                                    )}
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>

                            <div className="forMainCOntentInfo">
                              <div
                                className="aaaaaa"
                                style={
                                  isClicked
                                    ? {
                                        cursor: 'pointer',
                                        color:
                                          stateIndx === indexI ? 'white' : '',
                                      }
                                    : { cursor: 'pointer' }
                                }
                                onClick={() => {
                                  setCurrentTrackIndex(0);
                                  SetPlayAudio(s.freetipautoip);
                                  FetchRunningTrack(s.freetipautoip);
                                  setIsClicked(true);
                                  updateListner(s._id);
                                  setStateIndx(indexI);
                                  scrollToAudioPlayer();
                                }}
                              >
                                {s.title}
                                {`  -  ${s.description}`}
                              </div>

                              <div>
                                {s.createdID !== '' ? (
                                  <p
                                    style={
                                      isClicked
                                        ? {
                                            color:
                                              stateIndx === indexI
                                                ? 'white'
                                                : 'black',
                                          }
                                        : {}
                                    }
                                  >
                                    {showInstructor(s.createdID)}
                                  </p>
                                ) : (
                                  ''
                                )}
                              </div>

                              <div>{s.listner} Listen</div>
                            </div>

                            <div className="durationLikeWrapper">
                              <div className="durationOfAudio">
                                <p
                                  style={
                                    isClicked
                                      ? {
                                          color:
                                            stateIndx === indexI ? 'white' : '',
                                        }
                                      : {}
                                  }
                                >
                                  {CShowFormatedTime(s.duration)}
                                </p>
                              </div>

                              <div className="forLikeButton">
                                <CourseListAudioTips
                                  s={s}
                                  UPLOADNODEURL={UPLOADNODEURL}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FreeAudioTips2;
