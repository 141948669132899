// import Parser from 'html-react-parser';
import React from 'react';
export const ContactEmailID = 'enquiry@docstainstitute.com';
export const CurrencySybol = 'AED';
export const ContactMobile = '+971-543-229-664';
export const ContactWhatsapp = '+971543229664';
export const GoogleAnylyticsID = '';
export const defaultPreviewVideo =
  'https://www.youtube.com/watch?v=xFBkrBf-o5U';
export function GetIdFromYouTubeUrl(url) {
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
}
const AllMonthsName = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

// const TheDomain = 'https://docsta.com/';
const TheDomain = 'https://stagingreactjsv1.docsta.com/';

// export const PaymentURL = `https://learn.docstainstitute.com/ccavenue/index.php?token=${new Date()}`;
export const PaymentURL = `${TheDomain}ccavenue/index.php?token=${new Date()}`;
export const GiftPaymentURL = `${TheDomain}ccavenue/giftCourse.php?token=${new Date()}`;

export const BASEPHPURL = 'https://www.docstainstitute.com/';
export const IMGBASEAPIURL = `${TheDomain}images/`; // for react frontend files only
// export const UPLOADNODEURLCDN = 'https://ik.imagekit.io/fepy/docsta/'; // for node frontend files only
export const UPLOADNODEURLCDN = 'https://njs.docsta.com/uploads/'; // for node frontend files only
export const UPLOADNODEURL = 'https://njs.docsta.com/uploads/'; // for node frontend files only
// export const UPLOADNODEURL = 'http://njs.docsta.com/uploads/'; // for node frontend files only
export const BASEURL_LOCAL = 'https://learn.docstainstitute.com/';
export const BASEURL_MACHINE = 'http://localhost:3000/';
export const BASEURL_LIVE = TheDomain;

export const APIBASEURL_MACHINE = 'http://localhost:4000/learn';

let BaseURLFinal = BASEURL_LIVE;

let BaseAPIURLFinal = 'https://stagingnodejsv1.docsta.com/learn';
// let BaseAPIURLFinal = 'https://njs.docsta.com/learn';
// let BaseAPIURLFinal = 'http://localhost:3002/learn';

if (window.location.href.includes(BASEURL_MACHINE)) {
  BaseURLFinal = BASEURL_MACHINE;
  // BaseAPIURLFinal = APIBASEURL_MACHINE;
}

export const BASEURL = BaseURLFinal;

export const BASEAPIURL = BaseAPIURLFinal;

export function getCategoryURL(section, catid, parent) {
  let theURL = parent;
  switch (section) {
    case 'exam':
      theURL = `/courses/exam-oriented-courses?category=${catid}`;
      break;
    case 'skill':
      theURL = `/courses/skills-based-courses?category=${catid}`;
      break;
    default:
      theURL = section;
      break;
  }
  return theURL;
}

export function getCourseURL(CourseType, CourseSlug) {
  let theURL = '';
  switch (CourseType) {
    case 'MainCourse':
      theURL = `/course/${CourseSlug}`;
      break;
    case 'questionBank':
      theURL = `/questionBank/${CourseSlug}`;
      break;
    default:
      // theURL = section;
      break;
  }
  return theURL;
}

export function getStdourseURL(CourseType, CourseSlug) {
  let theURL = '';
  switch (CourseType) {
    case 'MainCourse':
      theURL = `/coursestudent/${CourseSlug}`;
      break;
    case 'questionBank':
      theURL = `/courseqBStudent/${CourseSlug}`;
      break;
    default:
      theURL = '/';
      break;
  }
  return theURL;
}

export function getDefaultCountry() {
  let returnValue = '';
  if (localStorage.getItem('country_name') === 'India') {
    returnValue = 'IN';
  }
  if (localStorage.getItem('country_name') === 'United Arab Emirates') {
    returnValue = 'AE';
  }
  return returnValue;
}

export function getCurrency() {
  let returnValue = 'USD';
  if (localStorage.getItem('country_name') === 'India') {
    returnValue = 'INR';
  }
  if (localStorage.getItem('country_name') === 'United Arab Emirates') {
    returnValue = 'AED';
  }
  return returnValue;
}

export function showRatingStar(gotRrating) {
  let returnValue = '';
  let rating = Number(gotRrating);
  rating = rating.toFixed(1);
  if (Number(rating) === 0) {
    returnValue = (
      <>
        <i className="far fa-star" />
        <i className="far fa-star" />
        <i className="far fa-star" />
        <i className="far fa-star" />
        <i className="far fa-star" />
      </>
    );
  }

  if (Number(rating) > 0) {
    returnValue = (
      <>
        <i className="fas fa-star-half-alt" />
        <i className="far fa-star" />
        <i className="far fa-star" />
        <i className="far fa-star" />
        <i className="far fa-star" />
      </>
    );
  }

  if (Number(rating) >= 1) {
    returnValue = (
      <>
        <i className="fas fa-star" />
        <i className="far fa-star" />
        <i className="far fa-star" />
        <i className="far fa-star" />
        <i className="far fa-star" />
      </>
    );
  }

  if (Number(rating) >= 1.5) {
    returnValue = (
      <>
        <i className="fas fa-star" />
        <i className="fas fa-star-half-alt" />
        <i className="far fa-star" />
        <i className="far fa-star" />
        <i className="far fa-star" />
      </>
    );
  }

  if (Number(rating) >= 2) {
    returnValue = (
      <>
        <i className="fas fa-star" />
        <i className="fas fa-star" />
        <i className="far fa-star" />
        <i className="far fa-star" />
        <i className="far fa-star" />
      </>
    );
  }

  if (Number(rating) >= 2.5) {
    returnValue = (
      <>
        <i className="fas fa-star" />
        <i className="fas fa-star" />
        <i className="fas fa-star-half-alt" />
        <i className="far fa-star" />
        <i className="far fa-star" />
      </>
    );
  }

  if (Number(rating) >= 3) {
    returnValue = (
      <>
        <i className="fas fa-star" />
        <i className="fas fa-star" />
        <i className="fas fa-star" />
        <i className="far fa-star" />
        <i className="far fa-star" />
      </>
    );
  }

  if (Number(rating) >= 3.5) {
    returnValue = (
      <>
        <i className="fas fa-star" />
        <i className="fas fa-star" />
        <i className="fas fa-star" />
        <i className="fas fa-star-half-alt" />
        <i className="far fa-star" />
      </>
    );
  }

  if (Number(rating) >= 4) {
    returnValue = (
      <>
        <i className="fas fa-star" />
        <i className="fas fa-star" />
        <i className="fas fa-star" />
        <i className="fas fa-star" />
        <i className="far fa-star" />
      </>
    );
  }

  if (Number(rating) >= 4.5) {
    returnValue = (
      <>
        <i className="fas fa-star" />
        <i className="fas fa-star" />
        <i className="fas fa-star" />
        <i className="fas fa-star" />
        <i className="fas fa-star-half-alt" />
      </>
    );
  }

  if (Number(rating) >= 5) {
    returnValue = (
      <>
        <i className="fas fa-star" />
        <i className="fas fa-star" />
        <i className="fas fa-star" />
        <i className="fas fa-star" />
        <i className="fas fa-star" />
      </>
    );
  }

  const finalValue = (
    <small className="ratingBox">
      <b className="text-dark">{Number(rating) === 0 ? 'N/A' : rating}</b>
      &nbsp;
      {returnValue}
    </small>
  );

  return finalValue;
}
export function ShowPriceDisplay(campaign, cost, costinr) {
  let returnValue = `
                    <h4 class="rateBox text-left btnBox">
                        ${
                          Number(campaign) !== 0 && campaign !== ''
                            ? window.showCurrencyValue(
                                Number(cost) -
                                  (Number(cost) * Number(campaign)) / 100,
                                Number(costinr) -
                                  (Number(costinr) * Number(campaign)) / 100
                              )
                            : window.showCurrencyValue(cost, costinr)
                        }
                    ${
                      Number(campaign) !== 0 && campaign !== ''
                        ? '<span>&nbsp;&nbsp;</span>'
                        : ''
                    }
                        <small style="color: #008300; fontWeight: 700">
                            ${
                              Number(campaign) !== 0 && campaign !== ''
                                ? `${campaign}% Off`
                                : ''
                            }
                        </small>
                        <br /> 
                        <small style=" textDecoration: line-through; color: #0000008a; fontWeight: 700;">
                        ${
                          Number(campaign) !== 0 && campaign !== ''
                            ? window.showCurrencyValue(cost, costinr)
                            : ''
                        }
                        </small>
                    </h4>
                `;
  if (!cost || cost === '') {
    returnValue = '';
  }
  return returnValue;
}

export function ShowPriceDisplayCourseListPageMobile(campaign, cost, costinr) {
  let returnValue = `
                    <small class="rateBox text-center btnBox" style=" display: flex; justify-content: unset; align-items: center; ">
                        <div>
                            ${
                              Number(campaign) !== 0 && campaign !== ''
                                ? window.showCurrencyValue(
                                    Number(cost) -
                                      (Number(cost) * Number(campaign)) / 100,
                                    Number(costinr) -
                                      (Number(costinr) * Number(campaign)) / 100
                                  )
                                : window.showCurrencyValue(cost, costinr)
                            }
                        </div>
                        &nbsp;
                        <div style=" textDecoration: line-through; color: #0000008a; fontWeight: 700; font-size: 77%;">
                        ${
                          Number(campaign) !== 0 && campaign !== ''
                            ? window.showCurrencyValue(cost, costinr)
                            : ''
                        }
                        </div>
                    </small>
                `;
  if (!cost || cost === '') {
    returnValue = '';
  }
  return returnValue;
}

export function ShowPriceDisplayCoursePage(campaign, cost, costinr) {
  let returnValue = `
                    <h4 class="rateBox text-left btnBox" style=" display: flex;
                    justify-content: space-around;
                    align-items: center; ">
                        <div>
                            ${
                              Number(campaign) !== 0 && campaign !== ''
                                ? window.showCurrencyValue(
                                    Number(cost) -
                                      (Number(cost) * Number(campaign)) / 100,
                                    Number(costinr) -
                                      (Number(costinr) * Number(campaign)) / 100
                                  )
                                : window.showCurrencyValue(cost, costinr)
                            }
                        </div>
                        &nbsp;
                        <small style=" textDecoration: line-through; color: #0000008a; fontWeight: 700; font-size: 70%;">
                        ${
                          Number(campaign) !== 0 && campaign !== ''
                            ? window.showCurrencyValue(cost, costinr)
                            : ''
                        }
                        </small>
                        &nbsp;
                        <small style="color: #008300; font-weight: 700; font-size: 48%;">
                            ${
                              Number(campaign) !== 0 && campaign !== ''
                                ? `${campaign}% Off`
                                : ''
                            }
                        </small>
                    </h4>
                `;
  if (!cost || cost === '') {
    returnValue = '';
  }
  return returnValue;
}

export function periodCheckBox(selectedId, id) {
  let checkbox = ` <div className=${
    id == selectedId ? 'selectedPeriodRadio' : 'periodRadio'
  }>
        <svg
          style={{
            fill: '#ffffff', // Color of the tick icon
            width: '20px', // Width of the icon
            height: '20px', // Height of the icon
          }}
          viewBox="0 0 24 24"
        >
          <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
        </svg>
      </div>`;
  return checkbox;
}
export function ShowPeriodPriceDisplayCoursePage(campaign, cost, costinr) {
  let returnValue = `
  <span className="text-center">
                    <h4 class="rateBox text-left btnBox" style=" display: flex;
                    justify-content: space-around;
                    align-items: center; ">
                        <div>
                            ${
                              Number(campaign) !== 0 && campaign !== ''
                                ? window.showCurrencyValue(
                                    Number(cost) -
                                      (Number(cost) * Number(campaign)) / 100,
                                    Number(costinr) -
                                      (Number(costinr) * Number(campaign)) / 100
                                  )
                                : window.showCurrencyValue(cost, costinr)
                            }
                        </div>
                        &nbsp;
                        </h4>
                        <small style=" textDecoration: line-through; color: #0000008a; fontWeight: 700; font-size: 50%;">
                        ${
                          Number(campaign) !== 0 && campaign !== ''
                            ? window.showCurrencyValue(cost, costinr)
                            : ''
                        }
                        </small>
                        </span>
                `;
  if (!cost || cost === '') {
    returnValue = '';
  }
  // <>
  //   <h4 class="rateBox text-left btnBox d-flex justify-content-around align-items-center">
  //     <div>
  //       $
  //       {Number(campaign) !== 0 && campaign !== ''
  //         ? window.showCurrencyValue(
  //             Number(cost) - (Number(cost) * Number(campaign)) / 100,
  //             Number(costinr) - (Number(costinr) * Number(campaign)) / 100
  //           )
  //         : window.showCurrencyValue(cost, costinr)}
  //     </div>
  //     &nbsp;
  //     <small style=" textDecoration: line-through; color: #0000008a; fontWeight: 700; font-size: 70%;">
  //       $
  //       {Number(campaign) !== 0 && campaign !== ''
  //         ? window.showCurrencyValue(cost, costinr)
  //         : ''}
  //     </small>
  //     &nbsp;
  //     <small style="color: #008300; font-weight: 700; font-size: 48%;">
  //       ${Number(campaign) !== 0 && campaign !== '' ? `${campaign}% Off` : ''}
  //     </small>
  //   </h4>
  // </>;
  return returnValue;
}

export function ShowPriceDisplayCoursePageMobile(campaign, cost, costinr) {
  let returnValue = `
                    <h4 class="rateBox text-left btnBox" style=" line-height: 17px; padding-top: 5px; ">
                        <div style=" margin-top: 10px; text-align: center; ">
                            ${
                              Number(campaign) !== 0 && campaign !== ''
                                ? window.showCurrencyValue(
                                    Number(cost) -
                                      (Number(cost) * Number(campaign)) / 100,
                                    Number(costinr) -
                                      (Number(costinr) * Number(campaign)) / 100
                                  )
                                : window.showCurrencyValue(cost, costinr)
                            }
                        </div>
                        <small style=" display: block; text-align: center; text-decoration: line-through; color: #0000008a; fontWeight: 700; font-size: 48%;">
                        ${
                          Number(campaign) !== 0 && campaign !== ''
                            ? window.showCurrencyValue(cost, costinr)
                            : ''
                        }
                        </small>
                    </h4>
                `;
  if (!cost || cost === '') {
    returnValue = '';
  }
  return returnValue;
}

// function getDiscountedRate(cost, campaign) {
//     return Number(cost) - (Number(cost) * Number(campaign)) / 100;
// }

export function ShowPriceDisplayCart(DiscountedValue, FullValue) {
  // localStorage.setItem('FVCampaign', campaign);
  // localStorage.setItem('FVCost', cost);
  // localStorage.setItem('FVCostinr', costinr);
  let returnValue = `
                    <div class="rateBox text-left btnBox">
                        <h4>Summary</h4>
                        <div class="row mt-3">
                            <div class="col-6">Original Price</div>
                            <div class="col-6 text-right">
                                ${window.showCurrencyValue(
                                  FullValue,
                                  FullValue
                                )}
                            </div>
                        </div><hr />
                        ${
                          Number(DiscountedValue) !== Number(FullValue)
                            ? `<div className="row mt-3">
                        <div className="col-6">Discounted Amount</div>
                        <div className="col-6 text-right">
                            ${window.showCurrencyValue(
                              FullValue - DiscountedValue,
                              FullValue - DiscountedValue
                            )}
                        </div>
                    </div><hr />
                    <h5 class="row mt-3">
                            <div class="col-6">Total</div>
                            <div class="col-6 text-right">
                                ${window.showCurrencyValue(
                                  DiscountedValue,
                                  DiscountedValue
                                )}
                            </div>
                        </h5>
                    `
                            : `<h5 class="row mt-3">
                                <div class="col-6">Total</div>
                                <div class="col-6 text-right">
                                    ${window.showCurrencyValue(
                                      DiscountedValue,
                                      DiscountedValue
                                    )}
                                </div>
                            </h5>`
                        }
                        
                    </div>
                `;
  if (!DiscountedValue || DiscountedValue === '') {
    returnValue = '';
  }
  return returnValue;
}

export function ShowPriceDisplaySmall(campaign, cost, costinr) {
  let returnValue = `
                    <h6 class="text-left">
                        ${
                          Number(campaign) !== 0 && campaign !== ''
                            ? window.showCurrencyValue(
                                Number(cost) -
                                  (Number(cost) * Number(campaign)) / 100,
                                Number(costinr) -
                                  (Number(costinr) * Number(campaign)) / 100
                              )
                            : window.showCurrencyValue(cost, costinr)
                        }
                        <small style="color: #008300; fontWeight: 700">
                            ${
                              Number(campaign) !== 0 && campaign !== ''
                                ? `${campaign}% Off`
                                : ''
                            }
                        </small>
                        <br /> 
                        ${
                          Number(campaign) !== 0 && campaign !== ''
                            ? `<small style=" textDecoration: line-through; color: #0000008a; fontWeight: 700;">${window.showCurrencyValue(
                                cost,
                                costinr
                              )}</small>`
                            : '&nbsp;'
                        }
                    </h6>
                `;
  if (cost === '') {
    returnValue = '';
  }
  return returnValue;
}

export function ShowPriceDisplayWhite(campaign, cost, costinr) {
  let returnValue = `
                    <h6 class="text-left PriceDivCont" style="line-height: 12px;">
                        ${
                          Number(campaign) !== 0 && campaign !== ''
                            ? window.showCurrencyValue(
                                Number(cost) -
                                  (Number(cost) * Number(campaign)) / 100,
                                Number(costinr) -
                                  (Number(costinr) * Number(campaign)) / 100
                              )
                            : window.showCurrencyValue(cost, costinr)
                        }
                        <small style="color: #008300; fontWeight: 700">
                            ${
                              Number(campaign) !== 0 && campaign !== ''
                                ? `${campaign}% Off`
                                : ''
                            }
                        </small>
                        <br />
                        <small style="text-decoration: line-through; color: #fff; fontWeight: 700; display: block !important">
                        ${
                          Number(campaign) !== 0 && campaign !== ''
                            ? window.showCurrencyValue(cost, costinr)
                            : ''
                        }
                        </small>
                    </h6>
                `;
  if (!cost || cost === '') {
    returnValue = '';
  }
  return returnValue;
}

export function CoursesMainCategory() {
  return [
    {
      id: 'exam',
      title: 'Exam-Oriented Courses',
      image: `${IMGBASEAPIURL}homePage/exam.png`,
      slug: '/exam-courses',
      slug2: '/courses/exam-oriented-courses',
    },
    {
      id: 'skill',
      title: 'Skill Upgrading Courses',
      image: `${IMGBASEAPIURL}homePage/skill.png`,
      slug: '/skill-courses',
      slug2: '/courses/skills-based-courses',
    },
    // {
    //     id: 'university',
    //     title: 'University Accredited Courses',
    //     image: `${IMGBASEAPIURL}homePage/qb.png`,
    //     slug: '/courses/university-accredited-courses',
    // },
    {
      id: 'qb',
      title: 'Question Bank',
      image: `${IMGBASEAPIURL}homePage/qb.png`,
      slug: '/question-bank',
    },
  ];
}

export function setCookie(name, value, days) {
  let expires = '';
  let temp = '';
  const date = new Date();
  if (days) {
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    temp = date.toUTCString();
    expires = `${temp}`;
  }
  // document.cookie = name + '=' + (value || '') + expires + '; path=/';
  // console.log(`${name}=${value || ''}; expires=${expires}; path=/;`);
  document.cookie = `${name}=${value || ''}; expires=${expires}; path=/;`;
}
export function getCookie(name) {
  const nameEQ = `${name}=`;
  let i = 0;
  let c = 0;
  const ca = document.cookie.split(';');
  for (i = 0; i < ca.length; i = +1) {
    c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
export function RemoveCookie(name) {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}
export function logoutaLL() {
  localStorage.removeItem('username');
  localStorage.removeItem('usertype');
  localStorage.removeItem('token');
  RemoveCookie('rytoken');
  window.location.href = BASEURL;
}
export function showdatetimein(datetime) {
  const tmp = new Date(datetime);
  return `${tmp.getDate()} ${
    AllMonthsName[tmp.getMonth()]
  }, ${tmp.getUTCFullYear()}`;
}
function gettimeinampm(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours %= 12;
  if (hours === 0) {
    hours = 12;
  }
  // hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  // const strTime = hours + ':' + minutes + ' ' + ampm;
  return `${hours}:${minutes}${ampm}`;
}
// export function gettimein24hour(timestamp) {
//     const date = new Date(Number(timestamp));
//     let hours = date.getHours();
//     let minutes = date.getMinutes();
//     minutes = minutes < 10 ? `0${minutes}` : minutes;
//     return `${hours}:${minutes}`;
// }
export function showfulldatetime(datetime) {
  const tmp = new Date(datetime);
  // alert(tmp);
  return `${tmp.getDate()} ${
    AllMonthsName[tmp.getMonth()]
  },${tmp.getUTCFullYear()} ${gettimeinampm(tmp)}`;
}

export function showLiveClassDateTime(timestamp) {
  const tmp = new Date(Number(timestamp));
  const formattedDate = new Intl.DateTimeFormat('en-GB', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  }).format(new Date(Number(timestamp)));
  return `${formattedDate} ${gettimeinampm(tmp)}`;
}

export function showLiveClassDateTime2(timestamp) {
  const tmp = new Date(Number(timestamp));
  const formattedDate = new Intl.DateTimeFormat('en-GB', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  }).format(new Date(Number(timestamp)));
  return (
    <span>
      {formattedDate} &nbsp;&nbsp; {gettimeinampm(tmp)}
    </span>
  );
}

export function showDateFormat(datetime) {
  const tmp = new Date(datetime);
  // alert(tmp);
  return `${tmp.getDate()} ${
    AllMonthsName[tmp.getMonth()]
  }, ${tmp.getUTCFullYear()}`;
}

export function showTimeFormat(theTime) {
  // Check correct time format and split into components
  let time = theTime
    .toString()
    .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [theTime];
  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(''); // return adjusted time or original string
}

export function CleanUrlWork(string) {
  return string.toLowerCase().replace(/[^0-9A-Z]+/gi, '_');
}
export function ShowValueInFormat(value) {
  return value.toLocaleString();
}

export function ShowNumberWithComma(value) {
  return value.toLocaleString();
}

export function DisplayNoneIfZero(value) {
  return Number(value) === 0 || value === '' ? 'none' : '';
}
export function LogOutAll() {
  localStorage.setItem('loggedToken', 0);
  localStorage.setItem('usertype', '');
  localStorage.setItem('userdata', '');
  setCookie('loggedToken', 0, 1);
  setCookie('usertype', 0, 1);
  setCookie('userdata', '', 1);
  window.location.href = '/';
}
export function CShowFormatedTime(value) {
  let seconds = Math.floor(value);
  let showValue = 0;
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  minutes -= hours * 60;
  seconds = seconds - hours * 60 * 60 - minutes * 60;

  hours = Number(hours).toFixed(0);
  minutes = Number(minutes).toFixed(0);
  seconds = Number(seconds).toFixed(0);
  if (Number(hours) !== 0) {
    showValue = `${hours < 10 ? `0${hours}` : hours}:${
      minutes < 10 ? `0${minutes}` : minutes
    }:${seconds < 10 ? `0${seconds}` : seconds}`;
  } else {
    showValue = `${minutes < 10 ? `0${minutes}` : minutes}:${
      seconds < 10 ? `0${seconds}` : seconds
    }`;
  }
  if (value !== '' && value !== '0' && value !== 'NA' && value !== 0) {
    // showValue = showValue;
  } else {
    showValue = 'N/A';
  }
  return showValue;
}

export const AllLanguages = [
  'Afrikaans',
  'Albanian',
  'Arabic',
  'Armenian',
  'Basque',
  'Bengali',
  'Bulgarian',
  'Catalan',
  'Cambodian',
  'Chinese (Mandarin)',
  'Croatian',
  'Czech',
  'Danish',
  'Dutch',
  'English',
  'Estonian',
  'Fiji',
  'Finnish',
  'French',
  'Georgian',
  'German',
  'Greek',
  'Gujarati',
  'Hebrew',
  'Hindi',
  'Hungarian',
  'Icelandic',
  'Indonesian',
  'Irish',
  'Italian',
  'Japanese',
  'Javanese',
  'Korean',
  'Latin',
  'Latvian',
  'Lithuanian',
  'Macedonian',
  'Malay',
  'Malayalam',
  'Maltese',
  'Maori',
  'Marathi',
  'Mongolian',
  'Nepali',
  'Norwegian',
  'Persian',
  'Polish',
  'Portuguese',
  'Punjabi',
  'Quechua',
  'Romanian',
  'Russian',
  'Samoan',
  'Serbian',
  'Slovak',
  'Slovenian',
  'Spanish',
  'Swahili',
  'Swedish',
  'Tamil',
  'Tatar',
  'Telugu',
  'Thai',
  'Tibetan',
  'Tonga',
  'Turkish',
  'Ukrainian',
  'Urdu',
  'Uzbek',
  'Vietnamese',
  'Welsh',
  'Xhosa',
];

export function secondsToHoursMinutesSeconds(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const formattedTime = [];

  if (hours > 0) {
    formattedTime.push(`${hours} Hour${hours !== 1 ? 's' : ''}`);
  }

  if (minutes > 0) {
    formattedTime.push(`${minutes} Minute${minutes !== 1 ? 's' : ''}`);
  }

  return formattedTime.join(' and ');
}

export function LoginRedirect() {
  const RedirectAfterLogin = localStorage.getItem('RedirectAfterLogin');
  if (
    RedirectAfterLogin &&
    RedirectAfterLogin !== null &&
    RedirectAfterLogin !== ''
  ) {
    localStorage.removeItem('RedirectAfterLogin');
    localStorage.removeItem('MsgBeforeLogin');
    window.location.href = RedirectAfterLogin;
  } else {
    window.location.href = BASEURL;
  }
}

export function isWebinarLive(startTime, Duration) {
  let returnValue = false;
  const currentTimestamp = new Date().getTime();
  if (
    currentTimestamp - startTime < Number(Duration) * 60 * 1000 &&
    currentTimestamp - startTime > 0
  ) {
    returnValue = true;
  }
  return returnValue;
}
