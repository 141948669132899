import instructorData from './reduxFunction/instructorSlice'
import commonData from './reduxFunction/commonSlice'
import CourseCategory from './reduxFunction/CourseCategorySlice'
import Courses from './reduxFunction/CoursesSlice'

import { configureStore } from '@reduxjs/toolkit'

export default configureStore({
    reducer: {
        instructorData,
        commonData,
        CourseCategory,
        Courses,
    },
})
