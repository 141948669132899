import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
// import Parser from 'html-react-parser';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import useToggle from '../../../../Hooks/useToggle';
import BackToTop from '../../../BackToTop';
import Footer from '../../../Helper/FooterInstitute';
import Drawer from '../../../Mobile/DrawerInstitute';
// import Header from '../Helper/Header';
import HeaderInstructor from '../../InstructorIndex_Insti';
import { IMGBASEAPIURL, BASEAPIURL } from '../../../../config';
import {
    RegFrmRateVidMkInstr,
    RegFrmExiAudiInstr,
    RegFrmLangInstr,
    RegFrmTeachDoneInstr,
    RegFrmValues2StdInstr,
    RegFrmCrsComInstr,
    RegFrmDocstaHelpInstr,
} from '../../../../masterdata';
import '../../instructor.css';

const serialize = require('form-serialize');

function InstructorRegister() {
    // const [file, setFile] = useState();
    // const [fileName, setFileName] = useState('');
    const [step1, setStep1] = useState('active');
    const [step2, setStep2] = useState('');
    const [step3, setStep3] = useState('');
    const [drawer, drawerAction] = useToggle(false);
    const [PerDetDiv, setPerDetDiv] = useState('block');
    const [AddiDet1Div, setAddiDet1Div] = useState('None');
    const [AddiDet2Div, setAddiDet2Div] = useState('None');
    const [ConfirmationDiv, setConfirmationDiv] = useState('None');
    const [PhoneNumber, setPhoneNumber] = useState('');
    const [InstrName, setInstrName] = useState('');
    const [InstrEmail, setInstrEmail] = useState('');
    const [CVfile, setCVfile] = useState();
    const [Msgcolor, setMsgcolor] = useState();
    // const [Email, setEmail] = useState();
    const [APIMessage, setMessage] = useState();
    const [NameErrorHere, setNameErrorHere] = useState('');
    const [MobileAready, setMobileAready] = useState('');
    const [EmailAready, setEmailAready] = useState('');
    const [classforform, setclassforform] = useState('col-md-7');

    const form = document.querySelector('#Inst-Regi-Form');
    const obj = serialize(form, { hash: true, empty: true });

    function ShowFormDiv(type) {
        let AllOkay = 1;

        if (MobileAready.trim() !== '') {
            AllOkay = 0;
        }
        if (EmailAready.trim() !== '') {
            AllOkay = 0;
        }
        if (InstrName.trim() === '') {
            setMsgcolor('red');
            setNameErrorHere('Name is Required.');
            AllOkay = 0;
        }
        if (PhoneNumber === '') {
            AllOkay = 0;
            setMsgcolor('red');
            setMobileAready('Phone Number is Required.');
        }
        if (InstrEmail.trim() === '') {
            AllOkay = 0;
            setMsgcolor('red');
            setEmailAready('Email is Required.');
        }
        if (/\S+@\S+\.\S+/.test(InstrEmail)) {
            console.log('Valid Email');
        } else {
            AllOkay = 0;
            setMsgcolor('red');
            setEmailAready('Please enter an valid Email.');
        }
        if (AllOkay === 1) {
            setPerDetDiv('none');
            setAddiDet1Div('none');
            setAddiDet2Div('none');
            setConfirmationDiv('none');
            setclassforform('col-md-7');
            setStep1(' ');
            setStep2(' ');
            setStep3(' ');
            switch (type) {
                case 'addi2':
                    setStep2('active');
                    setAddiDet2Div('block');
                    break;
                case 'addi1':
                    setStep2('active');
                    setAddiDet1Div('block');
                    break;
                case 'confirm':
                    setStep3('active');
                    setclassforform('col-md-12 text-center');
                    setConfirmationDiv('block');
                    break;
                default:
                    setStep1('active');
                    setPerDetDiv('block');
                    break;
            }
        }
    }

    // for file uploade will be here
    async function uploadFile(file, fileName) {
        // e.preventDefault();
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', fileName);
        const url = `${BASEAPIURL}/admin/instructure/uploadcv`;
        // const url = 'https://ktuniexpo.com/women-directors/test.php';
        try {
            const res = await axios.post(url, formData);
            console.log(res);
            setCVfile(res.data.filename);
        } catch (ex) {
            console.log(ex);
        }
    }

    const saveFile = (e) => {
        // setFile(e.target.files[0]);
        // setFileName(e.target.files[0].name);
        setTimeout(() => {
            uploadFile(e.target.files[0], e.target.files[0].name);
        }, 500);
    };
    // for file uploade will be here

    async function checkEmailAready(emailid) {
        let returntype = false;
        try {
            // console.log(BASEAPIURL);
            const url = `${BASEAPIURL}/admin/instructure/checkifalreadyemail`;
            const formData = new FormData();
            // formData.append(index, value); // console.log(index); console.log(obj[index]);
            formData.append('emailid', emailid);
            const config = {
                headers: {
                    'content-type': 'application/json', // multipart/form-data
                },
            };
            axios.post(url, formData, config).then((response) => {
                // const response = resJson.json();
                console.log(response.data.message);
                console.log(response.data);
                if (response.data.status === 1) {
                    setMsgcolor('red');
                    setEmailAready(response.data.message.toString());
                    ShowFormDiv('personal');
                    returntype = true;
                } else {
                    setEmailAready('');
                }
            });
        } catch (err) {
            setMsgcolor('red');
            console.log(err);
            setEmailAready('Something went wrong, Please try again later.');
        }
        return returntype;
    }

    async function checkMobileAready(mobileno) {
        let returntype = false;
        try {
            // console.log(BASEAPIURL);
            const url = `${BASEAPIURL}/admin/instructure/checkifalreadymobile`;
            const formData = new FormData();
            // formData.append(index, value); // console.log(index); console.log(obj[index]);
            formData.append('mobileno', mobileno);
            const config = {
                headers: {
                    'content-type': 'application/json', // multipart/form-data
                },
            };
            axios.post(url, formData, config).then((response) => {
                // const response = resJson.json();
                console.log(response.data.message);
                console.log(response.data);
                if (response.data.status === 1) {
                    setMsgcolor('red');
                    setMobileAready(response.data.message.toString());
                    ShowFormDiv('personal');
                    returntype = true;
                } else {
                    setMobileAready('');
                }
            });
        } catch (err) {
            setMsgcolor('red');
            console.log(err);
            setMobileAready('Something went wrong, Please try again later.');
        }
        return returntype;
    }

    async function RegistrerInstructor() {
        try {
            setMsgcolor('green');
            const url = `${BASEAPIURL}/admin/instructure/signup`;
            // const url = 'https://ktuniexpo.com/women-directors/test.php';
            const formData = new FormData();
            formData.append('edit', 0);
            // get all the data and add there
            formData.append('CVfile', CVfile);
            // Object.keys(obj).map(function(key) {
            Object.keys(obj).map((index) => {
                // formData.append(index, value); // console.log(index); console.log(obj[index]);
                formData.append(index, obj[index]);
                return index;
            });
            const config = {
                headers: {
                    'content-type': 'application/json', // multipart/form-data
                },
            };
            axios.post(url, formData, config).then((response) => {
                console.log(response);
                setMessage(response.data.message);
            });
        } catch (err) {
            setMsgcolor('red');
            console.log(err);
            setMessage('Something went wrong, Please try again later.');
        }
    }

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderInstructor drawer={drawer} action={drawerAction.toggle} />
            <>
                <div className="container pt-4 pb-4">
                    <div
                        className="p-3 text-center"
                        style={{
                            background: `url(${IMGBASEAPIURL}instructor/headerbg.jpg)`,
                            backgroundRepeat: 'repeat',
                            backgroundSize: '100%',
                        }}
                    >
                        <h2 className="m-3 text-white">Become an Instructor</h2>
                    </div>
                    <div className="row">
                        <div className="col-md-1"></div>

                        <div className="col-md-10">
                            <div className="mt-4 mb-4 ry_instr_shadow">
                                <div className="instr_signup_head d-none d-md-block">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <h2 className={`instr_signup_head_step ${step1}`}>1</h2>
                                        </div>
                                        <div className="col-md-4">
                                            <h2 className={`instr_signup_head_step ${step2}`}>2</h2>
                                        </div>
                                        <div className="col-md-4">
                                            <h2 className={`instr_signup_head_step ${step3}`}>3</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={classforform}>
                                        <form
                                            id="Inst-Regi-Form"
                                            encType="multipart/form-data"
                                            className="m-4 p-4"
                                            style={{ display: 'block' }}
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                RegistrerInstructor();
                                            }}
                                        >
                                            <div className="For_Form_title"></div>

                                            <div style={{ display: ConfirmationDiv }}>
                                                <img
                                                    src={`${IMGBASEAPIURL}instructor/inst_reg_done.jpg`}
                                                    alt="Docsta Learn Insinstructor"
                                                    style={{ maxWidth: '100%' }}
                                                    className="mt-1 mb-4"
                                                />
                                                <h3 className="mt-3">
                                                    Your request has been submitted successfully.
                                                </h3>
                                                <div
                                                    className="message mt-3"
                                                    style={{ color: Msgcolor }}
                                                >
                                                    {APIMessage ? <span>{APIMessage}</span> : ''}
                                                </div>
                                            </div>

                                            <div style={{ display: PerDetDiv }}>
                                                <h4 className="mb-2">Personal Details</h4>
                                                <div className="form-group">
                                                    <label className="ry_label">Name</label>
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        className="form-control"
                                                        placeholder="Enter Your Full Name"
                                                        onKeyUp={(e) => {
                                                            setNameErrorHere('');
                                                            setInstrName(e.target.value);
                                                        }}
                                                        onClick={(e) => {
                                                            setNameErrorHere('');
                                                            setInstrName(e.target.value);
                                                        }}
                                                    />
                                                    <div
                                                        className="message"
                                                        style={{ color: Msgcolor }}
                                                    >
                                                        {NameErrorHere ? (
                                                            <span>{NameErrorHere}</span>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="ry_label">
                                                        Course Category
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="coursecati"
                                                        className="form-control"
                                                        placeholder="Enter which category is your course in"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="ry_label">
                                                        Upload Document
                                                    </label>
                                                    <input
                                                        type="file"
                                                        name="cvfile"
                                                        className="form-control"
                                                        placeholder="Enter Your Full Name here"
                                                        onChange={(e) => {
                                                            saveFile(e);
                                                        }}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="ry_label">Youtube Link</label>
                                                    <input
                                                        type="text"
                                                        name="youtube"
                                                        className="form-control"
                                                        placeholder="Enter Youtube Link"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="ry_label">
                                                        Linkedin Link
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="linkedin"
                                                        className="form-control"
                                                        placeholder="Enter Linkedin Link"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="ry_label">Phone Number</label>
                                                    <PhoneInput
                                                        international
                                                        className="form-control"
                                                        name="phonenumber"
                                                        value={PhoneNumber}
                                                        onChange={setPhoneNumber}
                                                        onKeyUpCapture={(e) => {
                                                            checkMobileAready(e.target.value);
                                                        }}
                                                        defaultCountry="AE"
                                                        style={{ position: 'relative' }}
                                                    />
                                                    <div
                                                        className="message"
                                                        style={{ color: Msgcolor }}
                                                    >
                                                        {MobileAready ? (
                                                            <span>{MobileAready}</span>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="ry_label">Email ID</label>
                                                    <input
                                                        type="email"
                                                        name="emailid"
                                                        className="form-control"
                                                        placeholder="Enter Email ID"
                                                        onChange={(e) =>
                                                            setInstrEmail(e.target.value)
                                                        }
                                                        onKeyUpCapture={(e) => {
                                                            checkEmailAready(e.target.value);
                                                        }}
                                                    />
                                                    <div
                                                        className="message"
                                                        style={{ color: Msgcolor }}
                                                    >
                                                        {EmailAready ? (
                                                            <span>{EmailAready}</span>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="btn btn-docsta-instructor"
                                                    onClick={() => {
                                                        ShowFormDiv('addi1');
                                                    }}
                                                >
                                                    Proceed
                                                </button>
                                            </div>

                                            <div style={{ display: AddiDet1Div }}>
                                                <h4 className="mb-2">Additional Details</h4>
                                                <div className="form-group">
                                                    <label className="ry_label">
                                                        1. What Language Is Your Course In?
                                                    </label>
                                                    <select
                                                        name="language"
                                                        className="form-control"
                                                    >
                                                        {RegFrmLangInstr.map((singi) => (
                                                            <option
                                                                value={Object.keys(singi)[0]}
                                                                key={Object.keys(singi)[0]}
                                                            >
                                                                {Object.values(singi)[0]}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label className="ry_label">
                                                        2. Which Form Of Teaching Have You Done
                                                        Before?
                                                    </label>
                                                    <select
                                                        name="teachdone"
                                                        className="form-control"
                                                    >
                                                        {RegFrmTeachDoneInstr.map((singi) => (
                                                            <option
                                                                value={Object.keys(singi)[0]}
                                                                key={Object.keys(singi)[0]}
                                                            >
                                                                {Object.values(singi)[0]}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label className="ry_label">
                                                        3. Where Do You Rate Yourself Regarding Your
                                                        Video-Making Skills?
                                                    </label>
                                                    <select
                                                        name="vidrating"
                                                        className="form-control"
                                                    >
                                                        {RegFrmRateVidMkInstr.map((singi) => (
                                                            <option
                                                                value={Object.keys(singi)[0]}
                                                                key={Object.keys(singi)[0]}
                                                            >
                                                                {Object.values(singi)[0]}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label className="ry_label">
                                                        4. Do You Have An Existing Audience Or
                                                        Students To Share Your Course With?
                                                    </label>
                                                    <select name="exiaudi" className="form-control">
                                                        {RegFrmExiAudiInstr.map((singi) => (
                                                            <option
                                                                value={Object.keys(singi)[0]}
                                                                key={Object.keys(singi)[0]}
                                                            >
                                                                {Object.values(singi)[0]}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label className="ry_label">
                                                        5. What Set Of Values Are You Hoping To Add
                                                        To Your Ideal Student's Life?
                                                    </label>
                                                    <select name="val2std" className="form-control">
                                                        {RegFrmValues2StdInstr.map((singi) => (
                                                            <option
                                                                value={Object.keys(singi)[0]}
                                                                key={Object.keys(singi)[0]}
                                                            >
                                                                {Object.values(singi)[0]}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="btn btn-docsta-instructor"
                                                    onClick={() => {
                                                        ShowFormDiv('addi2');
                                                    }}
                                                >
                                                    Proceed
                                                </button>
                                            </div>

                                            <div style={{ display: AddiDet2Div }}>
                                                <h4 className="mb-2">Additional Details</h4>
                                                <div className="form-group">
                                                    <label className="ry_label">
                                                        6. What Would You Like to Name Your Course?
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="namecourse"
                                                        className="form-control"
                                                        placeholder="Type Here/  Eg: Data Analyst, Learn Photoshow Etc."
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="ry_label">
                                                        7. Who are Your Target Students?
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="tarstd"
                                                        className="form-control"
                                                        placeholder="Type Here/  Eg: School Students, College Students Etc."
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="ry_label">
                                                        8. Are There Any Requirements or
                                                        Pre-Requisities For Taking Your Course?
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="prereq"
                                                        className="form-control"
                                                        placeholder="No/ If Yes Type Here"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="ry_label">
                                                        9. What Would You Like Our Help With?
                                                    </label>
                                                    <select
                                                        name="docstahelp"
                                                        className="form-control"
                                                    >
                                                        {RegFrmDocstaHelpInstr.map((singi) => (
                                                            <option
                                                                value={Object.keys(singi)[0]}
                                                                key={Object.keys(singi)[0]}
                                                            >
                                                                {Object.values(singi)[0]}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label className="ry_label">
                                                        10. How Much Of Your Course Is Currently
                                                        Ready?
                                                    </label>
                                                    <select
                                                        name="crsready"
                                                        className="form-control"
                                                    >
                                                        {RegFrmCrsComInstr.map((singi) => (
                                                            <option
                                                                value={Object.keys(singi)[0]}
                                                                key={Object.keys(singi)[0]}
                                                            >
                                                                {Object.values(singi)[0]}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <button
                                                    type="submit"
                                                    className="btn btn-docsta-instructor"
                                                    onClick={() => {
                                                        ShowFormDiv('confirm');
                                                    }}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-md-5 p-4 hideonmobile text-center">
                                        <img
                                            src={`${IMGBASEAPIURL}instructor/InstrSignStep01.png`}
                                            alt="Docsta Learn Insinstructor Login"
                                            style={{ display: PerDetDiv }}
                                            className="step_1_img"
                                        />
                                        <img
                                            src={`${IMGBASEAPIURL}instructor/InstrSignStep02.png`}
                                            alt="Docsta Learn Insinstructor Login"
                                            style={{ display: AddiDet1Div }}
                                            className="step_2_img"
                                        />
                                        <img
                                            src={`${IMGBASEAPIURL}instructor/InstrSignStep03.png`}
                                            alt="Docsta Learn Insinstructor Login"
                                            style={{ display: AddiDet2Div }}
                                            className="step_3_img"
                                        />
                                    </div>
                                </div>
                                <p className="text-center mt-1 d-none">
                                    Already have an Account ?
                                    <br />
                                    <Link to="/instructor/login" className="mb-4  mt-3">
                                        <button type="submit" className="btn btn-info">
                                            Login as Instructor
                                        </button>
                                    </Link>
                                </p>
                            </div>
                        </div>

                        <div className="col-md-1"></div>
                    </div>
                </div>
            </>
            <Footer className="appie-footer-about-area" />
            <BackToTop />
        </>
    );
}

export default InstructorRegister;
