import React, { useState, Suspense } from 'react';
// import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';
import axios from 'axios';
import PhoneInput from 'react-phone-number-input';
// import Parser from 'html-react-parser';
import useToggle from '../../../Hooks/useToggle';
import BackToTop from '../../BackToTop';
// import Footer from '../../../Helper/Footer';
import Drawer from '../../Mobile/DrawerInstitute';

import Header from '../../Helper/HeaderInstitute';
import Footer from '../../Helper/FooterInstitute';

import { IMGBASEAPIURL, BASEAPIURL } from '../../../config';
import { Location4Insti, Courses4Insti } from '../../../masterdata';
import './bookaclass.css';

const OurClientsBox = React.lazy(() => import('../OurClientsBox'));
const serialize = require('form-serialize');

function BookTeamLearn() {
    const [drawer, drawerAction] = useToggle(false);
    const [msgcolor, setMsgcolor] = useState('red');
    const [showSent, setshowSent] = useState(false);

    const [message, setMessage] = useState('');
    const [Participants, setParticipants] = useState('');
    const [Notes, setNotes] = useState('');
    const [ReqDate, setReqDate] = useState(' ');
    const [PhoneNumber, setPhoneNumber] = useState('');

    const form = document.querySelector('#Inst-Enq-Form');
    const obj = serialize(form, { hash: true, empty: true });

    function closeshowSent() {
        setshowSent(false);
    }

    async function handleSubmit() {
        try {
            setMsgcolor('green');
            const url = `${BASEAPIURL}/institute/enquiry/requestasteam`;
            const formData = new FormData();
            Object.keys(obj).map((index) => {
                formData.append(index, obj[index]);
                return index;
            });
            const config = {
                headers: {
                    'content-type': 'application/json', // multipart/form-data
                },
            };
            axios.post(url, formData, config).then((response) => {
                console.log(response);
                setMessage(response.data.message);
                if (response.data.status === 1) {
                    setMessage('');
                    setshowSent(true);
                    document.getElementById('Inst-Enq-Form').reset();
                }
            });
        } catch (err) {
            setMsgcolor('red');
            console.log(err);
            setMessage('Something went wrong, Please try again later.');
        }
    }

    function getdefaultCountry() {
        let send = "IN";
        const country_name = localStorage.getItem('country_name');
        if (country_name === "India") {
            send = "IN";
        }
        if (country_name === "United Arab Emirates") {
            send = "AE";
        }
        return send;
    }

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header drawer={drawer} action={drawerAction.toggle} />
            <>
                {showSent && (
                    <div>
                        <div className="mfp-bg mfp-ready"></div>
                        <div
                            className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
                            tabIndex="-1"
                            style={{ overflow: ' hidden auto' }}
                        >
                            <div className="mfp-container mfp-s-ready mfp-iframe-holder">
                                <div className="mfp-content" style={{ maxWidth: 'max-content' }}>
                                    <div className="mfp-iframe-scaler-old">
                                        <button
                                            onClick={closeshowSent}
                                            title="Close (Esc)"
                                            type="button"
                                            className="mfp-close"
                                        >
                                            ×
                                        </button>
                                    </div>
                                    <div
                                        className="mfp-iframe p-4 text-center"
                                        style={{ background: '#fff' }}
                                    >
                                        <img
                                            src={`${IMGBASEAPIURL}institute/bookaclass.svg`}
                                            alt="Enquiry Sent"
                                            className="mb-3"
                                            style={{ maxWidth: '250px' }}
                                        />
                                        <h3 className="pagetitle mb-3">Enquiry Sent</h3>
                                        <h4 className="mt-3 mb-3">
                                            We will get back to you too soon.
                                        </h4>
                                    </div>
                                </div>
                                <div className="mfp-preloader">Loading...</div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="container pt-4 pb-4">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mt-4 mb-4 ry_instr_shadow">
                                <div className="row">
                                    <div className="col-md-7">
                                        <div className="m-4 p-4">
                                            <h1 className="pagetitle" style={{ fontSize: '1.7rem' }}>
                                                Request as Team/ Group of Individuals
                                            </h1>
                                            <p className="mt-3">
                                                {parse(`
                                                    Docsta Learn is thrilled at your initiative to learn and we encourage you to grow your skills together. We welcome you and your group to choose courses you would like to train together for at the choice of your time.
                                                    <br /><br />
                                                    Our co-ordination team will take care of the rest to give you a flexible learning experience and reward you for your commitment with highly discounted rates.
                                                `)}
                                            </p>
                                            <h4 className="mt-3 mb-3">
                                                What you need to let us know
                                            </h4>
                                            <div className="mb-2">
                                                <i className="fa fa-check mr-2 bulletpoint"></i>
                                                Contact personnel name & details
                                            </div>
                                            <div className="mb-2">
                                                <i className="fa fa-check mr-2 bulletpoint"></i>
                                                Preferred venue
                                            </div>
                                            <div className="mb-2">
                                                <i className="fa fa-check mr-2 bulletpoint"></i>
                                                Preferred date and time
                                            </div>
                                            <div className="mb-2">
                                                <i className="fa fa-check mr-2 bulletpoint"></i>
                                                Choice of course
                                            </div>
                                            <div className="mb-2">
                                                <i className="fa fa-check mr-2 bulletpoint"></i>
                                                Total expected participants
                                            </div>
                                        </div>

                                        <Suspense fallback={<div>Loading...</div>}>
                                            <OurClientsBox />
                                        </Suspense>
                                    </div>
                                    <div className="col-md-5">
                                        <form
                                            id="Inst-Enq-Form"
                                            className="bookaclass_frm m-4 pr-41 pl-41"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                handleSubmit();
                                            }}
                                        >
                                            <img
                                                src={`${IMGBASEAPIURL}institute/bac_top.png?v=0.01`}
                                                alt="Docsta"
                                            />
                                            <h5 className="text-center mt-3 mb-3">
                                                Please fill out the form
                                            </h5>

                                            <div className="row mr-3 ml-3">
                                                <div className="col-md-6 pr-1 pl-1">
                                                    <div className="form-group  mb-2">
                                                        <label>First Name*</label>
                                                        <input
                                                            type="text"
                                                            name="fname"
                                                            placeholder="First name"
                                                            className="form-control"
                                                            required="required"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 pr-1 pl-1">
                                                    <div className="form-group  mb-2">
                                                        <label>Last Name*</label>
                                                        <input
                                                            type="text"
                                                            name="lname"
                                                            placeholder="Last name"
                                                            className="form-control"
                                                            required="required"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mr-3 ml-3 mt-1">
                                                <div className="col-md-6 pr-1 pl-1">
                                                    <div className="form-group  mb-2">
                                                        <label>Email*</label>
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            placeholder="Email"
                                                            className="form-control"
                                                            required="required"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 pr-1 pl-1">
                                                    <div className="form-group mb-2">
                                                        <label>Phone Number*</label>
                                                        <PhoneInput
                                                            international
                                                            className="form-control"
                                                            name="phonenumber"
                                                            value={PhoneNumber}
                                                            onChange={setPhoneNumber}
                                                            required="required"
                                                            defaultCountry={getdefaultCountry()}
                                                            style={{ position: 'relative' }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mr-3 ml-3 mt-1">
                                                <div className="col-md-6 d-none pr-1 pl-1">
                                                    <div className="form-group  mb-2">
                                                        <label>Location*</label>
                                                        <select
                                                            name="location"
                                                            className="form-control"
                                                        >
                                                            <option value=" "></option>
                                                            {Location4Insti.map((singi) => (
                                                                <option
                                                                    value={Object.values(singi)[0]}
                                                                    key={Object.keys(singi)[0]}
                                                                >
                                                                    {Object.values(singi)[0]}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 pr-1 pl-1">
                                                    <div className="form-group mb-2">
                                                        <label>Course*</label>
                                                        <select
                                                            name="course"
                                                            className="form-control"
                                                        >
                                                            {Courses4Insti.map((singi) => (
                                                                <option
                                                                    value={Object.values(singi)[0]}
                                                                    key={Object.keys(singi)[0]}
                                                                >
                                                                    {Object.values(singi)[0]}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 d-none pr-1 pl-1">
                                                    <div className="form-group  mb-2">
                                                        <label>Requesting date*</label>
                                                        <input
                                                            type="text"
                                                            name="fordate"
                                                            placeholder="Requesting date"
                                                            className="form-control"
                                                            required="required"
                                                            onChange={(e) =>
                                                                setReqDate(e.target.value)
                                                            }
                                                            value={ReqDate}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 pr-1 pl-1">
                                                    <div className="form-group  mb-2">
                                                        <label>No. of participants*</label>
                                                        <input
                                                            type="number"
                                                            min={0}
                                                            name="participants"
                                                            placeholder="No. of participants"
                                                            className="form-control"
                                                            required="required"
                                                            onChange={(e) =>
                                                                setParticipants(e.target.value)
                                                            }
                                                            value={Participants}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mr-3 ml-3">
                                                <div className="col-md-12 pr-1 pl-1">
                                                    <div className="form-group  mb-2">
                                                        <label>Additional notes</label>
                                                        <textarea
                                                            type="date"
                                                            name="notes"
                                                            placeholder="Additional notes"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                setNotes(e.target.value)
                                                            }
                                                            value={Notes}
                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mr-3 ml-3">
                                                <div className="col-md-12 pr-1 pl-1">
                                                    <div className="form-group  mb-2">
                                                        <div
                                                            className="message mb-3"
                                                            style={{ color: msgcolor }}
                                                        >
                                                            {message ? <span>{message}</span> : ''}
                                                        </div>
                                                        <button
                                                            type="submit"
                                                            className="btn btn-docsta-instructor"
                                                        >
                                                            Send Enquiry
                                                        </button>
                                                        <small>
                                                            Field with * are required to complete
                                                            the request.
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>

                                            <img
                                                src={`${IMGBASEAPIURL}institute/bac_foot.png?v=0.01`}
                                                alt="Docsta"
                                                className="mt-4"
                                            />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-1"></div>
                    </div>
                </div>
            </>
            <Footer className="appie-footer-about-area" />
            <BackToTop />
        </>
    );
}

export default BookTeamLearn;
