import React from 'react';
import { Link } from 'react-router-dom';
import { IMGBASEAPIURL } from '../../../config';

function CorporateLearningHead() {
    return (
        <>
            <section
                className="corporate-learning"
                style={{
                    backgroundImage: `url(${IMGBASEAPIURL}institute/bg/corporate-learning-bg1.jpg?v=0.2)`,
                }}
                data-overlay="6"
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="headBlock">
                                <h3>Corporate Learning</h3>
                                <h4>
                                    Create the Resilient Work-Force of Tomorrow by learning from the
                                    Best Corporate Training Center in Abu Dhabi
                                </h4>
                                <p>
                                    We pride in up-skilling the competencies of your team and
                                    empower them to Lead, Innovate and Accelerate the productivity
                                    of your Institution with numerous Industry Demanded Courses in
                                    Abu Dhabi.
                                </p>
                                <Link
                                    to="/contact-us?refrer=request-corporate-learning"
                                    className="main-btn mt-5"
                                >
                                    Enquiry Now
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CorporateLearningHead;
