import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASEAPIURL, IMGBASEAPIURL } from '../config'

const initialState = {
    mainCategory: [
        {
            id: 'exam',
            title: 'Exam-Oriented Courses',
            image: `${IMGBASEAPIURL}homePage/exam.png`,
            slug: '/courses/exam-oriented-courses',
        },
        {
            id: 'skill',
            title: 'Skill Upgrading Courses',
            image: `${IMGBASEAPIURL}homePage/skill.png`,
            slug: '/courses/skills-based-courses',
        },
        {
            id: 'qb',
            title: 'Question Bank',
            image: `${IMGBASEAPIURL}homePage/qb.png`,
            slug: '/questionBanks',
        },
    ],
    category: []
}

// Define an async thunk to fetch CourseCategory START data
export const fetchCourseCategory = createAsyncThunk('fetchCourseCategory', async (FilterFrom) => {
    try {
        const headers = { 'Content-Type': 'application/json' };
        //   const response = await fetch('your-api-endpoint'); // Replace with your actual API endpoint
        const response = await fetch(`${BASEAPIURL}/admin/coursecategory/getAll`, {
            method: 'POST',
            headers,
            body: JSON.stringify({ ...FilterFrom, SortStatus: 1, recordsPerPage: 1000 }),
        });
        // maincatid
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching customer data:', error);
        throw error;
    }
});
// Define an async thunk to fetch CourseCategory END data


export const CourseCategoyShow = createSlice({
    name: 'paymentsDataShow',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // fetch item data will be start from here
                .addCase(fetchCourseCategory.pending, () => {
                    // console.log('loading')
                    // console.log(state)
                })
                .addCase(fetchCourseCategory.fulfilled, (state, action2) => {
                    // console.log('succeeded')
                    state.category = action2.payload.data;
                })
                .addCase(fetchCourseCategory.rejected, (state, action) => {
                    console.log('failed');
                    console.log(state);
                    console.error(action);
                })
            // fetch item data will be End from here
    },
});

// Action creators are generated for each case reducer function

export default CourseCategoyShow.reducer;
