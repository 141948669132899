import React from "react";
// import { Helmet } from 'react-helmet';
import useToggle from "../../../Hooks/useToggle";
import BackToTop from "../../BackToTop";
import Drawer from "../../Mobile/DrawerInstitute";
import Header from "../../Helper/HeaderInstitute";
import Footer from "../../Helper/FooterInstitute";
import FreeLifeTipsNew from "./FreeLifeTipsNew";
// import FreeLifeTips from './FreeLifeTips';

function DocInstitute2() {
  const [drawer, drawerAction] = useToggle(false);

  return (
    <>
      <Drawer drawer={drawer} action={drawerAction.toggle} />
      <Header action={drawerAction.toggle} />
      {/* <FreeLifeTips /> */}
      <FreeLifeTipsNew />
      <Footer />
      <BackToTop />
    </>
  );
}

export default DocInstitute2;
