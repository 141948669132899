import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { IMGBASEAPIURL } from '../../config';

function WebinarsBox({ className }) {
    const [tab, setTab] = useState('upcoming');
    const handleClick = (e, value) => {
        e.preventDefault();
        setTab(value);
    };

    const sliderRef = useRef();
    const webinars = {
        autoplay: false,
        arrows: true,
        dots: false,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    return (
        <>
            <section className={`appie-features-area ${className || ''}`}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Join our Webinars</h3>
                                <p>Explore our global classroom and learn from our experts</p>
                            </div>
                        </div>
                    </div>
                    {/* <tabBtn /> */}
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-features-tabs-btn">
                                <div
                                    className="nav nav-pills justify-content-center mb-40"
                                    id="v-pills-tab"
                                    role="tablist"
                                    aria-orientation="vertical"
                                >
                                    <a
                                        onClick={(e) => handleClick(e, 'upcoming')}
                                        className={`nav-link ${tab === 'upcoming' ? 'active' : ''}`}
                                        id="v-pills-upcoming-tab"
                                        data-toggle="pill"
                                        href="#v-pills-upcoming"
                                        role="tab"
                                        aria-controls="v-pills-upcoming"
                                        aria-selected="true"
                                    >
                                        Upcoming
                                    </a>
                                    <a
                                        onClick={(e) => handleClick(e, 'medical')}
                                        className={`nav-link ${tab === 'medical' ? 'active' : ''}`}
                                        id="v-pills-medical-tab"
                                        data-toggle="pill"
                                        href="#v-pills-medical"
                                        role="tab"
                                        aria-controls="v-pills-medical"
                                        aria-selected="true"
                                    >
                                        Medical
                                    </a>
                                    <a
                                        onClick={(e) => handleClick(e, 'business')}
                                        className={`nav-link ${tab === 'business' ? 'active' : ''}`}
                                        id="v-pills-business-tab"
                                        data-toggle="pill"
                                        href="#v-pills-business"
                                        role="tab"
                                        aria-controls="v-pills-business"
                                        aria-selected="false"
                                    >
                                        Business
                                    </a>
                                    <a
                                        onClick={(e) => handleClick(e, 'it')}
                                        className={`nav-link ${tab === 'it' ? 'active' : ''}`}
                                        id="v-pills-it-tab"
                                        data-toggle="pill"
                                        href="#v-pills-it"
                                        role="tab"
                                        aria-controls="v-pills-it"
                                        aria-selected="false"
                                    >
                                        IT
                                    </a>
                                    <a
                                        onClick={(e) => handleClick(e, 'software')}
                                        className={`nav-link ${tab === 'software' ? 'active' : ''}`}
                                        id="v-pills-software-tab"
                                        data-toggle="pill"
                                        href="#v-pills-software"
                                        role="tab"
                                        aria-controls="v-pills-software"
                                        aria-selected="false"
                                    >
                                        Software
                                    </a>
                                    <a
                                        onClick={(e) => handleClick(e, 'market')}
                                        className={`nav-link ${tab === 'market' ? 'active' : ''}`}
                                        id="v-pills-market-tab"
                                        data-toggle="pill"
                                        href="#v-pills-market"
                                        role="tab"
                                        aria-controls="v-pills-market"
                                        aria-selected="false"
                                    >
                                        Marketing
                                    </a>
                                    <a
                                        onClick={(e) => handleClick(e, 'finance')}
                                        className={`nav-link ${tab === 'finance' ? 'active' : ''}`}
                                        id="v-pills-finance-tab"
                                        data-toggle="pill"
                                        href="#v-pills-finance"
                                        role="tab"
                                        aria-controls="v-pills-finance"
                                        aria-selected="false"
                                    >
                                        Finance
                                    </a>
                                    <a
                                        onClick={(e) => handleClick(e, 'softskil')}
                                        className={`nav-link ${tab === 'softskil' ? 'active' : ''}`}
                                        id="v-pills-softskil-tab"
                                        data-toggle="pill"
                                        href="#v-pills-softskil"
                                        role="tab"
                                        aria-controls="v-pills-softskil"
                                        aria-selected="false"
                                    >
                                        Soft Skill
                                    </a>
                                    <a
                                        onClick={(e) => handleClick(e, 'programm')}
                                        className={`nav-link ${tab === 'programm' ? 'active' : ''}`}
                                        id="v-pills-programm-tab"
                                        data-toggle="pill"
                                        href="#v-pills-programm"
                                        role="tab"
                                        aria-controls="v-pills-programm"
                                        aria-selected="false"
                                    >
                                        Programming
                                    </a>
                                    <a
                                        onClick={(e) => handleClick(e, 'language')}
                                        className={`nav-link ${tab === 'language' ? 'active' : ''}`}
                                        id="v-pills-language-tab"
                                        data-toggle="pill"
                                        href="#v-pills-language"
                                        role="tab"
                                        aria-controls="v-pills-language"
                                        aria-selected="false"
                                    >
                                        Language
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <tabContent /> */}
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="tab-content" id="v-pills-tabContent">
                                {/* <tabUpcoming /> */}
                                <div
                                    className={`${tab === 'upcoming' ? 'show active' : ''}
                                    tab-pane fade`}
                                    id="v-pills-upcoming"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-upcoming-tab"
                                >
                                    <div className="row appie-showcase-slider">
                                        <div className="col-lg-12">
                                            <Slider ref={sliderRef} {...webinars}>
                                                {/* <slide1 /> */}
                                                <div>
                                                    <div className="appie-features-content">
                                                        <div className="imgBox">
                                                            <img
                                                                src={`${IMGBASEAPIURL}institute/webinars/img1.jpg`}
                                                                alt=""
                                                            />
                                                            <span>
                                                                <i className="fa fa-eye" /> 1388
                                                            </span>
                                                            <a href="/#" className="btnLive">
                                                                Live
                                                                <i className="far fa-webcam" />
                                                                <b>Join Now</b>
                                                            </a>
                                                        </div>
                                                        <div className="contentBox">
                                                            <span>Beginner</span>
                                                            <h6 className="mb-0">
                                                                <a href="/">
                                                                    How to manage a stubborn child?
                                                                </a>
                                                            </h6>
                                                            <p>10 effective tips for parenting</p>
                                                            <div className="instructorBox">
                                                                <img
                                                                    src={`${IMGBASEAPIURL}institute/instructor/img1.jpg`}
                                                                    alt=""
                                                                />
                                                                <a href="/">
                                                                    <b>Dr. Danish Salim</b>
                                                                </a>
                                                            </div>
                                                            <div className="ratingBox">
                                                                <i className="fas fa-star" />
                                                                <i className="fas fa-star" />
                                                                <i className="fas fa-star" />
                                                                <i className="fas fa-star-half-alt" />
                                                                <i className="far fa-star" />
                                                                <b>3.5/5.0</b>
                                                            </div>
                                                            <h3 className="rateBox">
                                                                $29 <i>$39</i>
                                                            </h3>
                                                            <div className="tagsBox">
                                                                <b>
                                                                    <a href="/#">Enroll Now</a>
                                                                </b>
                                                                <div className="shareBox">
                                                                    <a href="/#" className="mr-10">
                                                                        <i className="far fa-heart" />
                                                                    </a>
                                                                    <a
                                                                        href="/#"
                                                                        className="text-dark"
                                                                    >
                                                                        <i className="far fa-share-alt" />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="hLine">
                                                                <hr />
                                                            </div>
                                                            <div className="courseDetails">
                                                                <div className="durationBox">
                                                                    <i className="far fa-clock" />
                                                                    <b>5hr 30mins</b>
                                                                </div>
                                                                <div className="sessionsBox">
                                                                    <i className="far fa-play-circle" />
                                                                    <b>100 Sessions</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default WebinarsBox;
