import React, { useEffect, useState } from 'react';
import Parser from 'html-react-parser';
import { Link } from 'react-router-dom';
import axios from 'axios';
// import { Country, State, City } from 'country-state-city';
import PhoneInput from 'react-phone-number-input';
import { BASEAPIURL, BASEURL } from '../../../../config';
import Editor from '../../../htmleditor/EditorTinyMCE';

// const serialize = require('form-serialize');

// const form = document.querySelector('#Inst-Regi-Form');
// const obj = serialize(form, { hash: true, empty: true });
let i = 0;
function InstructorUpdate({ forpage }) {
    // const navigate = useNavigate();
    const [PageContent, SetPageContent] = useState('');
    // const [drawer, drawerAction] = useToggle(false);
    // const [lfVisibility, setlfVisibility] = useState('block');
    const description = `
    If teaching is your passion, we are your solution. If you have the knowledge that will benefit others, then we are here to enrich your potential and support you to create world-class courses and make your world wide impact.
    <br /><br />
    Our platform is designed to guide you from step 1 upto creation of a perfect, valuable program that will leave your mark on each of your students. Our expertise is not just in training you to create the best courses, but also in amplifying your reach exponentially so that your knowledge reaches the global population.
    <br /><br />
    Over time, we have touched the lives of more than a million students with multiple life enhancing courses and wants each of our instructors to do the same and reap the abundant rewards with maximum protection of their intellectual rights.`;

    const token = localStorage.getItem('token');
    const [Token] = useState(token);
    const [Profile, setProfile] = useState({
        email: '',
        country: '',
        state: '',
        city: '',
        linkedin: '',
        youtube: '',
        language: '',
        name: '',
        description: '',
    });
    const UpdateProfile = (name, value) => {
        setProfile({ ...Profile, [name]: value });
    };
    const [Msgcolor, setMsgcolor] = useState();
    const [APIMessage, setMessage] = useState();
    // const [ProfilePhoto, setProfilePhoto] = useState();
    const [ShowCVfile, setShowCVfile] = useState('Upload Profile Picture');
    const [PhoneNumber, setPhoneNumber] = useState('');
    const [MobileAready, setMobileAready] = useState('');
    const [EmailAready, setEmailAready] = useState('');

    // for file uploade will be here
    async function uploadFile(file, fileName) {
        // e.preventDefault();
        setShowCVfile(fileName);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', fileName);
        const url = `${BASEAPIURL}/admin/instructure/uploadcv`;
        try {
            const res = await axios.post(url, formData);
            console.log(res);
            UpdateProfile('photo', res.data.filename);
        } catch (ex) {
            console.log(ex);
        }
    }
    const UploadInstrProfiePics = () => {
        document.getElementById('instr_profie_pics').click();
    };
    // for file uploade will be here
    async function checkEmailAready(emailid) {
        let returntype = false;
        try {
            const url = `${BASEAPIURL}/admin/instructure/checkifalreadyemail`;
            const formData = new FormData();
            formData.append('emailid', emailid);
            formData.append('checkby', Token);
            const config = {
                headers: {
                    'content-type': 'application/json', // multipart/form-data
                },
            };
            axios.post(url, formData, config).then((response) => {
                console.log(response.data.message);
                console.log(response.data);
                if (response.data.status === 1) {
                    setMsgcolor('red');
                    setEmailAready(response.data.message.toString());
                    returntype = true;
                } else {
                    setEmailAready('');
                }
            });
        } catch (err) {
            setMsgcolor('red');
            console.log(err);
            setEmailAready('Something went wrong, Please try again later.');
        }
        return returntype;
    }

    async function checkMobileAready(mobileno) {
        let returntype = false;
        try {
            const url = `${BASEAPIURL}/admin/instructure/checkifalreadymobile`;
            const formData = new FormData();
            formData.append('mobileno', mobileno);
            formData.append('checkby', Token);
            const config = {
                headers: {
                    'content-type': 'application/json', // multipart/form-data
                },
            };
            axios.post(url, formData, config).then((response) => {
                if (response.data.status === 1) {
                    setMsgcolor('red');
                    setMobileAready(response.data.message.toString());
                    returntype = true;
                } else {
                    setMobileAready('');
                }
            });
        } catch (err) {
            setMsgcolor('red');
            console.log(err);
            setMobileAready('Something went wrong, Please try again later.');
        }
        return returntype;
    }
    async function UpdateInstructor() {
        try {
            setMsgcolor('green');
            const url = `${BASEAPIURL}/admin/instructure/updateprofilesetting`;
            const formData = new FormData();
            formData.append('edit', Token);
            // get all the data and add there
            // formData.append('photo', ProfilePhoto);
            // Object.keys(obj).map(function(key) {
            Object.keys(Profile).map((index) => {
                // formData.append(index, value); // console.log(index); console.log(obj[index]);
                formData.append(index, Profile[index]);
                return index;
            });
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('jwttoken')}`,
                },
            };
            axios.post(url, formData, config).then((response) => {
                console.log(response);
                if (response.data.status === 0) {
                    setMsgcolor('red');
                }
                i = i + 1;
                if (response.data.status === 1) {
                    // navigate('/instructor/page/profilesetting');
                    window.location.href = `${BASEURL}instructor/page/profilesetting`; 
                }
                setMessage(response.data.message);
            });
        } catch (err) {
            setMsgcolor('red');
            console.log(err);
            setMessage('Something went wrong, Please try again later.');
        }
    }

    useEffect(() => {
        const FetchProfile = async () => {
            try {
                const res = await fetch(`${BASEAPIURL}/admin/instructure/myprofile`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Basic ${localStorage.getItem('jwttoken')}`,
                    },
                    body: JSON.stringify({
                        token: localStorage.getItem('token'),
                    }),
                });
                const resJson = await res.json();
                if (res.status === 200) {
                    setProfile(resJson.data);
                    setPhoneNumber(resJson.data.mobile);
                    SetPageContent(resJson.data.description);
                    // setAllState(State.getStatesOfCountry(resJson.data.country));
                    // setAllCity(City.getCitiesOfState(resJson.data.country, resJson.data.state));
                } else {
                    console.log(resJson.message);
                }
            } catch (err) {
                console.log(err);
            }
        };
        i = 1;
        FetchProfile();
    }, []);

    return (
        <>
            <div className="pb-3" forpage={forpage}>
                <div className="row">
                    <div className="col-md-7">
                        <div className="ry_instr_shadow p-3">
                            <h5 className="mb-3 text-center">Profile Details</h5>
                            <form
                                id="Inst-Regi-Form"
                                encType="multipart/form-data"
                                className="p-1"
                                style={{ display: 'block' }}
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    UpdateInstructor();
                                }}
                            >
                                <div className="form-group mb-1">
                                    <label className="ry_label">Your Name</label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={Profile.name}
                                        onChange={(e) => {
                                            UpdateProfile('name', e.target.value);
                                        }}
                                        className="form-control"
                                        placeholder="Enter Your Name"
                                    />
                                </div>

                                <div className="form-group mb-1">
                                    <label className="ry_label">Designation</label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={Profile.subTitle}
                                        onChange={(e) => {
                                            UpdateProfile('subTitle', e.target.value);
                                        }}
                                        className="form-control"
                                        placeholder="Enter Your Designation"
                                    />
                                </div>

                                <div className="d-none">
                                    <div className="form-group mb-1">
                                        <label className="ry_label">Country</label>
                                        <input
                                            type="text"
                                            name="country"
                                            className="form-control"
                                            value={Profile.country}
                                            onChangeCapture={(e) => {
                                                // setAllState(
                                                //     State.getStatesOfCountry(e.target.value)
                                                // );
                                                UpdateProfile('country', e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="form-group mb-1 d-none">
                                        <label className="ry_label">State</label>
                                        <input
                                            type="text"
                                            name="state"
                                            className="form-control"
                                            value={Profile.state}
                                            onChangeCapture={(e) => {
                                                UpdateProfile('state', e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="form-group mb-1 d-none">
                                        <label className="ry_label">City</label>
                                        <input
                                            type="text"
                                            name="city"
                                            className="form-control"
                                            value={Profile.city}
                                            onChangeCapture={(e) => {
                                                UpdateProfile('city', e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="form-group mb-1">
                                    <label className="ry_label">Languages spoken</label>
                                    <input
                                        type="text"
                                        name="language"
                                        value={Profile.language}
                                        onChange={(e) => {
                                            UpdateProfile('language', e.target.value);
                                        }}
                                        className="form-control"
                                        placeholder="Enter Languages spoken"
                                    />
                                </div>

                                <div className="form-group mb-1">
                                    <label className="ry_label">Your profile description</label>
                                    <Editor
                                        PageContent={PageContent}
                                        setPageContent={SetPageContent}
                                        HandleForm={UpdateProfile}
                                        ForAttribute="description"
                                    />
                                    
                                    <textarea
                                        type="text"
                                        name="description"
                                        value={Profile.description}
                                        onChange={(e) => {
                                            UpdateProfile('description', e.target.value);
                                        }}
                                        className="form-control d-none"
                                        placeholder="Enter Profile Description"
                                    />
                                </div>

                                <div className="form-group mb-1">
                                    <label className="ry_label">Youtube Link</label>
                                    <input
                                        type="text"
                                        name="youtube"
                                        value={Profile.youtube}
                                        onChange={(e) => {
                                            UpdateProfile('youtube', e.target.value);
                                        }}
                                        className="form-control"
                                        placeholder="Enter Youtube Link"
                                    />
                                </div>
                                <div className="form-group mb-1">
                                    <label className="ry_label">Linkedin Link</label>
                                    <input
                                        type="text"
                                        value={Profile.linkedin}
                                        onChange={(e) => {
                                            UpdateProfile('linkedin', e.target.value);
                                        }}
                                        name="linkedin"
                                        className="form-control"
                                        placeholder="Enter Linkedin Link"
                                    />
                                </div>
                                <div className="form-group mb-1">
                                    <label className="ry_label">Phone Number</label>
                                    <PhoneInput
                                        international
                                        className="form-control"
                                        name="phonenumber"
                                        value={PhoneNumber}
                                        onChange={setPhoneNumber}
                                        onKeyUpCapture={(e) => {
                                            UpdateProfile('mobile', e.target.value);
                                            checkMobileAready(e.target.value);
                                        }}
                                        defaultCountry="IN"
                                        style={{ position: 'relative' }}
                                    />
                                    <div className="message" style={{ color: Msgcolor }}>
                                        {MobileAready ? <span>{MobileAready}</span> : ''}
                                    </div>
                                </div>

                                <div className="form-group mb-1">
                                    <label className="ry_label">Email ID</label>
                                    <input
                                        type="email"
                                        name="emailid"
                                        className="form-control"
                                        placeholder="Enter Email ID"
                                        value={Profile.email}
                                        onChange={(e) => {
                                            UpdateProfile('email', e.target.value);
                                            checkEmailAready(e.target.value);
                                        }}
                                    />
                                    <div className="message" style={{ color: Msgcolor }}>
                                        {EmailAready ? <span>{EmailAready}</span> : ''}
                                    </div>
                                </div>

                                <div className="text-right" onClick={UploadInstrProfiePics}>
                                    <input
                                        type="file"
                                        name="photo"
                                        accept="image/*"
                                        className="d-none"
                                        id="instr_profie_pics"
                                        onChange={(e) => {
                                            uploadFile(e.target.files[0], e.target.files[0].name);
                                        }}
                                    />
                                    {ShowCVfile} &nbsp;
                                    <i className="fa fa-upload"></i>
                                </div>
                                <div className="message mt-3" style={{ color: Msgcolor }}>
                                    {APIMessage ? <span>{APIMessage}</span> : ''}
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Link
                                            to="/instructor/page/profilesetting"
                                            className="btn d-block btn-outline-danger"
                                        >
                                            Cancel
                                        </Link>
                                    </div>
                                    <div className="col-md-6">
                                        <button type="submit" className="btn btn-docsta-instructor">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <h4 className="mb-3">Dream to leave a legacy in this World..</h4>
                        <p style={{ textAlign: 'justify' }}>{Parser(description)}</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default InstructorUpdate;
