import React, { useEffect, useState } from 'react';
import { BASEAPIURL, BASEURL } from '../../config';

function PlayerCourseHere({ DefaultVideoTitle, DefaultVideoId }) {
    const [RunTimer, setRunTimer] = useState(0);
    const [VideoURL, setVideoURL] = useState('');
    const [IsLoaded, setIsLoaded] = useState(false);

    const FetchVideoPlayURL = async (id) => {
        try {
            const res = await fetch(`${BASEAPIURL}/uploadChunk/FetchVideoPlayURLTP`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id }),
            });
            const resJson = await res.json();
            if (res.status === 200 && resJson.status === 1) {
                setVideoURL(resJson.token);
            } else {
               console.log(resJson);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        let TpStreamPlayerSelect = '';
        // here to be checked the Function
        const TpStreamPlayer = document.getElementById("TpStreamPlayerPopUp");

        if (TpStreamPlayer) {

            TpStreamPlayerSelect = new window.Testpress.Player(TpStreamPlayer);

            TpStreamPlayerSelect.loaded().then(()=>{
                // console.log('Player is ready now');
                setIsLoaded(true);
                TpStreamPlayerSelect.play();
            });
            setTimeout(() => {
                setIsLoaded(true);
            }, 3456);

            TpStreamPlayerSelect.getDuration().then(function(duration) {
                console.log(duration);
            });
        }
        // here to be checked the Function

        FetchVideoPlayURL(DefaultVideoId);
        // Clean up the event listener on component unmount
        const UpdateDone = setInterval(() => {
            TpStreamPlayerSelect.getCurrentTime().then(function(seconds) {
                setRunTimer(Number(seconds).toFixed(0));
            });
        }, 5000);
        return () => {
            clearInterval(UpdateDone);
        };
    }, [DefaultVideoId]);

    return (
        <>
            <section>
                <div id="VideoPlayerID" style={{ position: 'relative' }}>
                    <div className="RYDetailPlayer">
                        <b className="RYDetailPlayerTitle" style={{ display: RunTimer > 5 ? 'none' : '' }}>
                            {DefaultVideoTitle}
                        </b>
                        <div id="video-container" style={{position: 'relative'}}>
                            <div id="overlay"></div>
                            <div
                                style={{
                                    position: 'relative',
                                }}
                            >
                                <iframe
                                    id="TpStreamPlayerPopUp"
                                    title="Docsta Learn Video on Course"
                                    src={VideoURL}
                                    style={{ width: '100%', height: Number(localStorage.getItem('screenwidth')) < 800 ? '30vh' : '30vh', display: IsLoaded ? '' : 'none' }}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                            {IsLoaded ? (
                                ''
                            ) : (
                                <div className="text-center text-info">
                                    <img
                                        alt="Video is Loading, If video not loaded in sometime, Try refreshing the page or try after sometime." 
                                        src={`${BASEURL}loader.gif`}
                                        style={{
                                            margin: '20px auto',
                                            maxWidth: '110px',
                                        }}
                                    />
                                    {/* Video is Loading, If video not loaded in sometime, Try refreshing the page or try after sometime. */}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PlayerCourseHere;
