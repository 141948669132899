import React from 'react';
import PropTypes from 'prop-types';

const Pagination = ({ nPages, currentPage, setCurrentPage, FetchFilterBlogs, SortBy, Keyword }) => {
    let pageNumbers = [...Array(nPages + 1).keys()].slice(1);
    let forpagi = currentPage;
    if (forpagi < 4) {
        forpagi = 4;
    }
    if (forpagi > nPages - 3) {
        forpagi = nPages - 3;
    }
    if (nPages > 6) {
        pageNumbers = [
            forpagi - 3,
            forpagi - 2,
            forpagi - 1,
            forpagi,
            forpagi + 1,
            forpagi + 2,
            forpagi + 3,
        ];
    }

    return (
        <nav>
            <ul className="pagination justify-content-center">
                <li className="page-item">
                    <button
                        type="button"
                        onClick={(e) => {
                            let nextpage = 1;
                            if (currentPage !== 1) {
                                setCurrentPage(currentPage - 1);
                                nextpage = currentPage - 1;
                            }
                            setCurrentPage(nextpage);
                            FetchFilterBlogs(e, Keyword, SortBy, nextpage);
                        }}
                        className="page-link"
                    >
                        Prev
                    </button>
                </li>
                {pageNumbers.map((pgNumber) => (
                    <li
                        key={pgNumber}
                        className={`page-item ${currentPage === pgNumber ? 'active' : ''} `}
                    >
                        <button
                            type="button"
                            onClick={async (e) => {
                                await setCurrentPage(pgNumber);
                                FetchFilterBlogs(e, Keyword, SortBy, pgNumber);
                            }}
                            className="page-link"
                        >
                            {pgNumber}
                        </button>
                    </li>
                ))}
                <li className="page-item">
                    <button
                        type="button"
                        onClick={(e) => {
                            let nextpage = nPages;
                            if (currentPage !== nPages) {
                                setCurrentPage(currentPage + 1);
                                nextpage = currentPage + 1;
                            }
                            setCurrentPage(nextpage);
                            FetchFilterBlogs(e, Keyword, SortBy, nextpage);
                        }}
                        className="page-link"
                    >
                        Next
                    </button>
                </li>
            </ul>
        </nav>
    );
};
Pagination.propTypes = {
    nPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    Keyword: PropTypes.string.isRequired,
    SortBy: PropTypes.string.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
    FetchFilterBlogs: PropTypes.func.isRequired,
};
export default Pagination;
