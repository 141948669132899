import React, { useEffect, useState, Suspense } from 'react';
import { useParams, Link } from 'react-router-dom';
// import Parser from 'html-react-parser';
import useToggle from '../../../../Hooks/useToggle';
import BackToTop from '../../../BackToTop';
import Footer from '../../../Helper/FooterLearn';
import Drawer from '../../../Mobile/DrawerLearn';
// import Header from '../Helper/Header';
import HeaderInstitute from '../../../Helper/HeaderInstitute';
import ProfileMenu from './ProfileMenu';
import Dashboard from './Dashboard';
import MyCourses from './MyCourses';
import ProfileSetting from './ProfileSetting';
import EmailNotificationSetting from './EmailNotificationSetting';
import ProfileSettingUpdate from './ProfileSettingUpdate';
import ChangePassword from './ChangePassword';
import MyLifeTips from './MyLifeTips';
import MyBlog from './MyBlog';
import MyFavourite from './MyFavourite';
import MyPurchase from './MyPurchase';
import ShareAppsPage from './ShareApps';
import MyDeals from './MyDeals';
import GiftCourse from './GiftCourse';
import InviteFriends from './inviteFriends';
import '../../student.css';
import LoginPage from '../Login';
import { UPLOADNODEURL, BASEAPIURL, BASEURL } from '../../../../config';
import MyInvoices from './Invoices';
// import { BuyThisCourseStd } from '../../functions';

const HighlyPopularCourses = React.lazy(() =>
  import('../../../Learn/HighlyPopularCoursesSkills')
);
const HighlyPopularCoursesExam = React.lazy(() =>
  import('../../../Learn/HighlyPopularCoursesExam')
);

function InstructorDashboard() {
  // const navigate = useNavigate();
  const [defaultProfilePicture] = useState('defaultProfilePicture.png');
  const [isLoaded, setisLoaded] = useState(false);
  const [isLogin, setisLogin] = useState(false);
  const [Profile, setProfile] = useState({
    name: '',
    photo: defaultProfilePicture,
  });
  const { PageName } = useParams();
  const [drawer, drawerAction] = useToggle(false);

  const [PurchasedCourse, SetPurchasedCourse] = useState([]);
  const [PurchasedQBCourse, SetPurchasedQBCourse] = useState([]);
  const PurchasedCourseList = async (targetType) => {
    try {
      const res = await fetch(`${BASEAPIURL}/student/PurchasedCourseList`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
        },
        body: JSON.stringify({
          targetType,
          usertype: localStorage.getItem('usertype'),
        }),
      });
      const resJson = await res.json();
      if (resJson.data) {
        switch (targetType) {
          case 'MainCourse':
            SetPurchasedCourse(resJson.data);
            break;
          case 'questionBank':
            SetPurchasedQBCourse(resJson.data);
            break;
          default:
            break;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  function showTotalDuration(AllData, value) {
    let send = 0;
    if (AllData && AllData.length > 0) {
      send = AllData.reduce((acc, current) => acc + current[value], 0);
    }
    return send;
  }

  useEffect(() => {
    PurchasedCourseList('MainCourse');
    PurchasedCourseList('questionBank');
    const FetchProfile = async () => {
      try {
        const res = await fetch(`${BASEAPIURL}/student/checkLogin`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
          },
          body: JSON.stringify({
            loggedToken: localStorage.getItem('loggedToken'),
            usertype: localStorage.getItem('usertype'),
          }),
        });
        const resJson = await res.json();
        if (res.status === 200) {
          setisLoaded(true);
          if (
            resJson.status === 1 &&
            localStorage.getItem('usertype') === 'student'
          ) {
            setisLogin(true);
            // console.log(resJson.userdata);
            setProfile(resJson.userdata ? resJson.userdata : {});
          } else {
            const t = localStorage.getItem('usertype');
            if (t && t === 'student') {
              localStorage.setItem('usertype', '');
            }
            setisLogin(false);
          }
        } else {
          setisLoaded(true);
          setisLogin(false);
          console.log(resJson.message);
        }
      } catch (err) {
        setisLoaded(true);
        setisLogin(false);
        console.log(err);
      }
    };
    FetchProfile();
    const interval = setInterval(() => {
      FetchProfile();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <Drawer
        drawer={drawer}
        action={drawerAction.toggle}
      />
      <HeaderInstitute
        action={drawerAction.toggle}
        type="student"
        HideBottomMenu={isLogin ? 0 : 1}
      />
      <div
        className="container text-center pt-4 pb-4"
        style={{ display: isLoaded ? 'none' : '' }}
      >
        <img
          src={`${BASEURL}loader.gif`}
          alt=""
          className="pt-4 pb-4"
          style={{ maxWidth: '75px' }}
        />
      </div>
      <div
        style={{
          display: isLogin ? 'none' : '',
          opacity: isLoaded ? 1 : 0,
        }}
      >
        <LoginPage setisLogin={setisLogin} />
      </div>
      <div
        style={{
          display: isLogin ? '' : 'none',
          opacity: isLoaded ? 1 : 0,
        }}
      >
        <div className="container pt-4 pb-4">
          <div className="mb-4">
            <h3>
              <img
                src={`${UPLOADNODEURL}${
                  Profile.photo &&
                  Profile.photo !== null &&
                  Profile.photo !== ''
                    ? Profile.photo
                    : defaultProfilePicture
                }`}
                alt={Profile.name}
                className="mr-3 d-none d-sm-block instr_profile_pics ry-pull-left"
                style={{ float: 'left' }}
              />
              <div
                style={{ display: 'inline-block' }}
                className="mr-3 pt-2"
              >
                {Profile.name}
                <br />
                <small style={{ fontSize: '37%' }}>
                  <>
                    <i className="far fa-database text-warning" />
                    &nbsp;{PurchasedCourse.length +
                      PurchasedQBCourse.length}{' '}
                    Courses&nbsp;
                    <i className="fal fa-file-alt text-danger" />
                    &nbsp;{showTotalDuration(PurchasedCourse, 'sessionCount')}
                    &nbsp;Sessions&nbsp;
                    <i className="far fa-laptop-code text-info"></i> 0 Webinars
                    &nbsp;
                    <i className="fas fa-certificate text-success"></i> 0
                    Certificates
                  </>
                </small>
              </div>
              <Link
                to="/"
                target="_BLANK"
                className="btn btn-docsta-instructor d-none ry-pull-right"
                style={{ width: 'auto', borderRadius: '10px' }}
              >
                Explore New Courses
              </Link>
              <div style={{ clear: 'both' }}></div>
            </h3>
          </div>
          <div className="row">
            <div className="col-md-3 mb-3">
              <ProfileMenu forpage={PageName} />
            </div>

            <div className="col-md-9">
              {PageName === undefined ? <Dashboard forpage={PageName} /> : ''}
              {PageName === 'index' ? <Dashboard forpage={PageName} /> : ''}
              {PageName === 'mylifetips' ? (
                <MyLifeTips forpage={PageName} />
              ) : (
                ''
              )}
              {PageName === 'myblog' ? <MyBlog forpage={PageName} /> : ''}
              {PageName === 'mycourses' ? <MyCourses forpage={PageName} /> : ''}
              {PageName === 'myexamcourses' ? (
                <MyCourses
                  forpage={PageName}
                  typeCourse="exam"
                />
              ) : (
                ''
              )}
              {PageName === 'myskillcourses' ? (
                <MyCourses
                  forpage={PageName}
                  typeCourse="skill"
                />
              ) : (
                ''
              )}
              {PageName === 'myuniversitycourses' ? (
                <MyCourses
                  forpage={PageName}
                  typeCourse="university"
                />
              ) : (
                ''
              )}
              {PageName === 'MyFavourite' ? (
                <MyFavourite forpage={PageName} />
              ) : (
                ''
              )}
              {PageName === 'invoices' ? <MyInvoices forpage={PageName} /> : ''}
              {PageName === 'MyPurchase' ? (
                <MyPurchase forpage={PageName} />
              ) : (
                ''
              )}
              {PageName === 'shareApps' ? (
                <ShareAppsPage forpage={PageName} />
              ) : (
                ''
              )}
              {PageName === 'MyDeals' ? <MyDeals forpage={PageName} /> : ''}
              {PageName === 'giftCourse' && <GiftCourse />}
              {PageName === 'inviteFriends' ? <InviteFriends /> : ''}
              {PageName === 'profilesetting' ? (
                <ProfileSetting forpage={PageName} />
              ) : (
                ''
              )}
              {PageName === 'EmailNotificationSetting' ? (
                <EmailNotificationSetting forpage={PageName} />
              ) : (
                ''
              )}
              {PageName === 'updateprofilesetting' ? (
                <ProfileSettingUpdate forpage={PageName} />
              ) : (
                ''
              )}
              {PageName === 'changePassword' ? (
                <ChangePassword forpage={PageName} />
              ) : (
                ''
              )}
            </div>

            <div className="col-md-1"></div>
            <div style={{ clear: 'both' }}></div>
          </div>
          <div className="m-4"></div>
          <Suspense fallback={<div>Loading...</div>}>
            <HighlyPopularCourses />
          </Suspense>
          <div className="m-4"></div>
          <Suspense fallback={<div>Loading...</div>}>
            <HighlyPopularCoursesExam />
          </Suspense>
        </div>
      </div>
      <Footer className="appie-footer-about-area" />
      <BackToTop />
    </div>
  );
}

export default InstructorDashboard;
