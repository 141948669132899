import React, { useState, useEffect } from 'react';
import { BASEAPIURL, BASEURL, UPLOADNODEURL } from '../../../../config';

function MyDealsPage() {
    const [Deals, SetDeals] = useState([]);
    const [isLoded, SetisLoded] = useState(false);

    const GerDeals = async () => {
        try {
            const res = await fetch(`${BASEAPIURL}/course/getDeals`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
                body: JSON.stringify({
                    SortStatus: 1,
                    recordsPerPage: 10,
                    sortby: 'popular',
                    keyword: '',
                    sortfeatured: 1,
                    dealsOnly: 1,
                    usertype: localStorage.getItem('usertype'),
                }),
            });
            const resJson = await res.json();
            SetisLoded(true);
            if (resJson.data && resJson.data.length > 0) {
                SetDeals(resJson.data);
            } else {
                SetDeals([]);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        GerDeals();
    }, []);

    return (
        <>
            <div className="ry_instr_shadow p-4" style={{ display: isLoded ? '' : 'none' }}>
                <h4 className="std_header_on_page">My Deals</h4>
                <div
                    style={{
                        background: '#f2f5ff',
                        margin: '20px auto',
                        padding: '35px',
                        display: Deals.length > 0 ? 'none' : '',
                    }}
                >
                    <h4 className="text-center" style={{ color: '#5c5a5a' }}>
                        There is no available Deals now, Come Back Later to see the Deals.
                    </h4>
                </div>
                <div className="mt-3">
                    <div className="">
                        {Deals.map((s) => (
                            <div
                                key={s.image}
                                style={{
                                    border: '1px solid #f5f5f5',
                                    background: '#fff',
                                    marginBottom: '10px',
                                    padding: '10px',
                                }}
                            >
                                <div className="row">
                                    <div className="col-md-3 text-center">
                                        <a
                                            href={`${BASEURL}course/${s.slug}`}
                                            target="_MainCourse"
                                            rel="noreferrer"
                                            style={{ position: 'relative' }}
                                        >
                                            <img
                                                src={`${UPLOADNODEURL}${s.image}`}
                                                alt=""
                                                style={{
                                                    objectFit: 'cover',
                                                    height: '180px',
                                                    width: '100%',
                                                }}
                                            />
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '0px',
                                                    left: '0px',
                                                    padding: '3px 10px',
                                                    color: '#fff',
                                                    background: 'green',
                                                }}
                                            >
                                                {`${s.campaign} % Off`}
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-md-6 text-left">
                                        <a
                                            href={`${BASEURL}course/${s.slug}`}
                                            target="_MainCourse"
                                            rel="noreferrer"
                                        >
                                            <h5 className="mb-2">{s.title}</h5>
                                        </a>
                                        <p>{s.metaDescription}</p>
                                    </div>
                                    <div className="col-md-3 pt-4 text-center">
                                        <a
                                            href={`${BASEURL}course/${s.slug}`}
                                            target="_MainCourse"
                                            rel="noreferrer"
                                            className="btn btn-docsta-instructor"
                                        >
                                            Get This
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default MyDealsPage;
