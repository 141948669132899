import React, { useEffect, useState, Suspense } from 'react';
// import parse from 'html-react-parser';
import { useParams } from 'react-router-dom';
import Parser from 'html-react-parser';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
// import Footer from '../../../Helper/Footer';
import Drawer from '../Mobile/DrawerInstitute';

import Header from '../Helper/HeaderInstitute';
import Footer from '../Helper/FooterInstitute';

import { BASEAPIURL, BASEURL, CleanUrlWork, IMGBASEAPIURL, UPLOADNODEURL } from '../../config';
import { MakeFavoriteStd, CheckFavoriteStd } from '../Students/functions';

const CourseListBlock = React.lazy(() => import('../Helper/CourseListBlockFull'));

let loggedToken = localStorage.getItem('loggedToken');
let usertype = localStorage.getItem('usertype');

function InstructotPublicProfile() {
    // const history = useHistory();
    const { Slug } = useParams();
    const [drawer, drawerAction] = useToggle(false);
    const [PorfileData, setPorfileData] = useState({});
    const [CourseData, setCourseData] = useState({});
    const [isFavoriteStd, setisFavoriteStd] = useState(false);
    const [isFollowed, setisFollowed] = useState(false);
    function IfNoInstructor() {
        window.location.href = '/';
    }
    useEffect(() => {
        const CheckFavorite = async (singi) => {
            const temp = await CheckFavoriteStd(loggedToken, singi._id, 'instructor', usertype);
            if (temp === 1) {
                setisFavoriteStd(true);
            } else {
                setisFavoriteStd(false);
            }
        };
        const CheckFollow = async (singi) => {
            const temp = await CheckFavoriteStd(loggedToken, singi._id, 'instructorFollow', usertype);
            if (temp === 1) {
                setisFollowed(true);
            } else {
                setisFollowed(false);
            }
        };
        const FetchData = async () => {
            loggedToken = localStorage.getItem('loggedToken');
            usertype = localStorage.getItem('usertype');
            try {
                const res = await fetch(`${BASEAPIURL}/admin/instructure/GetProfielBySlug`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ Slug }),
                });
                const resJson = await res.json();
                if (res.status === 200) {
                    if (resJson.data && resJson.data.length > 0) {
                        setPorfileData(resJson.data[0]);
                        CheckFavorite(resJson.data[0]);
                        CheckFollow(resJson.data[0]);
                        if (resJson.courseData && resJson.courseData.length > 0) {
                            setCourseData(resJson.courseData);
                        }
                    } else {
                        IfNoInstructor('No Course Result');
                    }
                } else {
                    console.log(resJson.message);
                    IfNoInstructor('200 No Response');
                }
            } catch (err) {
                IfNoInstructor('error');
                console.log(err);
            }
        };
        FetchData();
    }, [Slug]);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header drawer={drawer} action={drawerAction.toggle} />
            <>
                <div className="container pt-4 pb-4">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mt-4 mb-4 ry_instr_shadow">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="p-4">
                                            <span>INSTRUCTOR</span>
                                            <h3 className="pagetitle">
                                                {PorfileData.name}
                                                <div className='pull-right'>
                                                    <span
                                                        // className="btn btn-outline-secondary d-block mt-3"
                                                        onClick={() => {
                                                            setisFavoriteStd(!isFavoriteStd);
                                                            MakeFavoriteStd(localStorage.getItem('loggedToken'), PorfileData._id, 'instructor', localStorage.getItem('usertype'));
                                                        }}
                                                    >
                                                        <i
                                                            className={`${isFavoriteStd ? 'fas mt-2 text-danger fa-heart' : 'far text-dark mt-2 fa-heart'}`}
                                                            title={`${isFavoriteStd ? 'Remove From Favorite' : 'Add to Favorite'}`}
                                                        ></i>
                                                        {/* {isFavoriteStd ? ' Unfollow' : ' follow'} */}
                                                    </span>
                                                </div>
                                                <div className='clearboth'></div>
                                            </h3>
                                            <div className="mt-3" style={{ whiteSpace: 'pre-line' }}>
                                                {PorfileData.description && PorfileData.description !== '' ? Parser(PorfileData.description) : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="text-center p-3">
                                            <img
                                                src={`${BASEAPIURL}/admin/instructure/profileimage/${PorfileData.tid}`}
                                                alt={PorfileData.name}
                                                style={{
                                                    width: '250px',
                                                    height: '250px',
                                                    maxWidth: '100%',
                                                    borderRadius: '50%',
                                                    objectFit: 'cover',
                                                }}
                                            />
                                            <span
                                                className="btn btn-outline-secondary d-block mt-3"
                                                onClick={() => {
                                                    setisFollowed(!isFollowed);
                                                    MakeFavoriteStd(localStorage.getItem('loggedToken'), PorfileData._id, 'instructorFollow', localStorage.getItem('usertype'));
                                                }}
                                            >
                                                <i className={`${isFollowed ? 'fas mt-2 text-success fa-check' : 'fas mt-2 fa-check'}`}></i>
                                                {isFollowed ? ' Unfollow' : ' follow'}
                                            </span>
                                            <a
                                                href={PorfileData.youtube}
                                                target="_BLANK"
                                                rel="noopener noreferrer"
                                                className="btn btn-outline-secondary d-block mt-3"
                                            >
                                                <img
                                                    src="https://www.youtube.com/favicon.ico"
                                                    alt="Youtube Link"
                                                    style={{ width: '20px' }}
                                                />
                                                &nbsp; Youtube
                                            </a>

                                            <a
                                                href={PorfileData.linkedin}
                                                target="_BLANK"
                                                rel="noopener noreferrer"
                                                className="btn btn-outline-secondary d-block mt-3"
                                            >
                                                <img
                                                    src="https://www.linkedin.com/favicon.ico"
                                                    alt="Linkedin Link"
                                                    style={{ width: '20px' }}
                                                />
                                                &nbsp; Linkedin
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        {CourseData && CourseData.length > 0 ? (
                                            <div className="p-4">
                                                <h3 className="pagetitle mb-2">My Courses</h3>
                                                <div className="">
                                                    {CourseData.map((singi) => (
                                                        <div className="col-md-12  mb-3 ry_instr_shadow">
                                                            <Suspense
                                                                fallback={<div>Loading...</div>}
                                                            >
                                                                <CourseListBlock
                                                                    IMGBASEAPIURL={IMGBASEAPIURL}
                                                                    CleanUrlWork={CleanUrlWork}
                                                                    singi={singi}
                                                                    UPLOADNODEURL={UPLOADNODEURL}
                                                                    // setShareThisURL={setShareThisURL}
                                                                    // setshowShare={setshowShare}
                                                                    BASEURL={BASEURL}
                                                                />
                                                            </Suspense>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-1"></div>
                    </div>
                </div>
            </>
            <Footer className="appie-footer-about-area" />
            <BackToTop />
        </>
    );
}

export default InstructotPublicProfile;
