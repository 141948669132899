import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BASEAPIURL, BASEURL } from '../../../../config';
import Pagination from './MyBlogPagination';
import '../../../Helper/rypopup.css';

function showdatetimein(datetime) {
    const tmp = new Date(datetime);
    return `${tmp.getDate()}/${tmp.getMonth()}/${tmp.getUTCFullYear()}`;
}

function MyFreeTipsList({ forpage }) {
    const [NoBlog, setNoBlog] = useState(1);
    const [ShowAlert, setShowAlert] = useState(false);
    // for pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(5);
    const [TotalRecords, setTotalRecords] = useState(5);
    const nPages = Math.ceil(TotalRecords / recordsPerPage);
    // for pagination
    const [Blogs, setBlogs] = useState([]);
    const [BlogsCati, setBlogsCati] = useState([]);
    const [Keyword, setKeyword] = useState('');
    const [SortBy, setSortBy] = useState('');

    // const [drawer, drawerAction] = useToggle(false);
    // const [lfVisibility, setlfVisibility] = useState('block');

    const FetchFreeTips = async () => {
        try {
            const res = await fetch(`${BASEAPIURL}/admin/freetips/getAll`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('jwttoken')}`,
                },
                body: JSON.stringify({
                    createdID: localStorage.getItem('tid'),
                    createdBy: 'instructor',
                    recordsPerPage: 5,
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                setBlogs(resJson.data);
                setNoBlog(resJson.data.length);
                setBlogsCati(resJson.category);
                setTotalRecords(resJson.count);
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };
    const FilterFetchFreeTips = async (e, keywordhere, sortbyhere, PageNumber) => {
        // Keyword SortBy
        e.preventDefault();
        try {
            const res = await fetch(`${BASEAPIURL}/admin/freetips/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    keyword: keywordhere,
                    sortby: sortbyhere,
                    PageNumber,
                    recordsPerPage: 5,
                    createdID: localStorage.getItem('tid'),
                    createdBy: 'instructor',
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                setBlogs(resJson.data);
                setBlogsCati(resJson.category);
                setTotalRecords(resJson.count);
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
        return false;
    };
    useEffect(() => {
        FetchFreeTips();
    }, []);

    return (
        <>
            <div
                className="ryPopUpBack"
                style={{ display: ShowAlert ? '' : 'none' }}
                onClick={() => {
                    setShowAlert(false);
                }}
            ></div>
            <div
                className="ryPopUp"
                style={{ display: ShowAlert ? '' : 'none', position: 'fixed' }}
            >
                <anew
                    href="#"
                    className="ryPopUpAct"
                    onClick={() => {
                        setShowAlert(false);
                    }}
                >
                    X
                </anew>
                <div style={{ margin: '30px 50px' }}>
                    <h3>Are you sure, You want to udpate the Free Tips? </h3>
                    <div className="mt-4 mb-4">
                        <h6 className="text-danger">
                            The Free Tips is already live, If you edit it again, It will be again
                            goes back to on Review Status.
                        </h6>
                    </div>
                    <Link
                        to="/create-freetip"
                        target="_CREATE"
                        className="btn btn-docsta-instructor"
                        onClick={() => {
                            setShowAlert(false);
                        }}
                        style={{
                            width: '150px',
                            margin: '20px auto',
                        }}
                    >
                        Confirm Edit
                    </Link>
                </div>
            </div>
            <div className="ry_instr_shadow p-4" forpage={forpage}>
                <h4>
                    My Free Life Tips
                    <div style={{ clear: 'both' }}></div>
                </h4>
                <form
                    id="blog_filter_frm"
                    className="row mt-3"
                    style={{
                        display: NoBlog === 0 ? 'none' : '',
                    }}
                >
                    <div className="col-md-6">
                        <input
                            name="keyword"
                            type="text"
                            className="form-control"
                            placeholder="Search Anything"
                            value={Keyword}
                            onChange={(e) => {
                                setKeyword(e.target.value);
                                FilterFetchFreeTips(e, e.target.value, SortBy, 1);
                            }}
                        />
                    </div>
                    <div className="col-md-6">
                        <select
                            name="sortby"
                            defaultValue={SortBy}
                            className="form-control"
                            onChangeCapture={(e) => {
                                setSortBy(e.target.value);
                                FilterFetchFreeTips(e, Keyword, e.target.value, 1);
                            }}
                        >
                            <option value="0">Sort By</option>
                            <option value="a2z">Name A2Z</option>
                            <option value="z2a">Name Z2A</option>
                            <option value="latest">Latest</option>
                            <option value="old">Oldest</option>
                        </select>
                    </div>
                </form>
                <div
                    style={{
                        display: NoBlog === 0 ? '' : 'none',
                        background: '#f5f5f5',
                        margin: '20px auto',
                        padding: '35px',
                        textAlign: 'center',
                    }}
                >
                    <div>
                        <h4 style={{ color: '#5c5a5a' }}>
                            Currently, you don't have any Free Life tips in this segment.
                        </h4>
                        <h6 style={{ color: '#5c5a5a' }}>
                            Once you publish your Life tips, come here to view them.
                        </h6>
                    </div>
                </div>
                <div
                    style={{
                        display: NoBlog === 0 ? '' : 'none',
                    }}
                >
                    <div className="row">
                        <div className="col-md-8 p-4">
                            <h3 className="mb-2">Why create a Free Life Tip?</h3>
                            <h4 className="mb-3" style={{ color: '#7f13ab' }}>
                                Let your voice rise to fame
                            </h4>
                            <p>
                                Leaving your impact doesn't always have to be with videos, our Free
                                Life Tips option provides you the freedom to spread your knowledge
                                through just your voice.
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                These small audio snippets are easy for you to create and powerful
                                enough to impact the lives of millions. By giving these free tiny
                                tips of knowledge, you are easily attracting a mass audience for all
                                your courses.
                            </p>
                            <Link
                                to="/create-freetip"
                                target="_CREATE"
                                className="btn btn-docsta-instructor ry-pull-left mt-3"
                                style={{ width: '33%', minWidth: '190px', borderRadius: '0px' }}
                                onClick={() => {
                                    localStorage.setItem('edit_fretip_id', 0);
                                }}
                            >
                                Create a Free Life Tip
                            </Link>
                        </div>
                        <div className="col-md-4 text-center">
                            <img
                                alt=""
                                src={`${BASEURL}/images/nofreetip.jpg`}
                                style={{ marginTop: '50px' }}
                            />
                        </div>
                    </div>
                </div>
                <div
                    className="mt-3 table-responsive"
                    style={{
                        display: NoBlog === 0 ? 'none' : '',
                    }}
                >
                    <table className="table table-hover table-borddered table-stripped">
                        <thead className="ry-table-header">
                            <tr>
                                <th>Title</th>
                                <th>Listner</th>
                                <th>Status</th>
                                <th>Created on</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Blogs.map((singi) => (
                                <tr key={singi.blogid}>
                                    <td>
                                        <div style={{ display: 'inline-block' }} className="">
                                            <b>{singi.title}</b>
                                            <br />
                                            <small style={{ fontSize: '70%' }}>
                                                {BlogsCati.map((cti) => (
                                                    <div key={cti.freetipid}>
                                                        {singi.category === cti.freetipid ? (
                                                            <div className="text-info">
                                                                <b>Category:</b> {cti.title}
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                ))}
                                            </small>
                                        </div>
                                    </td>
                                    <td>{singi.listner}</td>
                                    <td>
                                        {singi.status === 1 ? (
                                            <button
                                                type="button"
                                                className="alert p-1 alert-success"
                                            >
                                                Live
                                            </button>
                                        ) : (
                                            ''
                                        )}
                                        {singi.status === 2 ? (
                                            <button
                                                type="button"
                                                className="alert p-1 alert-warning"
                                                title="After reviewing your request, we will get back to you."
                                            >
                                                On Review
                                            </button>
                                        ) : (
                                            ''
                                        )}

                                        {singi.status === 0 ? (
                                            <button
                                                type="button"
                                                className="alert p-1 alert-danger"
                                                title="Your Entry rejected to Publish."
                                            >
                                                Rejected
                                            </button>
                                        ) : (
                                            ''
                                        )}
                                    </td>
                                    <td>
                                        {showdatetimein(singi.createdAt)}
                                        {singi.status === 1 ? (
                                            <Link
                                                to="#"
                                                className="btn btn-docsta-instructor ry-pull-right"
                                                style={{ width: 'auto', borderRadius: '10px' }}
                                                onClick={() => {
                                                    localStorage.setItem(
                                                        'edit_freetip_id',
                                                        singi.freetipautoip
                                                    );
                                                    setShowAlert(true);
                                                }}
                                            >
                                                <i className="fa fa-edit"></i>
                                            </Link>
                                        ) : (
                                            <Link
                                                to="/create-freetip"
                                                target="_CREATE"
                                                className="btn btn-docsta-instructor ry-pull-right"
                                                style={{ width: 'auto', borderRadius: '10px' }}
                                                onClick={() => {
                                                    localStorage.setItem(
                                                        'edit_freetip_id',
                                                        singi.freetipautoip
                                                    );
                                                }}
                                            >
                                                <i className="fa fa-edit"></i>
                                            </Link>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div
                    className="mt-3 table-responsive"
                    style={{
                        display: NoBlog === 0 ? 'none' : '',
                    }}
                >
                    <small className="text-center d-block mb-2">
                        Showing Page {currentPage} of Total page {nPages} from {TotalRecords}
                        &nbsp;Records
                    </small>
                    <Pagination
                        nPages={nPages}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        FetchFilterBlogs={FilterFetchFreeTips}
                        SortBy={SortBy}
                        Keyword={Keyword}
                    />
                </div>
            </div>
        </>
    );
}

export default MyFreeTipsList;
