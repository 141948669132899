import React from 'react';
import { Link } from 'react-router-dom';
import { IMGBASEAPIURL } from '../../config';

function DateVenueBox() {
    return (
        <>
            <section className="date-venue-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="venueBox">
                                <div className="venueImg">
                                    <img
                                        src={`${IMGBASEAPIURL}institute/venue/venue-img1.png`}
                                        alt=""
                                    />
                                </div>
                                <div className="venueText">
                                    <h3>Want to train at your convenience?</h3>
                                    <h4>Pick your choice of Date and Venue</h4>
                                    <Link to="/bookaclass" className="main-btn">
                                        Send Enquiry
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DateVenueBox;
