import axios from 'axios';
import { BASEAPIURL } from '../config';

const DataService = axios.create({
  baseURL: BASEAPIURL,
  timeout: 30000,
  //   headers: { 'X-Custom-Header': 'foobar' },
});

export default DataService;
