import React from 'react';
import { Link } from 'react-router-dom';
import { IMGBASEAPIURL } from '../../config';

function BannerBox() {
    return (
        <>
            <section className="appie-traffic-area">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="bannerBoxOne bgTwo">
                                <div className="bannerText pt-3 pb-3">
                                    <h4>Know our Legacy</h4>
                                    <p>
                                        20+ years of more than 1200+ renowned faculty led to this
                                        success.
                                    </p>
                                    <Link to="/courses" className="main-btn">
                                        View Courses
                                    </Link>
                                </div>
                                <div className="bannerImg">
                                    <img
                                        src={`${IMGBASEAPIURL}institute/banner/img2.png`}
                                        alt=""
                                        style={{ maxWidth: '270px' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="bannerBoxOne">
                                <div className="bannerText  pt-3 pb-3">
                                    <h4>Earn A Certificate</h4>
                                    <p>
                                        Get the right professional certificate program for you.
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </p>
                                    <Link to="/courses" className="main-btn">
                                        View Programs
                                    </Link>
                                </div>
                                <div className="bannerImg">
                                    <img
                                        src={`${IMGBASEAPIURL}institute/banner/img1.png`}
                                        alt=""
                                        style={{ maxWidth: '270px' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BannerBox;
