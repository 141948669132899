import React from 'react';
import useToggle from '../../../Hooks/useToggle';
import BackToTop from '../../BackToTop';
import Drawer from '../../Mobile/DrawerInstitute';
import Header from '../../Helper/HeaderInstitute';
import Footer from '../../Helper/FooterInstitute';
import InstructorsList from './InstructorsList';
import BannerBox from '../BannerBox';
import PopularCoursesBox from '../PopularCoursesBox';

function DocInstitute() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <InstructorsList className="pt-70 pb-50" />
            <BannerBox />
            <PopularCoursesBox />
            <Footer />
            <BackToTop />
        </>
    );
}

export default DocInstitute;
