import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
// import Parser from 'html-react-parser';
import useToggle from '../../../../Hooks/useToggle';
import BackToTop from '../../../BackToTop';
import Footer from '../../../Helper/FooterLearn';
import Drawer from '../../../Mobile/DrawerLearn';
// import Header from '../Helper/Header';
import HeaderInstructor from '../../InstructorIndex_Insti';
import ProfileMenu from './ProfileMenu';
import Dashboard from './Dashboard';
import ChangePassword from './ChangePassword';
import MyCourses from './MyCourses';
import ProfileSetting from './ProfileSetting';
import ProfileSettingUpdate from './ProfileSettingUpdate';
import MyLifeTips from './MyLifeTips';
import MyBlog from './MyBlog';
import MyQa from './MyQa';
import MyReviews from './MyReviews';
import MyRevenue from './MyRevenue';
import MyStudents from './MyStudents';
import MyQuestionBank from './MyQuestionBank';
import MyWebinar from './MyWebinar';
import PayoutSetting from './PayoutSetting';
import '../../instructor.css';
import LoginPage from '../Login';
import { UPLOADNODEURL, BASEAPIURL } from '../../../../config';
import { useSelector, useDispatch } from 'react-redux';
import { UpdateAccountData } from '../../../../reduxFunction/instructorSlice';
// import UploadChunk from '../Video2R2/uploadChunk';

function InstructorDashboard() {
    const [Loaded, setLoaded] = useState(false);
    const dispatch = useDispatch();
    const instructorData = useSelector((state) => state.instructorData)
    // const navigate = useNavigate();
    const [isLogin, setisLogin] = useState(false);
    const [Profile, setProfile] = useState({
        name: '',
        photo: '2022/11/22/1671716288627_9537.png',
    });
    const { PageName } = useParams();
    const [drawer, drawerAction] = useToggle(false);

    useEffect(() => {
        const FetchProfile = async () => {
            // let theURL = `${BASEAPIURL}/admin/instructure/myprofile`;
            // if (forUrl === 1) {
            //     theURL = `${BASEAPIURL}/admin/instructure/profileCounterData`;
            // }
            const theURL = `${BASEAPIURL}/admin/instructure/profileCounterData`;
            try {
                const res = await fetch(theURL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Basic ${localStorage.getItem('jwttoken')}`,
                    },
                    body: JSON.stringify({
                        token: localStorage.getItem('token'),
                    }),
                });
                const resJson = await res.json();
                setLoaded(true);
                if (res.status === 200) {
                    // if (forUrl === 1) {   } 
                    const chartData = [];
                        chartData.push(["Course", "Amount"]);

                        if (resJson.dataCourse && resJson.dataCourse.length > 0) {
                            resJson.dataCourse.forEach((item) => {
                                chartData.push([item.title, 0]);
                            });
                        }

                        if (resJson.dataBanks && resJson.dataBanks.length > 0) {
                            resJson.dataBanks.forEach((item) => {
                                chartData.push([item.title, 0]);
                            });
                        }
                         
                    dispatch(UpdateAccountData({ChartData: chartData, dataCourse: resJson.dataCourse, dataBanks: resJson.dataBanks}));
                    
                    if (resJson.data !== null) {
                        if (
                            Object.keys(resJson.data).length > 0 &&
                            localStorage.getItem('usertype') === 'instructor'
                        ) {
                            setProfile(resJson.data);
                            dispatch(UpdateAccountData(resJson.data))
                            setisLogin(true);
                            localStorage.setItem('tid', resJson.data.tid);
                        } else {
                            setisLogin(false);
                        }
                    } else {
                        setisLogin(false);
                    }
                } else {
                    setisLogin(false);
                    console.log(resJson.message);
                }
            } catch (err) {
                setisLogin(false);
                setLoaded(true);
                console.log(err);
            }
        };
        FetchProfile(1);
        const interval = setInterval(() => {
            FetchProfile(0);
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className={ Loaded ? '' : 'd-none'}>
            <Drawer drawer={drawer} action={drawerAction.toggle} type="instructor" />
            <HeaderInstructor drawer={drawer} action={drawerAction.toggle} />
            <div style={{ display: isLogin ? 'none' : '' }}>
                <LoginPage setisLogin={setisLogin} />
            </div>
            <div style={{ display: isLogin ? '' : 'none' }}>
                <div className="container pt-4 pb-4">
                    <div className="mb-4">
                        <h3>
                            <img
                                src={`${UPLOADNODEURL}${instructorData.photo}`}
                                alt={Profile.name}
                                className="mr-3 instr_profile_pics ry-pull-left"
                                style={{ float: 'left' }}
                            />
                            <div style={{ display: 'inline-block' }} className="mr-3 pt-2">
                                {instructorData.name}
                                <br />
                                <small style={{ fontSize: '50%' }}>
                                    <>
                                        <i className="fal fa-star text-warning" /> <b>4.7/5.0</b>
                                        &nbsp;
                                        <i className="fal fa-star text-warning" /> {instructorData.totalCourse} Courses&nbsp;
                                        <i className="fal fa-star text-warning" /> {instructorData.totalQb} Question Banks&nbsp;
                                        <i className="fal fa-star text-warning" /> {instructorData.totalLearner} Students
                                        &nbsp;
                                        <i className="fal fa-star text-warning" /> {instructorData.totalFollower} Followers
                                        {/* &nbsp;
                                        <i className="fal fa-star text-warning" /> 14M Watch Mins */}
                                        &nbsp;
                                        <i className="fal fa-star text-warning" /> {instructorData.totalReviews} Reviews
                                    </>
                                </small>
                            </div>
                            {PageName === undefined ||
                            PageName === 'index' ||
                            PageName === 'mycourses' ? (
                                <Link
                                    to="/create-course"
                                    target="_CREATE"
                                    className="btn btn-docsta-instructor ry-pull-right"
                                    style={{ width: 'auto', borderRadius: '10px' }}
                                    onClick={() => {
                                        localStorage.setItem('edit_course_id', 0);
                                    }}
                                >
                                    Create a Course
                                </Link>
                            ) : (
                                ''
                            )}
                            {PageName === 'myquestionbank' ? (
                                <Link
                                    to="/create-qb"
                                    target="_CREATE"
                                    className="btn btn-docsta-instructor ry-pull-right"
                                    style={{ width: 'auto', borderRadius: '10px' }}
                                    onClick={() => {
                                        localStorage.setItem('edit_qb_id', 0);
                                    }}
                                >
                                    Create a Question Bank
                                </Link>
                            ) : (
                                ''
                            )}
                            {PageName === 'mywebinar' ? (
                                <Link
                                    to="/create-webinar"
                                    target="_CREATE"
                                    className="btn btn-docsta-instructor ry-pull-right"
                                    style={{ width: 'auto', borderRadius: '10px' }}
                                >
                                    Create a Webinar
                                </Link>
                            ) : (
                                ''
                            )}
                            {PageName === 'myblog' ? (
                                <Link
                                    to="/create-blog"
                                    target="_CREATE"
                                    className="btn btn-docsta-instructor ry-pull-right"
                                    style={{ width: 'auto', borderRadius: '10px' }}
                                    onClick={() => {
                                        localStorage.setItem('edit_blog_id', 0);
                                    }}
                                >
                                    Create a New Blog
                                </Link>
                            ) : (
                                ''
                            )}
                            {PageName === 'mylifetips' ? (
                                <Link
                                    to="/create-freetip"
                                    target="_CREATE"
                                    className="btn btn-docsta-instructor ry-pull-right"
                                    style={{ width: 'auto', borderRadius: '10px' }}
                                    onClick={() => {
                                        localStorage.setItem('edit_freetip_id', 0);
                                    }}
                                >
                                    Create a Free Life Tips
                                </Link>
                            ) : (
                                ''
                            )}
                            <div style={{ clear: 'both' }}></div>
                        </h3>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <ProfileMenu forpage={PageName} />
                        </div>

                        <div className="col-md-9">
                            {PageName === undefined ? <Dashboard forpage={PageName} /> : ''}
                            {PageName === 'changePassword' ? <ChangePassword /> : ''}
                            {PageName === 'index' ? <Dashboard forpage={PageName} /> : ''}
                            {PageName === 'mylifetips' ? <MyLifeTips forpage={PageName} /> : ''}
                            {PageName === 'myblog' ? <MyBlog forpage={PageName} /> : ''}
                            {PageName === 'myqa' ? <MyQa forpage={PageName} /> : ''}
                            {PageName === 'myreviews' ? <MyReviews forpage={PageName} /> : ''}
                            {PageName === 'myrevenue' ? <MyRevenue forpage={PageName} /> : ''}
                            {PageName === 'mystudents' ? <MyStudents forpage={PageName} /> : ''}
                            {PageName === 'mycourses' ? <MyCourses forpage={PageName} /> : ''}
                            {PageName === 'mywebinar' ? <MyWebinar forpage={PageName} /> : ''}
                            {PageName === 'payoutsetting' ? (
                                <PayoutSetting forpage={PageName} />
                            ) : (
                                ''
                            )}
                            {PageName === 'profilesetting' ? (
                                <ProfileSetting forpage={PageName} />
                            ) : (
                                ''
                            )}
                            {PageName === 'updateprofilesetting' ? (
                                <ProfileSettingUpdate forpage={PageName} />
                            ) : (
                                ''
                            )}
                            {PageName === 'myquestionbank' ? (
                                <MyQuestionBank forpage={PageName} />
                            ) : (
                                ''
                            )}
                        </div>

                        <div className="col-md-1"></div>
                    </div>
                </div>
            </div>
            <Footer className="appie-footer-about-area" />
            <BackToTop />
        </div>
    );
}

export default InstructorDashboard;
