import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Parser from 'html-react-parser';
import {
    BASEURL,
    BASEAPIURL,
    UPLOADNODEURL,
    ShowPriceDisplaySmall,
    ShowPriceDisplay,
} from '../../config';
import { AddtoCartNoPopup } from '../Students/functions';

function Add2CartPopup({ targetID, targetType }) {
    const PrimaryKey = '_id';
    const [OtherTitle, setOtherTitle] = useState('Frequently Bought Together');
    const [AddedAlltoCart, setAddedAlltoCart] = useState(false);
    const [CourseDataRelated, setCourseDataRelated] = useState([]);
    const [CourseData, setCourseData] = useState([]);
    const FetchCartData = async (targetID1, targetType1) => {
        try {
            const res = await fetch(`${BASEAPIURL}/payments/checkout/getAddedtoCart`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
                body: JSON.stringify({ targetID: targetID1, targetType: targetType1 }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                if (resJson.dataRelated && resJson.dataRelated.length > 0) {
                    setCourseDataRelated(resJson.dataRelated);
                    setOtherTitle('Frequently Bought Together');
                } else if (resJson.dataOther && resJson.dataOther.length > 0) {
                    setCourseDataRelated(resJson.dataOther);
                    setOtherTitle('Other Courses');
                }
                if (resJson.data && resJson.data.length > 0) {
                    setCourseData(resJson.data);
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    const showTotalFBT = () => {
        let costinr = 0;
        let cost = 0;
        let FullValue = 0;
        let DiscountedValue = 0;
        CourseDataRelated.map((single) => {
            costinr = Number(costinr) + Number(single.costinr);
            cost = Number(cost) + Number(single.cost);
            FullValue = Number(FullValue) + Number(single.cost);
            if (single.campaign && Number(single.campaign) > 0) {
                DiscountedValue =
                    Number(DiscountedValue) +
                    (Number(single.cost) * (100 - Number(single.campaign))) / 100;
            }
            return '';
        });
        let discount = Number(((FullValue - DiscountedValue) / FullValue) * 100).toFixed(0);
        if (Number(discount) === 100) {
            discount = 0;
        }
        const returnValue =  Parser(ShowPriceDisplay(discount, cost, costinr));
        return returnValue
    };

    const AddAlltoCart = () => {
        CourseDataRelated.map((single) => {
            AddtoCartNoPopup(
                localStorage.getItem('loggedToken'),
                single[PrimaryKey],
                'MainCourse',
                localStorage.getItem('usertype')
            );
            return single;
        });
        window.location.href = `${BASEURL}checkout/cart`;
        setAddedAlltoCart(false);
        return 0;
    };

    useEffect(() => {
        if (targetID && targetID !== '') {
            FetchCartData(targetID, targetType);
        } else {
            // window.location.href = `${BASEURL}`;
        }
    }, [targetID, targetType]);

    return (
        <>
            <>
                <h4
                    className="text-center"
                    style={{ display: CourseData.length > 0 ? '' : 'none' }}
                >
                    Added to cart
                </h4>
                <div className="container pt-4 pb-4">
                    {Number(localStorage.getItem('screenwidth')) > 786 && CourseData.map((single) => (
                        <div key={single[PrimaryKey]} style={{ display: 'flex' }}>
                            <div>
                                <img
                                    src={`${UPLOADNODEURL}${single.image}`}
                                    style={{ width: '60px' }}
                                    alt={single.title}
                                    title={single.title}
                                    className="mr-2 mt-2 mb-2"
                                />
                            </div>
                            <div style={{ width: '60%' }}>
                                <h6>{single.title}</h6>
                                <small>{single.InstructorDisplay}</small>
                            </div>
                            <div style={{ width: '20%', textAlign: 'right' }}>
                                <a href={`${BASEURL}checkout/cart`} className="main-btn">
                                    Checkout
                                </a>
                            </div>
                        </div>
                    ))}
                    {Number(localStorage.getItem('screenwidth')) < 787 && CourseData.map((single) => (
                        <div key={single[PrimaryKey]}>
                            <div className="text-center">
                                <img
                                    src={`${UPLOADNODEURL}${single.image}`}
                                    style={{ width: '70%', minWidth: '70px', margin: '0px auto', display: 'block' }}
                                    alt={single.title}
                                    title={single.title}
                                    className="mt-2 mb-2"
                                />
                            </div>
                            <div className="text-center mt-2">
                                <h6>{single.title}</h6>
                                <small>By {single.InstructorDisplay}</small>
                            </div>
                            <div className="text-center mt-2" style={{ textAlign: 'center' }}>
                                <a href={`${BASEURL}checkout/cart`} className="main-btn" style={{ fontSize: '14px' }}>
                                    Checkout
                                </a>
                            </div>
                        </div>
                    ))}
                    {CourseDataRelated.length > 0 ? (
                        <div className="p-3 mt-4 mb-2" style={{ border: Number(localStorage.getItem('screenwidth')) > 786 ? '1px solid #dedede' : '' }}>
                            <div className="mb-3">
                                <b>{OtherTitle}</b>
                            </div>
                            {CourseDataRelated.map((single) => (
                                <div key={single[PrimaryKey]}>
                                    {Number(localStorage.getItem('screenwidth')) > 786 ? (
                                        <div className="mb-3" style={{ display: 'flex' }}>
                                            <Link to={`course/${single.slug}`} className="mr-4" style={{ width: '15%', textAlign: 'left' }}>
                                                <img
                                                    src={`${UPLOADNODEURL}${single.image}`}
                                                    style={{ width: '100%', maxWidth: '100px' }}
                                                    alt={single.title}
                                                    title={single.title}
                                                    className="mt-1 mb-3"
                                                />
                                            </Link>
                                            <div className="mr-4" style={{ width: '60%' }}>
                                                <b>{single.title}</b>
                                                <br />
                                                <small>{single.InstructorDisplay}</small>
                                            </div>
                                            <div style={{ width: '25%', textAlign: 'right' }}>
                                                {Parser(
                                                    ShowPriceDisplaySmall(
                                                        single.campaign,
                                                        single.cost,
                                                        single.costinr
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='row'>
                                            <div className='col-3 pl-1 pr-0 pt-0 pb-0'>
                                                <Link to={`course/${single.slug}`} className="" style={{ textAlign: 'left' }}>
                                                    <img
                                                        src={`${UPLOADNODEURL}${single.image}`}
                                                        style={{ width: '100%' }}
                                                        alt={single.title}
                                                        title={single.title}
                                                        className="mt-1 mb-3"
                                                    />
                                                </Link>
                                            </div>
                                            <div className='col-9 pl-2 pr-0 pt-0 pb-0'>
                                                <small className="d-block">
                                                    <b className='CLB_course_title d-block'>{single.title}</b>
                                                    <span>{single.InstructorDisplay}</span>
                                                </small>
                                                <div style={{ transform: 'scale(0.7)', transformOrigin: 0 }}>
                                                    {Parser(
                                                        ShowPriceDisplaySmall(
                                                            single.campaign,
                                                            single.cost,
                                                            single.costinr
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                            <div className='col-12 mb-3' style={{ border: '1px solid #dedede' }}></div>
                                            {/* <hr className='col-12 p-0' /> */}
                                        </div>
                                    )}
                                </div>
                            ))}
                            {Number(localStorage.getItem('screenwidth')) > 786 ? (
                                <div className="mt-3" style={{ display: 'flex' }}>
                                    <div style={{ width: '50%', display: 'flex' }}>
                                        <b>Total:</b>&nbsp; &nbsp; {showTotalFBT()}
                                    </div>
                                    <div style={{ width: '50%', textAlign: 'right' }}>
                                        {!AddedAlltoCart ? (
                                            <Link
                                                to="/checkout/cart"
                                                className="btn btn-outline"
                                                style={{
                                                    color: '#7f13ab',
                                                    borderColor: '#7f13ab',
                                                }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    AddAlltoCart();
                                                }}
                                            >
                                                Add All to cart
                                            </Link>
                                        ) : (
                                            <Link to="/checkout/cart" className="main-btn">
                                                View cart
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="mt-0">
                                    <div style={{ transform: 'scale(0.8)', display: 'flex', transformOrigin: 0 }}>
                                        <b>Total:</b>&nbsp; &nbsp;
                                        <span>{showTotalFBT()}</span>
                                    </div>
                                    <div className="mt-1 text-center">
                                        {!AddedAlltoCart ? (
                                            <Link
                                                to="/checkout/cart"
                                                className="btn btn-outline d-block"
                                                style={{
                                                    color: '#7f13ab',
                                                    borderColor: '#7f13ab',
                                                }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    AddAlltoCart();
                                                }}
                                            >
                                                Add All to cart
                                            </Link>
                                        ) : (
                                            <Link to="/checkout/cart" className="main-btn">
                                                View cart
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </>
        </>
    );
}

export default Add2CartPopup;
