import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import { IMGBASEAPIURL, BASEAPIURL, getDefaultCountry } from '../../../config';

function ContactUs() {
    const [APICall, SetAPICall] = useState(false);
    const [styleBTN, setstyleBTN] = useState({});
    const [styleMSG, setstyleMSG] = useState({ display: 'none' });
    const [Message, setMessage] = useState(
        `<img src="${IMGBASEAPIURL}loader.gif" style="max-width: 70px; margin: 0px auto; display: block;" />`
    );
    const [ContactForm, setContactForm] = useState({
        fname: '',
        lname: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
    });

    const setPhoneNumber = (value) => {
        setContactForm({ ...ContactForm, phone: value });
    };

    const HandleForm = (name, value) => {
        setContactForm({ ...ContactForm, [name]: value });
    };

    const SubmitContactForm = async (e) => {
        SetAPICall(true);
        e.preventDefault();
        setstyleMSG({ display: 'block' });
        try {
            const res = await fetch(`${BASEAPIURL}/institute/enquiry/send-contact-us`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(ContactForm),
            });
            setstyleBTN({ display: 'none' });

            const resJson = await res.json();
            // alert(res.status);
            SetAPICall(false);
            if (resJson.status === 1) {
                setContactForm({
                    fname: '',
                    lname: '',
                    email: '',
                    phone: '',
                    subject: '',
                    message: '',
                });
                setMessage(`<div class="alert alert-success">${resJson.message}</div>`);
            } else {
                setMessage(`<div class="alert alert-danger">${resJson.message}</div>`);
            }
            setstyleBTN({ display: '' });
        } catch (err) {
            setstyleBTN({ display: '' });
            SetAPICall(false);
            setMessage(`<div class="alert alert-danger">${err.message}</div>`);
        }
    };

    return (
        <>
            <section
                className="corporate-learning"
                data-overlay="6"
                style={{
                    backgroundImage: `url(${IMGBASEAPIURL}institute/contactus/contact-us.jpg)`,
                    padding: '70px 10px',
                    backgroundPosition: 'center',
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="headBlock">
                                <h3 className="mt-5 mb-0">Contact Us</h3>
                                <p className="mb-5">We're always eager to hear from you!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contact-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="contact--info-area">
                                <h3>Get in touch</h3>
                                <p>We're always eager to hear from you!</p>
                                <div className="single-info">
                                    <h5>Address</h5>
                                    <p>
                                        <i className="fal fa-map-marker-alt" />
                                        <b>DocSta Learn</b>
                                        <br />
                                        TC 15/148 (16), First Floor, Nakshatra, Opposite MG College,
                                        Above CBC Honda, Kesavadasapuram, Thiruvananthapuram, Kerala
                                        695004
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>Phone</h5>
                                    <p>
                                        <i className="fal fa-phone" />
                                        <b>Mobile:</b>
                                        <a href="tel:918714702941">+91 8714702941</a>
                                        <br />
                                        <b>Landline:</b>
                                        <a href="tel:914713577355">+91 471 3577355</a>
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>Email</h5>
                                    <p>
                                        <i className="fal fa-envelope" />
                                        <a href="mailto:info@docsta.com">info@docsta.com</a>
                                    </p>
                                </div>
                                {/* <div className="single-info d-done">
                                    <h5>Hours of Operation</h5>
                                    <p>
                                        <i className="fal fa-clock" />
                                        Monday - Sunday: 09:00 - 21:00
                                    </p>
                                </div> */}
                                <div className="ab-social">
                                    <h5>Social Media</h5>
                                    <a
                                        className="you"
                                        href="https://www.youtube.com/@docsta"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fab fa-youtube" />
                                    </a>
                                    <a
                                        className="fac"
                                        href="https://www.facebook.com/docstalearn"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fab fa-facebook-f" />
                                    </a>
                                    <a
                                        className="lin"
                                        href="https://www.linkedin.com/company/docsta/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fab fa-linkedin-in" />
                                    </a>
                                    <a
                                        className="inst"
                                        href="https://www.instagram.com/docstalearn/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fab fa-instagram" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="contact-form">
                                <h4>Let's Connect</h4>
                                <p>
                                    Fill the form below so we can get to know you and your needs
                                    better.
                                </p>
                                <form
                                    method="post"
                                    className="row"
                                    onSubmit={(e) => {
                                        SubmitContactForm(e);
                                    }}
                                >
                                    <div className="col-md-12">
                                        <input
                                            type="text"
                                            name="fname"
                                            value={ContactForm.fname}
                                            required
                                            placeholder="Enter Your Name"
                                            onChange={(e) => {
                                                HandleForm(e.target.name, e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-6 d-none">
                                        <input
                                            type="text"
                                            name="lname"
                                            value={ContactForm.lname}
                                            placeholder="Enter Last Name"
                                            onChange={(e) => {
                                                HandleForm(e.target.name, e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="email"
                                            required
                                            name="email"
                                            value={ContactForm.email}
                                            placeholder="Enter Email ID"
                                            onChange={(e) => {
                                                HandleForm(e.target.name, e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <PhoneInput
                                            required
                                            international
                                            defaultCountry={getDefaultCountry()}
                                            className="form-control"
                                            name="phonenumber"
                                            value={ContactForm.phone}
                                            onChange={setPhoneNumber}
                                            style={{ position: 'relative' }}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <textarea
                                            name="message"
                                            value={ContactForm.message}
                                            placeholder="How can we help?"
                                            onChange={(e) => {
                                                HandleForm(e.target.name, e.target.value);
                                            }}
                                            style={{
                                                marginTop: '20px',
                                            }}
                                        ></textarea>
                                    </div>
                                    <div className="col-md-12" style={{ clear: 'both' }}>
                                        <div style={styleMSG} dangerouslySetInnerHTML={{__html: Message}}></div>
                                    </div>
                                    <div className="col-md-6 d-none">
                                        <div className="condition-check">
                                            <input
                                                name="isaccept"
                                                id="terms-conditions"
                                                type="checkbox"
                                                style={{
                                                    display: 'inline-block',
                                                    opacity: 0,
                                                }}
                                            />
                                            <label htmlFor="terms-conditions">
                                                I agree to the &nbsp;
                                                <Link
                                                    target="_BLANK"
                                                    to="/information/termandconditions"
                                                >
                                                    <b>Terms & Conditions</b>
                                                </Link>
                                            </label>
                                        </div>
                                    </div>
                                    <div style={styleBTN} className="col-md-6 text-right">
                                        <input type="submit" disabled={APICall} name="submit" value="Send Message" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="bisylms-map">
                <iframe
                    title="map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3945.6575194567063!2d76.9405043!3d8.5325911!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b05b91ad6abea07%3A0x4cbb48256ad216fe!2sDocSta%20Learn!5e0!3m2!1sen!2sin!4v1695295502706!5m2!1sen!2sin"
                ></iframe>
            </div>
        </>
    );
}

export default ContactUs;
