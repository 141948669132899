import React, { useState, useEffect, Suspense } from 'react';
import { Link, useParams } from 'react-router-dom';
import { BASEAPIURL, BASEURL, UPLOADNODEURL } from '../../../config';
import Pagination from '../../Instructor/pages/Dashboard/MyBlogPagination';
import './blog.css';

const Sharethis = React.lazy(() => import('../../Helper/Sharethis'));
// eslint-disable-next-line no-underscore-dangle

// const HighlyPopular = React.lazy(() => import('../HighlyPopularCourses'));
const HighlyPopular = React.lazy(() => import('../PopularCoursesBox'));
const BlogPreview = React.lazy(() => import('./BlogPreview'));

function showdatetimein(datetime) {
    const tmp = new Date(datetime);
    return `${tmp.getDate()}/${tmp.getMonth()}/${tmp.getUTCFullYear()}`;
}

function BlogMain() {
    // for share this
    const [ShareThisURL, setShareThisURL] = useState(BASEURL);
    const [showShare, setshowShare] = useState(false);
    function closeshowShare() {
        setshowShare(false);
    }
    // for share this
    // for the Categories
    const [ShowCatId, setShowCatId] = useState(0);
    const [ShowCatTit, setShowCatTit] = useState('');
    const [ShowCatDesc, setShowCatDesc] = useState('');
    // for the Categories
    // for pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(6);
    const { CatID } = useParams();
    const [TotalRecords, setTotalRecords] = useState(50);
    const nPages = Math.ceil(TotalRecords / recordsPerPage);
    // for pagination
    // const [Category, setCategory] = useState('');
    const [Keyword, setKeyword] = useState('');
    const [SortBy] = useState('');
    const [Blogs, setBlogs] = useState([]);
    const [BlogsCati, setBlogsCati] = useState([]);

    const FetchFilterBlogs = async (e, keywordhere, sortbyhere, PageNumber) => {
        // Keyword SortBy
        e.preventDefault();
        try {
            const res = await fetch(`${BASEAPIURL}/blog/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    keyword: keywordhere,
                    sortby: sortbyhere,
                    PageNumber,
                    category: ShowCatId,
                    recordsPerPage: 9,
                    SortStatus: 1,
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                setBlogs(resJson.data);
                setBlogsCati(resJson.category);
                if (resJson.category && resJson.category.length > 0) {
                    setShowCatId(resJson.category[0].blogcatid);
                    setShowCatTit(resJson.category[0].title);
                    setShowCatDesc(resJson.category[0].metadescription);
                }
                setTotalRecords(resJson.count);
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
        return false;
    };
    const [TopBlogs, setTopBlogs] = useState([]);
    const [FeaturedBlogs, setFeaturedBlogs] = useState([]);
    const FetchTopBlogs = async (forHere, SortByHere, Limited, keywordHere) => {
        // Keyword SortBy
        try {
            const res = await fetch(`${BASEAPIURL}/blog/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    keyword: keywordHere,
                    sortby: SortByHere,
                    PageNumber: 1,
                    category: 0,
                    recordsPerPage: Limited,
                    SortStatus: 1,
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                if (forHere === 'featured') {
                    setFeaturedBlogs(resJson.data);
                } else {
                    setTopBlogs(resJson.data);
                }
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
        return false;
    };
    const FetchCategoryBlogs = async (category) => {
        // Keyword SortBy
        try {
            const res = await fetch(`${BASEAPIURL}/blog/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    sortby: 'latest',
                    PageNumber: 1,
                    category,
                    recordsPerPage: 9,
                    SortStatus: 1,
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                const section = document.querySelector('#categoryData');
                section.scrollIntoView({ behavior: 'smooth', block: 'start' });

                setBlogs(resJson.data);
                setTotalRecords(resJson.count);
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
        return false;
    };

    useEffect(() => {
        FetchTopBlogs('featured', 'featured', 9, '');
        FetchTopBlogs('trending', 'trending', 6, '');
        const FetchBlogs = async () => {
            try {
                const res = await fetch(`${BASEAPIURL}/blog/getAll`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        category: CatID,
                        SortStatus: 1,
                        recordsPerPage: 9,
                    }),
                });
                const resJson = await res.json();
                if (res.status === 200) {
                    setBlogs(resJson.data);
                    setBlogsCati(resJson.category);
                    setTotalRecords(resJson.count);
                } else {
                    console.log(resJson.message);
                }
            } catch (err) {
                console.log(err);
            }
        };
        FetchBlogs();
    }, [CatID]);

    return (
        <>
            {showShare && (
                <div>
                    <div className="mfp-bg mfp-ready"></div>
                    <div
                        className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
                        tabIndex="-1"
                        style={{ overflow: ' hidden auto' }}
                    >
                        <div className="mfp-container mfp-s-ready mfp-iframe-holder">
                            <div className="mfp-content" style={{ maxWidth: 'max-content' }}>
                                <div className="mfp-iframe-scaler-old">
                                    <button
                                        onClick={closeshowShare}
                                        title="Close (Esc)"
                                        type="button"
                                        className="mfp-close"
                                    >
                                        ×
                                    </button>
                                </div>
                                <div
                                    className="mfp-iframe p-4 text-center"
                                    style={{ background: '#fff' }}
                                >
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <Sharethis ShareThisURL={ShareThisURL} />
                                    </Suspense>
                                </div>
                            </div>
                            <div className="mfp-preloader">Loading...</div>
                        </div>
                    </div>
                </div>
            )}
            <div
                className="text-center pt-4 pb-4"
                style={{
                    background: '#d6e6ff',
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div>
                                <h1>Blog</h1>
                                <p className="mt-2">
                                    Awaken your mind with our most trending blogs
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="mt-5 blogpage-section1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="row">
                                {TopBlogs.map((singi) => (
                                    <div key={singi.blogid} className="col-md-6">
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <BlogPreview
                                                key={singi.blogid}
                                                BlogsDetails={singi}
                                                BlogsCati={BlogsCati}
                                                setShareThisURL={setShareThisURL}
                                                setshowShare={setshowShare}
                                                BASEURL={BASEURL}
                                            />
                                        </Suspense>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="blog-sidebar">
                                <aside className="widget widget-search">
                                    <div className="search-form">
                                        <input
                                            type="search"
                                            name="s"
                                            placeholder="Search..."
                                            onChange={(e) => {
                                                setKeyword('');
                                                FetchTopBlogs(
                                                    'featured',
                                                    'featured',
                                                    5,
                                                    e.target.value
                                                );
                                            }}
                                        />
                                        <button type="submit">
                                            <i className="fal fa-search"></i>
                                        </button>
                                    </div>
                                </aside>
                                <aside className="widget widget-trend-post">
                                    <h3 className="widget-title">Featured</h3>
                                    {FeaturedBlogs.length === 0 ? (
                                        <div className="alert alert-info">
                                            No Blog found with your Search.
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {FeaturedBlogs.map((sitem) => (
                                        <Link to={`/blog/${sitem.slug}`} className="popular-post">
                                            <img src={`${UPLOADNODEURL}${sitem.image}`} alt="" />
                                            <div className="textBox">
                                                <h5>{sitem.title}</h5>
                                                <span>{showdatetimein(sitem.createdAt)}</span>
                                            </div>
                                        </Link>
                                    ))}
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Choose a Category</h3>
                            </div>
                        </div>
                        {BlogsCati.map((cati) => (
                            <div className="col-md-3" key={cati.blogcatid}>
                                <div
                                    className="blog_cati_cont"
                                    onClick={() => {
                                        setShowCatId(cati.blogcatid);
                                        setShowCatTit(cati.title);
                                        setShowCatDesc(cati.metadescription);
                                        FetchCategoryBlogs(cati.blogcatid);
                                    }}
                                    style={{
                                        background: ShowCatId === cati.blogcatid ? '#7f13ab59' : '',
                                    }}
                                >
                                    <img
                                        src={`${UPLOADNODEURL}${cati.image}`}
                                        alt={cati.title}
                                        className=""
                                    />
                                    {cati.title}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div id="categoryData"></div>
                    <div className="row" catid={ShowCatId}>
                        <div className="col-md-12 mt-4 mb-3">
                            <h3>{ShowCatTit}</h3>
                            <p className="mb-3">{ShowCatDesc}</p>
                        </div>
                        {Blogs.map((singi) => (
                            <div key={singi.blogid} className="col-md-4">
                                <Suspense fallback={<div>Loading...</div>}>
                                    <BlogPreview
                                        key={singi.blogid}
                                        BlogsDetails={singi}
                                        BlogsCati={BlogsCati}
                                        setShareThisURL={setShareThisURL}
                                        setshowShare={setshowShare}
                                        BASEURL={BASEURL}
                                    />
                                </Suspense>
                            </div>
                        ))}
                        <div
                            className="alert alert-info col-md-12"
                            style={{
                                display: Blogs.length > 0 ? 'none' : '',
                            }}
                        >
                            There is no Blog with your search.
                        </div>
                    </div>
                    <div
                        className="row"
                        style={{
                            display: Blogs.length > 0 ? '' : 'none',
                        }}
                    >
                        <div className="col-lg-12">
                            <div className="bisylms-pagination">
                                <Pagination
                                    nPages={nPages}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    FetchFilterBlogs={FetchFilterBlogs}
                                    SortBy={SortBy}
                                    Keyword={Keyword}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Suspense fallback={<div>Loading...</div>}>
                <HighlyPopular />
            </Suspense>
            <div className="m-2"></div>
        </>
    );
}

export default BlogMain;
