import React from 'react';
import { Link } from 'react-router-dom';

function HomeMain() {
    return (
        <>
            <section className="appie-hero-area">
                <div className="container-fluid p-0">
                    <div
                        className="row align-items-center justify-content-center text-center"
                        style={{ height: '50vh' }}
                    >
                        <div className="col-lg-12 p-0">
                            <div className="appie-hero-content">
                                <span>Welcome To DocSta</span>
                            </div>
                        </div>
                        <div className="col-lg-6 p-0">
                            <div className="appie-hero-content">
                                <h1 className="appie-title">
                                    DocSta Institute
                                    <sup>+</sup>
                                </h1>
                                <ul>
                                    <li>
                                        <Link to="/institute">
                                            Enter <i className="far fa-chevron-right" />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 p-0">
                            <div className="appie-hero-content">
                                <h1 className="appie-title">
                                    DocSta Learn
                                    <sup>+</sup>
                                </h1>
                                <ul>
                                    <li>
                                        <Link to="/learn">
                                            Enter <i className="far fa-chevron-right" />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HomeMain;
