import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import ReactGA from 'react-ga4';
import { askForPermissionToReceiveNotifications } from '../../firebaseConfig';
import {
    // IMGBASEAPIURL,
    // CleanUrlWork,
    UPLOADNODEURL,
    BASEAPIURL,
    // GoogleAnylyticsID,
    BASEURL,
    LogOutAll,
    CoursesMainCategory,
} from '../../config';
import logo from '../../assets/images/logo-learn.png';
import StickyMenu from '../../lib/StickyMenu';
import Navigation from '../NavigationLearn';
import Add2CartPopup from '../Checktout/Add2CartPopup';
import './HeaderInstitute.css';
import './rypopup.css';
import { BuyThisCourseStdAfterLoginAuto, FetchCoursetoBuy } from '../Students/functions';
import { UpdateCommonData } from '../../reduxFunction/commonSlice';
import { useDispatch, useSelector } from 'react-redux';

let ForDateTopMsg = localStorage.getItem('TopMsg');

function Header({ action, type, pageName, HideBottomMenu }) {
    const urlParams = new URLSearchParams(window.location.search);
    const couponCode = urlParams.get('couponCode');
    const CommonData = useSelector((state) => state.commonData)
    const dispatch = useDispatch();
    const [defaultProfilePicture] = useState('defaultProfilePicture.png');
    const [userData, Setuserdata] = useState({ name: '', photo: '' });
    const [OnCart, SetOnCart] = useState(FetchCoursetoBuy().length);
    const [UserType, setUserType] = useState(localStorage.getItem('usertype'));
    const [UserName, setUserName] = useState(localStorage.getItem('username'));
    // ReactGA.initialize(GoogleAnylyticsID);
    // For Top Messages
    const [DocstaTopMsgDisplay, setDocstaTopMsgDisplay] = useState('none');
    const [DocstaTopMsg, setDocstaTopMsg] = useState({});
    // For Top Messages

    // for Search Course By Keywords
    const [ShowMobileSearch, setShowMobileSearch] = useState('none');
    const [ShowKeyWords, setShowKeyWords] = useState([]);
    const [KeySearchCourses, setKeySearchCourses] = useState([]);
    const [KeySearchInstructor, setKeySearchInstructor] = useState([]);
    // for Search Course By Keywords

    // for Category and Sub Category
    const [ShowCatNavigation, setShowCatNavigation] = useState('none');
    const [CourseListLink, setCourseListLink] = useState('/courses/exam-oriented-courses');
    const [CatiData, setCatiData] = useState([]);
    const [SubCatiData, setSubCatiData] = useState([]);
    const [CourseData, setCourseData] = useState([]);
    const [MainCatId, setMainCatId] = useState('exam');
    const [CatId, setCatId] = useState(0);
    const [SubCatId, setSubCatId] = useState(0);
    // for Category and Sub Category

    const FetchDocstaTopMsg = async () => {
        try {
            const res = await fetch(`${BASEAPIURL}/admin/topmessage/getHeaderMsg`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    SortStatus: 1,
                    recordsPerPage: 1,
                    sortby: 'latest',
                    country: localStorage.getItem('country_name'),
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                if (Object.keys(resJson.data).length > 0) {
                    setDocstaTopMsg(resJson.data[0]);
                    setDocstaTopMsgDisplay('block');
                } else {
                    setDocstaTopMsgDisplay('none');
                }
            } else {
                console.log(resJson.message);
                setDocstaTopMsgDisplay('none');
            }
        } catch (err) {
            console.log(err);
            setDocstaTopMsgDisplay('none');
        }
    };

    const getKeywordsHere = async (e, keyword) => {
        if (keyword === '') {
            setShowKeyWords([]);
            showKeywordSearch();
        } else {
            try {
                const res = await fetch(`${BASEAPIURL}/admin/courses/getKeywords`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        SortStatus: 1,
                        recordsPerPage: 5,
                        keyword: keyword.trim(),
                        sortby: 'a2z',
                    }),
                });
                const resJson = await res.json();
                if (res.status === 200) {
                    if (resJson.data.length > 0) {
                        setShowKeyWords(resJson.data);
                        showKeywordSearch();
                    } else {
                        setShowKeyWords([]);
                        showKeywordSearch();
                    }
                } else {
                    console.log(resJson.message);
                }
            } catch (err) {
                console.log(err);
            }
        }
        return 1;
    };

    function showKeywordSearch() {
        if(ShowKeyWords.length === 0 && KeySearchCourses.length === 0 && KeySearchInstructor.length === 0) {
            dispatch(UpdateCommonData({showKeywordSearch: 'block'}));
        } else {
            dispatch(UpdateCommonData({showKeywordSearch: 'block'}));
        }
    }

    const FilterCoursesbyKeyword = async (e, keyword) => {
        if (keyword === '') {
            setKeySearchCourses([]);
            showKeywordSearch();
            return 0;
        } else {
            try {
                const res = await fetch(`${BASEAPIURL}/course/getAll`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        SortStatus: 1,
                        recordsPerPage: 30,
                        keyword: keyword.trim(),
                        sortby: 'a2z',
                    }),
                });
                const resJson = await res.json();
                if (res.status === 200) {
                    if (resJson.data.length > 0) {
                        setKeySearchCourses(resJson.data);
                        showKeywordSearch();
                    } else {
                        setKeySearchCourses([]);
                        showKeywordSearch();
                    }
                } else {
                    console.log(resJson.message);
                }
            } catch (err) {
                console.log(err);
            }
        }
        return 1;
    };

    const FilterInstructorsbyKeyword = async (e, keyword) => {
        if (keyword === '') {
            showKeywordSearch();
            setKeySearchInstructor([]);
            return 0;
        } else {
            try {
                const res = await fetch(`${BASEAPIURL}/admin/instructure/getAll`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        SortStatus: 1,
                        recordsPerPage: 30,
                        keyword: keyword.trim(),
                        sortby: 'a2z',
                    }),
                });
                const resJson = await res.json();
                if (res.status === 200) {
                    if (resJson.data.length > 0) {
                        setKeySearchInstructor(resJson.data);
                        showKeywordSearch();
                    } else {
                        setKeySearchInstructor([]);
                        showKeywordSearch();
                    }
                } else {
                    console.log(resJson.message);
                }
            } catch (err) {
                console.log(err);
            }
        }
        return 1;
    };

    const FetchCatiData = async (maincatid, catid) => {
        if (catid === 0) {
            // setCatiData([]);
        } else {
            // setSubCatiData([]);
        }
        try {
            const res = await fetch(`${BASEAPIURL}/admin/coursecategory/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    SortStatus: 1,
                    parent: catid,
                    maincatid,
                    featured: 0,
                    recordsPerPage: 20,
                    sortby: 'a2z',
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                if (Object.keys(resJson.data).length > 0) {
                    if (catid === 0) {
                        setCatiData(resJson.data);
                    } else {
                        setSubCatiData(resJson.data);
                    }
                    // FetchCoursesData(resJson.data[0].catid, 0, 0);
                } else if (catid === 0) {
                    setCatiData([]);
                } else {
                    setSubCatiData([]);
                }
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const FetchCoursesData = async (maincatid, catid, subcatid) => {
        let getchURL = `${BASEAPIURL}/course/getAll`;
        if (maincatid === 'qb') {
            getchURL = `${BASEAPIURL}/courseQB/getAll`;
        }
        try {
            const res = await fetch(getchURL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    SortStatus: 1,
                    PageNumber: 1,
                    recordsPerPage: 13,
                    MainCatID: maincatid,
                    category: catid,
                    subcategory: subcatid,
                    sortby: 'popular',
                    selectAttributes: 'limited',
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                if (Object.keys(resJson.data).length > 0) {
                    const dataWithType = resJson.data.map(item => ({ ...item, courseType: maincatid === 'qb' ? 'question-bank' : 'course' }));
                    setCourseData(dataWithType);
                } else {
                    setCourseData([]);
                }
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    async function fetchCouponCodeValidaction(code) {
        if (code && code !== "") {
            try {
                const res = await fetch(`${BASEAPIURL}/student/fetchCouponCodeValidaction`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ targetID: code, targetType: 'code' }),
                });
                const resJson = await res.json();
                if (res.status === 200) {
                    if (resJson.data && resJson.data.length > 0) {
                        localStorage.setItem('couponCodeValue', resJson.data[0].mobile);
                    }
                } else {
                    console.error(resJson.message);
                }
            } catch (err) {
                console.error(err);
            }
        }
    }

    const [Showadded2Cart, setShowadded2Cart] = useState(false);

    useEffect(() => {
        if (couponCode && couponCode !== '') {
            fetchCouponCodeValidaction(couponCode);
        }
        document.title = window.GetDocumentTitle();
        // for the Enrolled courses
        if (localStorage.getItem('Enroll_targetID') && localStorage.getItem('Enroll_targetType') && localStorage.getItem('Enroll_targetID') !== '' && localStorage.getItem('Enroll_targetType') !== '') {
            setTimeout(() => {
                BuyThisCourseStdAfterLoginAuto(localStorage.getItem('loggedToken'), localStorage.getItem('Enroll_targetID'), localStorage.getItem('Enroll_targetType'), localStorage.getItem('usertype'));
            }, 786);
        }
        // for the Enrolled courses

        // get firebaseToken from here start
        // initializeFirebase();   
        askForPermissionToReceiveNotifications();    
        // get firebaseToken from here end
        const temp = localStorage.getItem('userdata');
        try {
            if (temp) {
                const resJson = JSON.parse(temp);
                Setuserdata(resJson);
            }
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
        // ReactGA.send('pageview');
        localStorage.removeItem('RYSJAIN');
        StickyMenu();
        ForDateTopMsg = localStorage.getItem('TopMsg');
        FetchDocstaTopMsg();
        FetchCatiData('exam', 0);
        setUserType(localStorage.getItem('usertype'));
        setUserName(localStorage.getItem('username'));
        const interval = setInterval(() => {
            setUserType(localStorage.getItem('usertype'));
            setUserName(localStorage.getItem('username'));
        }, 5000);
        const cartInterval = setInterval(() => {
            SetOnCart(FetchCoursetoBuy().length);
        }, 2000);
        return () => {
            clearInterval(interval);
            clearInterval(cartInterval);
        };
    }, [couponCode]);
    return (
        <>
            <span
                id="closeKeywordSearch"
                className="d-none"
                onClick={() => {
                    dispatch(UpdateCommonData({showKeywordSearch: 'none'}));
                }}
            >
                Close Keyword Search
            </span>
            <div
                className={`${Number(HideBottomMenu) === 1 ? 'd-none' : 'd-block d-sm-none'}`}
                style={{
                    position: 'fixed',
                    bottom: '0px',
                    right: '0px',
                    left: '0px',
                    zIndex: 99,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        background: '#383838',
                        padding: '10px 0px',
                        justifyContent: 'space-around',
                        fontSize: '11px',
                    }}
                >
                    <Link to="/" className="text-center d-block text-white">
                        <b>
                            <i className="far fa-2x fa-home" style={{ fontSize: '1.5em' }}></i>
                            <br />
                            Home
                        </b>
                    </Link>
                    <Link to="/user/dashboard" className="text-center d-block text-white">
                        <b>
                            <i className="far fa-2x fa-books" style={{ fontSize: '1.5em' }}></i>
                            <br />
                            My Learning
                        </b>
                    </Link>
                    <Link to="https://www.instagram.com/docstalearn/reels/" target="_BLANK" className="text-center d-block text-white">
                        <b>
                            <i className="far fa-2x fa-video" style={{ fontSize: '1.5em' }}></i>
                            <br />
                            Reels
                        </b>
                    </Link>
                    {/* <Link to="/user/dashboard" className="text-center d-block text-white">
                        <b>
                            <i className="far fa-2x fa-chalkboard" style={{ fontSize: '1.5em' }}></i>
                            <br />
                            Live Class
                        </b>
                    </Link> */}
                    <Link to="/user/page/profilesetting" className="text-center d-block text-white">
                        <b>
                            <i className="far fa-2x fa-user" style={{ fontSize: '1.5em' }}></i>
                            <br />
                            My Profile
                        </b>
                    </Link>
                </div>
            </div>
            <span
                onClick={() => {
                    setShowadded2Cart(true);
                }}
                id="Showadded2CartBtn"
                className="d-none"
            >
                Show Me
            </span>
            {/* Show Popup after Add to Cart  */}
            {Showadded2Cart ? (
                <>
                    <div
                        className="ryPopUpBack"
                        onClick={() => {
                            setShowadded2Cart(false);
                        }}
                    ></div>
                    <div
                        className="ryPopUp"
                        style={{ width: '45%', minWidth: '300px', padding: '20px 5px' }}
                    >
                        <a
                            href="/"
                            className="ryPopUpAct text-white"
                            onClick={(e) => {
                                e.preventDefault();
                                setShowadded2Cart(false);
                            }}
                        >
                            X
                        </a>
                        <div>
                            <Add2CartPopup
                                targetID={localStorage.getItem('targetID')}
                                targetType={localStorage.getItem('targetType')}
                            />
                        </div>
                    </div>
                </>
            ) : (
                ''
            )}
            {/* Show Popup after Add to Cart  */}
            <div style={{ display: ForDateTopMsg === DocstaTopMsg.updatedAt ? 'none' : '' }}>
                <div className="docsta_top_msg text-white" style={{ display: DocstaTopMsgDisplay }}>
                    <a 
                        href="/"
                        className="topMessageClose"
                        onClick={(e) => {
                            setDocstaTopMsgDisplay('none');
                            localStorage.setItem('TopMsg', `${DocstaTopMsg.updatedAt}`);
                            e.preventDefault();
                        }}
                    >
                        X
                    </a>
                    <span href={DocstaTopMsg.url}>
                        <b className="text-white">{DocstaTopMsg.title}</b>
                        <p className="text-white">{DocstaTopMsg.description}</p>
                    </span>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
            <form
                className="SearchAnything SearchAnythingMobile d-lg-none"
                style={{ display: ShowMobileSearch }}
                autoComplete="off"
            >
                <div className="input-box" style={{ position: 'relative' }}>
                    <input
                        type="text"
                        name="keyword"
                        className="form-control"
                        placeholder="Search anything"
                        onChange={(e) => {
                            getKeywordsHere(e, e.target.value);
                            FilterCoursesbyKeyword(e, e.target.value);
                            FilterInstructorsbyKeyword(e, e.target.value);
                        }}
                    />
                    <button
                        type="button"
                        className="btn btn-outline-danger"
                        style={{
                            position: 'fixed',
                            zIndex: 999,
                            right: '10px',
                            top: '10px',
                            border: '0px',
                            color: '#333',
                        }}
                        onClick={() => {
                            setShowMobileSearch('none');
                        }}
                    >
                        <i className="far fa-window-close"></i>
                    </button>
                    <div
                        className="SearchAnythingResult"
                        style={{
                            maxHeight: 'initial',
                            bottom: '0px',
                            position: 'fixed',
                        }}
                    >
                        <div
                            className="text-center lead noksres text-danger mt-3 mb-3"
                            style={{
                                display: 'none',
                                displa1: KeySearchCourses.length === 0 ? 'none' : 'none',
                            }}
                        >
                            There is no course found with your Search.
                        </div>
                        {ShowKeyWords.map((si) => (
                            <div key={si.value} className="KeySearchDivContent">
                                <i className="fal fa-search mr-3"></i>
                                {si.value}
                            </div>
                        ))}
                        {KeySearchCourses.map((singi) => (
                            <div key={singi.TheCourseID} className="KeySearchDivContent">
                                <Link
                                    className="row"
                                    to={`/course/${singi.slug}`}
                                    onClick={() => {
                                        setKeySearchCourses([]);
                                        setShowMobileSearch('none');
                                    }}
                                >
                                    <div className="col-2 pr-0">
                                        <img
                                            src={`${UPLOADNODEURL}${singi.image}`}
                                            alt={singi.title}
                                        />
                                    </div>
                                    <div className="col-10">
                                        <b
                                            style={{
                                                display: 'block',
                                                fontWeight: '700',
                                            }}
                                        >
                                            {singi.title}
                                        </b>
                                        <div className="row">
                                            <div className="col-6">
                                                <small>By {singi.InstructorDisplay}</small>
                                            </div>
                                            <div className="col-6">
                                                <i className="far fa-globe"></i> <small>{singi.language}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ clear: 'both' }}></div>
                                </Link>
                            </div>
                        ))}
                        {KeySearchInstructor.map((singi) => (
                            <div key={singi.TheCourseID} className="KeySearchDivContent">
                                <Link
                                    className="row"
                                    to={`/instructor/profile/${singi.slug}`}
                                    onClick={() => {
                                        setKeySearchCourses([]);
                                        setShowMobileSearch('none');
                                    }}
                                >
                                    <div className="col-2 pr-0">
                                        <img
                                            src={`${BASEAPIURL}/admin/instructure/profileimage/${singi.tid}`}
                                            alt={singi.name}
                                        />
                                    </div>
                                    <div className="col-10">
                                        <b
                                            style={{
                                                display: 'block',
                                                fontWeight: '700',
                                            }}
                                        >
                                            {singi.name}
                                        </b>
                                        <p>
                                            <small>Instructor</small>
                                        </p>
                                    </div>
                                    <div style={{ clear: 'both' }}></div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </form>
            <header className="appie-header-area appie-sticky">
                <div className="headSection">
                    <div
                        className={`container-fluid ${
                            Number(localStorage.getItem('screenwidth')) > 767 ? 'p-0' : ''
                        }`}
                    >
                        <div className="header-nav-box">
                            <div className="row align-items-center">
                                <div className="d-lg-none d-block col-12">
                                    <div className="appie-logo-box text-center">
                                        <Link to="/">
                                            <img src={logo} alt="" />
                                        </Link>
                                        <div
                                            id="showMobileSearch"
                                            // document.getElementById('showMobileSearch').click();
                                            className="toggle-btn ml-30 d-lg-none d-block"
                                            style={{ right: '40px' }}
                                            onClick={() => {
                                                setShowMobileSearch('block');
                                            }}
                                        >
                                            <i className="fa fa-search" />
                                        </div>
                                        <div
                                            onClick={(e) => action(e)}
                                            className="toggle-btn ml-30 canvas_open"
                                        >
                                            <i className="fa fa-bars" />
                                        </div>
                                        <div className="toggle-btn mr-2">
                                            <Link
                                                className="text-dark"
                                                to="/checkout/cart"
                                                style={{ position: 'relative' }}
                                            >
                                                <i className="fal fa-shopping-cart" />
                                                <span className="cartCounter">
                                                    {Number(OnCart)}
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7 p-0 col-md-7 col-12">
                                    <div className="appie-search-box">
                                        <Link to="/">
                                            <img
                                                src={logo}
                                                alt=""
                                                style={{
                                                    maxWidth: '185px',
                                                    marginRight: '15px',
                                                }}
                                            />
                                        </Link>
                                        <span
                                            className="d-none"
                                            onMouseEnter={() => {
                                                console.log('onMouseEnter');
                                                setShowCatNavigation('block');
                                            }}
                                        >
                                            Categories
                                        </span>
                                        <form
                                            className="SearchAnything"
                                            action={`${BASEURL}courses`}
                                            autoComplete="off"
                                        >
                                            <div
                                                className="input-box"
                                                style={{ position: 'relative' }}
                                            >
                                                <input
                                                    type="text"
                                                    name="keyword"
                                                    placeholder="Search anything"
                                                    onChange={(e) => {
                                                        getKeywordsHere(e, e.target.value);
                                                        FilterCoursesbyKeyword(e, e.target.value);
                                                        FilterInstructorsbyKeyword(
                                                            e,
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                                <button type="button">
                                                    <Link to="/courses-all">
                                                        <i className="fal fa-search" />
                                                    </Link>
                                                </button>
                                                <div
                                                    className="SearchAnythingResult"
                                                    style={{
                                                        display:
                                                            KeySearchCourses.length === 0 &&
                                                            KeySearchInstructor.length === 0 &&
                                                            ShowKeyWords.length === 0
                                                                ? 'none'
                                                                : CommonData.showKeywordSearch,
                                                    }}
                                                >
                                                    {ShowKeyWords.map((si) => (
                                                        <Link
                                                            to={`/courses?keyword=${si.value}`}
                                                            key={si.value}
                                                            className="KeySearchDivContent d-block"
                                                            onClick={() => {
                                                                setKeySearchCourses([]);
                                                                setShowKeyWords([]);
                                                            }}
                                                        >
                                                            <i className="fal fa-search mr-3"></i>
                                                            {si.value}
                                                        </Link>
                                                    ))}
                                                    {KeySearchCourses.map((singi) => (
                                                        <div
                                                            key={singi.TheCourseID}
                                                            className="KeySearchDivContent"
                                                        >
                                                            <Link
                                                                to={`/course/${singi.slug}`}
                                                                onClick={() => {
                                                                    // setKeySearchDiv('none');
                                                                    setKeySearchCourses([]);
                                                                }}
                                                                style={{ display: 'block' }}
                                                            >
                                                                <img
                                                                    src={`${UPLOADNODEURL}${singi.image}`}
                                                                    alt={singi.title}
                                                                />
                                                                <div>
                                                                    <b
                                                                        style={{
                                                                            display: 'block',
                                                                            fontWeight: '700',
                                                                        }}
                                                                    >
                                                                        {singi.title}
                                                                    </b>
                                                                    <div className="d-flex">
                                                                        <div>
                                                                            <small>By {singi.InstructorDisplay}</small>
                                                                        </div>
                                                                        <div>
                                                                            &nbsp;
                                                                            <i className="far fa-globe"></i> <small>{singi.language}</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    style={{ clear: 'both' }}
                                                                ></div>
                                                            </Link>
                                                        </div>
                                                    ))}
                                                    {KeySearchInstructor.map((singi) => (
                                                        <div
                                                            key={singi.tid}
                                                            className="KeySearchDivContent"
                                                        >
                                                            <Link
                                                                to={`/instructor/profile/${singi.slug}`}
                                                                onClick={() => {
                                                                    // setKeySearchDiv('none');
                                                                    setKeySearchInstructor([]);
                                                                }}
                                                                style={{ display: 'block' }}
                                                            >
                                                                <img
                                                                    src={`${BASEAPIURL}/admin/instructure/profileimage/${singi.tid}`}
                                                                    alt={singi.name}
                                                                />
                                                                <div>
                                                                    <b
                                                                        style={{
                                                                            display: 'block',
                                                                            fontWeight: '700',
                                                                        }}
                                                                    >
                                                                        {singi.name}
                                                                    </b>
                                                                    <p>
                                                                        <small>Instructor</small>
                                                                    </p>
                                                                </div>
                                                                <div
                                                                    style={{ clear: 'both' }}
                                                                ></div>
                                                            </Link>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5 col-12">
                                    <div className="appie-btn-box">
                                        {type === 'instructor' ? (
                                            <>
                                                <Link to="/">
                                                    <span className="text-dark">Learner</span>
                                                </Link>
                                                <Link
                                                    className="login-btn"
                                                    to="/instructor-login"
                                                    style={{
                                                        lineHeight: 'initial',
                                                        padding: '5px 20px',
                                                        display:
                                                            UserType === 'instructor' ? '' : 'none',
                                                    }}
                                                >
                                                    My Account
                                                    <br />
                                                    <small>({UserName})</small>
                                                </Link>
                                                <Link
                                                    className="login-btn"
                                                    to="/instructor-login"
                                                    style={{
                                                        display:
                                                            UserType === 'instructor' ? 'none' : '',
                                                    }}
                                                >
                                                    Sign in
                                                </Link>
                                                <Link
                                                    className="signup-btn ml-1"
                                                    to="/instructor-register"
                                                    style={{
                                                        display:
                                                            UserType === 'instructor'
                                                                ? 'none'
                                                                : 'none',
                                                    }}
                                                >
                                                    Signup
                                                </Link>
                                            </>
                                        ) : (
                                            <>
                                                <Link to="/instructor">
                                                    <span className="text-dark">
                                                        <b>Instructor</b>
                                                    </span>
                                                </Link>
                                                <Link
                                                    className="login-btn"
                                                    to="/user/login"
                                                    style={{
                                                        display:
                                                            UserType === 'student' ? 'none' : '',
                                                    }}
                                                >
                                                    Sign in
                                                </Link>
                                                <Link
                                                    className="signup-btn ml-1"
                                                    to="/user/register"
                                                    style={{
                                                        display:
                                                            UserType === 'student'
                                                                ? 'none'
                                                                : 'none',
                                                    }}
                                                >
                                                    Signup
                                                </Link>
                                                <Link
                                                    className="login-btn ml-1"
                                                    to="/user/dashboard"
                                                    style={{
                                                        display:
                                                            UserType === 'student' ? '' : 'none',
                                                    }}
                                                >
                                                    My Courses
                                                </Link>
                                            </>
                                        )}
                                        <Link className="signup-btn ml-1" to="/checkout/cart">
                                            <i className="fal fa-shopping-cart" />
                                            &nbsp;{Number(OnCart)} in Cart
                                        </Link>
                                        {/* <Link className="appie-icon-box" to="/checkout/cart">
                                            <i className="fal fa-shopping-cart" />
                                            <span className="cartCounter">{Number(OnCart)}</span>
                                        </Link> */}
                                        <div className="appie-icon-box ml-1">
                                            <span className="showLangOptCont">
                                                <a
                                                    href="/"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                    }}
                                                >
                                                    <i className="fal fa-globe" />
                                                </a>
                                                <div className="showLangOpt">English</div>
                                            </span>
                                            {/* <Link className="appie-icon-box" to="/checkout/cart">
                                                <i className="fal fa-shopping-cart" />
                                                <span className="cartCounter">
                                                    {Number(OnCart)}
                                                </span>
                                            </Link> */}
                                            <Link
                                                className="appie-icon-box d-none"
                                                to="/user/page/MyFavourite"
                                            >
                                                <i className="fal fa-heart" />
                                            </Link>
                                            {/* <a href="#">
                                                <i className="fal fa-bell" />
                                            </a> */}
                                            {type !== 'instructora' && UserType === 'student' ? (
                                                <div className="ProfileMenuCont">
                                                    <img
                                                        src={`${UPLOADNODEURL}${
                                                            userData.photo &&
                                                            userData.photo !== null &&
                                                            userData.photo !== ''
                                                                ? userData.photo
                                                                : defaultProfilePicture
                                                        }`}
                                                        alt={userData.name}
                                                        className=""
                                                    />
                                                    <div className="ProfileMenu">
                                                        <Link to="/user/page/profilesetting">
                                                            <h6>{userData.name}</h6>
                                                            {userData.email}
                                                        </Link>
                                                        <hr />
                                                        <Link to="/user/dashboard">
                                                            My Learning
                                                        </Link>
                                                        <Link to="/user/page/MyFavourite">
                                                            My Wishlist
                                                        </Link>
                                                        <Link to="/user/page/updateprofilesetting">
                                                            Account Setting
                                                        </Link>
                                                        <Link to="/user/page/changePassword">
                                                            Change Password
                                                        </Link>
                                                        <Link
                                                            to="/user/dashboard"
                                                            onClick={() => {
                                                                LogOutAll();
                                                            }}
                                                        >
                                                            Logout
                                                        </Link>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            {type !== 'instructor1' && UserType === 'instructor' ? (
                                                <div className="ProfileMenuCont">
                                                    <img
                                                        src={`${BASEAPIURL}/admin/instructure/profileimage/${localStorage.getItem(
                                                            'tid'
                                                        )}`}
                                                        alt={localStorage.getItem('username')}
                                                        className=""
                                                    />
                                                    <div className="ProfileMenu">
                                                        <Link to="/instructor/page/profilesetting">
                                                            <h6>
                                                                {localStorage.getItem('username')}
                                                            </h6>
                                                            {/* {userData.email} */}
                                                        </Link>
                                                        <hr />
                                                        <Link to="/instructor/page/mycourses">
                                                            My Courses
                                                        </Link>
                                                        <Link to="/instructor/page/myquestionbank">
                                                            My Question Bank
                                                        </Link>
                                                        <Link to="/instructor/page/updateprofilesetting">
                                                            Account Setting
                                                        </Link>
                                                        <Link to="/instructor/page/changePassword">
                                                            Change Password
                                                        </Link>
                                                        <Link
                                                            to="/user/dashboard"
                                                            onClick={() => {
                                                                LogOutAll();
                                                            }}
                                                        >
                                                            Logout
                                                        </Link>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                            <span className="mr-3"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="navSection navSection-sjain"
                    style={{ display: pageName === 'courseDetail' ? 'none' : '' }}
                >
                    <div className="">
                        <div className="header-nav-box">
                            <div className="align-items-center">
                                <div className="">
                                    <div className="appie-header-main-menu">
                                        <Navigation
                                            setShowCatNavigation={setShowCatNavigation}
                                            setMainCatId={setMainCatId}
                                            setCatId={setCatId}
                                            setSubCatId={setSubCatId}
                                            FetchCatiData={FetchCatiData}
                                            setCourseListLink={setCourseListLink}
                                            FetchCoursesData={FetchCoursesData}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div style={{ position: 'relative' }}>
                <a
                    href="/"
                    onClick={(e) => {
                        e.preventDefault();
                        if (ShowCatNavigation === 'block') {
                            setShowCatNavigation('none');
                            setMainCatId('');
                        }
                    }}
                    id="closeCatNavigationBtn"
                    className="d-none"
                >
                    stringify
                </a>
                <div
                    className={`categoryNanaCont ${
                        ShowCatNavigation === 'block' ? 'showOnHover' : ''
                    }`}
                    style={{ display: ShowCatNavigation }}
                    onClick={() => {
                        setShowCatNavigation('none');
                    }}
                >
                    <div
                        className="container-fluid"
                        style={{ maxWidth: '1024px' }}
                        onMouseLeave={() => {
                            setShowCatNavigation('none');
                        }}
                    >
                        <div className="row">
                            <div
                                className="col-lg-2 d-none col-md-2 p-0 cmc_cont"
                                style={{ maxWidth: '180px' }}
                            >
                                {CoursesMainCategory().map((s) => (
                                    <div
                                        className="text-center MainDivCont"
                                        key={s.id}
                                        onMouseEnter={() => {
                                            setMainCatId(s.id);
                                            setCatId(0);
                                            setSubCatId(0);
                                            FetchCatiData(s.id, 0);
                                            setCourseListLink(s.slug);
                                        }}
                                    >
                                        <div className="imgBox">
                                            <Link
                                                to={s.slug}
                                                style={{
                                                    border: `2px solid ${
                                                        s.id === MainCatId ? '#6c0095' : '#F5F5F5'
                                                    }`,
                                                }}
                                            >
                                                <img src={s.image} alt={s.title} />
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div
                                className="col-lg-4 col-md-4 p-0 cmc_cont"
                                style={{ borderLeft: '2px solid #f5f5f5' }}
                            >
                                {CatiData.map((s) => (
                                    <Link
                                        to={`/category/${s.slug}`}
                                        to2={`${CourseListLink}?category=${s.catid}`}
                                        key={s.catid}
                                        className={`cmc_linkcat ${
                                            CatId === s.catid ? 'cmc_linkcat_active' : ''
                                        }`}
                                        catid={CatId}
                                        onMouseEnter={() => {
                                            setCatId(s.catid);
                                            setSubCatId(0);
                                            FetchCatiData(MainCatId, s.catid);
                                            FetchCoursesData(MainCatId, s.catid, 0);
                                        }}
                                    >
                                        {s.title}
                                        <i className="pull-right fas fa-angle-right"></i>
                                        <div style={{ clear: 'both' }}></div>
                                    </Link>
                                ))}
                            </div>
                            <div
                                className="col-lg-4 col-md-4 p-0 cmc_cont"
                                style={{
                                    borderLeft: '2px solid #f5f5f5',
                                    display: SubCatiData.length === 0 ? 'none' : '',
                                }}
                            >
                                {SubCatiData.map((s) => (
                                    <Link
                                        to2={`${CourseListLink}?category=${CatId}&subcategory=${s.catid}`}
                                        to={`/category/${s.slug}`}
                                        key={s.catid}
                                        catid={SubCatId}
                                        className={`cmc_linkcat ${
                                            SubCatId === s.catid ? 'cmc_linkcat_active' : ''
                                        }`}
                                        onMouseEnter={() => {
                                            setSubCatId(s.catid);
                                            FetchCoursesData(MainCatId, CatId, s.catid);
                                        }}
                                    >
                                        {s.title}
                                        <i className="pull-right fas fa-angle-right"></i>
                                    </Link>
                                ))}
                            </div>
                            <div
                                className="col-lg-6 col-md-6 p-0 cmc_cont"
                                style={{
                                    borderLeft: '2px solid #f5f5f5',
                                    display: CourseData.length === 0 ? 'none' : '',
                                }}
                            >
                                {CourseData.map((s, i) => (
                                    <>
                                        {i < 12 ? (
                                            <Link
                                                onClick={() => {
                                                    setShowCatNavigation('none');
                                                }}
                                                to={`/${s.courseType}/${s.slug}`}
                                                key={s.slug}
                                                className="cmc_linkcat"
                                                style={{ display: 'flex' }}
                                                title={s.title}
                                            >
                                                <b className="CLB_course_title_small">
                                                    {s.titleShow && s.titleShow !== ''
                                                        ? s.titleShow
                                                        : s.title}
                                                </b>
                                            </Link>
                                        ) : (
                                            <Link
                                                onClick={() => {
                                                    setShowCatNavigation('none');
                                                }}
                                                to={CourseListLink}
                                                key={s.slug}
                                                className="cmc_linkcat"
                                                style={{ display: 'flex' }}
                                                title={s.title}
                                            >
                                                <b style={{ color: '#7f13ab' }}>View All</b>
                                            </Link>
                                        )}
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header;
