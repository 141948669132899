import React, { useState, useEffect } from 'react';

function Faqs2Courses({ whatlearn, handleForm, DeleteWhatlearn }) {
    const PrimaryIndex = '_id';
    const [UploadFaq, setUploadFaq] = useState(true);
    const [EditIndex, setEditIndex] = useState('NA');
    const [Error, setError] = useState('');
    const [Bullet, setBullet] = useState('');
    const [AddModeFaqs, setAddModeFaqs] = useState(false);
    // for for Multiple Video List
    // console.log(faqs);
    const [FaqsList, setFaqsList] = useState(whatlearn);
    const handleAddFaqsList = (index) => {
        // ExpensesTypeSelected.push(1);
        setFaqsList((temp) => [
            ...temp,
            {
                index,
                bullet: '',
                answer: '',
            },
        ]);
    };
    const handleEditFaqsList = (changeVidId, ind, val) => {
        FaqsList[changeVidId][ind] = val;
    };
    const handleRemoveFaqsList = (ind) => {
        // if (ind !== 1) {
        setFaqsList(FaqsList.filter((item) => item.index !== ind));
        handleForm(
            'whatlearn',
            FaqsList.filter((item) => item.index !== ind)
        );
    };
    const RefreshFaqsList = () => {
        const temp = 999999;
        handleAddFaqsList(temp);
        handleRemoveFaqsList(temp);
        handleForm('whatlearn', FaqsList);
    };

    // for Multiple Video List
    useEffect(() => {
        setFaqsList(whatlearn);
    }, [whatlearn]);
    function addFaq() {
        if (Bullet !== '') {
            if (EditIndex === 'NA') {
                let t = Number(FaqsList.length);
                t = FaqsList[t - 1] ? FaqsList[t - 1].index : 0;
                setFaqsList((temp) => [
                    ...temp,
                    {
                        index: Number(t) + 1,
                        bullet: Bullet,
                    },
                ]);
            }
            setError('');
            setAddModeFaqs(false);
        } else {
            setError('Point is required.');
        }
    }
    return (
        <div
            style={{ minWidth: '300px' }}
            onBlur={() => {
                handleForm('whatlearn', FaqsList);
            }}
            // handleForm('faqs', FaqsList);
        >
            <div className="ryPopUpBack" style={{ display: AddModeFaqs ? '' : 'none' }}></div>
            <div
                className="ryPopUp"
                style={{
                    minWidth: '30%',
                    top: '20px',
                    bottom: '20px',
                    display: AddModeFaqs ? '' : 'none',
                    maxHeight: '100%',
                    position: 'fixed',
                    overflow: 'auto',
                }}
            >
                <aNew
                    href="#"
                    className="ryPopUpAct"
                    onClick={() => {
                        handleForm('whatlearn', FaqsList);
                        setAddModeFaqs(false);
                    }}
                >
                    X
                </aNew>
                <div style={{ margin: '30px 10px' }}>
                    <h3>Add Point</h3>
                    <div className="form-group">
                        {/* <label>Point</label> */}
                        <input
                            type="text"
                            value={Bullet}
                            name="bullet"
                            className="form-control"
                            onChange={(e) => {
                                setBullet(e.target.value);
                                if (EditIndex !== 'NA') {
                                    handleEditFaqsList(EditIndex, 'bullet', e.target.value);
                                    RefreshFaqsList();
                                }
                            }}
                        />
                    </div>

                    <div className="text-danger">{Error}</div>

                    <div className="row">
                        <div className="col-md-6 d-none">
                            <button
                                type="button"
                                className="btn btn-outline-secondary"
                                onClick={() => {
                                    setAddModeFaqs(false);
                                }}
                                style={{
                                    width: '150px',
                                    margin: '20px auto',
                                }}
                            >
                                Close
                            </button>
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                            <button
                                type="button"
                                className="btn btn-docsta-instructor"
                                onClick={() => {
                                    addFaq();
                                }}
                                style={{
                                    width: '150px',
                                    margin: '20px auto',
                                }}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <h4
                style={{
                    background: '#152569',
                    color: '#f5f5f5',
                    padding: '10px 20px',
                }}
            >
                What they'll learn
                <div style={{ float: 'right' }}>
                    <i
                        className="fa fa-arrow-down"
                        style={{ display: UploadFaq ? '' : 'none' }}
                        onClick={() => {
                            setUploadFaq(false);
                        }}
                    ></i>
                    <i
                        className="fa fa-arrow-up"
                        style={{ display: UploadFaq ? 'none' : '' }}
                        onClick={() => {
                            setUploadFaq(true);
                        }}
                    ></i>
                </div>
                <div style={{ clear: 'both' }}></div>
            </h4>
            <div
                className="form-group"
                style={{
                    border: '1px solid #152569',
                    padding: '20px',
                    background: '#ededed',
                    display: UploadFaq ? '' : 'none',
                }}
            >
                <div className="text-right">
                    <span
                        onClick={() => {
                            setAddModeFaqs(true);
                            setEditIndex('NA');
                            setBullet('');
                        }}
                        className="ryuploadAct"
                        style={{
                            maxWidth: 'max-content',
                            background: '#7f13ab',
                            color: '#f5f5f5',
                            fontWeight: 800,
                            borderRadius: '10px',
                            padding: '5px 20px',
                            cursor: 'pointer',
                            margin: '0px auto',
                            marginBottom: '10px',
                            float: 'right',
                        }}
                    >
                        Add Point
                    </span>
                    <div style={{ clear: 'both' }}></div>
                </div>
                {/* // sort((a, b) => a.index - b.index) */}
                {FaqsList.map((s, changeId) => (
                    <div className="form-group mb-3 p-3 bg-white" key={s.index}>
                        <h4>
                            {s.index} {s.bullet}
                            <div style={{ float: 'right' }}>
                                <i
                                    onClick={() => {
                                        // eslint-disable-next-line no-restricted-globals
                                        if (confirm('Press a button!') === true) {
                                            // text = "You pressed OK!";
                                            handleRemoveFaqsList(s.index);
                                            if (s[PrimaryIndex]) {
                                                DeleteWhatlearn(s[PrimaryIndex]);
                                            }
                                            // RefreshFaqsList();
                                        }
                                    }}
                                    className="fa fa-trash text-danger mr-2"
                                ></i>
                                <i
                                    onClick={() => {
                                        setAddModeFaqs(true);
                                        setEditIndex(changeId);
                                        setBullet(s.bullet);
                                    }}
                                    className="fa fa-edit text-info"
                                ></i>
                            </div>
                            <div style={{ clear: 'both' }}></div>
                        </h4>
                        <p>{s.answer}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Faqs2Courses;
