// import React from 'react';
import React, { Suspense } from 'react';
// import { Helmet } from 'react-helmet';
import useToggle from '../../../Hooks/useToggle';
import BackToTop from '../../BackToTop';
import Drawer from '../../Mobile/DrawerInstitute';
import Header from '../../Helper/HeaderInstitute';
import Footer from '../../Helper/FooterInstitute';
// import AllCoursesMain from './AllCoursesMain';
import BigBannerBox from '../BigBannerBox2';
import CalendarBox from '../CalendarBox';
// import PopularCoursesBox from '../PopularCoursesBox';

const AllCoursesMain = React.lazy(() => import('./AllWebinarsMain'));
const PopularCoursesBox = React.lazy(() => import('../PopularCoursesBox'));

function AllCourses() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            {/* <AllCoursesMain className="pt-70" /> */}
            <Suspense fallback={<div>Loading...</div>}>
                <AllCoursesMain className="pt-3" />
            </Suspense>
            <BigBannerBox />
            <CalendarBox />
            <Suspense fallback={<div>Loading...</div>}>
                <PopularCoursesBox />
            </Suspense>
            <Footer />
            <BackToTop />
        </>
    );
}

export default AllCourses;
