import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
// import HTMLReactParser from 'html-react-parser';
// import Parser from 'html-react-parser';
import 'react-phone-number-input/style.css';
// import PhoneInput from 'react-phone-number-input';
import useToggle from '../../../../Hooks/useToggle';
import BackToTop from '../../../BackToTop';
import Footer from '../../../Helper/FooterInstitute';
import Drawer from '../../../Mobile/DrawerInstitute';
// import Header from '../Helper/Header';
import HeaderInstructor from '../../InstructorIndex_Insti';
import {
  IMGBASEAPIURL,
  BASEAPIURL,
  UPLOADNODEURL,
  BASEURL,
  AllLanguages,
  CleanUrlWork,
} from '../../../../config';
import '../../instructor.css';

import '../../../Helper/rypopup.css';
import LoginPage from '../Login';
import Video2Courses from './Video2Courses';
import Resource2Courses from './Resource2Courses';
import Faqs2Courses from './Faqs2Courses';
import Whatlearn from './whatlearn';
import Question2Courses from './Question2Session';
// import Editor from '../../../htmleditor/Editor';
import Editor from '../../../htmleditor/EditorTinyMCE';
import '../../../htmleditor/RichTextEditor.css';
import UploadChunk from '../../pages/Video2R2/uploadChunk';
import VideoPlayer from '../../../videoPlayer/player';
const validityIndex = [];
let i = 0;
while (i <= 364) {
  i += 1;
  validityIndex.push(i);
}

const CampaignIndex = [];
let i2 = 0;
while (i2 <= 49) {
  i2 += 1;
  CampaignIndex.push(i2);
}
const min = 10000;
const max = 99999;
function CreateMainCourse() {
  const [CustomLevel, setCustomLevel] = useState(false);
  const [EditModuInd, setEditModuInd] = useState(1);
  const [rows, setRows] = useState([{ period: '', cost: 0, costinr: 0 }]);
  // for the Editors
  const [PageContent, setPageContent] = useState('');
  const [RequirementContent, setRequirementContent] = useState('');
  // for the Editors

  // for the Session Resouses Variables here
  const [UploadVideo2Courses, SetUploadVideo2Courses] = useState('');
  const [UploadAudio2Courses, SetUploadAudio2Courses] = useState('');
  const [UploadResource2Courses, SetUploadResource2Courses] = useState('');

  // for for Multiple Question List
  const [UploadMCQs2Courses, SetUploadMCQs2Courses] = useState('');

  // for the Session Resouses Variables here
  const [CurrentSectionIndex, SetCurrentSectionIndex] = useState(1);
  const [AddSessionAction, SetAddSessionAction] = useState(false);
  const [ValidityUOM, SetValidityUOM] = useState('Days');
  // const [file, setFile] = useState();
  // const [SeeMore, SetSeeMore] = useState(0);
  // const [fileName, setFileName] = useState('');
  const [ImageUpload, setImageUpload] = useState(false);
  const [TheCourseID, setTheCourseID] = useState(
    localStorage.getItem('edit_course_id')
  );
  const [step1, setStep1] = useState('active');
  const [step2, setStep2] = useState('');
  const [step3, setStep3] = useState('');
  const [step4, setStep4] = useState('');
  const [step5, setStep5] = useState('');
  const [Category, setCategory] = useState([]);
  const [SubCategory, setSubCategory] = useState([]);
  const [drawer, drawerAction] = useToggle(false);
  const [PerDetDiv, setPerDetDiv] = useState('block');
  const [AddiDet1Div, setAddiDet1Div] = useState('None');
  const [AddiDet2Div, setAddiDet2Div] = useState('None');
  const [AddiDet3Div, setAddiDet3Div] = useState('None');
  const [ConfirmationDiv, setConfirmationDiv] = useState('None');
  const [Msgcolor, setMsgcolor] = useState('');
  // const [Email, setEmail] = useState();
  const [APIMessage, setMessage] = useState();
  const [classforform, setclassforform] = useState('col-md-12');

  // const form = document.querySelector('#Inst-Regi-Form');
  // const obj = serialize(form, { hash: true, empty: true });
  // Session Videos and Resourses
  const [SessionVideos, setSessionVideos] = useState([]);
  const [SessionResources, setSessionResources] = useState([]);
  // Session Videos and Resourses
  const [SessionQuestions, setSessionQuestions] = useState([]);
  const [SessionAnswers, setSessionAnswers] = useState([]);
  const [regformdata, setForm] = useState({
    TheCourseID: 0,
    NewcreatedBy: 0,
    createdID: localStorage.getItem('tid'),
    InstructorDisplay: localStorage.getItem('username'),
    createdBy: localStorage.getItem('usertype'),
    title: '',
    image: '',
    description: '',
    agegroup: '',
    language: '',
    section: 'exam',
    category: 0,
    subcategory: 0,
    requirement: '',
    level: '',
    mode: 'Recorded',
    validity: '',
    validityIn: '',
    features: '',
    metaKeywords: '',
    faqs: [],
    whatlearn: [],
    SessionQuestions,
    SessionAnswers,
    SessionResources,
    SessionVideos,
    reviewer: '',
    periodValidity: [],
  });
  // For the Preview Video
  const [t1, setT1] = useState(localStorage.getItem('tid'));
  const [t2, setT2] = useState(
    Math.floor(Math.random() * (max - min + 1)) + min
  );
  const [t3, setT3] = useState(
    Math.floor(Math.random() * (max - min + 1)) + min
  );
  function setPreviewVideo(videoID) {
    handleForm('previewVideo', videoID);
  }

  const handleAddRow = () => {
    setRows([...rows, { period: null, cost: null, costinr: null }]);
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newRows = [...rows];
    newRows[index][name] = Number(value);
    setRows(newRows);

    //Dont push the values if one is empty
    if (newRows[index].period !== null && newRows[index].price !== null) {
      setForm({ ...regformdata, periodValidity: newRows });
    }
  };

  const handleDeleteRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
    setForm({ ...regformdata, periodValidity: newRows });
  };

  const [UploadPreviewVideo, SetUploadPreviewVideo] = useState(false);
  // For the Preview Video
  console.log(rows, 'Row Data');
  const [defaultbtnText, setdefaultbtnText] = useState('Custom');
  const [ShowLoader, setShowLoader] = useState(true);
  function hideLoader() {
    setTimeout(() => {
      setShowLoader(false);
    }, 786);
  }

  const handleForm = (name, value) => {
    setForm({ ...regformdata, [name]: value });
  };
  function ShowFormDiv(type) {
    setMessage('');
    let AllOkay = 1;
    console.log(regformdata);
    if (regformdata.image.trim() === '') {
      AllOkay = 0;
      setMsgcolor('red');
      setMessage('Please Upload the Banner Image.');
    }

    if (
      typeof regformdata.category === 'undefined' ||
      regformdata.category === 0 ||
      regformdata.category === '0'
    ) {
      AllOkay = 0;
      setMsgcolor('red');
      setMessage('Please Select the Category.');
    }

    if (regformdata.description.trim() === '') {
      AllOkay = 0;
      setMsgcolor('red');
      setMessage('Please Write About this Course.');
    }

    if (
      typeof regformdata.title === 'undefined' ||
      regformdata.title === 0 ||
      regformdata.title === ''
    ) {
      AllOkay = 0;
      setMsgcolor('red');
      setMessage('Please Enter the Course Title.');
    }

    if (type === 'addi2') {
      ModuleList.map((s) => {
        if (s.title.trim() === '') {
          AllOkay = 0;
          setMsgcolor('red');
          setMessage('Please Enter the All Module Name.');
        }
        return '';
      });
    }

    if (AllOkay === 1) {
      setPerDetDiv('none');
      setAddiDet1Div('none');
      setAddiDet2Div('none');
      setAddiDet3Div('none');
      setConfirmationDiv('none');
      setclassforform('col-md-12');
      setStep1(' ');
      setStep2(' ');
      setStep3(' ');
      setStep4(' ');
      setStep5(' ');
      switch (type) {
        case 'addi3':
          setStep4('active');
          setAddiDet3Div('block');
          setclassforform('col-md-12');
          break;
        case 'addi2':
          setStep3('active');
          setAddiDet2Div('block');
          setclassforform('col-md-12');
          break;
        case 'addi1':
          setStep2('active');
          setAddiDet1Div('block');
          break;
        case 'confirm':
          setStep3('active');
          setclassforform('col-md-12 text-center');
          setConfirmationDiv('block');
          break;
        default:
          setStep1('active');
          setPerDetDiv('block');
          break;
      }
    }
  }

  // for file uploade will be here
  async function uploadFile(file, fileName) {
    // e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', fileName);
    const url = `${BASEAPIURL}/admin/instructure/uploadcv`;
    try {
      const res = await axios.post(url, formData);
      console.log(res);
      // setCVfile(res.data.filename);
    } catch (ex) {
      console.log(ex);
    }
  }

  async function uploadBanner(file, fileName, forName) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', fileName);
    const url = `${BASEAPIURL}/admin/instructure/uploadcv`;
    try {
      const res = await axios.post(url, formData);
      handleForm(forName, res.data.filename);
    } catch (ex) {
      console.log(ex);
    }
  }

  const saveFile = (e) => {
    setTimeout(() => {
      // const temp = Number(AudioList.length) + 1;
      uploadFile(e.target.files[0], e.target.files[0].name);
    }, 500);
  };
  // for file uploade will be here

  const MainCategory = [
    {
      id: 'exam',
      title: 'Exam Oriented Course',
    },
    {
      id: 'skill',
      title: 'Skill Upgrading Course',
    },
    {
      id: 'university',
      title: 'University Accredited Course',
    },
  ];
  const FetchCategory = async (maincatid) => {
    // e.preventDefault();
    try {
      const res = await fetch(`${BASEAPIURL}/admin/coursecategory/getAll`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          maincatid,
          SortStatus: 1,
          parent: 0,
          sortby: 'a2z',
          recordsPerPage: 1000,
          selectOnly: 1,
        }),
      });
      const resJson = await res.json();
      if (res.status === 200) {
        setCategory(resJson.data);
      } else {
        console.log(resJson.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [InstructorList, setInstructorList] = useState([]);
  const [InstructorDisplay, setInstructorDisplay] = useState([]);
  const fetchInstructor = async (keyword) => {
    try {
      const res = await fetch(`${BASEAPIURL}/admin/instructure/getAll`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          keyword,
          PageNumber: 1,
          SortStatus: 1,
          sortby: 'a2z',
          recordsPerPage: 20,
        }),
      });
      const resJson = await res.json();
      if (res.status === 200 && resJson.data.length > 0) {
        setInstructorList(resJson.data);
      } else {
        setInstructorList([]);
      }
    } catch (err) {
      alert(err);
    }
  };

  const FetchSubCategory = async (e, parentid) => {
    // e.preventDefault();
    try {
      const res = await fetch(`${BASEAPIURL}/admin/coursecategory/getAll`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          parent: parentid,
        }),
      });
      const resJson = await res.json();
      if (res.status === 200) {
        setSubCategory(resJson.data);
      } else {
        console.log(resJson.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  async function RegistrerInstructor() {
    try {
      console.log(regformdata, 'Submitted Values');
      setMsgcolor('green');
      const url = `${BASEAPIURL}/mainCourse/create`;
      const config = {
        headers: {
          'content-type': 'application/json', // multipart/form-data
        },
      };
      axios.post(url, regformdata, config).then((response) => {
        ShowFormDiv('confirm');
        setMessage(response.data.message);
      });
    } catch (err) {
      setMsgcolor('red');
      ShowFormDiv('confirm');
      setMessage('Something went wrong, Please try again later.');
    }
  }
  const PrimaryIndex = '_id';
  async function DeleteModuleList(fileid) {
    // alert(fileid);
    try {
      const url = `${BASEAPIURL}/mainCourse/DeleteModuleList`;
      const config = {
        headers: {
          'content-type': 'application/json', // multipart/form-data
        },
      };
      axios.post(url, { fileid }, config).then((response) => {
        console.log(response);
      });
    } catch (err) {
      setMessage('Something went wrong, Please try again later.');
    }
  }

  async function DeleteSessionsList(fileid) {
    // alert(fileid);
    try {
      const url = `${BASEAPIURL}/mainCourse/DeleteSessionsList`;
      const config = {
        headers: {
          'content-type': 'application/json', // multipart/form-data
        },
      };
      axios.post(url, { fileid }, config).then((response) => {
        console.log(response);
      });
    } catch (err) {
      setMessage('Something went wrong, Please try again later.');
    }
  }

  async function DeleteWhatlearn(fileid) {
    // alert(fileid);
    try {
      const url = `${BASEAPIURL}/mainCourse/DeleteWhatlearn`;
      const config = {
        headers: {
          'content-type': 'application/json', // multipart/form-data
        },
      };
      axios.post(url, { fileid }, config).then((response) => {
        console.log(response);
      });
    } catch (err) {
      setMessage('Something went wrong, Please try again later.');
    }
  }

  async function DeleteFAQs(fileid) {
    // alert(fileid);
    try {
      const url = `${BASEAPIURL}/mainCourse/DeleteFAQs`;
      const config = {
        headers: {
          'content-type': 'application/json', // multipart/form-data
        },
      };
      axios.post(url, { fileid }, config).then((response) => {
        console.log(response);
      });
    } catch (err) {
      setMessage('Something went wrong, Please try again later.');
    }
  }

  // for for Multiple ModuleList List
  // const defaultModuleList = [
  //     { index: 1, title: 'Hello 1', open: 1 },
  //     { index: 2, title: 'Hello 2', open: 0 },
  // ];
  const [ModuleList, setModuleList] = useState([]);
  function nextModulesIndex() {
    let maxIndex = 1;
    ModuleList.forEach((item) => {
      if (item.index > maxIndex) {
        maxIndex = item.index;
      }
    });
    return maxIndex;
  }
  const handleAddModuleList = () => {
    // ExpensesTypeSelected.push(1);
    const index = nextModulesIndex();
    setModuleList((temp) => [
      ...temp,
      {
        srno: index + 1,
        index: index + 1,
        title: '',
        open: 1,
      },
    ]);
  };
  const handleEditModuleList = (changeId, ind, val) => {
    ModuleList[changeId][ind] = val;
  };
  const handleRemoveModuleList = (ind) => {
    setShowLoader(true);
    hideLoader();
    if (ind !== 1) {
      setModuleList(ModuleList.filter((item) => item.index !== ind));
    }
  };
  const RefreshModuleList = () => {
    const temp = 999999;
    handleAddModuleList(temp);
    handleRemoveModuleList(temp);
    handleForm('ModuleList', ModuleList);
  };
  // for Multiple ModuleList List

  // for for Multiple Sessions List
  // const defaultSessionsList = [
  //     {
  //         index: 1,
  //         title: '',
  //         module: 1,
  //         videos: [
  //             {
  //                 index: 1,
  //                 url: '',
  //             },
  //         ],
  //         audios: [],
  //         resources: [],
  //     },
  // ];
  const [tempSessionList, setTempSessionList] = useState(1);
  const [SessionsList, setSessionsList] = useState([]);
  // const handleAddSessionsList = (index) => {
  //     // ExpensesTypeSelected.push(1);
  //     setSessionsList((temp) => [
  //         ...temp,
  //         {
  //             index: Number(index),
  //             srno: index,
  //             title: '',
  //             module: 1,
  //             videos: [],
  //             audios: [],
  //             resources: [],
  //         },
  //     ]);
  // };
  function nextSessionsIndex() {
    let maxIndex = 1;
    SessionsList.forEach((item) => {
      if (item.index > maxIndex) {
        maxIndex = item.index;
      }
    });
    return maxIndex;
  }
  const handleEditSessionsList = (changeId, ind, val) => {
    SessionsList[changeId][ind] = val;
  };
  const handleRemoveSessionsList = (ind) => {
    const temp = SessionsList.filter((item) => item.index !== ind);
    setShowLoader(true);
    hideLoader();
    setSessionsList(temp);
    handleForm('SessionsList', temp);
    if (ind !== 1) {
      // setSessionsList(SessionsList.filter((item) => item.index !== ind));
      // RefreshSessionsList();
    }
    setTempSessionList(tempSessionList + 1);
  };
  const RefreshSessionsList = () => {
    setTempSessionList(tempSessionList + 1);
    // handleAddSessionsList(temp);
    // handleRemoveSessionsList(temp);
    // handleForm('SessionsList', SessionsList);
  };
  // for Multiple Sessions List

  // for login check here
  const [isLogin, setisLogin] = useState(false);
  // for login check here

  // for the Video Part will be here
  function ForUploadVideo(sessionindex) {
    VideoAddForm = '';
    SetUploadVideo2Courses(sessionindex);
  }
  let VideoAddForm = '';
  if (UploadVideo2Courses !== '' && UploadVideo2Courses) {
    VideoAddForm = (
      <Video2Courses
        SetUploadVideo2Courses={SetUploadVideo2Courses}
        handleForm={handleForm}
        SessionVideos={
          regformdata.SessionVideos && regformdata.SessionVideos.length > 0
            ? regformdata.SessionVideos
            : SessionVideos
        }
        SessionVideosCount={SessionVideos.length}
        setSessionVideos={setSessionVideos}
        RefreshSessionsList={RefreshSessionsList}
        SessionIndex={UploadVideo2Courses}
      />
    );
  } else {
    VideoAddForm = '';
  }
  // for the Video Part will be here

  useEffect(() => {
    // audioIndex = 0;
    setTheCourseID(localStorage.getItem('edit_course_id'));
    FetchCategory('exam');
    // get Blog Details
    const FetchBlogDetails = async (CourseID) => {
      // e.preventDefault();
      try {
        const res = await fetch(`${BASEAPIURL}/mainCourse/GetCourseDetail`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            TheCourseID: CourseID,
            createdID: localStorage.getItem('tid'),
            createdBy: localStorage.getItem('usertype'),
          }),
        });
        const resJson = await res.json();
        setShowLoader(false);
        if (res.status === 200 && resJson.data && resJson.data.length > 0) {
          if (resJson.FaqsList && resJson.FaqsList.length > 0) {
            resJson.data[0].faqs = resJson.FaqsList;
          } else {
            resJson.data[0].faqs = [];
          }
          if (resJson.whatlearn && resJson.whatlearn.length > 0) {
            resJson.data[0].whatlearn = resJson.whatlearn;
          } else {
            resJson.data[0].whatlearn = [];
          }
          if (localStorage.getItem('usertype') === 'instructor') {
            resJson.data[0].InstructorDisplay =
              localStorage.getItem('username');
          }
          setRows(resJson.data[0].periodValidity);
          setInstructorDisplay(resJson.data[0].InstructorDisplay);
          setForm(resJson.data[0]);
          FetchCategory(resJson.data[0].section);
          setPageContent(
            resJson.data[0].description ? resJson.data[0].description : '&nbsp;'
          );
          setRequirementContent(
            resJson.data[0].requirement ? resJson.data[0].requirement : '&nbsp;'
          );
          FetchSubCategory(null, resJson.data[0].category);
          SetValidityUOM(resJson.data[0].validityIn);
          if (resJson.ModuleList && resJson.ModuleList.length > 0) {
            setModuleList(resJson.ModuleList);
          }
          if (resJson.SessionsList && resJson.SessionsList.length > 0) {
            setSessionsList(resJson.SessionsList);
          }
          if (resJson.SessionAnswers && resJson.SessionAnswers.length > 0) {
            setSessionAnswers(resJson.SessionAnswers);
          }
          if (resJson.SessionQuestions && resJson.SessionQuestions.length > 0) {
            setSessionQuestions(resJson.SessionQuestions);
          }
          if (resJson.SessionResources && resJson.SessionResources.length > 0) {
            setSessionResources(resJson.SessionResources);
          }
          if (resJson.SessionVideos && resJson.SessionVideos.length > 0) {
            setSessionVideos(resJson.SessionVideos);
          }
        } else {
          setSessionQuestions([]);
          setSessionAnswers([]);
          setSessionResources([]);
          setSessionVideos([]);
          setForm({
            TheCourseID: 0,
            createdID: localStorage.getItem('tid'),
            createdBy: localStorage.getItem('usertype'),
            InstructorDisplay: localStorage.getItem('username'),
            title: '',
            image: '',
            description: '',
            agegroup: '',
            language: '',
            section: 'exam',
            category: 0,
            subcategory: 0,
            requirement: '',
            level: '',
            validity: '',
            validityIn: '',
            features: '',
            metaKeywords: '',
            faqs: [],
            whatlearn: [],
            SessionQuestions: [],
            SessionAnswers: [],
            SessionResources: [],
            SessionVideos: [],
            reviewer: '',
            periodValidity: [],
          });
        }
      } catch (err) {
        console.log(err);
      }
    };
    FetchBlogDetails(TheCourseID);
    // get Blog Details

    // for login check here
    const FetchProfile = async () => {
      try {
        const res = await fetch(`${BASEAPIURL}/admin/instructure/myprofile`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic ${localStorage.getItem('jwttoken')}`,
          },
          body: JSON.stringify({
            token: localStorage.getItem('token'),
          }),
        });
        const resJson = await res.json();
        if (res.status === 200) {
          if (resJson.data !== null) {
            if (
              Object.keys(resJson.data).length > 0 &&
              localStorage.getItem('usertype') === 'instructor'
            ) {
              setisLogin(true);
              localStorage.setItem('tid', resJson.data.tid);
            } else {
              setisLogin(false);
            }
          } else {
            setisLogin(false);
          }
        } else {
          setisLogin(false);
          console.log(resJson.message);
        }
      } catch (err) {
        setisLogin(false);
        console.log(err);
      }
    };
    const isAdmin = localStorage.getItem('usertype');
    const isAdmintoken = localStorage.getItem('token');
    if (isAdmin && isAdmin === 'admin' && isAdmintoken) {
      setisLogin(true);
    } else {
      FetchProfile();
    }
    // for preview video
    setT1(localStorage.getItem('tid'));
    setT2(Math.floor(Math.random() * (max - min + 1)) + min);
    setT3(Math.floor(Math.random() * (max - min + 1)) + min);
    // for preview video
    const interval = setInterval(() => {
      if (isAdmin && isAdmin === 'admin' && isAdmintoken) {
        setisLogin(true);
      } else {
        FetchProfile();
      }
    }, 5000);
    return () => clearInterval(interval);
    // for login check here
  }, [TheCourseID]);

  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: '0px',
          bottom: '0px',
          left: '0px',
          right: '0px',
          zIndex: '999999',
          display: ShowLoader ? '' : 'none',
          background: '#ffffff24',
        }}
      >
        <img
          src={`${BASEURL}loader.gif`}
          alt=""
          style={{
            maxWidth: '120px',
            margin: '0px auto',
            marginTop: '15%',
            display: 'none',
          }}
        />
      </div>
      <Drawer
        drawer={drawer}
        action={drawerAction.toggle}
      />
      <HeaderInstructor
        drawer={drawer}
        action={drawerAction.toggle}
      />
      <div style={{ display: isLogin ? 'none' : '' }}>
        <LoginPage setisLogin={setisLogin} />
      </div>
      <div style={{ display: isLogin ? '' : 'none' }}>
        <div
          className="ryPopUpBack"
          style={{ display: AddSessionAction ? '' : 'none' }}
        ></div>
        <div
          className="ryPopUp"
          style={{ display: AddSessionAction ? '' : 'none' }}
        >
          <anew
            href="#"
            className="ryPopUpAct"
            onClick={() => {
              SetAddSessionAction(false);
            }}
          >
            X
          </anew>
          <form
            style={{ margin: '30px 50px' }}
            onSubmit={(e) => {
              e.preventDefault();
              // let t = Number(SessionsList.length);
              // t = SessionsList[t - 1] ? SessionsList[t - 1].index : 0;
              const t = nextSessionsIndex();
              setSessionsList((temp) => [
                ...temp,
                {
                  index: Number(t) + 1,
                  srno: Number(t) + 1,
                  title: '',
                  module: CurrentSectionIndex,

                  dateClass: '',
                  timeClass: '',
                  durationClass: '',
                  dateTimeClass: '',

                  videos: [],
                  audios: [],
                  resources: [],
                },
              ]);
              SetAddSessionAction(false);
            }}
          >
            <h3 className="text-center">Select Module to Add Session</h3>
            <div
              className="file_inp_cont text-center"
              style={{
                background: '#f7faff',
                border: '1px dashed #4f6a97',
              }}
            >
              <select
                required
                name="selectModule"
                className="form-control"
                value={CurrentSectionIndex}
                onChangeCapture={(e) => {
                  SetCurrentSectionIndex(e.target.value);
                }}
              >
                <option value="">Select Module</option>
                {ModuleList &&
                  ModuleList.length > 0 &&
                  ModuleList.sort((a, b) => a.srno - b.srno).map((s) => (
                    <option
                      key={s.index}
                      value={s.index}
                    >
                      {s.title}
                    </option>
                  ))}
              </select>
            </div>
            <button
              type="submit"
              className="btn btn-docsta-instructor"
              style={{
                width: '150px',
                margin: '20px auto',
              }}
            >
              Add Session
            </button>
          </form>
        </div>

        {/* Add Videos to Sessions  */}
        <div
          className="ryPopUpBack"
          style={{ display: UploadVideo2Courses !== '' ? '' : 'none' }}
        ></div>
        <div
          className="ryPopUp"
          style={{
            minWidth: '30%',
            top: '20px',
            bottom: '20px',
            display: UploadVideo2Courses !== '' ? '' : 'none',
          }}
        >
          <anew
            className="ryPopUpAct text-white"
            onClick={() => {
              SetUploadVideo2Courses('');
              handleForm('SessionVideos', SessionVideos);
            }}
          >
            X
          </anew>
          <div style={{ clear: 'both' }}>
            {VideoAddForm}
            <anew
              className="btn btn-docsta-instructor d-none"
              onClick={() => {
                SetUploadVideo2Courses('');
                handleForm('SessionVideos', SessionVideos);
              }}
              style={{
                width: '150px',
                margin: '20px auto',
              }}
            >
              Save
            </anew>
          </div>
        </div>
        {/* Add Videos to Sessions  */}

        {/* Add Audios to Sessions  */}
        <div
          className="ryPopUpBack"
          style={{ display: UploadAudio2Courses !== '' ? '' : 'none' }}
        ></div>
        <div
          className="ryPopUp"
          style={{
            minWidth: '30%',
            top: '20px',
            bottom: '20px',
            display: UploadAudio2Courses !== '' ? '' : 'none',
          }}
        >
          <anew
            href="#"
            className="ryPopUpAct"
            onClick={() => {
              SetUploadAudio2Courses('');
            }}
          >
            X
          </anew>
          <div style={{ margin: '30px 10px' }}>
            {UploadAudio2Courses}
            <anew
              className="btn btn-docsta-instructor"
              onClick={() => {
                SetUploadAudio2Courses('');
              }}
              style={{
                width: '150px',
                margin: '20px auto',
              }}
            >
              Save
            </anew>
          </div>
        </div>
        {/* Add Audios to Sessions  */}

        {/* Add Resources to Sessions  */}
        <div
          className="ryPopUpBack"
          style={{ display: UploadResource2Courses !== '' ? '' : 'none' }}
        ></div>
        <div
          className="ryPopUp"
          style={{
            minWidth: '30%',
            top: '20px',
            bottom: '20px',
            display: UploadResource2Courses !== '' ? '' : 'none',
          }}
        >
          <anew
            href="#"
            className="ryPopUpAct"
            onClick={() => {
              SetUploadResource2Courses('');
              handleForm('SessionResources', SessionResources);
            }}
          >
            X
          </anew>
          <div style={{ margin: '30px 10px' }}>
            {UploadResource2Courses}
            <anew
              className="btn btn-docsta-instructor"
              onClick={() => {
                SetUploadResource2Courses('');
                handleForm('SessionResources', SessionResources);
              }}
              style={{
                width: '150px',
                margin: '20px auto',
              }}
            >
              Save
            </anew>
          </div>
        </div>
        {/* Add Resources to Sessions  */}

        {/* Add MCQs to Sessions  */}
        <div
          className="ryPopUpBack"
          style={{ display: UploadMCQs2Courses !== '' ? '' : 'none' }}
        ></div>
        <div
          className="ryPopUp"
          style={{
            minWidth: '70%',
            padding: '0px',
            display: UploadMCQs2Courses !== '' ? '' : 'none',
          }}
        >
          <anew
            href="#"
            className="ryPopUpAct"
            onClick={() => {
              SetUploadMCQs2Courses('');
              setForm({
                ...regformdata,
                SessionAnswers: SessionAnswers,
                SessionQuestions: SessionQuestions,
              });
              // handleForm('SessionAnswers', SessionAnswers);
            }}
            style={{
              marginRight: '5px',
              marginTop: '5px',
              background: 'transparent',
              color: '#fff',
              fontWeight: 'bold',
            }}
          >
            X
          </anew>
          <div style={{ margin: '0px' }}>
            {UploadMCQs2Courses}
            <anew
              className="btn btn-docsta-instructor d-none"
              onClick={() => {
                SetUploadMCQs2Courses('');
                handleForm('SessionAnswers', SessionAnswers);
              }}
              style={{
                width: '150px',
                margin: '20px auto',
              }}
            >
              Save
            </anew>
          </div>
        </div>
        {/* Add MCQs to Sessions  */}

        <div
          className="ryPopUpBack"
          style={{ display: ImageUpload ? '' : 'none' }}
        ></div>
        <div
          className="ryPopUp"
          style={{ display: ImageUpload ? '' : 'none' }}
        >
          <anew
            href="#"
            className="ryPopUpAct"
            onClick={() => {
              setImageUpload(false);
            }}
          >
            X
          </anew>
          <div style={{ margin: '30px 50px' }}>
            <h3 className="text-center">Upload Audio</h3>
            <div
              className="file_inp_cont text-center"
              style={{
                background: '#f7faff',
                border: '1px dashed #4f6a97',
              }}
            >
              <input
                type="file"
                name="cvfile"
                className="ryfile"
                accept="audio/*"
                onChange={(e) => {
                  saveFile(e);
                }}
                style={{
                  width: 'max-content',
                  margin: '0px auto',
                  display: 'block',
                }}
              />
              <p>Please upload a high quality noice free Audio.</p>
            </div>
            <anew
              className="btn btn-docsta-instructor"
              onClick={() => {
                setImageUpload(false);
              }}
              style={{
                width: '150px',
                margin: '20px auto',
              }}
            >
              Upload
            </anew>
          </div>
        </div>

        <div className="container pt-4 pb-4">
          <div
            className="p-3 text-center"
            style={{
              background: `url(${IMGBASEAPIURL}instructor/headerbg.jpg)`,
              backgroundRepeat: 'repeat',
              backgroundSize: '100%',
            }}
          >
            <h2 className="m-3 text-white">Submit A New Course</h2>
          </div>
          <div className="row">
            {/* <div className="col-md-1"></div> */}

            <div className="col-md-12">
              <div className="mt-4 mb-4 ry_instr_shadow">
                <div className="instr_signup_head d-none d-md-block">
                  <div className="row">
                    <div className="col-md-1 text-center"></div>
                    <div className="col-md-2 text-center">
                      <h2 className={`mb-0 instr_signup_head_step ${step1}`}>
                        1
                      </h2>
                      <b className="mb-2 d-block">Course Details</b>
                    </div>
                    <div className="col-md-2 text-center">
                      <h2 className={`mb-0 instr_signup_head_step ${step2}`}>
                        2
                      </h2>
                      <b className="mb-2 d-block">Create Modules</b>
                    </div>
                    <div className="col-md-2 text-center">
                      <h2 className={`mb-0 instr_signup_head_step ${step3}`}>
                        3
                      </h2>
                      <b className="mb-2 d-block">Add Sessions</b>
                    </div>
                    <div className="col-md-2 text-center">
                      <h2 className={`mb-0 instr_signup_head_step ${step4}`}>
                        4
                      </h2>
                      <b className="mb-2 d-block">Additional Details</b>
                    </div>
                    <div className="col-md-2 text-center">
                      <h2 className={`mb-0 instr_signup_head_step ${step5}`}>
                        5
                      </h2>
                      <b className="mb-2 d-block">Submit for Review</b>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className={classforform}>
                    <form
                      id="Inst-Regi-Form"
                      encType="multipart/form-data"
                      className="m-4 p-4"
                      style={{ display: 'block' }}
                      onSubmit={(e) => {
                        e.preventDefault();
                        RegistrerInstructor();
                      }}
                    >
                      <div className="For_Form_title"></div>

                      <div style={{ display: ConfirmationDiv }}>
                        <img
                          src={`${IMGBASEAPIURL}instructor/inst_reg_done.jpg`}
                          alt="Docsta Learn Insinstructor"
                          style={{ maxWidth: '100%' }}
                          className="mt-1 mb-4"
                        />
                        <h3 className="mt-3">
                          Your Course has been submitted successfully.
                        </h3>
                        <div
                          className="message mt-3"
                          style={{ color: Msgcolor }}
                        >
                          {APIMessage ? <span>{APIMessage}</span> : ''}
                        </div>
                        <Link
                          to="instructor-dashboard-mycourses"
                          className="btn btn-docsta-instructor d-inline-block m-3"
                          style={{ width: '190px' }}
                        >
                          Back to My Courses
                        </Link>
                      </div>

                      <div style={{ display: PerDetDiv }}>
                        {localStorage.getItem('usertype') === 'admin' ? (
                          <div
                            className="mb-3 pt-3 pl-3 pr-3 pb-2"
                            style={{ border: '1px solid #dc3545' }}
                          >
                            <div className="row">
                              <h4 className="col-md-12 mb-2">Admin Setting</h4>

                              <div className="col-md-3">
                                <div className="form-group">
                                  <label className="ry_label">
                                    Default Button Text
                                  </label>
                                  <select
                                    name="defaultbtn"
                                    value={regformdata.defaultbtn}
                                    className="form-control"
                                    onChangeCapture={(e) => {
                                      if (e.target.value === 'Custom') {
                                        setdefaultbtnText('Custom');
                                      }
                                      handleForm(e.target.name, e.target.value);
                                    }}
                                  >
                                    <option value="">Select Button Text</option>
                                    <option value="Newly Added">
                                      Newly Added
                                    </option>
                                    <option value="Highest Rated">
                                      Highest Rated
                                    </option>
                                    <option value="Bestseller">
                                      Bestseller
                                    </option>
                                    <option value="Custom">Custom</option>
                                    {defaultbtnText !== 'Custom' &&
                                    defaultbtnText !== '' ? (
                                      <option value={defaultbtnText}>
                                        {defaultbtnText}
                                      </option>
                                    ) : (
                                      ''
                                    )}
                                    <option value="0">Don't Show</option>
                                  </select>
                                </div>
                              </div>

                              <div
                                className="col-md-3"
                                style={{
                                  display:
                                    regformdata.defaultbtn === defaultbtnText
                                      ? ''
                                      : 'none',
                                }}
                              >
                                <div className="form-group">
                                  <label className="ry_label">
                                    Custom Default Button Text
                                  </label>
                                  <input
                                    name="defaultbtnText"
                                    value={defaultbtnText}
                                    className="form-control"
                                    onChangeCapture={(e) => {
                                      setdefaultbtnText(e.target.value);
                                      handleForm('defaultbtn', e.target.value);
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div
                                  className="form-group"
                                  style={{ position: 'relative' }}
                                >
                                  <label className="ry_label">Instructor</label>
                                  <input
                                    name="defaultbtn2"
                                    value={InstructorDisplay}
                                    className="form-control"
                                    onChange={(e) => {
                                      setInstructorDisplay(e.target.value);
                                      fetchInstructor(e.target.value);
                                    }}
                                    onClick={(e) => {
                                      setInstructorDisplay(e.target.value);
                                      fetchInstructor(e.target.value);
                                    }}
                                  />
                                  <a
                                    href="/"
                                    className="btn btn-sm btn-outline-danger"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setInstructorList([]);
                                    }}
                                    style={{
                                      display:
                                        InstructorList.length === 0
                                          ? 'none'
                                          : '',
                                      position: 'absolute',
                                      zIndex: 999999,
                                      right: '10px',
                                      top: '27px',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    X
                                  </a>
                                  <div
                                    className="keywordSearchInput"
                                    style={{
                                      display:
                                        InstructorList.length === 0
                                          ? 'none'
                                          : '',
                                    }}
                                  >
                                    {InstructorList.map((instS) => (
                                      <div
                                        className="single"
                                        key={instS.tid}
                                      >
                                        <img
                                          src={`${BASEAPIURL}/admin/instructure/profileimage/${instS.tid}`}
                                          alt=""
                                        />
                                        <div className="details">
                                          {instS.name}
                                          <br />
                                          <small>{instS.email}</small>
                                        </div>
                                        <div className="action">
                                          <span
                                            className="btn btn-sm btn-success mr-1"
                                            onClick={() => {
                                              regformdata.NewcreatedBy =
                                                'instructor';
                                              regformdata.createdID = instS.tid;
                                              setInstructorDisplay(instS.name);
                                              handleForm(
                                                'InstructorDisplay',
                                                instS.name
                                              );
                                              setInstructorList([]);
                                            }}
                                          >
                                            <i className="fa fa-plus"></i>
                                          </span>
                                          <Link
                                            to={`/instructor/profile/${instS.slug}`}
                                            target="_BLANK"
                                            className="btn btn-sm btn-info"
                                          >
                                            <i className="fa fa-eye"></i>
                                          </Link>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}

                        <h4 className="mb-2">Course Details</h4>
                        <div className="row m-2">
                          <div className="col-md-6"></div>
                          <div className="col-md-6"></div>
                          <div style={{ clear: 'both' }}></div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="ry_label">Course Title</label>
                              <input
                                value={regformdata.title}
                                type="text"
                                name="title"
                                className="form-control"
                                placeholder="Enter"
                                onChange={(e) => {
                                  handleForm(e.target.name, e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="ry_label">
                                Course Display Title
                                <i
                                  className="fa fa-question"
                                  title="Display on Search Results"
                                />
                              </label>
                              <input
                                value={regformdata.titleShow}
                                type="text"
                                maxLength={80}
                                name="titleShow"
                                className="form-control"
                                placeholder="Enter"
                                onClick={(e) => {
                                  if (e.target.value === '') {
                                    handleForm(
                                      e.target.name,
                                      regformdata.title
                                    );
                                  }
                                }}
                                onChange={(e) => {
                                  handleForm(e.target.name, e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="ry_label">Course URL</label>
                              <input
                                value={regformdata.slug}
                                type="text"
                                maxLength={160}
                                name="slug"
                                className="form-control"
                                placeholder="Enter"
                                onClick={(e) => {
                                  if (e.target.value === '') {
                                    handleForm(
                                      e.target.name,
                                      CleanUrlWork(regformdata.title)
                                    );
                                  }
                                }}
                                onChange={(e) => {
                                  handleForm(e.target.name, e.target.value);
                                }}
                              />
                              <small>
                                {BASEURL}course/{regformdata.slug}
                              </small>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="ry_label">
                                Before they join
                              </label>
                              <Editor
                                PageContent={RequirementContent}
                                setPageContent={setRequirementContent}
                                HandleForm={handleForm}
                                ForAttribute="requirement"
                              />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="ry_label">
                                About this Course
                              </label>
                              <Editor
                                PageContent={PageContent}
                                setPageContent={setPageContent}
                                HandleForm={handleForm}
                                ForAttribute="description"
                              />
                            </div>
                          </div>

                          <div style={{ clear: 'both' }}></div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="ry_label">Section</label>
                              <select
                                type="text"
                                name="section"
                                className="form-control"
                                value={regformdata.section}
                                onChange={(e) => {
                                  handleForm(e.target.name, e.target.value);
                                }}
                                onBlur={(e) => {
                                  FetchCategory(e.target.value);
                                }}
                              >
                                <option
                                  key="0"
                                  value="0"
                                >
                                  Select Section
                                </option>
                                {MainCategory.map((singi) => (
                                  <option
                                    key={singi.id}
                                    value={singi.id}
                                  >
                                    {singi.title}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="ry_label">Category</label>
                              <select
                                type="text"
                                name="category"
                                className="form-control"
                                value={regformdata.category}
                                onChange={(e) => {
                                  handleForm(e.target.name, e.target.value);
                                }}
                                onBlur={(e) => {
                                  FetchSubCategory(e, e.target.value);
                                }}
                              >
                                <option
                                  key="0"
                                  value="0"
                                >
                                  Select Category
                                </option>
                                {Category.map((singi) => (
                                  <option
                                    key={singi.catid}
                                    value={singi.catid}
                                  >
                                    {singi.title}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="ry_label">Sub Category</label>
                              <select
                                value={regformdata.subcategory}
                                type="text"
                                name="subcategory"
                                className="form-control"
                                onChangeCapture={(e) => {
                                  handleForm(e.target.name, e.target.value);
                                }}
                              >
                                <option
                                  key="0"
                                  value="0"
                                >
                                  {SubCategory.length > 0
                                    ? 'Select Sub Category'
                                    : 'No Sub Category'}
                                </option>
                                {SubCategory.map((singi) => (
                                  <option
                                    key={singi.catid}
                                    value={singi.catid}
                                  >
                                    {singi.title}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div style={{ clear: 'both' }}></div>
                        </div>

                        <div className="row mt-2">
                          <div className="col-md-5">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="ry_label d-block">
                                    Level
                                    <div
                                      style={{ float: 'right' }}
                                      onClick={(e) => {
                                        if (!CustomLevel) {
                                          handleForm('level', '');
                                        }
                                        setCustomLevel(!CustomLevel);
                                      }}
                                      className="btn btn-info btn-sm p-0 pl-2 pr-2"
                                    >
                                      +
                                    </div>
                                  </label>
                                  {!CustomLevel ? (
                                    <select
                                      name="level"
                                      className="form-control"
                                      value={regformdata.level}
                                      onChangeCapture={(e) => {
                                        handleForm(
                                          e.target.name,
                                          e.target.value
                                        );
                                      }}
                                    >
                                      {regformdata.level !== '' ? (
                                        <option value={regformdata.level}>
                                          {regformdata.level}
                                        </option>
                                      ) : (
                                        ''
                                      )}
                                      <option value="">Select Level</option>
                                      <option value="Beginner">Beginner</option>
                                      <option value="Intermediate">
                                        Intermediate
                                      </option>
                                      <option value="Expert">Expert</option>
                                      <option value="All Level">
                                        All Level
                                      </option>
                                    </select>
                                  ) : (
                                    <input
                                      name="level"
                                      className="form-control"
                                      value={regformdata.level}
                                      placeholder="Enter Custom Level"
                                      onChange={(e) => {
                                        handleForm(
                                          e.target.name,
                                          e.target.value
                                        );
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="ry_label">Mode</label>
                                  <select
                                    value={regformdata.mode}
                                    name="mode"
                                    className="form-control"
                                    onChange={(e) => {
                                      handleForm(e.target.name, e.target.value);
                                    }}
                                  >
                                    <option value="">Select Mode</option>
                                    <option value="On Demand">On Demand</option>
                                    <option value="Live">Live</option>
                                    <option value="Live + Recorded">
                                      Live + Recorded
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label className="ry_label">Language</label>
                              <select
                                value={regformdata.language}
                                type="text"
                                name="language"
                                className="form-control"
                                placeholder="Enter"
                                onChange={(e) => {
                                  handleForm(e.target.name, e.target.value);
                                }}
                              >
                                <option value="">Select Language</option>
                                {AllLanguages.map((L) => (
                                  <option
                                    value={L}
                                    key={L}
                                  >
                                    {L}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="row">
                              <div
                                className="col-md-6"
                                style={{
                                  display:
                                    regformdata.validityIn === 'Lifetime'
                                      ? 'none'
                                      : '',
                                }}
                              >
                                <div className="form-group">
                                  <label className="ry_label">Validity</label>
                                  <select
                                    name="validity"
                                    className="form-control"
                                    value={regformdata.validity}
                                    onChangeCapture={(e) => {
                                      handleForm(e.target.name, e.target.value);
                                    }}
                                  >
                                    <option value="">Select Validity</option>
                                    {validityIndex.map((s) => (
                                      <option
                                        value={s}
                                        key={s}
                                      >
                                        {s} {ValidityUOM}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="ry_label">
                                    Validity Type
                                  </label>
                                  <select
                                    name="validityIn"
                                    className="form-control"
                                    value={regformdata.validityIn}
                                    onChangeCapture={(e) => {
                                      handleForm(e.target.name, e.target.value);
                                      SetValidityUOM(e.target.value);
                                    }}
                                  >
                                    <option value="">
                                      Select Validity Type
                                    </option>
                                    <option value="Hour">Hours</option>
                                    <option value="Day">Day</option>
                                    <option value="Month">Month</option>
                                    <option value="Year">Year</option>
                                    <option value="Lifetime">Lifetime</option>
                                  </select>
                                </div>
                              </div>
                              <div style={{ clear: 'both' }}></div>
                            </div>
                          </div>

                          <div style={{ clear: 'both' }}></div>
                        </div>

                        <div className="row mt-2">
                          <div className="col-md-2">
                            <div className="form-group">
                              <label className="ry_label">Age Group</label>
                              <select
                                name="agegroup"
                                className="form-control"
                                value={regformdata.agegroup}
                                onChangeCapture={(e) => {
                                  handleForm(e.target.name, e.target.value);
                                }}
                              >
                                <option value="">Select Age group</option>
                                <option value="Below 18">Below 18</option>
                                <option value="18 Plus">18 Plus</option>
                                <option value="Open For All">
                                  Open For All
                                </option>
                              </select>
                            </div>
                          </div>

                          <div className="col-md-2">
                            <div className="form-group">
                              <label className="ry_label">Cost in $</label>
                              <input
                                value={regformdata.cost}
                                type="number"
                                name="cost"
                                className="form-control"
                                placeholder="Enter"
                                onChange={(e) => {
                                  handleForm(e.target.name, e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-2">
                            <div className="form-group">
                              <label className="ry_label">Cost in ₹</label>
                              <input
                                value={regformdata.costinr}
                                type="number"
                                name="costinr"
                                className="form-control"
                                placeholder="Enter"
                                onChange={(e) => {
                                  handleForm(e.target.name, e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-2">
                            <div className="form-group">
                              <label className="ry_label">
                                Product ID <small>(iOS Store) </small>
                                <a
                                  href="https://appstoreconnect.apple.com/apps/6475391570/distribution/iaps"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i className="fa fa-link"></i>
                                </a>
                              </label>
                              <input
                                value={regformdata.costIOS}
                                type="text"
                                name="costIOS"
                                className="form-control"
                                placeholder="Enter"
                                onChange={(e) => {
                                  handleForm(e.target.name, e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="ry_label">Run Campaign</label>
                              <select
                                name="campaign"
                                className="form-control"
                                value={regformdata.campaign}
                                onChangeCapture={(e) => {
                                  handleForm(e.target.name, e.target.value);
                                }}
                              >
                                <option value="">Select Validity</option>
                                <option value="0">No Campaign</option>
                                {CampaignIndex.map((s) => (
                                  <option
                                    value={s}
                                    key={s}
                                  >
                                    {s} %
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4"></div>

                          <div style={{ clear: 'both' }}></div>
                        </div>

                        <div className="row mt-2">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="ry_label">
                                Main Key Features
                              </label>
                              <input
                                value={regformdata.features}
                                type="text"
                                name="features"
                                className="form-control"
                                placeholder="Enter Minimum 5 (Comma Seperated)"
                                onChange={(e) => {
                                  handleForm(e.target.name, e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="ry_label">Meta Keywords</label>
                              <input
                                value={regformdata.metaKeywords}
                                type="text"
                                name="metaKeywords"
                                className="form-control"
                                placeholder="Enter"
                                onChange={(e) => {
                                  handleForm(e.target.name, e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="ry_label">
                                Meta Description
                              </label>
                              <input
                                maxLength={250}
                                value={regformdata.metaDescription}
                                type="text"
                                name="metaDescription"
                                className="form-control"
                                placeholder="Enter"
                                onChange={(e) => {
                                  handleForm(e.target.name, e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-4"></div>
                          <div className="col-md-4"></div>
                          <div style={{ clear: 'both' }}></div>
                        </div>

                        <div className="row mt-2">
                          <div className="col-md-4">
                            <label>Banner Image</label>
                            <input
                              type="file"
                              className="form-control"
                              accept="image/*"
                              onChange={(e) => {
                                uploadBanner(
                                  e.target.files[0],
                                  e.target.files[0].name,
                                  'image'
                                );
                              }}
                            />
                            {regformdata.image !== '' ? (
                              <div>
                                <img
                                  src={`${UPLOADNODEURL}${regformdata.image}`}
                                  alt=""
                                  style={{ maxHeight: '50px' }}
                                />
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="col-md-4">
                            <label>Preview Video Image</label>
                            <input
                              type="file"
                              name="previewVideoImg"
                              className="form-control"
                              accept="image/*"
                              onChange={(e) => {
                                uploadBanner(
                                  e.target.files[0],
                                  e.target.files[0].name,
                                  'previewVideoImg'
                                );
                              }}
                            />
                            {regformdata.previewVideoImg !== '' ? (
                              <div>
                                <img
                                  src={`${UPLOADNODEURL}${regformdata.previewVideoImg}`}
                                  alt=""
                                  style={{ maxHeight: '50px' }}
                                />
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="col-md-4">
                            <label>Preview Video</label>
                            <div
                              className="form-control"
                              onClick={() => {
                                setT1(localStorage.getItem('tid'));
                                setT2(
                                  Math.floor(Math.random() * (max - min + 1)) +
                                    min
                                );
                                setT3(
                                  Math.floor(Math.random() * (max - min + 1)) +
                                    min
                                );
                                SetUploadPreviewVideo(!UploadPreviewVideo);
                              }}
                            >
                              Upload Preview Video &nbsp;
                              {regformdata.previewVideo !== '' ? (
                                <i className="fa fa-edit"></i>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>

                          <div style={{ clear: 'both' }}></div>
                        </div>
                        <div className="row mt-2 ">
                          <div className="col-md-12">
                            <label>Course Validity</label>
                            <table className="w-100">
                              <thead>
                                <tr>
                                  <th className="border p-2">Months</th>
                                  <th className="border p-2">Cost in $</th>
                                  <th className="border p-2">Cost in ₹</th>
                                  <th className="border p-2">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {rows.map((row, index) => (
                                  <tr key={index}>
                                    <td className="border p-2">
                                      <input
                                        type="number"
                                        name="period"
                                        value={row.period}
                                        onChange={(e) =>
                                          handleInputChange(index, e)
                                        }
                                        className="form-control outline-none"
                                      />
                                    </td>
                                    <td className="border p-2">
                                      <input
                                        type="number"
                                        name="cost"
                                        value={row.cost}
                                        onChange={(e) =>
                                          handleInputChange(index, e)
                                        }
                                        className="form-control outline-none"
                                      />
                                    </td>
                                    <td className="border p-2">
                                      <input
                                        type="number"
                                        name="costinr"
                                        value={row.costinr}
                                        onChange={(e) =>
                                          handleInputChange(index, e)
                                        }
                                        className="form-control outline-none"
                                      />
                                    </td>
                                    <td className="border p-2 d-flex justify-content-center">
                                      <a
                                        onClick={(e) => {
                                          e.preventDefault();
                                          /* eslint-disable no-restricted-globals */
                                          if (
                                            confirm('Are you sure to delete?')
                                          ) {
                                            handleDeleteRow(index);
                                          }
                                          /* eslint-disable no-restricted-globals */
                                        }}
                                        className="text-danger fs-6 form-control border-0 outline-none"
                                      >
                                        <i className="far fa-trash"></i>
                                      </a>
                                    </td>
                                  </tr>
                                ))}
                                <tr>
                                  <td className="p-2">
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleAddRow();
                                      }}
                                      className="btn btn-sm btn-primary font-bold py-2 px-4 rounded ml-auto"
                                    >
                                      Add Row
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-4" />
                          <div className="col-md-4" />
                          <div className="col-md-4">
                            <div
                              className="message"
                              style={{ color: Msgcolor }}
                            >
                              {APIMessage ? (
                                <label>{APIMessage}</label>
                              ) : (
                                <label>&nbsp;</label>
                              )}
                            </div>
                            <anew
                              href="#"
                              className="btn btn-docsta-instructor"
                              onClick={() => {
                                console.log('first');
                                ShowFormDiv('addi1');
                              }}
                            >
                              Proceed
                            </anew>
                            <div className="text-center mt-2">
                              Help
                              <Link
                                to="/instructor-dashboard-help"
                                target="_blank"
                                style={{
                                  color: '#fff',
                                  background: 'blue',
                                  borderRadius: '50%',
                                  textAlign: 'center',
                                  fontSize: '12px',
                                  width: '20px',
                                  height: '20px',
                                  lineHeight: '20px',
                                  marginLeft: '7px',
                                }}
                              >
                                <i className="fa fa-question"></i>
                              </Link>
                              <a
                                href="https://api.whatsapp.com/send?phone=+971543229664&text=Hello,%20Sending%20you%20message%20from%20https://docstalearn.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={`${BASEURL}images/whatsappus.png`}
                                  alt=""
                                  style={{
                                    width: '25px',
                                    height: '25px',
                                    marginLeft: '7px',
                                  }}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Upload Preview Video will be here  */}
                      <div
                        className="ryPopUpBack"
                        style={{ display: UploadPreviewVideo ? '' : 'none' }}
                      ></div>
                      <div
                        className="ryPopUp"
                        style={{
                          minWidth: '30%',
                          top: '20px',
                          bottom: '20px',
                          display: UploadPreviewVideo ? '' : 'none',
                        }}
                      >
                        <h3>
                          Upload Preview Video
                          <small
                            className="ryPopUpAct text-white"
                            onClick={() => {
                              SetUploadPreviewVideo(!UploadPreviewVideo);
                            }}
                          >
                            X
                          </small>
                          <div style={{ clear: 'both' }}></div>
                        </h3>
                        <div>
                          Video ID: <b>{regformdata.previewVideo}</b>
                        </div>
                        {UploadPreviewVideo ? (
                          <>
                            <VideoPlayer
                              DefaultVideoTitle="Preview Video"
                              DefaultVideoId={regformdata.previewVideo}
                              VIDPlaying={true}
                              responsive={true}
                            />
                            <UploadChunk
                              t1={t1}
                              t2={t2}
                              t3={t3}
                              setVideoIDOnList={setPreviewVideo}
                            />
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                      {/* Upload Preview Video will be here  */}

                      <div style={{ display: AddiDet1Div }}>
                        {/* <h4 className="mb-2">Additional Details</h4> */}
                        <div className="row">
                          <div className="col-md-3">
                            {regformdata.image !== '' ? (
                              <div>
                                <img
                                  src={`${UPLOADNODEURL}${regformdata.image}`}
                                  alt=""
                                  style={{
                                    maxWidth: '100%',
                                    border: '1px solid #f5f5f5',
                                    borderRadius: '10px',
                                    marginBottom: '20px',
                                    lineHeight: '100px',
                                  }}
                                />
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="col-md-9">
                            <h3
                              style={{
                                textDecoration: 'underline',
                                lineHeight: '70px',
                              }}
                            >
                              Course Title: {regformdata.title}
                            </h3>
                          </div>
                        </div>
                        <div className="form-group">
                          <span
                            onClick={() => {
                              let t = Number(ModuleList.length);
                              t = ModuleList[t - 1]
                                ? ModuleList[t - 1].index
                                : 0;
                              handleAddModuleList(t + 1);
                            }}
                            className="ryuploadAct"
                            style={{
                              maxWidth: 'max-content',
                              background: '#e7effd',
                              color: '#000',
                              fontWeight: 700,
                              borderRadius: '10px',
                              padding: '10px 20px',
                              cursor: 'pointer',
                            }}
                          >
                            Add a Module &nbsp;
                            <i
                              className="fa fa-plus"
                              style={{
                                borderRadius: '50%',
                                background: '#000',
                                color: '#f5f5f5',
                                width: '25px',
                                height: '25px',
                                lineHeight: '25px',
                                marginLeft: '5px',
                              }}
                            ></i>
                          </span>
                        </div>

                        <h4
                          style={{
                            background: '#152569',
                            color: '#f5f5f5',
                            padding: '10px 20px',
                          }}
                        >
                          Modules
                        </h4>

                        <div
                          className="form-group"
                          style={{
                            border: '1px solid #152569',
                            padding: '20px',
                          }}
                        >
                          {ModuleList.map((s, changeId) => (
                            <div key={s.index}>
                              <div className="row mb-3">
                                <div className="col-md-1">
                                  <b>Sr. No.</b>
                                  <input
                                    type="number"
                                    min={1}
                                    name="audio[]"
                                    className="form-control"
                                    value={s.srno}
                                    onChange={(e) => {
                                      handleEditModuleList(
                                        changeId,
                                        'srno',
                                        e.target.value
                                      );
                                      RefreshModuleList();
                                    }}
                                  />
                                </div>
                                <div className="col-md-9">
                                  <b>
                                    Module Title
                                    {/* <small>
                                                                            (Total Session: {SessionsList.filter((item) => Number(item.module) !== Number(s.index)).length})
                                                                            </small> */}
                                  </b>
                                  <input
                                    type="text"
                                    name="audio[]"
                                    className="form-control"
                                    value={s.title}
                                    onChange={(e) => {
                                      handleEditModuleList(
                                        changeId,
                                        'title',
                                        e.target.value
                                      );
                                      RefreshModuleList();
                                    }}
                                  />
                                </div>
                                <div className="col-md-1">
                                  <b>&nbsp;</b>
                                  <br />
                                  <span
                                    value={s.title}
                                    style={{
                                      display: s.index === 1 ? 'none' : '',
                                    }}
                                    className="text-danger"
                                    onClick={() => {
                                      /* eslint-disable no-restricted-globals */
                                      if (
                                        confirm(
                                          'Are you sure to Delete Module ?'
                                        )
                                      ) {
                                        handleRemoveModuleList(s.index);
                                        if (s[PrimaryIndex]) {
                                          DeleteModuleList(s[PrimaryIndex]);
                                        }
                                      }
                                      /* eslint-enable no-restricted-globals */
                                    }}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>

                        <div
                          className="message mt-3"
                          style={{ color: Msgcolor }}
                        >
                          {APIMessage ? <span>{APIMessage}</span> : ''}
                        </div>
                        <div style={{ textAlign: 'center' }}>
                          <anew
                            href="#"
                            className="btn btn-info mr-3"
                            onClick={() => {
                              ShowFormDiv('initial');
                            }}
                            style={{
                              margin: '20px auto',
                              width: '150px',
                            }}
                          >
                            <i className="fa fa-edit"></i>
                            &nbsp;Edit Details
                          </anew>
                          <anew
                            href="#"
                            className="btn btn-docsta-instructor d-inline-block"
                            onClick={() => {
                              ShowFormDiv('addi2');
                            }}
                            style={{
                              margin: '20px auto',
                              width: '150px',
                            }}
                          >
                            Proceed
                          </anew>
                        </div>
                      </div>

                      <div
                        className="form-group"
                        style={{ display: AddiDet2Div }}
                      >
                        <span
                          onClick={() => {
                            SetAddSessionAction(true);
                            // handleAddSessionsList(
                            //     Number(SessionsList.length) + 1
                            // );
                          }}
                          className="ryuploadAct"
                          style={{
                            maxWidth: 'max-content',
                            background: '#e7effd',
                            color: '#000',
                            fontWeight: 700,
                            borderRadius: '10px',
                            padding: '10px 20px',
                            cursor: 'pointer',
                          }}
                        >
                          Add a Session &nbsp;
                          <i
                            className="fa fa-plus"
                            style={{
                              borderRadius: '50%',
                              background: '#000',
                              color: '#f5f5f5',
                              width: '25px',
                              height: '25px',
                              lineHeight: '25px',
                              marginLeft: '5px',
                            }}
                          ></i>
                        </span>
                      </div>

                      <div style={{ display: AddiDet2Div }}>
                        {ModuleList.sort((a, b) => a.srno - b.srno).map((s) => (
                          <div
                            key={s.index}
                            style={{ marginBottom: '20px' }}
                          >
                            <h4
                              style={{
                                background: '#152569',
                                color: '#f5f5f5',
                                padding: '10px 20px',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setEditModuInd(
                                  Number(s.index) === Number(EditModuInd)
                                    ? 0
                                    : s.index
                                );
                                RefreshSessionsList();
                              }}
                            >
                              {s.title}
                              <div style={{ float: 'right' }}>
                                <i
                                  className={`fa fa-arrow-${
                                    Number(s.index) === Number(EditModuInd)
                                      ? 'down'
                                      : 'right'
                                  }`}
                                ></i>
                              </div>
                              <div style={{ clear: 'both' }}></div>
                            </h4>
                            {Number(EditModuInd) === Number(s.index) &&
                            tempSessionList ? (
                              <div
                                style={{ border: '1px solid rgb(21, 37, 105)' }}
                              >
                                {tempSessionList &&
                                  SessionsList.map((s2, changeId) => (
                                    <div
                                      key={s2.index}
                                      className="pt-4 pb-4 pl-4 pr-4"
                                      style={{
                                        border: '1px solid #c7c7c7',
                                        display:
                                          Number(s2.module) === Number(s.index)
                                            ? ''
                                            : 'none',
                                      }}
                                    >
                                      {Number(s2.module) === Number(s.index) ? (
                                        <div className="row">
                                          <div className="col-md-2">
                                            <b>Sr. No.</b>
                                            <input
                                              type="number"
                                              min={1}
                                              className="form-control"
                                              value={s2.srno}
                                              onChange={(e) => {
                                                handleEditSessionsList(
                                                  changeId,
                                                  'srno',
                                                  e.target.value
                                                );
                                                RefreshSessionsList();
                                              }}
                                            />
                                          </div>
                                          <div className="col-md-4">
                                            <b>#{s2.index} Session Title</b>
                                            <input
                                              type="text"
                                              name="sessionTitle[]"
                                              className="form-control"
                                              value={s2.title}
                                              onChange={(e) => {
                                                handleEditSessionsList(
                                                  changeId,
                                                  'title',
                                                  e.target.value
                                                );
                                                RefreshSessionsList();
                                              }}
                                            />
                                          </div>
                                          <div className="col-md-2">
                                            <b>Type</b>
                                            <select
                                              name="sessionTitle[]"
                                              className="form-control"
                                              value={s2.type}
                                              onChangeCapture={(e) => {
                                                handleEditSessionsList(
                                                  changeId,
                                                  'type',
                                                  e.target.value
                                                );
                                                RefreshSessionsList();
                                              }}
                                            >
                                              <option>Select Type</option>
                                              <option value="Video">
                                                Video
                                              </option>
                                              <option value="Live">Live</option>
                                              <option value="MCQs">MCQs</option>
                                            </select>
                                          </div>
                                          {s2.type === 'Video' ? (
                                            <anew
                                              style={{ fontSize: '11px' }}
                                              className="col-md-1 p-0 text-center"
                                              onClick={() => {
                                                ForUploadVideo(s2.index);
                                              }}
                                            >
                                              &nbsp;
                                              <br />
                                              <i
                                                className="far text-warning fa-video"
                                                style={{ fontSize: '2em' }}
                                              ></i>
                                              <br />
                                              Video
                                            </anew>
                                          ) : (
                                            ''
                                          )}

                                          {s2.type === 'MCQs' ? (
                                            <anew
                                              style={{ fontSize: '11px' }}
                                              className="col-md-1 p-0 text-center"
                                              onClick={() => {
                                                SetUploadMCQs2Courses(
                                                  <Question2Courses
                                                    setSessionQuestions={
                                                      setSessionQuestions
                                                    }
                                                    setSessionAnswers={
                                                      setSessionAnswers
                                                    }
                                                    EditQuestionSession={
                                                      s2.index
                                                    }
                                                    SessionTitle={s2.title}
                                                    handleForm={handleForm}
                                                    SessionQuestions={
                                                      SessionQuestions
                                                    }
                                                    SessionAnswers={
                                                      SessionAnswers
                                                    }
                                                  />
                                                );
                                              }}
                                            >
                                              &nbsp;
                                              <br />
                                              <i
                                                className="far text-warning fa-question"
                                                style={{ fontSize: '2em' }}
                                              ></i>
                                              <br />
                                              MCQ
                                            </anew>
                                          ) : (
                                            ''
                                          )}

                                          <a
                                            href="/"
                                            style={{ fontSize: '11px' }}
                                            className="col-md-1 p-0 text-center"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              SetUploadResource2Courses(
                                                <Resource2Courses
                                                  SessionResources={
                                                    SessionResources
                                                  }
                                                  setSessionResources={
                                                    setSessionResources
                                                  }
                                                  RefreshSessionsList={
                                                    RefreshSessionsList
                                                  }
                                                  resources={
                                                    s2.resources
                                                      ? s2.resources
                                                      : []
                                                  }
                                                  changeId={changeId}
                                                  handleEditSessionsList={
                                                    handleEditSessionsList
                                                  }
                                                  SessionIndex={s2.index}
                                                />
                                              );
                                            }}
                                          >
                                            &nbsp;
                                            <br />
                                            <i
                                              className="far text-warning fa-file"
                                              style={{
                                                fontSize: '2em',
                                              }}
                                            ></i>
                                            <br />
                                            <span
                                              style={{
                                                marginRight: '5px',
                                                display:
                                                  s2.resources &&
                                                  s2.resources.length > 0
                                                    ? ''
                                                    : 'nono',
                                              }}
                                            >
                                              {
                                                // s2.resourceCount
                                              }
                                            </span>
                                            Resource
                                          </a>

                                          <div
                                            style={{ fontSize: '11px' }}
                                            className="col-md-1 p-0 text-center"
                                          >
                                            &nbsp;
                                            <br />
                                            <a
                                              style={{
                                                display:
                                                  s2.index === 1 ? '' : '',
                                              }}
                                              className="text-danger"
                                              href="/"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setTempSessionList(
                                                  tempSessionList + 1
                                                );
                                                /* eslint-disable no-restricted-globals */
                                                if (
                                                  confirm(
                                                    'Are you sure to Delete This Session ?'
                                                  )
                                                ) {
                                                  handleRemoveSessionsList(
                                                    s2.index
                                                  );
                                                  if (s[PrimaryIndex]) {
                                                    DeleteSessionsList(
                                                      s2[PrimaryIndex]
                                                    );
                                                  }
                                                }
                                                /* eslint-enable no-restricted-globals */
                                              }}
                                            >
                                              <i
                                                className="far fa-trash"
                                                style={{ fontSize: '2em' }}
                                              ></i>
                                              <br />
                                              Delete Session
                                            </a>
                                          </div>

                                          <div className="clearboth col-12">
                                            {s2.type === 'Live' && (
                                              <div className="row mt-2">
                                                <div className="col-md-3">
                                                  <div className="form-group">
                                                    <label>Date of Class</label>
                                                    <input
                                                      type="date"
                                                      name="dateClass"
                                                      className="form-control"
                                                      value={s2.dateClass}
                                                      onChange={(e) => {
                                                        SessionsList[changeId][
                                                          'dateClass'
                                                        ] = e.target.value;
                                                        const userTimestamp =
                                                          Date.parse(
                                                            `${e.target.value}T${s2.timeClass}`
                                                          );
                                                        SessionsList[changeId][
                                                          'dateTimeClass'
                                                        ] = userTimestamp;
                                                        RefreshSessionsList();
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-md-3">
                                                  <div className="form-group">
                                                    <label>Start Time</label>
                                                    <input
                                                      type="time"
                                                      name="timeClass"
                                                      className="form-control"
                                                      value={s2.timeClass}
                                                      onChange={(e) => {
                                                        const userTimestamp =
                                                          Date.parse(
                                                            `${s2.dateClass}T${e.target.value}`
                                                          );
                                                        SessionsList[changeId][
                                                          'dateTimeClass'
                                                        ] = userTimestamp;
                                                        SessionsList[changeId][
                                                          'timeClass'
                                                        ] = e.target.value;
                                                        RefreshSessionsList();
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-md-2">
                                                  <div className="form-group">
                                                    <label>
                                                      Duration (Minutes)
                                                    </label>
                                                    <input
                                                      type="number"
                                                      className="form-control"
                                                      name="durationClass"
                                                      value={s2.durationClass}
                                                      onChange={(e) => {
                                                        handleEditSessionsList(
                                                          changeId,
                                                          e.target.name,
                                                          e.target.value
                                                        );
                                                        RefreshSessionsList();
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-md-2">
                                                  <div className="form-group">
                                                    <label title="Allow to view in Public Course Page">
                                                      Free Session{' '}
                                                    </label>
                                                    <select
                                                      className="form-control"
                                                      name="isFree"
                                                      value={s2.isFree}
                                                      onChange={(e) => {
                                                        handleEditSessionsList(
                                                          changeId,
                                                          e.target.name,
                                                          e.target.value
                                                        );
                                                        RefreshSessionsList();
                                                      }}
                                                    >
                                                      <option value="0">
                                                        No
                                                      </option>
                                                      <option value="1">
                                                        Yes
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>

                                                <div className="col-md-2">
                                                  <div className="form-group">
                                                    <label>
                                                      Show Live Students
                                                    </label>
                                                    <input
                                                      type="number"
                                                      className="form-control"
                                                      name="showJoinCount"
                                                      value={s2.showJoinCount}
                                                      onChange={(e) => {
                                                        handleEditSessionsList(
                                                          changeId,
                                                          e.target.name,
                                                          e.target.value
                                                        );
                                                        RefreshSessionsList();
                                                      }}
                                                    />
                                                  </div>
                                                </div>

                                                <div className="col-12 mb-3">
                                                  {s2.dateTimeClass &&
                                                    s2.dateTimeClass !==
                                                      null && (
                                                      <a
                                                        href={`https://www.timebie.com/tz/worldclock.php?q=${Number(
                                                          s2.dateTimeClass
                                                        )}`}
                                                        target="_BLANK"
                                                        rel="noopener noreferrer"
                                                      >
                                                        <span className="text-danger">
                                                          In GMT:&nbsp;
                                                          {new Date(
                                                            Number(
                                                              s2.dateTimeClass
                                                            )
                                                          ).toLocaleString(
                                                            'en-US',
                                                            { timeZone: 'GMT' }
                                                          )}
                                                        </span>
                                                        <span className="text-info">
                                                          &nbsp;&nbsp;|&nbsp;&nbsp;In
                                                          IST:&nbsp;
                                                          {new Date(
                                                            Number(
                                                              s2.dateTimeClass
                                                            )
                                                          ).toLocaleString(
                                                            'en-US',
                                                            {
                                                              timeZone:
                                                                'Asia/Kolkata',
                                                            }
                                                          )}
                                                        </span>
                                                        <span>
                                                          <h3 className="text-success">
                                                            &nbsp;&nbsp;|&nbsp;&nbsp;
                                                            Join + View Counter{' '}
                                                            <b>
                                                              {s2.joinCount}
                                                            </b>
                                                          </h3>
                                                        </span>
                                                      </a>
                                                    )}
                                                </div>

                                                <div className="col-md-6">
                                                  <div
                                                    className="form-group"
                                                    style={{
                                                      position: 'relative',
                                                    }}
                                                  >
                                                    <label className="ry_label">
                                                      Instructor
                                                      {/* {s2.instructorID} : {s2.instructor} */}
                                                    </label>
                                                    <input
                                                      name="sessionInstructor"
                                                      value={s2.instructor}
                                                      className="form-control"
                                                      onChange={(e) => {
                                                        fetchInstructor(
                                                          e.target.value
                                                        );
                                                        handleEditSessionsList(
                                                          changeId,
                                                          'instructorID',
                                                          ''
                                                        );
                                                        handleEditSessionsList(
                                                          changeId,
                                                          'instructor',
                                                          e.target.value
                                                        );
                                                        SessionsList[changeId][
                                                          'instructorID'
                                                        ] = '';
                                                        SessionsList[changeId][
                                                          'instructor'
                                                        ] = e.target.value;
                                                        RefreshSessionsList();
                                                      }}
                                                      onClick={(e) => {
                                                        fetchInstructor(
                                                          e.target.value
                                                        );
                                                      }}
                                                    />
                                                    <a
                                                      href="/"
                                                      className="btn btn-sm btn-outline-danger"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        setInstructorList([]);
                                                      }}
                                                      style={{
                                                        display:
                                                          InstructorList.length ===
                                                          0
                                                            ? 'none'
                                                            : '',
                                                        position: 'absolute',
                                                        zIndex: 999999,
                                                        right: '10px',
                                                        top: '27px',
                                                        fontWeight: 'bold',
                                                      }}
                                                    >
                                                      X
                                                    </a>
                                                    <div
                                                      className="keywordSearchInput"
                                                      style={{
                                                        display:
                                                          InstructorList.length ===
                                                          0
                                                            ? 'none'
                                                            : '',
                                                      }}
                                                    >
                                                      {InstructorList.map(
                                                        (instS) => (
                                                          <div
                                                            className="single"
                                                            key={instS.tid}
                                                          >
                                                            <img
                                                              src={`${BASEAPIURL}/admin/instructure/profileimage/${instS.tid}`}
                                                              alt=""
                                                            />
                                                            <div className="details">
                                                              {instS.name}
                                                              <br />
                                                              <small>
                                                                {instS.email}
                                                              </small>
                                                            </div>
                                                            <div className="action">
                                                              <span
                                                                className="btn btn-sm btn-success mr-1"
                                                                onClick={() => {
                                                                  handleEditSessionsList(
                                                                    changeId,
                                                                    'instructorID',
                                                                    instS._id
                                                                  );
                                                                  handleEditSessionsList(
                                                                    changeId,
                                                                    'instructor',
                                                                    instS.name
                                                                  );
                                                                  SessionsList[
                                                                    changeId
                                                                  ][
                                                                    'instructorID'
                                                                  ] = instS._id;
                                                                  SessionsList[
                                                                    changeId
                                                                  ][
                                                                    'instructor'
                                                                  ] =
                                                                    instS.name;
                                                                  RefreshSessionsList();
                                                                  setInstructorList(
                                                                    []
                                                                  );
                                                                }}
                                                              >
                                                                <i className="fa fa-plus"></i>
                                                              </span>
                                                              <Link
                                                                to={`/instructor/profile/${instS.slug}`}
                                                                target="_BLANK"
                                                                className="btn btn-sm btn-info"
                                                              >
                                                                <i className="fa fa-eye"></i>
                                                              </Link>
                                                            </div>
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-md-6">
                                                  <div className="form-group">
                                                    <label>
                                                      Video ID&nbsp;
                                                      <i
                                                        title="It will be auto Creted automatically in sometime via CRON. If you already created the Live Strem, you can add it here or If you want to change the Sceadule, Please make it blank."
                                                        className="fa fa-question"
                                                      ></i>
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      name="videoID"
                                                      value={s2.videoID}
                                                      onChange={(e) => {
                                                        handleEditSessionsList(
                                                          changeId,
                                                          e.target.name,
                                                          e.target.value
                                                        );
                                                        RefreshSessionsList();
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  ))}
                                <div className="text-right m-2">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-info"
                                    onClick={() => {
                                      setShowLoader(true);
                                      hideLoader();
                                      const t = nextSessionsIndex();
                                      setSessionsList((temp) => [
                                        ...temp,
                                        {
                                          index: t + 1,
                                          srno: t + 1,
                                          title: '',
                                          module: s.index,
                                          dateClass: '',
                                          timeClass: '',
                                          durationClass: '',
                                          dateTimeClass: '',
                                          videos: [],
                                          audios: [],
                                          resources: [],
                                        },
                                      ]);
                                    }}
                                  >
                                    Add New Session
                                  </button>
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        ))}
                        <div style={{ textAlign: 'center' }}>
                          <anew
                            href="#"
                            className="btn btn-info mr-3"
                            onClick={() => {
                              ShowFormDiv('addi1');
                            }}
                            style={{
                              margin: '20px auto',
                              width: '150px',
                            }}
                          >
                            <i className="fa fa-edit"></i>
                            &nbsp;Edit Module
                          </anew>
                          <anew
                            href="#"
                            className="btn btn-docsta-instructor d-inline-block"
                            onClick={() => {
                              ShowFormDiv('addi3');
                            }}
                            style={{
                              margin: '20px auto',
                              width: '150px',
                            }}
                          >
                            Proceed
                          </anew>
                        </div>
                      </div>

                      <div style={{ display: AddiDet3Div }}>
                        <Whatlearn
                          whatlearn={regformdata.whatlearn}
                          handleForm={handleForm}
                          DeleteWhatlearn={DeleteWhatlearn}
                        />
                        <Faqs2Courses
                          faqs={regformdata.faqs}
                          handleForm={handleForm}
                          DeleteFAQs={DeleteFAQs}
                        />
                        <h4
                          style={{
                            background: '#152569',
                            color: '#f5f5f5',
                            padding: '10px 20px',
                            margintop: '20px',
                          }}
                        >
                          Message to the Reviewer
                        </h4>

                        <div
                          className="form-group"
                          style={{
                            border: '1px solid #152569',
                            padding: '20px',
                            background: '#ededed',
                          }}
                        >
                          <textarea
                            required
                            value={regformdata.reviewer}
                            name="question"
                            className="form-control"
                            onChange={(e) => {
                              handleForm('reviewer', e.target.value);
                            }}
                          />
                        </div>
                        <div style={{ textAlign: 'center' }}>
                          <anew
                            href="#"
                            className="btn btn-info mr-3 mb-3"
                            onClick={() => {
                              ShowFormDiv('addi2');
                            }}
                          >
                            <i className="fa fa-edit"></i>
                            &nbsp;Edit Sessions
                          </anew>
                          <button
                            type="submit"
                            className="btn btn-warning d-inline-block mr-3 mb-3 text-dark"
                            onClick={() => {
                              regformdata.draft = 1;
                              setMessage('');
                              handleForm('SessionsList', SessionsList);
                            }}
                            style={{ width: 'auto' }}
                          >
                            Save as Draft
                          </button>
                          <button
                            type="submit"
                            className="btn btn-docsta-instructor d-inline-block mb-3"
                            onClick={() => {
                              regformdata.draft = 0;
                              setMessage('');
                              handleForm('SessionsList', SessionsList);
                            }}
                            style={{ width: 'auto' }}
                          >
                            Submit for Review
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <p className="text-center mt-1 d-none">
                  Already have an Account ?
                  <br />
                  <Link
                    to="/instructor/login"
                    className="mb-4  mt-3"
                  >
                    <button
                      type="submit"
                      className="btn btn-info"
                    >
                      Login as Instructor
                    </button>
                  </Link>
                </p>
              </div>
            </div>

            <div className="col-md-1"></div>
          </div>
        </div>
      </div>
      <Footer className="appie-footer-about-area" />
      <BackToTop />
    </>
  );
}

export default CreateMainCourse;
