import React, { Suspense, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useToggle from '../../../Hooks/useToggle';
import BackToTop from '../../BackToTop';
import Drawer from '../../Mobile/DrawerInstitute';
import Header from '../../Helper/HeaderCourse';
import Footer from '../../Helper/FooterInstitute';
import Sharethis from '../../Helper/Sharethis';

import { BASEAPIURL, BASEURL } from '../../../config';

const CourseDetail = React.lazy(() => import('./CourseDetail'));

function CourseDetailNew() {
  // for share this URL
  const [ShareThisURL, setShareThisURL] = useState(BASEURL);
  const [showShare, setshowShare] = useState(false);
  function closeshowShare() {
    setshowShare(false);
  }
  // for share this URL
  const [IsEntendedView, SetIsEntendedView] = useState(
    Number(localStorage.getItem('screenwidth')) > 1023
  );
  const { CourseSlug } = useParams();
  const [ShowData, SetShowData] = useState({ title: '' });
  const [showReviewForm, setshowReviewForm] = useState(false);
  const [drawer, drawerAction] = useToggle(false);

  useEffect(() => {
    const FetchData = async () => {
      try {
        const res = await fetch(`${BASEAPIURL}/mainCourse/GetCourseDetailStd`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            courseslug: CourseSlug,
          }),
        });
        const resJson = await res.json();
        if (res.status === 200) {
          if (resJson.data.length > 0) {
            SetShowData(resJson.data[0]);
            // console.log(resJson.data[0]);
          }
        } else {
          console.log(resJson.message);
        }
      } catch (err) {
        console.log(err);
      }
    };
    SetIsEntendedView(Number(localStorage.getItem('screenwidth')) > 1023);
    FetchData();
    setShareThisURL(`${BASEURL}course/${CourseSlug}`);
  }, [CourseSlug]);

  return (
    <div className="courseStudent">
      {showShare && (
        <div>
          <div className="mfp-bg mfp-ready"></div>
          <div
            className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
            tabIndex="-1"
            style={{ overflow: ' hidden auto' }}
          >
            <div className="mfp-container mfp-s-ready mfp-iframe-holder">
              <div
                className="mfp-content"
                style={{ maxWidth: 'max-content' }}
              >
                <div className="mfp-iframe-scaler-old">
                  <button
                    onClick={closeshowShare}
                    title="Close (Esc)"
                    type="button"
                    className="mfp-close"
                  >
                    ×
                  </button>
                </div>
                <div
                  className="mfp-iframe p-4 text-center"
                  style={{ background: '#fff' }}
                >
                  <Sharethis ShareThisURL={ShareThisURL} />
                </div>
              </div>
              <div className="mfp-preloader">Loading...</div>
            </div>
          </div>
        </div>
      )}
      {showReviewForm ? '' : ''}
      <Drawer
        drawer={drawer}
        action={drawerAction.toggle}
      />
      <Header
        setshowReviewForm={setshowReviewForm}
        ShowData={ShowData}
        action={drawerAction.toggle}
        url={`${BASEURL}/course/${CourseSlug}`}
        setshowShare={setshowShare}
      />
      <div className={IsEntendedView ? 'makeSpace4CourseCont' : ''}>
        <Suspense fallback={<div></div>}>
          <CourseDetail
            setshowReviewForm={setshowReviewForm}
            showReviewForm={showReviewForm}
            SetIsEntendedView={SetIsEntendedView}
            IsEntendedView={IsEntendedView}
          />
        </Suspense>
        <Footer />
      </div>
      <BackToTop />
    </div>
  );
}

export default CourseDetailNew;
