import React from 'react';
import useToggle from '../../../Hooks/useToggle';
import BackToTop from '../../BackToTop';
import Drawer from '../../Mobile/DrawerInstitute';
import Header from '../../Helper/HeaderInstitute';
import Footer from '../../Helper/FooterInstitute';
import CategorySingle from './CategorySingle';
import CoursesStarted from '../CoursesStarted';
import PopularInstructors from '../PopularInstructors';
import CompleteCourseList from '../CompleteCourseList';
import TrainingBox from '../TrainingBox';
import WebinarsBox from '../WebinarsBox';
import PersonalDevelopBox from '../PersonalDevelopBox';
import BannerBox from '../BannerBox';
import InstructorBox from '../InstructorBox';
import PopularCoursesBox from '../PopularCoursesBox';

function DocInstitute() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <CategorySingle />
            <CoursesStarted />
            <PopularInstructors />
            <CompleteCourseList className="pt-70" />
            <TrainingBox />
            <WebinarsBox className="pt-70 pb-0" />
            <PersonalDevelopBox />
            <BannerBox />
            <InstructorBox />
            <PopularCoursesBox className="pt-70" />
            <Footer />
            <BackToTop />
        </>
    );
}

export default DocInstitute;
