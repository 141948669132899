import React, { useEffect } from 'react';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/DrawerInstitute';
import Header from '../Helper/HeaderInstitute';
import Footer from '../Helper/FooterInstitute';
import { useParams } from 'react-router-dom';
import useToggle from '../../Hooks/useToggle';
import './style.module.css';

const Invoice = () => {
  const { MainCatID } = useParams();
  const queryParameters = new URLSearchParams(window.location.search);
  const [drawer, drawerAction] = useToggle(false);

  return (
    <div className="border ">
      <Drawer
        drawer={drawer}
        action={drawerAction.toggle}
      />
      <Header action={drawerAction.toggle} />
      <div className="border p-5 w-50 my-5 mx-auto rounded">
        <div className="d-flex justify-content-between">
          <img
            src="/logo.png"
            alt=""
            className="w-25"
          />
          <h2>TAX INVOICE</h2>
        </div>
        <hr />
        <br />
        <div>
          <div className="w-50">
            <p>
              <b>Company Address</b>
            </p>
            <table className="w-100">
              <tr>
                <td>Invoice #</td>
                <td>545 / 2017-18</td>
              </tr>
              <tr>
                <td>Invoice Date:</td>
                <td>12-06-2017</td>
              </tr>
              <tr>
                <td>Due Date</td>
                <td>12-07-2017</td>
              </tr>
              <tr>
                <td>G.R #</td>
                <td>2424</td>
              </tr>
              <tr>
                <td>Order #</td>
                <td> PP/23</td>
              </tr>
              <tr>
                <td>GSTN</td>
                <td> 082647566ASD</td>
              </tr>
            </table>
          </div>
          <div className="w-50"></div>
        </div>
        <br />
        <div>
          <table className="w-100 border ">
            <thead>
              <tr>
                <th>S.N.</th>
                <th>Description</th>
                <th>Qty</th>
                <th>Rate</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody className='invoice__items'>
              <tr>
                <td>01</td>
                <td>XXXX</td>
                <td>5566</td>
                <td>1</td>
                <td>1000</td>
                <td>1000</td>
              </tr>
              <tr>
                <td colSpan={5}>
                  <div className="d-flex justify-content-end">
                    {' '}
                    Freight Charges
                  </div>
                </td>
                <td>1000</td>
              </tr>
              <tr>
                <td colSpan={5}>
                  <div className="d-flex justify-content-end"> IGST @ 18 %</div>
                </td>
                <td>0.00</td>
              </tr>
              <tr>
                <td colSpan={5}>
                  <div className="d-flex justify-content-end"> SGST @ 9 %</div>
                </td>
                <td>0.00</td>
              </tr>
              <tr>
                <td colSpan={5}>
                  <div className="d-flex justify-content-end"> CGST @ 9 %</div>
                </td>
                <td>0.00</td>
              </tr>
              <tr>
                <td colSpan={5}>
                  <div className="d-flex justify-content-end"> Total Due</div>
                </td>
                <td>0.00</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div>
          <p>
            <b>TERMS AND CONDITIONS</b>
          </p>
          <p>
            Please send payment within 30 days of receiving this invoice. There
            will be a 1.5% interest charge per month on late invoices.
          </p>
          <br />
          <p>
            <b>PLEASE MAKE A PAYMENT TO</b>
          </p>
          <p>Beneficiary Name: [Company Name]</p>
          <p>Beneficiary Account Number: [1234567890]</p>
          <p>Bank Name and Address: [Bank Name and Address]</p>
          <p>Bank Swift Code: [1234567890]</p>
          <p>IBAN Number: [1234567890]</p>
        </div>
        <br />
        <br />
        <br />
        <h4 className="text-center">Thank you for your business!</h4>
      </div>
      <Footer />
      <BackToTop />
    </div>
  );
};

export default Invoice;
