import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { IMGBASEAPIURL } from '../../config';

function CounsellingTeam() {
    const sliderRef = useRef();
    const counselling = {
        autoplay: false,
        arrows: true,
        dots: false,
        slidesToShow: 5,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    return (
        <>
            <section className="appie-features-area">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Meet Our Counselling Team</h3>
                                <p>Meet the consultants who can reshape your career</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row appie-showcase-slider">
                                <div className="col-lg-12">
                                    <Slider ref={sliderRef} {...counselling}>
                                        {/* <slide1 /> */}
                                        <div>
                                            <div className="appie-features-content">
                                                <div className="imgBox">
                                                    <img
                                                        src={`${IMGBASEAPIURL}institute/instructor/img5.jpg`}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="contentBox">
                                                    <h4>Frank Gordon</h4>
                                                    <b>Medical Doctor</b>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <slide2 /> */}
                                        <div>
                                            <div className="appie-features-content">
                                                <div className="imgBox">
                                                    <img
                                                        src={`${IMGBASEAPIURL}institute/instructor/img6.jpg`}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="contentBox">
                                                    <h4>Anthony Wells</h4>
                                                    <b>Medical Doctor</b>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <slide3 /> */}
                                        <div>
                                            <div className="appie-features-content">
                                                <div className="imgBox">
                                                    <img
                                                        src={`${IMGBASEAPIURL}institute/instructor/img7.jpg`}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="contentBox">
                                                    <h4>Carmen Fox</h4>
                                                    <b>Medical Doctor</b>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <slide4 /> */}
                                        <div>
                                            <div className="appie-features-content">
                                                <div className="imgBox">
                                                    <img
                                                        src={`${IMGBASEAPIURL}institute/instructor/img8.jpg`}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="contentBox">
                                                    <h4>Carmen Fox</h4>
                                                    <b>Medical Doctor</b>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <slide5 /> */}
                                        <div>
                                            <div className="appie-features-content">
                                                <div className="imgBox">
                                                    <img
                                                        src={`${IMGBASEAPIURL}institute/instructor/img1.jpg`}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="contentBox">
                                                    <h4>Dr. Danish Salim</h4>
                                                    <b>Medical Doctor</b>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <slide6 /> */}
                                        <div>
                                            <div className="appie-features-content">
                                                <div className="imgBox">
                                                    <img
                                                        src={`${IMGBASEAPIURL}institute/instructor/img2.jpg`}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="contentBox">
                                                    <h4>Dr. Raiza Shukoor</h4>
                                                    <b>Medical Doctor</b>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <slide7 /> */}
                                        <div>
                                            <div className="appie-features-content">
                                                <div className="imgBox">
                                                    <img
                                                        src={`${IMGBASEAPIURL}institute/instructor/img3.jpg`}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="contentBox">
                                                    <h4>John Willams</h4>
                                                    <b>Medical Doctor</b>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <slide8 /> */}
                                        <div>
                                            <div className="appie-features-content">
                                                <div className="imgBox">
                                                    <img
                                                        src={`${IMGBASEAPIURL}institute/instructor/img4.jpg`}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="contentBox">
                                                    <h4>Daiana John</h4>
                                                    <b>Medical Doctor</b>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CounsellingTeam;
