import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BASEURL } from '../../../config';

function DocInstitute() {
    const { InfoTitle } = useParams();
    
    useEffect(() => {
        switch (InfoTitle) {
            case 'termandconditions':
                window.location.href = `${BASEURL}terms-and-conditions`;
                break;
                
            case 'privacy':
                window.location.href = `${BASEURL}privacy-policy`;
                break;

            case 'RefundAndCancellation':
                window.location.href = `${BASEURL}refunds-and-cancellations`;
                break;

            default:
                window.location.href = BASEURL;
                break;
        }
    }, [InfoTitle]);

    return <></>;
}

export default DocInstitute;
