import React, { useEffect } from 'react';
import { BASEURL } from '../../config';

function BlogRed() {
    
    useEffect(() => {
        window.location.href = `${BASEURL}blog`;
    }, []);

    return <></>;
}

export default BlogRed;
