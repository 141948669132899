import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASEAPIURL, UPLOADNODEURL } from '../../../../config';

const PrimaryIndex = '_id';

function Question2Courses({
    handleForm,
    EditQuestionSession,
    SessionQuestions,
    SessionAnswers,
    setSessionQuestions,
    setSessionAnswers,
    SessionTitle,
}) {
    const [Running, setRunning] = useState(1);
    const [EditQuestionInd, setEditQuestionInd] = useState('');
    const [EditQuestionNo, setEditQuestionNo] = useState(1);
    const [QuestionsList, setQuestionsList] = useState(SessionQuestions);
    const handleAddQuestionsList = (Index, sessionIndex) => {
        setQuestionsList((temp) => [
            ...temp,
            {
                Index,
                question: '',
                sessionIndex,
                questionImage: '',
                level: '',
                status: 1, 
                explanationAnswer: '',
                explanationImage: '',
                answers: [],
            },
        ]);
    };
    const handleEditQuestionsList = (changeId, ind, val) => {
        QuestionsList[changeId][ind] = val;
    };
    const handleRemoveQuestionsList = (ind) => {
        if (ind !== 0) {
            const temp = QuestionsList.filter((item) => Number(item.Index) !== Number(ind));
            setQuestionsList(temp);
            setSessionQuestions(temp);
            handleForm('SessionQuestions', temp);
        } else {
            alert("Unable to Delete");
        }
    };
    const RefreshQuestionsList = () => {
        const temp = 999999999999;
        handleAddQuestionsList(temp, 0);
        handleRemoveQuestionsList(temp);
        handleForm('SessionQuestions', QuestionsList);
        setSessionQuestions(QuestionsList);
    };
    // for questions
    // for Answers
    const [AnswersList, setAnswersList] = useState(SessionAnswers);
    const handleAddAnswersList = (Index) => {
        setAnswersList((temp) => [
            ...temp,
            {
                Index,
                sessionIndex: EditQuestionSession,
                QuestionIndex: EditQuestionNo,
                answer: '',
                status: 1,
                isCorrect: 0,
            },
        ]);
    };
    const handleEditAnswersList = (changeId, ind, val) => {
        AnswersList[changeId][ind] = val;
    };
    const handleRemoveAnswersList = (ind) => {
        if (ind !== 1) {
            setAnswersList(AnswersList.filter((item) => item.Index !== ind));
        }
    };
    const RefreshAnswersList = () => {
        const temp = 999999;
        handleAddAnswersList(temp);
        handleRemoveAnswersList(temp);
        handleForm('SessionAnswers', AnswersList);
        setSessionAnswers(AnswersList);
    };
    // for Amswers
    // for Multiple Question List

    // for the File Uploads will be here
    async function uploadFile(file, fileName, index) {
        // e.preventDefault();
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', fileName);
        const url = `${BASEAPIURL}/admin/instructure/uploadcv`;
        try {
            const res = await axios.post(url, formData);
            handleEditQuestionsList(EditQuestionInd, index, res.data.filename);
        } catch (ex) {
            console.log(ex);
        }
    }
    const saveFile = (e, indexName) => {
        if (e.target.files && e.target.files.length > 0) {
            uploadFile(e.target.files[0], e.target.files[0].name, indexName);
        }
    };
    // for the File Uploads will be here

    useEffect(() => {
        // setAnswersList(Answers);
        // console.log(SessionQuestions);
        // console.log(SessionAnswers);
        // console.log(EditQuestionSession);
    }, []);

    function nextQuestionIndex() {
        let maxIndex = 1;
        QuestionsList.forEach((item) => {
            if (item.Index > maxIndex) {
              maxIndex = item.Index;
            }
          });
        return maxIndex;
    }

    function nextAnswerIndex() {
        let maxIndex = 1;
        AnswersList.forEach((item) => {
            if (item.Index > maxIndex) {
              maxIndex = item.Index;
            }
          });
        return maxIndex;
    }

    return (
        <div style={{ minWidth: '300px' }}>
            {EditQuestionInd !== '' ? (
                <>
                    <h4
                        style={{
                            background: '#152569',
                            color: '#f5f5f5',
                            padding: '10px 20px',
                        }}
                    >
                        Question
                    </h4>
                    <div
                        className="form-group"
                        style={{
                            border: '1px solid #152569',
                            padding: '20px',
                            background: '#ededed',
                        }}
                    >
                        <div className="mb-3">
                            <div className="row">
                                <div className="col-md-9">
                                    <label>Question</label>
                                    <textarea
                                        name="question"
                                        className="form-control"
                                        value={QuestionsList[EditQuestionInd].question}
                                        onClick={() => {
                                            let OptionCount = 0;
                                            AnswersList.map((s) => {
                                                if (
                                                    Number(s.QuestionIndex) ===
                                                    Number(EditQuestionNo)
                                                ) {
                                                    OptionCount += 1;
                                                }
                                                return '';
                                            });
                                            if (OptionCount === 0) {
                                                document.getElementById('AddMoreOptions').click();
                                            }
                                        }}
                                        onChange={(e) => {
                                            handleEditQuestionsList(
                                                EditQuestionInd,
                                                'question',
                                                e.target.value
                                            );
                                            RefreshQuestionsList(e);
                                        }}
                                    />
                                </div>
                                <div
                                    className="col-md-3"
                                    style={{
                                        background: `url('${UPLOADNODEURL}/${QuestionsList[EditQuestionInd].questionImage}')`,
                                        backgroundSize: '100%',
                                    }}
                                >
                                    <label style={{ background: '#f5f5f5d1' }}>
                                        Question Image
                                    </label>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => {
                                            saveFile(e, 'questionImage');
                                        }}
                                        style={{ background: 'transparent' }}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className=""
                            onBlur={() => {
                                // handleForm('SessionAnswers', AnswersList);
                                // setSessionAnswers(AnswersList);
                            }}
                        >
                            <label>Options</label>
                            {AnswersList.map((s, AnsRowID) => (
                                <div
                                    key={`${EditQuestionInd}_${s.Index}`}
                                    style={{ display: Running && Number(s.status) === 1 ? '' : 'none' }}
                                >
                                    {Number(s.QuestionIndex) === Number(EditQuestionNo) ? (
                                        <div className="row mb-1" style={{}}>
                                            <div className="col-md-8">
                                                {/* {`EditQuestionNo : ${EditQuestionNo}`}
                                                {`s.QuestionIndex : ${s.QuestionIndex}`} */}
                                                <div className="form-group">
                                                    {/* <label>Answer {Number(AnsRowID) + 1}</label> */}
                                                    <textarea
                                                        name="answer"
                                                        className="form-control"
                                                        // eslint-disable-next-line no-plusplus
                                                        placeholder="Enter Option"
                                                        value={s.answer}
                                                        onChange={(e) => {
                                                            handleEditAnswersList(
                                                                AnsRowID,
                                                                e.target.name,
                                                                e.target.value
                                                            );
                                                            RefreshAnswersList();
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-1 text-center">
                                                <i
                                                    style={{
                                                        display:
                                                            s[PrimaryIndex] &&
                                                            s[PrimaryIndex] !== null
                                                                ? 'none'
                                                                : '',
                                                    }}
                                                    className="far fa-trash text-danger"
                                                    onClick={() => {
                                                        handleRemoveAnswersList(s.Index);
                                                    }}
                                                ></i>
                                                <i
                                                    style={{ display: s[PrimaryIndex] && s[PrimaryIndex] !== null ? '' : 'none' }}
                                                    className="fas fa-trash text-danger"
                                                    onClick={() => {
                                                        if ( window.confirm( ' Do you really want to Remove this Option ?' ) ) {
                                                            handleEditAnswersList(AnsRowID, 'status', 0);
                                                            setRunning(Running + 1);
                                                        }
                                                    }}
                                                ></i>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <input
                                                        type="checkbox"
                                                        name="isCorrect"
                                                        checked={s.isCorrect === 1}
                                                        className="form-control1"
                                                        value={s.isCorrect}
                                                        onChange={(e) => {
                                                            if (e.target.checked) {
                                                                handleEditAnswersList(AnsRowID, e.target.name, 1);
                                                            } else {
                                                                handleEditAnswersList(AnsRowID, e.target.name, 0);
                                                            }
                                                            RefreshAnswersList();
                                                        }}
                                                    />
                                                    &nbsp;Correct Answer
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            ))}
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <span
                                    id="AddMoreOptions"
                                    onClick={() => {
                                        const t = nextAnswerIndex();
                                        // t = AnswersList[t - 1] ? AnswersList[t - 1].Index : 0;
                                        handleAddAnswersList(t + 1);
                                    }}
                                    className="ryuploadAct"
                                    style={{
                                        maxWidth: 'max-content',
                                        background: '#7f13ab',
                                        color: '#f5f5f5',
                                        fontWeight: 800,
                                        borderRadius: '10px',
                                        padding: '5px 20px',
                                        cursor: 'pointer',
                                        margin: '0px auto',
                                        marginBottom: '10px',
                                        float: 'right',
                                    }}
                                >
                                    <i className="fa fa-plus"></i> Option
                                </span>
                            </div>
                            <div className="col-md-3"></div>
                        </div>

                        <div className="mb-3">
                            <div className="row">
                                <div className="col-md-9">
                                    <label>Explanation</label>
                                    <input
                                        type="text"
                                        name="explanationAnswer"
                                        className="form-control"
                                        value={QuestionsList[EditQuestionInd].explanationAnswer}
                                        onChange={(e) => {
                                            handleEditQuestionsList(
                                                EditQuestionInd,
                                                'explanationAnswer',
                                                e.target.value
                                            );
                                            RefreshQuestionsList(e);
                                        }}
                                    />
                                </div>
                                <div
                                    className="col-md-3"
                                    style={{
                                        background: `url('${UPLOADNODEURL}/${QuestionsList[EditQuestionInd].explanationImage}')`,
                                        backgroundSize: '100%',
                                    }}
                                >
                                    <label style={{ background: '#f5f5f5d1' }}>
                                        Explanation Image
                                    </label>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => {
                                            saveFile(e, 'explanationImage');
                                        }}
                                        style={{ background: 'transparent' }}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 text-center">
                            <anew
                                name="saveQuestion"
                                className="btn btn-danger mr-1 text-white"
                                onClick={() => {
                                    setEditQuestionInd('');
                                }}
                            >
                                Cancel
                            </anew>
                            <anew
                                name="saveQuestion"
                                className="btn btn-success text-white"
                                onClick={() => {
                                    setEditQuestionInd('');
                                    handleForm('SessionQuestions', QuestionsList);
                                    setSessionQuestions(QuestionsList);
                                    // handleForm('SessionAnswers', AnswersList);
                                    setSessionAnswers(AnswersList);
                                    // setEditQuestionForm('NA');
                                }}
                            >
                                Submit
                            </anew>
                        </div>
                    </div>
                </>
            ) : (
                <div className="text-left">
                    <h4
                        style={{
                            background: '#152569',
                            color: '#f5f5f5',
                            padding: '5px 10px',
                        }}
                    >
                        {SessionTitle}
                    </h4>
                    <div style={{ border1: '1px solid #152569', padding: '10px' }}>
                        {QuestionsList.map((Que, QuestionInd) => (
                            <div
                                key={`${EditQuestionSession}_${Que.Index}`}
                                style={{ display: Running && Number(Que.status) === 1 ? '' : 'none' }}
                            >
                                {Number(EditQuestionSession) === Number(Que.sessionIndex) ? (
                                    <>
                                        <div>{Que.Index} {Que.question}</div>
                                        <span
                                            href="#"
                                            onClick={() => {
                                                setEditQuestionInd(QuestionInd);
                                                setEditQuestionNo(Que.Index);
                                            }}
                                            className="mr-2"
                                        >
                                            <i className="fa fa-edit"></i>
                                        </span>
                                        <span
                                            style={{ display: Que[PrimaryIndex] && Que[PrimaryIndex] !== null ? 'none' : '' }}
                                            onClick={() => {
                                                if ( window.confirm( ' Do you really want to Remove this Question ?' ) ) {
                                                    handleRemoveQuestionsList(Que.Index);
                                                }
                                            }}
                                            className="text-danger"
                                        >
                                            <i className="far fa-trash"></i>
                                        </span>
                                        <span
                                            style={{ display: Que[PrimaryIndex] && Que[PrimaryIndex] !== null ? '' : 'none' }}
                                            onClick={() => {
                                                if ( window.confirm( ' Do you really want to Remove this Question ?' ) ) {
                                                    // handleRemoveQuestionsList(Que.Index);
                                                    handleEditQuestionsList(QuestionInd, 'status', 0);
                                                    setRunning(Running + 1);
                                                }
                                            }}
                                            className="text-danger"
                                        >
                                            <i className="fas fa-trash" title=""></i>
                                        </span>
                                        <hr />
                                    </>
                                ) : (
                                    ''
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="text-center mb-3">
                        <anew
                            href="#"
                            name="saveQuestion"
                            className="btn btn-success"
                            onClick={() => {
                                setEditQuestionInd(QuestionsList.length);
                                // t = QuestionsList[t - 1] ? QuestionsList[t - 1].Index : 0;
                                let t = nextQuestionIndex();
                                setEditQuestionNo(t + 1);
                                handleAddQuestionsList(t + 1, EditQuestionSession);
                            }}
                        >
                            Add MCQ
                        </anew>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Question2Courses;
