import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import Parser from 'html-react-parser';
import { BASEAPIURL, UPLOADNODEURL } from '../../../config';
import '../../Helper/rypopup.css';

let keyword = '';
let freetipid = '';
let SortBy = '';

function FreeAudioTips() {
    const [NoTips, SetNoTips] = useState(false);
    const [PlayAudio, SetPlayAudio] = useState('');
    const [LifeTipsCategory, setLifeTipsCategory] = useState([]);
    const [Instructures, setInstructures] = useState([]);
    const [Audios, setAudios] = useState([]);
    const [AllFreeLifeTips, setAllFreeLifeTips] = useState([]);

    const GetFreeLifeTips = async () => {
        // SetNoTips(false);
        try {
            const res = await fetch(`${BASEAPIURL}/admin/freetips/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    type: 'students',
                    keyword,
                    category: freetipid,
                    sortby: SortBy,
                    SortStatus: 1,
                    recordsPerPage: 100,
                }),
            });
            const resJson = await res.json();

            if (resJson && resJson.data && resJson.data.length > 0) {
                setAllFreeLifeTips(resJson.data);
                SetNoTips(false);
            } else {
                SetNoTips(true);
                setAllFreeLifeTips([]);
            }

            if (resJson && resJson.instructure && resJson.instructure.length > 0) {
                setInstructures(resJson.instructure);
            }

            if (resJson && resJson.audios && resJson.audios.length > 0) {
                setAudios(resJson.audios);
            }
        } catch (err) {
            alert(err);
        }
    };

    const GetFreeLifeTipsCategory = async () => {
        try {
            const res = await fetch(`${BASEAPIURL}/admin/freetipscategory/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    type: 'students',
                    recordsPerPage: 100,
                    sortby: 'a2z',
                }),
            });
            const resJson = await res.json();
            if (resJson && resJson.data && resJson.data.length > 0) {
                setLifeTipsCategory(resJson.data);
            }
        } catch (err) {
            alert(err);
        }
    };

    function showCatName(Freetipid) {
        let returnValue = '';
        LifeTipsCategory.map((s) => {
            if (Number(s.freetipid) === Number(Freetipid)) {
                returnValue = s.title;
            }
            return '';
        });
        return returnValue;
    }

    const [RunningTrack, SetRunningTrack] = useState('');
    function FetchRunningTrack(iptcatid) {
        const temp = [];
        if (Audios.length > 0) {
            Audios.map((s) => {
                if (Number(iptcatid) === Number(s.freetipautoip)) {
                    temp.push(s);
                }
                return '';
            });
        }
        SetRunningTrack(temp);
    }

    function showInstructor(tid) {
        let returnValue = '';
        Instructures.map((s) => {
            if (Number(s.tid) === Number(tid)) {
                returnValue = (
                    <div className="row">
                        <div className="col-3 pr-0">
                            <Link to={`/instructor/profile/${s.slug}`} target="_BLANK">
                                <img
                                    src={`${BASEAPIURL}/admin/instructure/profileimage/${tid}`}
                                    alt=""
                                    className="pt-2"
                                />
                            </Link>
                        </div>
                        <div className="col-9">
                            <Link to={`/instructor/profile/${s.slug}`} target="_BLANK">
                                <div className="text-dark">Added By:</div>
                                <b className="text-info">{s.name}</b>
                                <br />
                                <span className="text-dark">{s.subTitle}</span>
                            </Link>
                        </div>
                    </div>
                );
            }
            return '';
        });
        return returnValue;
    }
    // for playing next audio track
    const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
    const [isPlaying, setisPlaying] = useState(1);
    const handlePlayPause = (index) => {
        if (currentTrackIndex === index) {
            const audioPlayer = document.getElementById(`audioPlayer${index}`);
            if (audioPlayer.paused) {
                audioPlayer.play();
                setisPlaying(1);
            } else {
                audioPlayer.pause();
                setisPlaying(0);
            }
        } else {
            if (currentTrackIndex !== null) {
                const previousAudio = document.getElementById(`audioPlayer${currentTrackIndex}`);
                if (previousAudio) {
                    setisPlaying(0);
                    previousAudio.pause();
                }
            }
            setCurrentTrackIndex(index);
            const audioPlayer = document.getElementById(`audioPlayer${index}`);
            if (audioPlayer) {
                setisPlaying(1);
                audioPlayer.play();
            }
        }
    };
    // for playing next audio track
    useEffect(() => {
        keyword = '';
        freetipid = '';
        SortBy = 'Latest';
        GetFreeLifeTipsCategory();
        GetFreeLifeTips();
        // for playing next audio track
        const playNextTrack = () => {
            const nextTrackIndex = (currentTrackIndex + 1) % RunningTrack.length;
            setCurrentTrackIndex(nextTrackIndex);
        };
        const audioPlayer = document.getElementById(`audioPlayer${currentTrackIndex}`);
        if (audioPlayer) {
            audioPlayer.load();
            audioPlayer.play();
            audioPlayer.addEventListener('ended', playNextTrack);
            return () => {
                audioPlayer.removeEventListener('ended', playNextTrack);
            };
        }
        // for playing next audio track
        // return 0;
    }, [currentTrackIndex, RunningTrack]);

    return (
        <>
            {/* play audio option will be here option new */}
            {AllFreeLifeTips.length > 0 &&
                AllFreeLifeTips.map((s) => (
                    <div key={s.freetipautoip}>
                        {Number(PlayAudio) === Number(s.freetipautoip) ? (
                            <>
                                {RunningTrack.length > 0 &&
                                    RunningTrack.map((s2, i) => (
                                        <div key={s.index}>
                                            {Number(s.freetipautoip) ===
                                            Number(s2.freetipautoip) ? (
                                                <div
                                                    className="audioPlayTrackCont"
                                                    style={{
                                                        display:
                                                            currentTrackIndex === i ? '' : 'none',
                                                    }}
                                                >
                                                    <b>
                                                        {s2.title}
                                                        <div
                                                            style={{
                                                                float: 'right',
                                                                paddingRight: '20px',
                                                            }}
                                                        >
                                                            <Link
                                                                href="#"
                                                                className="text-danger"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setCurrentTrackIndex('NA');
                                                                }}
                                                            >
                                                                X
                                                            </Link>
                                                        </div>
                                                        <div style={{ clear: 'both' }}></div>
                                                    </b>
                                                    <div className="audioPlayTrack">
                                                        <audio
                                                            onContextMenu={(e) => {
                                                                e.preventDefault();
                                                            }}
                                                            id={`audioPlayer${i}`}
                                                            autoPlay={i === 0}
                                                            src={`${UPLOADNODEURL}${s2.audio}`}
                                                            controls
                                                            controlsList="nodownload"
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            className="custom-audio"
                                                        >
                                                            <track
                                                                kind="captions"
                                                                src="/media/examples/friday.vtt"
                                                            />
                                                            Your browser does not support the audio
                                                            element.
                                                        </audio>
                                                        <button
                                                            type="button"
                                                            className="ry"
                                                            onClick={() => {
                                                                handlePlayPause(i);
                                                            }}
                                                        >
                                                            {isPlaying === 1 ? (
                                                                <i className="fa fa-pause"></i>
                                                            ) : (
                                                                <i className="fa fa-play"></i>
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    ))}
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                ))}
            {/* play audio option will be here option new */}
            {/* play audio option will be here */}
            {/* play audio option will be here */}
            <section>
                <div className="appie-section-title text-center">
                    <h4
                        className="mt-4 mb-3 text-left"
                        style={{
                            borderBottom: '1px solid rgb(122, 54, 148)',
                            display: 'inline-block',
                            paddingBottom: '5px',
                        }}
                    >
                        Free Audio Tips
                    </h4>
                </div>
            </section>
            <section className="pt-3 pb-3" style={{ background: '#f5f5f5' }}>
                <div className="container mb-4">
                    <div className="row">
                        <div className="col-md-12">
                            <form id="blog_filter_frm" className="row mt-1">
                                <div className="col-md-4">
                                    <input
                                        name="keyword"
                                        type="text"
                                        className="form-control mb-1"
                                        placeholder="Search Anything"
                                        value={keyword}
                                        onChange={(e) => {
                                            keyword = e.target.value;
                                            GetFreeLifeTips();
                                        }}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <select
                                        name="SortStatus"
                                        value={freetipid}
                                        className="form-control mb-1"
                                        onChangeCapture={(e) => {
                                            freetipid = e.target.value;
                                            GetFreeLifeTips();
                                        }}
                                    >
                                        <option value="">Select Category</option>
                                        {LifeTipsCategory.length > 0 &&
                                            LifeTipsCategory.map((s) => (
                                                <option key={s.freetipid} value={s.freetipid}>
                                                    {s.title}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <div className="col-md-4">
                                    <select
                                        name="sortby"
                                        value={SortBy}
                                        className="form-control mb-1"
                                        onChangeCapture={(e) => {
                                            SortBy = e.target.value;
                                            GetFreeLifeTips();
                                        }}
                                    >
                                        <option value="0">Sort By</option>
                                        <option value="a2z">Title A2Z</option>
                                        <option value="z2a">Title Z2A</option>
                                        <option value="latest">Latest</option>
                                        <option value="old">Oldest</option>
                                    </select>
                                </div>
                            </form>
                            <div className="mt-3">
                                <div className="row">
                                    {NoTips ? (
                                        <div className="col-12 mb-3">
                                            <div className="alert alert-info mt-4 mb-4">
                                                There is no Audio Tips found with your search, Try
                                                with search from other Parameters.
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {AllFreeLifeTips.length > 0 &&
                                        AllFreeLifeTips.map((s) => (
                                            <div
                                                className="col-12 col-md-4 mb-3"
                                                key={s.freetipautoip}
                                            >
                                                <div className="audidTips p-3">
                                                    <h6
                                                        className="text-info d-flex"
                                                        style={{
                                                            justifyContent: 'space-between',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            setCurrentTrackIndex(0);
                                                            SetPlayAudio(s.freetipautoip);
                                                            FetchRunningTrack(s.freetipautoip);
                                                        }}
                                                    >
                                                        {s.title}
                                                        <i className="far fa-play"></i>
                                                    </h6>
                                                    <p>{s.description}</p>
                                                    <small className="row mt-1">
                                                        {s.createdID !== '' ? (
                                                            <div className="col-12 mb-1">
                                                                {showInstructor(s.createdID)}
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {s.category !== '' ? (
                                                            <div className="col-12 mb-1">
                                                                <b>Category: </b>
                                                                <span
                                                                    onClick={() => {
                                                                        freetipid = s.category;
                                                                        GetFreeLifeTips();
                                                                    }}
                                                                >
                                                                    {showCatName(s.category)}
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {s.language !== '' ? (
                                                            <div
                                                                className="col-12"
                                                                onClick={() => {
                                                                    keyword = s.language;
                                                                    GetFreeLifeTips();
                                                                }}
                                                            >
                                                                <b>Language: </b>
                                                                <span>{s.language}</span>
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                        {s.agegroup !== '' ? (
                                                            <div
                                                                className="col-6 d-none"
                                                                onClick={() => {
                                                                    keyword = s.agegroup;
                                                                    GetFreeLifeTips();
                                                                }}
                                                            >
                                                                <b>Age Group:</b>
                                                                <div>{s.agegroup}</div>
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </small>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FreeAudioTips;
