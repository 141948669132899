import React from 'react';
import { IMGBASEAPIURL } from '../../../config';
// import img1 from '../../../assets/images/background_course.png';

function CorporateLearning() {
    return (
        <>
            <section className="corporate-content d-none">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="learningBlock">
                                <div className="innerBox">
                                    <div className="imgBox">
                                        <img
                                            src={`${IMGBASEAPIURL}institute/corporate-learning/img1.jpg`}
                                            alt=""
                                        />
                                    </div>
                                    <div className="contentBox">
                                        <h6>Soft Skills Learning</h6>
                                    </div>
                                </div>
                                <div className="innerBox">
                                    <div className="imgBox">
                                        <img
                                            src={`${IMGBASEAPIURL}institute/corporate-learning/img2.jpg`}
                                            alt=""
                                        />
                                    </div>
                                    <div className="contentBox">
                                        <h6>Cyber Security Learning</h6>
                                    </div>
                                </div>
                                <div className="innerBox">
                                    <div className="imgBox">
                                        <img
                                            src={`${IMGBASEAPIURL}institute/corporate-learning/img3.jpg`}
                                            alt=""
                                        />
                                    </div>
                                    <div className="contentBox">
                                        <h6>Microsoft Learning</h6>
                                    </div>
                                </div>
                                <div className="innerBox">
                                    <div className="imgBox">
                                        <img
                                            src={`${IMGBASEAPIURL}institute/corporate-learning/img4.jpg`}
                                            alt=""
                                        />
                                    </div>
                                    <div className="contentBox">
                                        <h6>Designing Learning</h6>
                                    </div>
                                </div>
                                <div className="innerBox">
                                    <div className="imgBox">
                                        <img
                                            src={`${IMGBASEAPIURL}institute/corporate-learning/img5.jpg`}
                                            alt=""
                                        />
                                    </div>
                                    <div className="contentBox">
                                        <h6>Development Learning</h6>
                                    </div>
                                </div>
                                <div className="innerBox">
                                    <div className="imgBox">
                                        <img
                                            src={`${IMGBASEAPIURL}institute/corporate-learning/img6.jpg`}
                                            alt=""
                                        />
                                    </div>
                                    <div className="contentBox">
                                        <h6>Project Management Learning</h6>
                                    </div>
                                </div>
                                <div className="innerBox">
                                    <div className="imgBox">
                                        <img
                                            src={`${IMGBASEAPIURL}institute/corporate-learning/img7.jpg`}
                                            alt=""
                                        />
                                    </div>
                                    <div className="contentBox">
                                        <h6>Language Learning</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="corporateBlock3">
                {/* style={{
                    backgroundImage: `url(${IMGBASEAPIURL}institute/bg/corporate-learning-bg2.jpg?v1=0.2)`,
                }} */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6">
                            <img
                                src={`${IMGBASEAPIURL}institute/bg/corporate-learning-bg2.jpg?v1=0.2`}
                                style={{
                                    display: 'flex',
                                    width: '504px',
                                    marginLeft: '100px',
                                    padding: '5px',
                                    boxShadow: '3px 3px 10px #00000070',
                                }}
                                alt=""
                            />
                        </div>
                        <div className="col-lg-6">
                            <div className="textBlock">
                                <h3>
                                    Experience CuratedTraining Solutions to Boost Employee
                                    Productivity in Abu Dhabi
                                </h3>
                                <h4>
                                    DocSta supports your Organizational Growth by providing the most
                                    In-Demand Industry Coursesfor your employees
                                </h4>
                                <p>
                                    We develop & deliver world-class corporate learning programs for
                                    employees that align with your company's culture. In addition,
                                    we offer both private and custom corporate learning programs for
                                    corporate groups or organizations with specific learning and
                                    development needs.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="counterBlock">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="innerBox">
                                <div>
                                    <h3>
                                        120K+<span>People Trained</span>
                                    </h3>
                                </div>
                                <div>
                                    <h3>
                                        30+<span>Industrial Sectors</span>
                                    </h3>
                                </div>
                                <div>
                                    <h3>
                                        1.2K+<span>Competent Trainers</span>
                                    </h3>
                                </div>
                                <div>
                                    <h3>
                                        8K+<span>Sessions Completed</span>
                                    </h3>
                                </div>
                                {/* <div>
                                    <h3>
                                        15+<span>ERP Projects</span>
                                    </h3>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="benefitBlock d-none">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3>How You Benefit</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="innerBox">
                                <div>
                                    <i className="fal fa-file-invoice-dollar" />
                                    <h6>Improves Cost Efficiency</h6>
                                </div>
                                <p>
                                    We help you cut the cost of hiring new employees by training
                                    your existing employees. Corporate learning and development
                                    improve efficiency and build their existing knowledge.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="innerBox">
                                <div>
                                    <i className="fal fa-user-headset" />
                                    <h6>Retain your Clients/Customers</h6>
                                </div>
                                <p>
                                    From signing new customers to maintaining existing, our training
                                    will improve your employees' engagement which results in
                                    developing best customer service practices.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="innerBox">
                                <div>
                                    <i className="fal fa-user-chart" />
                                    <h6>Lowers Absenteeism</h6>
                                </div>
                                <p>
                                    When you improve employee engagement, it will naturally reflect
                                    in their sense of commitment to your company resulting in
                                    lowering absenteeism.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="innerBox">
                                <div>
                                    <i className="fal fa-users-class" />
                                    <h6>Eliminates Weaknesses</h6>
                                </div>
                                <p>
                                    Team training helps employees to understand each other's
                                    weaknesses and to eliminate these insufficiencies when the
                                    circumstances are right.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="innerBox">
                                <div>
                                    <i className="fal fa-users-crown" />
                                    <h6>Improves Employee Morale</h6>
                                </div>
                                <p>
                                    Our corporate training helps improve your employees' morale and
                                    creates a sense of value which inspires them to produce better
                                    results.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="innerBox">
                                <div>
                                    <i className="fal fa-alarm-clock" />
                                    <h6>Ramps Up Productivity</h6>
                                </div>
                                <p>
                                    Instead of hiring a new team, training your existing team
                                    virtually guarantees an increase in their future level of
                                    production.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="featuresBlock d-none">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3>Our Stand Alone Features</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="innerBox">
                                <i className="fal fa-file-invoice-dollar" />
                                <h6>Customized Training</h6>
                                <p>
                                    Our customized corporate training solutions deliver key business
                                    outcomes that guarantee you a great return on investment.
                                    <br />
                                    <br />
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="innerBox">
                                <i className="fal fa-user-headset" />
                                <h6>Competent Trainers</h6>
                                <p>
                                    Our Trainers will understand your business challenges and
                                    discover skills and competency gaps in the domain of leadership
                                    and team performance.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="innerBox">
                                <i className="fal fa-user-chart" />
                                <h6>Comprehensive Learning</h6>
                                <p>
                                    We follow a comprehensive method of teaching which takes an
                                    active, customised, authentic, flexible, engaging and practical
                                    approach.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="benefitBlock">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3>Why DocSta is a Preferred Choice?</h3>
                            <p>
                                We create engaging and application-oriented learning experiences
                                that have a lasting impact on people and their careers, inspiring
                                new ways of thinking, building key capabilities and thereby paving
                                the way for organizational success.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="innerBox">
                                <div>
                                    <i className="fal fa-lightbulb-on" />
                                    <h6>Bespoke Solutions</h6>
                                </div>
                                <p>
                                    We prioritize role based competencies that are critical to
                                    business success and addressed in the context of business
                                    problems thereby creating bespoke solutions that are
                                    organization and audience specific.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="innerBox">
                                <div>
                                    <i className="fal fa-print-search" />
                                    <h6>Researched Content</h6>
                                </div>
                                <p>
                                    Our content is well-researched, immersive and easy to understand
                                    keeping in mind the principles of andragogy.
                                </p>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="innerBox">
                                <div>
                                    <i className="fal fa-badge-check" />
                                    <h6>Certified Facilitators</h6>
                                </div>
                                <p>
                                    Our Subject matter experts (SMEs) / Faculty are Industry
                                    practitioners with at least 15 years of relevant experience and
                                    with a flair for training. Our selection of trainers is based on
                                    multiple criteria's and Industry relevance context is a key
                                    component.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="innerBox">
                                <div>
                                    <i className="fal fa-usd-circle" />
                                    <h6>ROI Measurement</h6>
                                </div>
                                <p>
                                    We use reliable metrics and methods to measure training
                                    effectiveness and ROI for the client. We believe in continual
                                    intervention allowing us to monitor and mentor learners so that
                                    they demonstrate a positive impact of training through improved
                                    productivity.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="featuresBlock">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3>Our Learning Solutions</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="innerBox">
                                <i className="fal fa-users-class" />
                                <h6>Corporate Training</h6>
                                <p>
                                    Bespoke, cost-effective and high-quality training programs
                                    delivered by industry practitioners.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="innerBox">
                                <i className="fal fa-user-chart" />
                                <h6>Exec Education</h6>
                                <p>
                                    We offer programs from top Ivy League Universities on
                                    Leadership, Strategy, Digital Transformation and Future skills.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="innerBox">
                                <i className="fal fa-analytics" />
                                <h6>Digital Content Development</h6>
                                <p>
                                    Develop highly engaging and interactive digital learning assets
                                    for reskilling employees at an industrial scale.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="innerBox">
                                <i className="fal fa-chalkboard-teacher" />
                                <h6>Nationalisation Programs</h6>
                                <p>
                                    Create custom developmental programs for building local Emirati
                                    talent pool.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="innerBox">
                                <i className="fal fa-graduation-cap" />
                                <h6>Management Development</h6>
                                <p>
                                    Develop middle management capabilities and leadership pipeline
                                    across levels.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="innerBox">
                                <i className="fal fa-file-certificate" />
                                <h6>Certification Programs</h6>
                                <p>
                                    Benchmark the skills and knowledge of your employees to industry
                                    standards.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="benefitBlock borderBottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h3>The DocSta Approach</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="innerBox">
                                <div>
                                    <i className="fal fa-share-alt" />
                                    <h6>Understand the Context</h6>
                                </div>
                                <p>
                                    Begin with the end in mind - We define success and end result
                                    and design a framework to achieve the desired impact.
                                </p>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="innerBox">
                                <div>
                                    <i className="fal fa-chalkboard" />
                                    <h6>Learning Programs</h6>
                                </div>
                                <p>
                                    Our comprehensive learning programs are not just conceptual but
                                    also contextual and span across sectors providing you the
                                    required choice of programs.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="innerBox">
                                <div>
                                    <i className="fal fa-laptop-code" />
                                    <h6>Digital Solutions</h6>
                                </div>
                                <p>
                                    Our solutions integrate online, mobile, gamification and
                                    simulation elements to make learning engaging and receptive.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="innerBox">
                                <div>
                                    <i className="fal fa-headset" />
                                    <h6>Support Model</h6>
                                </div>
                                <p>
                                    We believe in continual intervention. We support the
                                    organizations until a measurable impact is witnessed.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CorporateLearning;
