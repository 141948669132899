import React from 'react';
import { IMGBASEAPIURL } from '../../config';

function BigBannerBox2() {
    return (
        <>
            <section className="big-banner-area d-none">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="bannerImg">
                                <a href="/">
                                    <img src={`${IMGBASEAPIURL}institute/banner/img4.jpg`} alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BigBannerBox2;
