import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PhoneInput from 'react-phone-number-input';
import { BASEAPIURL } from '../../../../config';

function PayoutSetting({ forpage }) {
    // const navigate = useNavigate();
    // const [drawer, drawerAction] = useToggle(false);
    // const [lfVisibility, setlfVisibility] = useState('block');

    const token = localStorage.getItem('token');
    const [Token] = useState(token);
    const [TID, setTID] = useState(0);
    const [UserFormData, setUserFormData] = useState({
        preferred: 'Bank',
        bank_name: '',
        bank_branch: '',
        bank_acc_no: '',
        bank_acc_holder: '',
        bank_acc_icsc: '',
        paypal: '',
        stripe: '',
        paystack: '',
        rozorpay: '',
        gpay: '',
        paytm: '',
    });
    const UpdateUserFormData = (name, value) => {
        setUserFormData({ ...UserFormData, [name]: value });
    };
    const [Msgcolor, setMsgcolor] = useState();
    const [APIMessage, setMessage] = useState();
    const [GPayPhoneNumber, setGPayPhoneNumber] = useState('');
    const [PaytmPhoneNumber, setPaytmPhoneNumber] = useState('');

    async function updatePayoutSettings() {
        try {
            setMsgcolor('green');
            const url = `${BASEAPIURL}/admin/instructure/update_payout_settings`;
            const formData = new FormData();
            formData.append('edit', Token);
            formData.append('edittid', TID);
            // get all the data and add there
            // formData.append('photo', ProfilePhoto);
            // Object.keys(obj).map(function(key) {
            Object.keys(UserFormData).map((index) => {
                // formData.append(index, value); // console.log(index); console.log(obj[index]);
                formData.append(index, UserFormData[index]);
                return index;
            });
            const config = {
                headers: {
                    'content-type': 'application/json', // multipart/form-data
                },
            };
            axios.post(url, formData, config).then((response) => {
                console.log(response);
                if (response.data.status === 0) {
                    setMsgcolor('red');
                }
                if (response.data.status === 1) {
                    setUserFormData(response.data.newvalues);
                }
                setMessage(response.data.message);
            });
        } catch (err) {
            setMsgcolor('red');
            console.log(err);
            setMessage('Something went wrong, Please try again later.');
        }
    }

    useEffect(() => {
        const FetchProfile = async () => {
            try {
                const res = await fetch(`${BASEAPIURL}/admin/instructure/myprofile`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Basic ${localStorage.getItem('jwttoken')}`,
                    },
                    body: JSON.stringify({
                        token: localStorage.getItem('token'),
                    }),
                });
                const resJson = await res.json();
                if (res.status === 200) {
                    if (resJson.data !== null) {
                        if (Object.keys(resJson.data).length > 0) {
                            setTID(resJson.data.tid);
                        } else {
                            // history.push('instructor-login');
                        }
                    } else {
                        // history.push('instructor-login');
                    }
                } else {
                    console.log(resJson.message);
                }
            } catch (err) {
                console.log(err);
            }
            try {
                const res = await fetch(`${BASEAPIURL}/admin/instructure/get_payout_settings`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Basic ${localStorage.getItem('jwttoken')}`,
                    },
                    body: JSON.stringify({
                        tid: TID,
                    }),
                });
                const resJson = await res.json();
                if (res.status === 200) {
                    if (resJson.data !== null) {
                        if (Object.keys(resJson.data).length > 0) {
                            console.log(resJson.data);
                            setUserFormData(resJson.data);
                            setGPayPhoneNumber(resJson.data.gpay);
                            setPaytmPhoneNumber(resJson.data.paytm);
                        }
                    }
                } else {
                    console.log(resJson.message);
                }
            } catch (err) {
                console.log(err);
            }
        };
        FetchProfile();
    }, [Token, TID, setTID]);

    return (
        <>
            <div className="pb-3" forpage={forpage}>
                <div className="row">
                    <div className="col-md-12">
                        <h4
                            className="mb-0 p-3"
                            style={{
                                background: '#152569 0% 0% no-repeat padding-box',
                                borderRadius: '15px 15px 0px 0px',
                                color: '#f5f5f5',
                            }}
                        >
                            Payout Setting
                        </h4>
                        <div
                            className="ry_instr_shadow p-3"
                            style={{ borderRadius: '0px 0px 15px 15px' }}
                        >
                            <form
                                id="Inst-Regi-Form"
                                encType="multipart/form-data"
                                className="p-1"
                                style={{ display: 'block' }}
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    updatePayoutSettings();
                                }}
                            >
                                <div className="instr_payput_type">
                                    Set Payout
                                    <button
                                        className={`btn btn-outline-secondary ml-2 ${
                                            UserFormData.preferred === 'Bank' ||
                                            UserFormData.preferred === ''
                                                ? 'active'
                                                : ''
                                        }`}
                                        type="button"
                                        onClick={() => {
                                            UpdateUserFormData('preferred', 'Bank');
                                        }}
                                    >
                                        Bank Account
                                    </button>
                                    <button
                                        className={`btn btn-outline-secondary ml-2 ${
                                            UserFormData.preferred === 'Paypal' ? 'active' : ''
                                        }`}
                                        type="button"
                                        onClick={() => {
                                            UpdateUserFormData('preferred', 'Paypal');
                                        }}
                                    >
                                        Paypal
                                    </button>
                                    <button
                                        className={`btn btn-outline-secondary ml-2 ${
                                            UserFormData.preferred === 'Stripe' ? 'active' : ''
                                        }`}
                                        type="button"
                                        onClick={() => {
                                            UpdateUserFormData('preferred', 'Stripe');
                                        }}
                                    >
                                        Stripe
                                    </button>
                                    <button
                                        className={`btn btn-outline-secondary ml-2 ${
                                            UserFormData.preferred === 'Paystack' ? 'active' : ''
                                        }`}
                                        type="button"
                                        onClick={() => {
                                            UpdateUserFormData('preferred', 'Paystack');
                                        }}
                                    >
                                        Paystack
                                    </button>
                                    <button
                                        className={`btn btn-outline-secondary ml-2 ${
                                            UserFormData.preferred === 'Razorpay' ? 'active' : ''
                                        }`}
                                        type="button"
                                        onClick={() => {
                                            UpdateUserFormData('preferred', 'Razorpay');
                                        }}
                                    >
                                        Razorpay
                                    </button>
                                    <button
                                        className={`btn btn-outline-secondary ml-2 ${
                                            UserFormData.preferred === 'Gpay' ? 'active' : ''
                                        }`}
                                        type="button"
                                        onClick={() => {
                                            UpdateUserFormData('preferred', 'Gpay');
                                        }}
                                    >
                                        Gpay
                                    </button>
                                    <button
                                        className={`btn btn-outline-secondary ml-2 ${
                                            UserFormData.preferred === 'Paytm' ? 'active' : ''
                                        }`}
                                        type="button"
                                        onClick={() => {
                                            UpdateUserFormData('preferred', 'Paytm');
                                        }}
                                    >
                                        Paytm
                                    </button>
                                </div>

                                <div
                                    className="ry_instr_shadow p-4 mt-4"
                                    style={{ background: '#f4f4f4' }}
                                >
                                    <div
                                        className="bankdiv"
                                        style={{
                                            display:
                                                UserFormData.preferred === 'Bank' ? '' : 'none',
                                        }}
                                    >
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group mb-3">
                                                    <label className="ry_label">Bank Name</label>
                                                    <input
                                                        type="text"
                                                        name="bank_name"
                                                        className="form-control"
                                                        placeholder="Enter Bank Name"
                                                        value={UserFormData.bank_name}
                                                        onChange={(e) => {
                                                            UpdateUserFormData(
                                                                'bank_name',
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group mb-3">
                                                    <label className="ry_label">Branch Name</label>
                                                    <input
                                                        type="text"
                                                        name="bank_branch"
                                                        className="form-control"
                                                        placeholder="Enter Branch Name"
                                                        value={UserFormData.bank_branch}
                                                        onChange={(e) => {
                                                            UpdateUserFormData(
                                                                'bank_branch',
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group mb-3">
                                                    <label className="ry_label">
                                                        Account Number
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="bank_acc_no"
                                                        className="form-control"
                                                        placeholder="Enter Account Number"
                                                        value={UserFormData.bank_acc_no}
                                                        onChange={(e) => {
                                                            UpdateUserFormData(
                                                                'bank_acc_no',
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group mb-3">
                                                    <label className="ry_label">
                                                        Account Holder
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="bank_acc_holder"
                                                        className="form-control"
                                                        placeholder="Enter Account Holder"
                                                        value={UserFormData.bank_acc_holder}
                                                        onChange={(e) => {
                                                            UpdateUserFormData(
                                                                'bank_acc_holder',
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group mb-1">
                                                    <label className="ry_label">
                                                        IFSC Code / IBAAN No.
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="bank_acc_icsc"
                                                        className="form-control"
                                                        placeholder="Enter IFSC Code / IBAAN No."
                                                        value={UserFormData.bank_acc_icsc}
                                                        onChange={(e) => {
                                                            UpdateUserFormData(
                                                                'bank_acc_icsc',
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="ry_label"></label>
                                                <button
                                                    type="submit"
                                                    className="btn btn-docsta-instructor"
                                                >
                                                    Set as Preferred Payout
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="form-group mb-1"
                                        style={{
                                            display:
                                                UserFormData.preferred === 'Gpay' ? '' : 'none',
                                        }}
                                    >
                                        <label className="ry_label">
                                            Google Pay Payout Mobile Number
                                        </label>
                                        <PhoneInput
                                            international
                                            className="form-control"
                                            name="gpay"
                                            value={GPayPhoneNumber}
                                            onChange={setGPayPhoneNumber}
                                            onKeyUpCapture={(e) => {
                                                UpdateUserFormData('gpay', e.target.value);
                                            }}
                                            defaultCountry="IN"
                                            style={{ position: 'relative' }}
                                        />
                                    </div>

                                    <div
                                        className="form-group mb-1"
                                        style={{
                                            display:
                                                UserFormData.preferred === 'Paytm' ? '' : 'none',
                                        }}
                                    >
                                        <label className="ry_label">
                                            Paytm Payout Mobile number
                                        </label>
                                        <PhoneInput
                                            international
                                            className="form-control"
                                            name="paytm"
                                            value={PaytmPhoneNumber}
                                            onChange={setPaytmPhoneNumber}
                                            onKeyUpCapture={(e) => {
                                                UpdateUserFormData('paytm', e.target.value);
                                            }}
                                            defaultCountry="IN"
                                            style={{ position: 'relative' }}
                                        />
                                    </div>

                                    <div
                                        className="form-group mb-1"
                                        style={{
                                            display:
                                                UserFormData.preferred === 'Razorpay' ? '' : 'none',
                                        }}
                                    >
                                        <label className="ry_label">Razorpay Payout email</label>
                                        <input
                                            type="email"
                                            name="rozorpay"
                                            className="form-control"
                                            placeholder="Enter Razorpay Payout email"
                                            value={UserFormData.rozorpay}
                                            onChange={(e) => {
                                                UpdateUserFormData('rozorpay', e.target.value);
                                            }}
                                        />
                                    </div>

                                    <div
                                        className="form-group mb-1"
                                        style={{
                                            display:
                                                UserFormData.preferred === 'Paystack' ? '' : 'none',
                                        }}
                                    >
                                        <label className="ry_label">Paystack Payout email</label>
                                        <input
                                            type="email"
                                            name="paystack"
                                            className="form-control"
                                            placeholder="Enter Paystack Payout email"
                                            value={UserFormData.paystack}
                                            onChange={(e) => {
                                                UpdateUserFormData('paystack', e.target.value);
                                            }}
                                        />
                                    </div>

                                    <div
                                        className="form-group mb-1"
                                        style={{
                                            display:
                                                UserFormData.preferred === 'Stripe' ? '' : 'none',
                                        }}
                                    >
                                        <label className="ry_label">Stripe Payout email</label>
                                        <input
                                            type="email"
                                            name="stripe"
                                            className="form-control"
                                            placeholder="Enter Stripe Payout email"
                                            value={UserFormData.stripe}
                                            onChange={(e) => {
                                                UpdateUserFormData('stripe', e.target.value);
                                            }}
                                        />
                                    </div>

                                    <div
                                        className="form-group mb-1"
                                        style={{
                                            display:
                                                UserFormData.preferred === 'Paypal' ? '' : 'none',
                                        }}
                                    >
                                        <label className="ry_label">Paypal Payout email</label>
                                        <input
                                            type="email"
                                            name="paypal"
                                            className="form-control"
                                            placeholder="Enter Paypal Payout email"
                                            value={UserFormData.paypal}
                                            onChange={(e) => {
                                                UpdateUserFormData('paypal', e.target.value);
                                            }}
                                        />
                                    </div>

                                    <div className="message mt-3" style={{ color: Msgcolor }}>
                                        {APIMessage ? <span>{APIMessage}</span> : ''}
                                    </div>
                                    <div
                                        className="row"
                                        style={{
                                            display:
                                                UserFormData.preferred === 'Bank' ? 'none' : '',
                                        }}
                                    >
                                        <div className="col-md-6"></div>
                                        <div className="col-md-6">
                                            <button
                                                type="submit"
                                                className="btn btn-docsta-instructor"
                                            >
                                                Set as Preferred Payout
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PayoutSetting;
