import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    name: '',
    mobile: '',
    email: '',
    totalCourse: '',
    totalQb: '',
    totalLearner: '',
    totalFollower: '',
    totalReviews: '',
    totalBlogs: '',
    ChartData: [["Course", "Amount"]]
}

export const instructorData = createSlice({
    name: 'instructorData',
    initialState,
    reducers: {
        UpdateAccountData: (state, newData) => {
            switch (newData.payload.forType) {
                case 'business':
                    Object.keys(newData.payload).forEach((key) => {
                        state.business[key] = newData.payload[key]
                    })
                    break
                default:
                    Object.keys(newData.payload).forEach((key) => {
                        state[key] = newData.payload[key]
                    })
                    break
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const { UpdateAccountData } = instructorData.actions

export default instructorData.reducer
