import React, { useState, useEffect, Suspense } from 'react';
import Parser from 'html-react-parser';
import { Link, useParams } from 'react-router-dom';
import { IMGBASEAPIURL, BASEAPIURL, UPLOADNODEURL, BASEURL } from '../../../config';
import useToggle from '../../../Hooks/useToggle';
import BackToTop from '../../BackToTop';
import Drawer from '../../Mobile/DrawerInstitute';
import Header from '../../Helper/HeaderInstitute';
import Footer from '../../Helper/FooterInstitute';
import Sharethis from '../../Helper/Sharethis';
import './blog.css';

const HighlyPopular = React.lazy(() => import('../HighlyPopularCourses'));

function showdatetimein(datetime) {
    const tmp = new Date(datetime);
    return `${tmp.getDate()}/${tmp.getMonth()}/${tmp.getUTCFullYear()}`;
}

function BlogSingle() {
    const [ShareThisURL, setShareThisURL] = useState(BASEURL);
    const [drawer, drawerAction] = useToggle(false);
    const [Blogs, setBlogs] = useState({ description: '' });
    const { BlogID } = useParams();
    // const [BlogsCati, setBlogsCati] = useState([]);
    const [FeaturedBlogs, setFeaturedBlogs] = useState([]);
    const FetchTopBlogs = async (forHere, SortByHere, Limited, keywordHere) => {
        // Keyword SortBy
        try {
            const res = await fetch(`${BASEAPIURL}/blog/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    keyword: keywordHere,
                    sortby: SortByHere,
                    PageNumber: 1,
                    category: 0,
                    recordsPerPage: Limited,
                    SortStatus: 1,
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                if (forHere === 'featured') {
                    setFeaturedBlogs(resJson.data);
                }
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
        return false;
    };
    useEffect(() => {
        FetchTopBlogs('featured', 'featured', 7, '');
        const FetchBlog = async () => {
            try {
                const res = await fetch(`${BASEAPIURL}/blog/GetBlogBySlug`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        token: BlogID,
                    }),
                });
                const resJson = await res.json();
                if (res.status === 200 && resJson.data) {
                    setBlogs(resJson.data);
                    // setBlogsCati(resJson.category);
                    setShareThisURL(`${BASEURL}/blog/${resJson.slug}`);
                    // setTotalRecords(resJson.count);
                } else {
                    console.log(resJson.message);
                }
            } catch (err) {
                console.log(err);
            }
        };
        FetchBlog();
    }, [BlogID]);

    console.log(BlogID);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />

            {/* <div className="appie-page-title-area d-none">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-page-title-item">
                                <h3 className="title">Blog</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <section className="blogpage-section" style={{ paddingTop: '30px' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div
                                className="single-post-area"
                                style={{
                                    border: '1px solid #f5f5f5',
                                    padding: '20px',
                                }}
                            >
                                <h1 className="article-title">{Blogs.title}</h1>
                                <div className="post-thumb">
                                    <img src={`${UPLOADNODEURL}${Blogs.image}`} alt="" />
                                </div>
                                <div>
                                    {Parser(
                                        Blogs.description.replaceAll(
                                            '<table>',
                                            '<table class="table tableRY table-responsive table-hover">'
                                        )
                                    )}
                                </div>
                                {/* <div>{Parser(Blogs.description)}</div> */}
                                <div className="post-tags d-none">
                                    <h5>Tags:</h5>
                                    {/* <a href="#">Bisy LMS</a>
                                    <a href="#">Design</a>
                                    <a href="#">General</a> */}
                                </div>
                                <div className="post-share">
                                    {/* <h5>Share:</h5> */}
                                    <Sharethis ShareThisURL={ShareThisURL} />
                                </div>
                                <div className="post-admin d-none">
                                    <img
                                        src={`${IMGBASEAPIURL}institute/blog/author-img1.png`}
                                        alt=""
                                    />
                                    <a href="/#">Justin Case</a>
                                    <span>About Author</span>
                                    <p>
                                        Me old mucker bits and bobs you mug tickety-boo a load of
                                        old tosh bender mufty bloke old daft.
                                    </p>
                                </div>
                            </div>
                            <div className="comment-area  d-none">
                                <h3>Comment (3)</h3>
                                <ol className="comment-list">
                                    <li>
                                        <div className="single-comment">
                                            <img
                                                src={`${IMGBASEAPIURL}institute/blog/c1.png`}
                                                alt=""
                                            />
                                            <h5>
                                                <a href="/#">Jason Response</a>
                                            </h5>
                                            <span>April 03, 2019</span>
                                            <div className="comment">
                                                <p>
                                                    He legged it bevvy mush owt to do with me such
                                                    fibber fa about cup of tea sloshed morish butty
                                                    bubble. Butty gormless lavatory twit bits and
                                                    bobs pardon you daft ummm I'm telling.
                                                </p>
                                            </div>
                                            <a className="comment-reply-link" href="/">
                                                <i className="fal fa-reply"></i>Reply
                                            </a>
                                            <div className="c-border"></div>
                                        </div>
                                        <ul className="children">
                                            <li>
                                                <div className="single-comment">
                                                    <img
                                                        src={`${IMGBASEAPIURL}institute/blog/c2.png`}
                                                        alt=""
                                                    />
                                                    <h5>
                                                        <a href="/#">Sue Shei</a>
                                                    </h5>
                                                    <span>April 14, 2019</span>
                                                    <div className="comment">
                                                        <p>
                                                            Super chancer cheeky bloke haggle give
                                                            us a bell well mufty chinwag say bite.
                                                            lavatory twit bits and bobs pardon you
                                                            daft ummm I'm telling. Blatant matie boy
                                                            say bugger all mate butty gormless.
                                                        </p>
                                                    </div>
                                                    <a className="comment-reply-link" href="/#">
                                                        <i className="fal fa-reply"></i>Reply
                                                    </a>
                                                    <div className="c-border"></div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                            </div>
                            <div className="comment-form-area  d-none">
                                <h3>Post a Comment</h3>
                                <p>
                                    Your email address will not be published. Required fields are
                                    marked
                                </p>
                                <div className="comment-form">
                                    <form className="row" action="#" method="post">
                                        <div className="col-md-6">
                                            <input type="text" name="name" placeholder="Name" />
                                        </div>
                                        <div className="col-md-6">
                                            <input type="email" name="email" placeholder="Email" />
                                        </div>
                                        <div className="col-md-12">
                                            <input type="url" name="url" placeholder="Website" />
                                        </div>
                                        <div className="col-md-12">
                                            <div className="save-comment">
                                                <input id="history-name" type="checkbox" />
                                                <label htmlFor="history-name">
                                                    Save my name, email, and website in this browser
                                                    for the next time I comment.
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <textarea placeholder="Coment"></textarea>
                                        </div>
                                        <div className="col-md-12">
                                            <button type="submit">Post Comment</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="blog-sidebar">
                                <aside className="widget widget-search">
                                    <div className="search-form">
                                        <input
                                            type="search"
                                            name="s"
                                            placeholder="Search..."
                                            onChange={(e) => {
                                                FetchTopBlogs(
                                                    'featured',
                                                    'featured',
                                                    5,
                                                    e.target.value
                                                );
                                            }}
                                        />
                                        <button type="submit">
                                            <i className="fal fa-search"></i>
                                        </button>
                                    </div>
                                </aside>
                                <aside className="widget widget-trend-post">
                                    <h3 className="widget-title">Featured</h3>
                                    {FeaturedBlogs.length === 0 ? (
                                        <div className="alert alert-info">
                                            No Blog Found with Your Search.
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {FeaturedBlogs.map((sitem) => (
                                        <Link to={`/blog/${sitem.slug}`} className="popular-post">
                                            <img src={`${UPLOADNODEURL}${sitem.image}`} alt="" />
                                            <div className="textBox">
                                                <h5>{sitem.title}</h5>
                                                <span>{showdatetimein(sitem.createdAt)}</span>
                                            </div>
                                        </Link>
                                    ))}
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Suspense fallback={<div>Loading...</div>}>
                <HighlyPopular />
            </Suspense>

            <Footer />
            <BackToTop />
        </>
    );
}

export default BlogSingle;
