import React from 'react';
import { IMGBASEAPIURL } from '../../config';

function InstructorBox() {
    return (
        <>
            <section
                className="appie-instructor-area"
                style={{
                    backgroundImage: `url(${IMGBASEAPIURL}institute/bg/instructor-bg.jpg)`,
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="appie-instructor-content">
                                <h3>Be Our Instructor</h3>
                                <h4>
                                    If teaching is your passion, empowering you is our mission.
                                    Teach the world at DocSta Learn. Become our faculty today.
                                </h4>
                                <a className="main-btn" href="/">
                                    Start Teaching Today
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default InstructorBox;
