// 1. What Language Is Your Course In?
export const RegFrmLangInstr = [{ 1: 'English' }, { 2: 'Malayalam' }];

// 5. What Set Of Values Are You Hoping To Add To Your Ideal Student’s Life?
export const RegFrmValues2StdInstr = [
    { 1: 'To teach people' },
    { 2: 'To motivate people' },
    { 3: 'To teach a skill' },
    { 4: 'Other' },
];

// 2. Which Form Of Teaching Have You Done Before?
export const RegFrmTeachDoneInstr = [
    { 1: 'Online' },
    { 2: 'Offline' },
    { 3: 'Online + Offline' },
    { 4: 'Other' },
];

// 3. Where Do You Rate Yourself Regarding Your Video-Making Skills?
export const RegFrmRateVidMkInstr = [
    { 1: 'I am a Beginner' },
    { 2: 'I have some knowledge' },
    { 3: 'I am experienced' },
    { 4: 'I have videos ready to upload' },
];

// 9. What Would You Like Our Help With?
export const RegFrmDocstaHelpInstr = [
    { 1: 'To develop a course completely' },
    { 2: 'To shoot and edit videos Only' },
    { 3: 'to publish the course' },
    { 4: 'Other' },
];

// 4. Do You Have An Existing Audience Or Students To Share Your Course With?
export const RegFrmExiAudiInstr = [
    { 1: 'Not at the moment' },
    { 2: 'I have a small following' },
    { 3: 'I have a sizeable following' },
    { 4: 'I have some other plans' },
];

// 10. How Much Of Your Course Is Currently Ready?
export const RegFrmCrsComInstr = [{ 1: '25%' }, { 2: '50%' }, { 4: '75%' }, { 5: '100%' }];

// location for the institues
export const Location4Insti = [{ 1: 'Docsta Institute' }, { 2: 'External Venue' }];
export const Courses4Insti = [
    { 1: 'Programming & Development' },
    { 2: 'MS office' },
    { 3: 'Medical & First Aid' },
    { 4: 'Data Science & ERP' },
    { 5: 'Cybersecurity & Ethical Hacking' },
    { 6: 'Project Management' },
    { 7: 'AutoCAD & Engineering' },
    { 8: 'HSE' },
    { 9: 'Banking & Finance' },
    { 10: 'Adobe Suite & Designing' },
    { 11: 'IT & Networking' },
    { 12: 'Language Training' },
    { 13: 'AI & Machine Learning' },
    { 14: 'Licensing & Test Preparation' },
    { 15: 'Business Soft Skills' },
    { 16: 'Other' },
];
