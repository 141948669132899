import React, { useEffect, useState } from 'react';
import Parser from 'html-react-parser';
import { Link } from 'react-router-dom';
// import { Country, State, City } from 'country-state-city';
import { BASEAPIURL, UPLOADNODEURL } from '../../../../config';

function ProfileSetting({ forpage }) {
    // const [drawer, drawerAction] = useToggle(false);
    // const [lfVisibility, setlfVisibility] = useState('block');
    const description = `
    If teaching is your passion, we are your solution. If you have the knowledge that will benefit others, then we are here to enrich your potential and support you to create world-class courses and make your world wide impact.
    <br /><br />
    Our platform is designed to guide you from step 1 upto creation of a perfect, valuable program that will leave your mark on each of your students. Our expertise is not just in training you to create the best courses, but also in amplifying your reach exponentially so that your knowledge reaches the global population.
    <br /><br />
    Over time, we have touched the lives of more than a million students with multiple life enhancing courses and wants each of our instructors to do the same and reap the abundant rewards with maximum protection of their intellectual rights.`;

    // const token = localStorage.getItem('token');
    // const [AllCountry] = useState(Country.getAllCountries());
    // const [AllState, setAllState] = useState(State.getStatesOfCountry('IN'));
    // const [AllCity, setAllCity] = useState(City.getCitiesOfState('IN', 'AN'));
    const [AllCountry] = useState([]); // Country.getAllCountries()
    const [AllState] = useState([]);
    const [AllCity] = useState([]);
    // const [Token] = useState(token);
    const [Profile, setProfile] = useState({
        email: '',
        country: '',
        state: '',
        city: '',
        linkedin: '',
        youtube: '',
        photo: '2022/11/22/1671716288627_9537.png',
    });

    useEffect(() => {
        const FetchProfile = async () => {
            try {
                const res = await fetch(`${BASEAPIURL}/admin/instructure/myprofile`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Basic ${localStorage.getItem('jwttoken')}`,
                    },
                    body: JSON.stringify({
                        token: localStorage.getItem('token'),
                    }),
                });
                const resJson = await res.json();
                if (res.status === 200) {
                    setProfile(resJson.data);
                    // setAllState(State.getStatesOfCountry(resJson.data.country));
                    // setAllCity(City.getCitiesOfState(resJson.data.country, resJson.data.state));
                } else {
                    console.log(resJson.message);
                }
            } catch (err) {
                console.log(err);
            }
        };
        FetchProfile();
    }, []);

    return (
        <>
            <div className="pb-3" forpage={forpage}>
                <div className="row">
                    <div className="col-md-7">
                        <div className="ry_instr_shadow p-3">
                            <img
                                src={`${UPLOADNODEURL}${Profile.photo}`}
                                alt={Profile.name}
                                className="instr_profile_pics"
                            />
                            <h6 className="">{Profile.name}</h6>
                            <b>Designation: </b>
                            {Profile.subTitle}
                            {AllCity.map((singi) => (
                                <span
                                    key={singi.name}
                                    style={{
                                        display: Profile.city === singi.name ? '' : 'none',
                                    }}
                                >
                                    {singi.name}
                                </span>
                            ))}
                            {AllState.map((singi) => (
                                <span
                                    key={singi.isoCode}
                                    style={{
                                        display: Profile.state === singi.isoCode ? 'none' : 'none',
                                    }}
                                >
                                    {singi.name}
                                </span>
                            ))}
                            {AllCountry.map((singi) => (
                                <span
                                    key={singi.isoCode}
                                    style={{
                                        display: Profile.country === singi.isoCode ? '' : 'none',
                                    }}
                                >
                                    {singi.name}
                                </span>
                            ))}
                            <br />
                            <b>Language: </b>
                            {Profile.language}
                            <div style={{ clear: 'both' }}></div>
                            <hr />
                            <b>Profile Description: </b>
                            <div>{Profile.description && Profile.description !== '' ? Parser(Profile.description) : ''}</div>
                            <hr />
                            <b>LinkedIn Profile: </b>
                            <br />
                            <a
                                href={Profile.linkedin}
                                rel="noopener noreferrer"
                                target="_BLANK"
                                style={{ color: '#0a66c2' }}
                            >
                                {Profile.linkedin}
                            </a>
                            <br />
                            <b>Youtube Profile: </b>
                            <br />
                            <a
                                href={Profile.youtube}
                                rel="noopener noreferrer"
                                target="_BLANK"
                                style={{ color: '#9B0101' }}
                            >
                                {Profile.youtube}
                            </a>
                            <hr />
                            <div className="row">
                                <div className="col-md-4">Phone Number</div>
                                <div className="col-md-8">
                                    <a href={`tel:${Profile.mobile}`}>{Profile.mobile}</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">Email</div>
                                <div className="col-md-8">
                                    <a href={`mailto:${Profile.email}`}>{Profile.email}</a>
                                </div>
                            </div>
                            <div className="row d-none">
                                <div className="col-md-4">Email</div>
                                <div className="col-md-8">
                                    <a href={`mailto:${Profile.email}`}>{Profile.email}</a>
                                </div>
                            </div>
                            <div className="text-center mt-2">
                                <Link
                                    to="/instructor/page/updateprofilesetting"
                                    className="btn btn-secondary p-1 pl-4 pr-4 mt-4"
                                >
                                    Edit
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <h4 className="mb-3">Dream to leave a legacy in this World..</h4>
                        <p style={{ textAlign: 'justify' }}>{Parser(description)}</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProfileSetting;
