import React from 'react';
import { IMGBASEAPIURL } from '../../../config';

function CareerCounseling() {
    return (
        <>
            <section className="careerCounselingBlock">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3>We equip you with..</h3>
                        </div>
                        <div className="col-lg-5">
                            <div className="appie-features-content">
                                <div className="imgBox">
                                    <img
                                        src={`${IMGBASEAPIURL}institute/career-counseling/img-1.jpg`}
                                        alt=""
                                    />
                                </div>
                                <div className="contentBox">
                                    <h6>Aptitude Capture</h6>
                                    <p>
                                        All the information essential in making the best informed
                                        choices to pick a career in line with your talents, passion
                                        and aptitude
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="blockTwo">
                                <div className="imgBox">
                                    <img
                                        src={`${IMGBASEAPIURL}institute/career-counseling/img-2.jpg`}
                                        alt=""
                                    />
                                </div>
                                <div className="contentBox">
                                    <h6>Individualized Counselling</h6>
                                    <p>
                                        Taking you by hand to guide you through the whole process of
                                        defining your ideal career and how to reach there
                                    </p>
                                </div>
                            </div>
                            <div className="blockTwo">
                                <div className="imgBox">
                                    <img
                                        src={`${IMGBASEAPIURL}institute/career-counseling/img-3.jpg`}
                                        alt=""
                                    />
                                </div>
                                <div className="contentBox">
                                    <h6>100% Satisfaction</h6>
                                    <p>
                                        Power to craft your perfect career where you are guaranteed
                                        maximum success and satisfaction
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="distinctiveBlock">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3>We will pick your distinctive..</h3>
                        </div>
                        <div className="col-lg-12">
                            <div className="imgBox">
                                <img
                                    src={`${IMGBASEAPIURL}institute/career-counseling/distinctive-img1.svg`}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="possibleBlock">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3>How do we make this possible?</h3>
                        </div>
                        <div className="col-lg-4">
                            <div className="innerBox">
                                <img
                                    src={`${IMGBASEAPIURL}institute/career-counseling/possible-img1.png`}
                                    alt=""
                                />
                                <h6>Discovering You</h6>
                                <p>
                                    Recognizing your nexus of unique talents using world-renowned
                                    assessment techniques like the Myers-Briggs type indicator, etc.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="innerBox">
                                <img
                                    src={`${IMGBASEAPIURL}institute/career-counseling/possible-img2.png`}
                                    alt=""
                                />
                                <h6>One to One Focus Sessions</h6>
                                <p>
                                    Facilitating hourly virtual consultations for 3 days with
                                    certified consultants to sharpen your focus and get a clear cut
                                    path to your ultimate career.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="innerBox">
                                <img
                                    src={`${IMGBASEAPIURL}institute/career-counseling/possible-img3.png`}
                                    alt=""
                                />
                                <h6>Target Spotting</h6>
                                <p>
                                    Steering you to the ideal courses that set you up for a flawless
                                    career and save you from the regret of taking a wrong career
                                    decision.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CareerCounseling;
