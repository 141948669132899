import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { BASEAPIURL, UPLOADNODEURL } from '../../config';

function ApprovalsBox({ className }) {
    const [Clients, setClients] = useState([]);
    const sliderRef = useRef();
    const approvals = {
        autoplay: true,
        arrows: true,
        dots: true,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    const FetchClients = async () => {
        try {
            const res = await fetch(`${BASEAPIURL}/admin/accreditations/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    recordsPerPage: 30,
                    SortStatus: 1,
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                setClients(resJson.data);
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        FetchClients();
        // document.getElementById('blog_filter_frm').submit();
    }, []);

    return (
        <>
            <section className={`approvals-area ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Our Accreditations & Affiliations</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="approvals-thumb">
                                <Slider ref={sliderRef} {...approvals}>
                                    {Clients.map((singi) => (
                                        <div key={singi.accreditationid}>
                                            <img
                                                src={`${UPLOADNODEURL}${singi.image}`}
                                                alt={singi.title}
                                                style={{ maxHeight: '150px', lineHeight: '150px' }}
                                            />
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ApprovalsBox;
