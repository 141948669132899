import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InlineShareButtons } from 'sharethis-reactjs';

const Sharethis = ({ ShareThisURL }) => {
    const [ShareThisURL2, setShareThisURL2] = useState(ShareThisURL);
    useEffect(() => {
        setShareThisURL2(ShareThisURL);
    }, [ShareThisURL]);
    return (
        <>
            <div style={{ clear: 'both' }}></div>
            <InlineShareButtons
                config={{
                    alignment: 'center',
                    color: 'social',
                    enabled: true,
                    font_size: 16,
                    labels: 'cta',
                    language: 'en',
                    networks: [
                        'facebook',
                        'linkedin',
                        'whatsapp',
                        // 'copy',
                        'email',
                        'twitter',
                        'sharethis',
                    ],
                    padding: 12,
                    radius: 4,
                    show_total: true,
                    size: 40,
                    url: ShareThisURL2,
                    image: 'https://www.docstainstitute.com/images/logo/logo-institute.png',
                    title: 'Sg',
                    description: 'a',
                }}
            />
            <div style={{ clear: 'both' }}></div>
            <div className="text-center d-none mt-4">
                <a href={ShareThisURL2} target="_BLANK" rel="noopener noreferrer">
                    <small>{ShareThisURL2}</small>
                </a>
            </div>
        </>
    );
};
Sharethis.propTypes = {
    ShareThisURL: PropTypes.string.isRequired,
};
export default Sharethis;
