import React, { useState, useEffect } from 'react';
import Editor from './Editor';

const ShowHtmlEditor = ({ 
    PageContent,
    changeId,
    handleEditModuleList,
    RefreshModuleList,
    ForAttribute,
 }) => {
    const RYEditor = 1;
    const [isEditview, setisEditview] = useState(1);
    function getContent(value) {
        setPageContent(value);
    }
    function setPageContent(value) {
        handleEditModuleList( changeId, ForAttribute, value);
        RefreshModuleList();
    }
    useEffect(() => {
        // setisEditview(PageContent ? 1 : 0);
    }, []);
    return (
        <div ry={RYEditor}>
            {/* #{PageContent.length}# */}
            {/* ##{isEditview}## */}
            <div>
                <Editor
                    getContent={getContent}
                    PageContent={PageContent}
                    isEditview={isEditview}
                    setisEditview={setisEditview}
                    setPageContent={setPageContent}
                />
            </div>
        </div>
    );
};

export default ShowHtmlEditor;
