import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { IMGBASEAPIURL } from '../../config';

function PersonalDevelopBox({ className }) {
    const sliderRef = useRef();
    const settings = {
        autoplay: false,
        arrows: true,
        dots: false,
        slidesToShow: 4,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    return (
        <>
            <section className={`appie-features-area examOriented ${className || ''}`}>
                <div className="container-fluid">
                    <div className="row appie-showcase-slider">
                        <div className="col-lg-12">
                            <h3 className="courseHead">
                                Top Courses in <span>Personality Development</span>
                            </h3>
                            <Slider ref={sliderRef} {...settings}>
                                {/* <slide1 /> */}
                                <div>
                                    <div className="appie-features-content">
                                        <div className="imgBox">
                                            <img
                                                src={`${IMGBASEAPIURL}institute/courses/img9.jpg`}
                                                alt=""
                                            />
                                            <span>
                                                <i className="fa fa-eye" /> 1388
                                            </span>
                                        </div>
                                        <div className="contentBox">
                                            <span>Beginner</span>
                                            <h6>
                                                <a href="/">How To Overcome Depression?</a>
                                            </h6>
                                            <div className="instructorBox">
                                                <img
                                                    src={`${IMGBASEAPIURL}institute/instructor/img1.jpg`}
                                                    alt=""
                                                />
                                                <a href="/">
                                                    <b>Dr. Danish Salim</b>
                                                </a>
                                            </div>
                                            <div className="ratingBox">
                                                <i className="fas fa-star" />
                                                <i className="fas fa-star" />
                                                <i className="fas fa-star" />
                                                <i className="fas fa-star-half-alt" />
                                                <i className="far fa-star" />
                                                <b>3.5/5.0</b>
                                            </div>
                                            <h3 className="rateBox">
                                                $29 <i>$39</i>
                                            </h3>
                                            <div className="tagsBox">
                                                <b>Best Seller</b>
                                                <div className="shareBox">
                                                    <a href="/#" className="mr-10">
                                                        <i className="far fa-heart" />
                                                    </a>
                                                    <a href="/#" className="text-dark">
                                                        <i className="far fa-share-alt" />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="hLine">
                                                <hr />
                                            </div>
                                            <div className="courseDetails">
                                                <div className="durationBox">
                                                    <i className="far fa-clock" />
                                                    <b>5hr 30mins</b>
                                                </div>
                                                <div className="sessionsBox">
                                                    <i className="far fa-play-circle" />
                                                    <b>100 Sessions</b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PersonalDevelopBox;
