import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import logo from '../../assets/images/logo-learn.png';
import {
    // IMGBASEAPIURL,
    // CleanUrlWork,
    UPLOADNODEURL,
    BASEAPIURL,
    LogOutAll,
    // GoogleAnylyticsID,
    // BASEURL,
    // LogOutAll,
    // CoursesMainCategory,
} from '../../config';
import { useSelector } from 'react-redux';

let countryName = localStorage.getItem('country_name');

function Drawer({ drawer, action, type }) {
    const instructorData = useSelector((state) => state.instructorData);
    const [MoreMenu, SetMoreMenu] = useState(false);
    const [UserType, setUserType] = useState(localStorage.getItem('usertype'));
    const [defaultProfilePicture] = useState('defaultProfilePicture.png');
    const [userData, Setuserdata] = useState({ name: '', photo: '' });
    const [MainCatID, SetMainCatID] = useState(0);
    const [CatiData, setCatiData] = useState([]);
    // const [SubCatiData, setSubCatiData] = useState([]);

    useEffect(() => {
        setUserType(localStorage.getItem('usertype'));
        countryName = localStorage.getItem('country_name');
        const temp = localStorage.getItem('userdata');
        // alert(temp);
        try {
            if (temp) {
                const resJson = JSON.parse(temp);
                Setuserdata(resJson);
            }
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
        const interval = setInterval(() => {
            setUserType(localStorage.getItem('usertype'));
            // setUserName(localStorage.getItem('username'));
        }, 5000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const FetchCatiData = async (maincatid, catid) => {
        if (catid === 0) {
            setCatiData([]);
        } else {
            // setSubCatiData([]);
        }
        try {
            const res = await fetch(`${BASEAPIURL}/admin/coursecategory/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    SortStatus: 1,
                    parent: catid,
                    maincatid,
                    featured: 0,
                    recordsPerPage: 20,
                    sortby: 'a2z',
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                if (Object.keys(resJson.data).length > 0) {
                    if (catid === 0) {
                        setCatiData(resJson.data);
                    } else {
                        // setSubCatiData(resJson.data);
                    }
                    // FetchCoursesData(resJson.data[0].catid, 0, 0);
                }
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <div
                onClick={(e) => action(e)}
                className={`off_canvars_overlay ${drawer ? 'active' : ''}`}
            ></div>
            <div className="offcanvas_menu">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className={`offcanvas_menu_wrapper ${drawer ? 'active' : ''}`}>
                                <div className="canvas_close">
                                    <a href="/" onClick={(e) => {
                                        action(e);
                                        e.preventDefault();
                                    }} id="canvas_close_btn">
                                        <i className="far fa-times"></i>
                                    </a>
                                </div>
                                <div className="offcanvas-brand text-left mb-40" style={{ width: '100%' }}>
                                    {/* <img src={logo} alt="" /> */}
                                    {type && type === "instructor" ? (
                                        <>
                                            <h6>
                                                <Link
                                                    style={{
                                                        display: UserType === 'instructor' ? 'none' : 'block',
                                                    }}
                                                    to="/instructor-login"
                                                    className="docsta_color mb-3"
                                                >
                                                    Login as Instructor
                                                </Link>
                                                {/* {UserType} */}
                                                <Link
                                                    style={{
                                                        display: UserType === 'instructor' ? 'none' : 'block',
                                                    }}
                                                    to="/instructor-register"
                                                    className="docsta_color"
                                                >
                                                    Signup as Instructor
                                                </Link>
                                            </h6>
                                        </>
                                    ) : (
                                        <>
                                            <h6>
                                                <Link
                                                    style={{
                                                        display: UserType === 'student' ? 'none' : 'block',
                                                    }}
                                                    to="/user/login"
                                                    className="docsta_color mb-3"
                                                >
                                                    Login
                                                </Link>
                                                {/* {UserType} */}
                                                <Link
                                                    style={{
                                                        display: UserType === 'student' ? 'none' : 'block',
                                                    }}
                                                    to="/user/register"
                                                    className="docsta_color"
                                                >
                                                    Register
                                                </Link>
                                            </h6>
                                        </>
                                    )}
                                    {/* {UserType === 'student'} */}
                                    <div
                                        className="ProfileMenuCont"
                                        style={{
                                            display: ( type !== "instructor" ) &&  UserType && UserType === 'student' ? 'block' : 'none',
                                        }}
                                    >
                                        <div style={{ display: 'flex' }}>
                                            <img
                                                src={`${UPLOADNODEURL}${
                                                    userData.photo &&
                                                    userData.photo !== null &&
                                                    userData.photo !== ''
                                                        ? userData.photo
                                                        : defaultProfilePicture
                                                }`}
                                                alt={userData.name}
                                                style={{
                                                    margin: '0px 7px',
                                                    width: '40px',
                                                    height: '40px',
                                                }}
                                            />
                                            <div
                                                style={{
                                                    // maxWidth: '170px',
                                                    wordBreak: 'break-all',
                                                    fontSize: '12px',
                                                }}
                                            >
                                                <b>{userData.name}</b>
                                                <br />
                                                {userData.email}
                                            </div>
                                        </div>
                                        <div className="ProfileMenu">
                                            <Link to="/user/page/profilesetting">
                                                <h6>{userData.name}</h6>
                                                {userData.email}
                                            </Link>
                                            <hr />
                                            <Link to="/user/dashboard">My Learning</Link>
                                            <Link to="/user/page/MyFavourite">My Wishlist</Link>
                                            <Link to="/user/page/updateprofilesetting">
                                                Account Setting
                                            </Link>
                                            <Link to="/user/page/changePassword">
                                                Change Password
                                            </Link>
                                            <Link
                                                to="/user/dashboard"
                                                onClick={() => {
                                                    LogOutAll();
                                                }}
                                            >
                                                Logout
                                            </Link>
                                        </div>
                                    </div>

                                    <div
                                        className="ProfileMenuCont"
                                        style={{
                                            display: type && type === "instructor" && UserType && UserType === 'instructor' ? 'block' : 'none',
                                        }}
                                    >
                                        <div style={{ display: 'flex' }}>
                                            <img
                                                src={`${UPLOADNODEURL}${
                                                    instructorData.photo &&
                                                    instructorData.photo !== null &&
                                                    instructorData.photo !== ''
                                                        ? instructorData.photo
                                                        : defaultProfilePicture
                                                }`}
                                                alt={instructorData.name}
                                                style={{
                                                    margin: '0px 7px',
                                                    width: '40px',
                                                    height: '40px',
                                                }}
                                            />
                                            <div
                                                style={{
                                                    // maxWidth: '170px',
                                                    wordBreak: 'break-all',
                                                    fontSize: '12px',
                                                }}
                                            >
                                                <b>{localStorage.getItem('username')}</b>
                                                <div>{localStorage.getItem('useremail')}</div>
                                            </div>
                                        </div>
                                        <div className="ProfileMenu">
                                            <Link to="/instructor/page/profilesetting">
                                                <h6>{localStorage.getItem('username')}</h6>
                                                {localStorage.getItem('useremail')}
                                            </Link>
                                            <hr />
                                            <Link to="/instructor/page/mycourses">My Courses</Link>
                                            <Link to="/instructor/page/profilesetting">Profile Overview</Link>
                                            <Link to="/instructor/page/changePassword">Change Password</Link>
                                            <Link
                                                to="/user/dashboard"
                                                onClick={() => {
                                                    LogOutAll();
                                                }}
                                            >
                                                Logout
                                            </Link>
                                        </div>
                                    </div>
                                    {type && type === "instructor" ? (
                                        <b className="d-block mt-3">
                                            <Link to="/">
                                                <span className="docsta_color">
                                                    <b>Go to Student Panel</b>
                                                </span>
                                            </Link>
                                        </b>
                                    ) : (
                                        <b className="d-block mt-3">
                                            <Link to="/instructor">
                                                <span className="docsta_color">
                                                    <b>Go to Instructor Panel</b>
                                                </span>
                                            </Link>
                                        </b>
                                    )}
                                </div>
                                <div id="menu" className="text-left">
                                    <ul className="offcanvas_main_menu">
                                        <li className=''>
                                            <Link
                                                to="/courses/exam-oriented-courses"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (MainCatID !== 'exam') {
                                                        FetchCatiData('exam', 0);
                                                    }
                                                    SetMainCatID(
                                                        MainCatID === 'exam' ? '' : 'exam'
                                                    );
                                                }}
                                            >
                                                Exam Preparation &nbsp;
                                                <i
                                                    className={`fal fa-angle-${
                                                        MainCatID === 'exam' ? 'down' : 'right'
                                                    }`}
                                                />
                                            </Link>
                                            <ul
                                                className="sub-menu"
                                                style={{
                                                    display: MainCatID === 'exam' ? '' : 'none',
                                                }}
                                            >
                                                {CatiData.map((s) => (
                                                    <li key={s.catid}>
                                                        <Link
                                                            to={`/courses/exam-oriented-courses?category=${s.catid}`}
                                                            onClick={() => {
                                                                document
                                                                    .getElementById(
                                                                        'canvas_close_btn'
                                                                    )
                                                                    .click();
                                                            }}
                                                        >
                                                            {s.title}
                                                        </Link>
                                                    </li>
                                                ))}
                                                <li>
                                                    <Link
                                                        to="/courses/exam-oriented-courses"
                                                        style={{ lineHeight: '1rem' }}
                                                    >
                                                        {CatiData.length === 0 ? (
                                                            'Loading'
                                                        ) : (
                                                            <>
                                                                View All&nbsp;
                                                                <i className="fas fa-angle-right viewAllMobLink"></i>
                                                            </>
                                                        )}
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>

                                        <li>
                                            <Link
                                                to="/courses/skills-based-courses"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (MainCatID !== 'skill') {
                                                        FetchCatiData('skill', 0);
                                                    }
                                                    SetMainCatID(
                                                        MainCatID === 'skill' ? '' : 'skill'
                                                    );
                                                }}
                                            >
                                                Skill Learn &nbsp;
                                                <i
                                                    className={`fal fa-angle-${
                                                        MainCatID === 'skill' ? 'down' : 'right'
                                                    }`}
                                                />
                                            </Link>
                                            <ul
                                                className="sub-menu"
                                                style={{
                                                    display: MainCatID === 'skill' ? '' : 'none',
                                                }}
                                            >
                                                {CatiData.map((s) => (
                                                    <li key={s.catid}>
                                                        <Link
                                                            to={`/courses/skills-based-courses?category=${s.catid}`}
                                                            onClick={() => {
                                                                document
                                                                    .getElementById(
                                                                        'canvas_close_btn'
                                                                    )
                                                                    .click();
                                                            }}
                                                        >
                                                            {s.title}
                                                        </Link>
                                                    </li>
                                                ))}
                                                <li>
                                                    <Link to="/courses/skills-based-courses">
                                                        {CatiData.length === 0 ? (
                                                            'Loading'
                                                        ) : (
                                                            <>
                                                                View All&nbsp;
                                                                <i className="fas fa-angle-right viewAllMobLink"></i>
                                                            </>
                                                        )}
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>

                                        <li className=''>
                                            <Link
                                                to="/questionBanks"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (MainCatID !== 'qb') {
                                                        FetchCatiData('qb', 0);
                                                    }
                                                    SetMainCatID(MainCatID === 'qb' ? '' : 'qb');
                                                }}
                                            >
                                                Question Banks &nbsp;
                                                <i
                                                    className={`fal fa-angle-${
                                                        MainCatID === 'qb' ? 'down' : 'right'
                                                    }`}
                                                />
                                            </Link>
                                            <ul
                                                className="sub-menu"
                                                style={{
                                                    display: MainCatID === 'qb' ? '' : 'none',
                                                }}
                                            >
                                                {CatiData.map((s) => (
                                                    <li key={s.catid}>
                                                        <Link
                                                            to={`/courses/exam-oriented-courses?category=${s.catid}`}
                                                            onClick={() => {
                                                                document
                                                                    .getElementById(
                                                                        'canvas_close_btn'
                                                                    )
                                                                    .click();
                                                            }}
                                                        >
                                                            {s.title}
                                                        </Link>
                                                    </li>
                                                ))}
                                                <li>
                                                    <Link to="/questionBanks">
                                                        {CatiData.length === 0 ? (
                                                            <>
                                                                View All&nbsp;
                                                                <i className="fas fa-angle-right viewAllMobLink"></i>
                                                            </>
                                                        ) : (
                                                            <>
                                                                View All&nbsp;
                                                                <i className="fas fa-angle-right viewAllMobLink"></i>
                                                            </>
                                                        )}
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>

                                        <li
                                            style={{
                                                display:
                                                    countryName === 'United Arab Emirates'
                                                        ? ''
                                                        : 'none',
                                            }}
                                        >
                                            <a
                                                href="https://docstainstitute.com"
                                                target="_BLANK"
                                                rel="noopener noreferrer"
                                            >
                                                Docsta Institute
                                            </a>
                                        </li>
                                        <li className='d-none'>
                                            <Link to="/free-audio-tips">Free Audio tips</Link>
                                        </li>
                                        <li>
                                            <Link to="/request-as-team">Team Learning</Link>
                                        </li>
                                        <li className='d-none'>
                                            <Link to="/contact-us">Careers</Link>
                                        </li>
                                        <li>
                                            <Link to="/user/page/MyDeals">Deals</Link>
                                        </li>
                                        <li className="">
                                            <Link
                                                href="/"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    SetMoreMenu(!MoreMenu);
                                                }}
                                            >
                                                More &nbsp;
                                                <i
                                                    className={`fal fa-angle-${
                                                        MoreMenu ? 'down' : 'right'
                                                    }`}
                                                />
                                            </Link>
                                            <ul
                                                className="sub-menu"
                                                style={{ display: MoreMenu ? '' : 'none' }}
                                            >
                                                <li>
                                                    <Link to="/about-us">About Us</Link>
                                                </li>
                                                <li>
                                                    <Link to="/gallery">Gallery</Link>
                                                </li>
                                                <li>
                                                    <Link to="/mentors">Mentors</Link>
                                                </li>
                                                <li>
                                                    <Link to="/contact-us">Contact Us</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <Link
                                                to="/user/dashboard"
                                                onClick={() => {
                                                    LogOutAll();
                                                }}
                                            >
                                                Logout
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="offcanvas-social">
                                    <ul>
                                        <li>
                                            <a
                                                className="you"
                                                href="https://www.youtube.com/@docsta"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="fab fa-youtube" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="fac"
                                                href="https://www.facebook.com/docstalearn"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="lin"
                                                href="https://www.linkedin.com/company/docsta/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="inst"
                                                href="https://www.instagram.com/docstalearn/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="footer-widget-info d-none text-left">
                                    <ul>
                                        <li>
                                            <a href="mailto:support@yourdomian.com">
                                                <i className="fal fa-envelope" />{' '}
                                                support@yourdomian.com
                                            </a>
                                        </li>
                                        <li>
                                            <a href="tel:+91">
                                                <i className="fal fa-phone" /> +(0) 1234 56789
                                            </a>
                                        </li>
                                        <li>
                                            <a href="asd">
                                                <i className="fal fa-map-marker-alt" />
                                                sdfg
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Drawer;
