import React, { useRef, useState, useEffect } from 'react';
import Slider from 'react-slick';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { BASEAPIURL, BASEURL } from '../../config';

const HomeCourseSlider = React.lazy(() => import('../Learn/HomeCourseSlider'));

// for next and pre
let PageNumber = 1;
let catID = 0;
let recordsPerPage = 10;
if (localStorage.getItem('screenwidth') > 767) {
    recordsPerPage = 20;
}
// for next and pre

function HomeSliderSkillsCourse() {
    const [IsLoaded, setIsLoaded] = useState(false);
    const [ShowCatiData, setShowCatiData] = useState([]);
    const [Sliders, setSliders] = useState([]);
    const sliderRef = useRef();
    const [settings, setsettings] = useState({
        infinite: false, // Disable infinite looping
        autoplay: false,
        arrows: true,
        dots: true,
        slidesToShow: 5,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    });
    const FetchCatiData = async () => {
        try {
            const res = await fetch(`${BASEAPIURL}/admin/coursecategory/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    // featured: 1,
                    maincatid: 'skill',
                    recordsPerPage: 50,
                    sortby: 'a2z',
                    SortStatus: 1,
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                // alert(resJson.data.length);
                if (Object.keys(resJson.data).length > 0) {
                    setShowCatiData(resJson.data);
                    setsettings({
                        infinite: false, // Disable infinite looping
                        autoplay: false,
                        arrows: true,
                        dots: false,
                        slidesToShow: Number(resJson.data.length) > 4 ? 5 : resJson.data.length,
                        responsive: [
                            {
                                breakpoint: 768,
                                settings: {
                                    slidesToShow: 3,
                                },
                            },
                            {
                                breakpoint: 480,
                                settings: {
                                    slidesToShow: 2,
                                },
                            },
                        ],
                    });
                }
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const FetchCourseData = async () => {
        try {
            const res = await fetch(`${BASEAPIURL}/course/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    MainCatID: 'skill',
                    category: catID,
                    recordsPerPage,
                    status: 1,
                    PageNumber,
                    sortby: 'popular',
                    rahul: 'Yadav',
                }),
            });
            const resJson = await res.json();
            setIsLoaded(true);
            if (res.status === 200) {
                if (Object.keys(resJson.data).length > 0) {
                    setSliders(resJson.data);
                } else {
                    setSliders([]);
                }
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        FetchCatiData();
        FetchCourseData();
    }, []);

    return (
        <>
            <section className="appie-hero-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-left">
                                <h3 className="appie-title mb-3 mt-0">Highest Rated Courses</h3>
                                <p className="mb-2 d-none">
                                    {Number(localStorage.getItem('screenwidth')) > 550 ? (
                                        <>Magnify your skills with our most demanded courses</>
                                    ) : (
                                        ''
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3 d-block d-sm-none">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <select
                                    className="form-control"
                                    onChangeCapture={(e) => {
                                        catID = e.target.value;
                                        PageNumber = 1;
                                        FetchCourseData();
                                    }}
                                >
                                    <option value="0">Select Category</option>
                                    {ShowCatiData.map((singi) => (
                                        <option value={singi.catid} key={singi.catid}>
                                            {singi.title}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3 d-none d-sm-block FeaturedCategoriesSlider">
                        <div className="col-lg-12">
                            <div className="appie-hero-thumb HomeCourseCategorySlider">
                                <Slider key="category" className="pl-3 pr-3" ref={sliderRef} {...settings}>
                                    {ShowCatiData.map((singi) => (
                                        <div key={singi.catid}>
                                            <span
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (catID === singi.catid) {
                                                        catID = 0;
                                                    } else {
                                                        catID = singi.catid;
                                                    }
                                                    PageNumber = 1;
                                                    FetchCourseData();
                                                }}
                                                className={catID === singi.catid ? 'active' : ''}
                                            >
                                                {singi.title}
                                            </span>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: IsLoaded ? '' : 'none' }}>
                        <HomeCourseSlider
                            Sliders={Sliders}
                            key={Sliders.length}
                            ViewAllUrl={`${BASEURL}courses/skills-based-courses`}
                        />
                    </div>
                </div>
            </section>
        </>
    );
}

export default HomeSliderSkillsCourse;
