import React from 'react';
import useToggle from '../../../Hooks/useToggle';
import BackToTop from '../../BackToTop';
import Drawer from '../../Mobile/DrawerInstitute';
import Header from '../../Helper/HeaderInstitute';
import Footer from '../../Helper/FooterInstitute';
import CounselingTeamList from './CounselingTeamList';
import CareerCounselingBox from '../CareerCounselingBox';
import PopularCoursesBox from '../PopularCoursesBox';

function DocInstitute() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <CounselingTeamList className="pb-50" />
            <CareerCounselingBox />
            <PopularCoursesBox className="pt-70" />
            <Footer />
            <BackToTop />
        </>
    );
}

export default DocInstitute;
