import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import Parser from 'html-react-parser';
// import { , showdatetimein } from '../../../../config';
import { IMGBASEAPIURL, BASEAPIURL } from '../../../config';

function Faq() {
    const [Blogs, setBlogs] = useState([]);

    const FetchFaqs = async () => {
        try {
            const res = await fetch(`${BASEAPIURL}/admin/faqs/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    RY: 'SJAIN',
                    SortStatus: 1,
                    recordsPerPage: 100,
                    sortby: 'a2z',
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                setBlogs(resJson.data);
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        FetchFaqs();
    }, []);

    return (
        <>
            <section
                className="corporate-learning"
                data-overlay="6"
                style={{
                    backgroundImage: `url(${IMGBASEAPIURL}institute/contactus/about-us.jpg)`,
                    padding: '70px 10px',
                    backgroundPosition: 'center',
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="headBlock">
                                <h1 className="mt-5 mb-2 text-white">FAQ's</h1>
                                <p className="mb-5">Unlock Brilliance, Unlock Life</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="faqBlock">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {Blogs.map((singi, i) => (
                                <div
                                    className="contentBox"
                                    style={{
                                        display: 'block',
                                        border: '1px solid #f5f5f5',
                                    }}
                                    key={singi.fagid}
                                >
                                    <div className="row m-0">
                                        <div className="col-md-1 m-0 hideonmobile">
                                            <span>{i + 1}</span>
                                        </div>
                                        <div className="col-md-11 m-0">
                                            <div className="m-0">
                                                <h5>
                                                    <b className="docsta_no hideondesktop">
                                                        {i + 1}
                                                    </b>
                                                    {singi.title}
                                                </h5>
                                                <p>{Parser(singi.description)}</p>
                                            </div>
                                        </div>
                                        <div style={{ clear: 'both' }}></div>
                                    </div>
                                    <div style={{ clear: 'both' }}></div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Faq;
