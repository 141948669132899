import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import 'react-phone-number-input/style.css';
import useToggle from '../../../../Hooks/useToggle';
import BackToTop from '../../../BackToTop';
import Footer from '../../../Helper/FooterInstitute';
import Drawer from '../../../Mobile/DrawerInstitute';
import HeaderInstructor from '../../InstructorIndex_Insti';
import {
  IMGBASEAPIURL,
  BASEAPIURL,
  UPLOADNODEURL,
  BASEURL,
  AllLanguages,
  CleanUrlWork,
} from '../../../../config';
import '../../instructor.css';

import '../../../Helper/rypopup.css';
import LoginPage from '../Login';
import Faqs2Courses from './Faqs2Courses';
import Question2Courses from './Question2Courses';
import Editor from '../../../htmleditor/EditorTinyMCE';
import EditorQBModule from '../../../htmleditor/EditorQBModule';
import '../../../htmleditor/RichTextEditor.css';

const validityIndex = [];
let i = 0;
while (i <= 364) {
  i += 1;
  validityIndex.push(i);
}

const CampaignIndex = [];
let i2 = 0;
while (i2 <= 49) {
  i2 += 1;
  CampaignIndex.push(i2);
}

function InstructorCreateQB() {
  const [Action, SetAction] = useState(1);
  // for loader
  const [IsLoader, setIsLoader] = useState(false);
  // for loader
  // for the Editors
  const [PageContent, setPageContent] = useState('');
  const [RequirementContent, setRequirementContent] = useState('');
  // for the Editors
  const [CurrentSectionIndex, SetCurrentSectionIndex] = useState(1);
  const [AddSessionAction, SetAddSessionAction] = useState(false);
  const [ValidityUOM, SetValidityUOM] = useState('Days');
  // const [file, setFile] = useState();
  // const [SeeMore, SetSeeMore] = useState(0);
  // const [fileName, setFileName] = useState('');
  const [ImageUpload, setImageUpload] = useState(false);
  const [TheCourseID, setTheCourseID] = useState(
    localStorage.getItem('edit_qb_id')
  );
  const [step1, setStep1] = useState('active');
  const [step2, setStep2] = useState('');
  const [step3, setStep3] = useState('');
  const [step4, setStep4] = useState('');
  const [step5, setStep5] = useState('');
  const [Category, setCategory] = useState([]);
  const [SubCategory, setSubCategory] = useState([]);
  const [drawer, drawerAction] = useToggle(false);
  const [PerDetDiv, setPerDetDiv] = useState('block');
  const [AddiDet1Div, setAddiDet1Div] = useState('None');
  const [AddiDet2Div, setAddiDet2Div] = useState('None');
  const [AddiDet3Div, setAddiDet3Div] = useState('None');
  const [ConfirmationDiv, setConfirmationDiv] = useState('None');
  const [Msgcolor, setMsgcolor] = useState('');
  // const [Email, setEmail] = useState();
  const [APIMessage, setMessage] = useState();
  const [classforform, setclassforform] = useState('col-md-12');

  // const form = document.querySelector('#Inst-Regi-Form');
  // const obj = serialize(form, { hash: true, empty: true });
  const [regformdata, setForm] = useState({
    TheCourseID: 0,
    createdID: localStorage.getItem('tid'),
    InstructorDisplay: localStorage.getItem('username'),
    createdBy: localStorage.getItem('usertype'),
    title: '',
    image: '',
    description: '',
    agegroup: '',
    language: '',
    section: 'exam',
    category: 0,
    subcategory: 0,
    requirement: '',
    level: '',
    validity: '',
    validityIn: '',
    features: '',
    metaKeywords: '',
    faqs: [],
    SessionQuestions: [],
    SessionAnswers: [],
  });
  const handleForm = (name, value) => {
    setForm({ ...regformdata, [name]: value });
  };
  function ShowFormDiv(type) {
    setMessage('');
    let AllOkay = 1;

    if (regformdata.image.trim() === '') {
      AllOkay = 0;
      setMsgcolor('red');
      setMessage('Please Upload the Banner Image.');
    }

    if (
      typeof regformdata.category === 'undefined' ||
      regformdata.category === 0 ||
      regformdata.category === '0'
    ) {
      AllOkay = 0;
      setMsgcolor('red');
      setMessage('Please Select the Category.');
    }

    if (regformdata.description.trim() === '') {
      AllOkay = 0;
      setMsgcolor('red');
      setMessage('Please Write a Description.');
    }

    if (
      typeof regformdata.title === 'undefined' ||
      regformdata.title === 0 ||
      regformdata.title === ''
    ) {
      AllOkay = 0;
      setMsgcolor('red');
      setMessage('Please Enter the Course Title.');
    }

    if (type === 'addi2') {
      ModuleList.map((s) => {
        if (s.title.trim() === '') {
          AllOkay = 0;
          setMsgcolor('red');
          setMessage('Please Enter the All Module Name.');
        }
        return '';
      });
    }

    if (AllOkay === 1) {
      setPerDetDiv('none');
      setAddiDet1Div('none');
      setAddiDet2Div('none');
      setAddiDet3Div('none');
      setConfirmationDiv('none');
      setclassforform('col-md-12');
      setStep1(' ');
      setStep2(' ');
      setStep3(' ');
      setStep4(' ');
      setStep5(' ');
      switch (type) {
        case 'addi3':
          setStep4('active');
          setAddiDet3Div('block');
          setclassforform('col-md-12');
          break;
        case 'addi2':
          setStep3('active');
          setAddiDet2Div('block');
          setclassforform('col-md-12');
          break;
        case 'addi1':
          setStep2('active');
          setAddiDet1Div('block');
          break;
        case 'confirm':
          setStep3('active');
          setclassforform('col-md-12 text-center');
          setConfirmationDiv('block');
          break;
        default:
          setStep1('active');
          setPerDetDiv('block');
          break;
      }
    }
  }

  // for file uploade will be here
  async function uploadFile(file, fileName) {
    // e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', fileName);
    const url = `${BASEAPIURL}/admin/instructure/uploadcv`;
    try {
      const res = await axios.post(url, formData);
      console.log(res);
      // setCVfile(res.data.filename);
    } catch (ex) {
      console.log(ex);
    }
  }

  async function uploadBanner(file, fileName) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', fileName);
    const url = `${BASEAPIURL}/admin/instructure/uploadcv`;
    try {
      const res = await axios.post(url, formData);
      handleForm('image', res.data.filename);
    } catch (ex) {
      console.log(ex);
    }
  }

  const saveFile = (e) => {
    setTimeout(() => {
      // const temp = Number(AudioList.length) + 1;
      uploadFile(e.target.files[0], e.target.files[0].name);
    }, 500);
  };
  // for file uploade will be here

  const FetchCategory = async () => {
    // e.preventDefault();
    try {
      const res = await fetch(`${BASEAPIURL}/admin/coursecategory/getAll`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          SortStatus: 1,
          parent: 0,
          sortby: 'a2z',
          recordsPerPage: 1000,
          selectOnly: 1,
        }),
      });
      const resJson = await res.json();
      if (res.status === 200) {
        setCategory(resJson.data);
      } else {
        console.log(resJson.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const FetchSubCategory = async (e, parentid) => {
    // e.preventDefault();
    try {
      const res = await fetch(`${BASEAPIURL}/admin/coursecategory/getAll`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          parent: parentid,
        }),
      });
      const resJson = await res.json();
      if (res.status === 200) {
        setSubCategory(resJson.data);
      } else {
        console.log(resJson.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  async function RegistrerInstructor() {
    try {
      setMsgcolor('green');
      const url = `${BASEAPIURL}/questionBank/create`;
      const config = {
        headers: {
          'content-type': 'application/json', // multipart/form-data
        },
      };
      axios.post(url, regformdata, config).then((response) => {
        ShowFormDiv('confirm');
        setIsLoader(false);
        setMessage(response.data.message);
      });
    } catch (err) {
      setIsLoader(false);
      setMsgcolor('red');
      ShowFormDiv('confirm');
      setMessage('Something went wrong, Please try again later.');
    }
  }
  const PrimaryIndex = '_id';
  async function DeleteModuleList(fileid) {
    // alert(fileid);
    try {
      const url = `${BASEAPIURL}/questionBank/DeleteModuleList`;
      const config = {
        headers: {
          'content-type': 'application/json', // multipart/form-data
        },
      };
      axios.post(url, { fileid }, config).then((response) => {
        console.log(response);
      });
    } catch (err) {
      setMessage('Something went wrong, Please try again later.');
    }
  }

  // for for Multiple ModuleList List
  // const defaultModuleList = [
  //     { index: 1, title: 'Hello 1', open: 1 },
  //     { index: 2, title: 'Hello 2', open: 0 },
  // ];
  const [ModuleList, setModuleList] = useState([]);
  function nextModulesIndex() {
    let maxIndex = 1;
    ModuleList.forEach((item) => {
      if (item.index > maxIndex) {
        maxIndex = item.index;
      }
    });
    return maxIndex;
  }
  const handleAddModuleList = () => {
    const index = nextModulesIndex();
    // ExpensesTypeSelected.push(1);
    setModuleList((temp) => [
      ...temp,
      {
        srNo: index + 1,
        index: index + 1,
        title: '',
        syllabus: '',
        instruction: '',
        open: 0,
      },
    ]);
  };
  const handleEditModuleList = (changeId, ind, val) => {
    ModuleList[changeId][ind] = val;
  };
  const handleRemoveModuleList = (ind) => {
    if (ind !== 1) {
      setModuleList(ModuleList.filter((item) => item.index !== ind));
    }
  };
  const RefreshModuleList = () => {
    const temp = 999999;
    handleAddModuleList(temp);
    handleRemoveModuleList(temp);
    handleForm('ModuleList', ModuleList);
  };
  // for Multiple ModuleList List

  // for for Multiple Question List
  const [SessionQuestions, setSessionQuestions] = useState([]);
  const [SessionAnswers, setSessionAnswers] = useState([]);
  const [Question2CoursesModule, setQuestion2CoursesModule] = useState(1);
  // for Multiple Question List

  // for login check here
  const [isLogin, setisLogin] = useState(false);
  // for login check here
  useEffect(() => {
    // audioIndex = 0;
    setTheCourseID(localStorage.getItem('edit_qb_id'));
    FetchCategory();
    // get Blog Details
    const FetchBlogDetails = async (CourseID) => {
      try {
        const res = await fetch(`${BASEAPIURL}/questionBank/GetCourseDetail`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            TheCourseID: CourseID,
            createdID: localStorage.getItem('tid'),
            createdBy: localStorage.getItem('usertype'),
          }),
        });
        const resJson = await res.json();
        if (res.status === 200 && resJson.data && resJson.data.length > 0) {
          if (resJson.FaqsList && resJson.FaqsList.length > 0) {
            resJson.data[0].faqs = resJson.FaqsList;
          } else {
            resJson.data[0].faqs = [];
          }

          if (resJson.QuestionsList && resJson.QuestionsList.length > 0) {
            resJson.data[0].SessionQuestions = resJson.QuestionsList;
          } else {
            resJson.data[0].SessionQuestions = [];
          }

          if (resJson.AnswersList && resJson.AnswersList.length > 0) {
            resJson.data[0].SessionAnswers = resJson.AnswersList;
          } else {
            resJson.data[0].SessionAnswers = [];
          }

          resJson.data[0].InstructorDisplay = localStorage.getItem('username');
          setForm(resJson.data[0]);
          setPageContent(resJson.data[0].description);
          setRequirementContent(resJson.data[0].requirement);
          FetchCategory();
          FetchSubCategory(null, resJson.data[0].category);
          SetValidityUOM(resJson.data[0].validityIn);

          if (resJson.ModuleList && resJson.ModuleList.length > 0) {
            setModuleList(resJson.ModuleList);
          }

          if (resJson.QuestionsList && resJson.QuestionsList.length > 0) {
            setSessionQuestions(resJson.QuestionsList);
          }

          if (resJson.AnswersList && resJson.AnswersList.length > 0) {
            setSessionAnswers(resJson.AnswersList);
          }
          setQuestion2CoursesModule(0);
        } else {
          setPageContent('');
          setRequirementContent('');
          setForm({
            TheCourseID: 0,
            createdID: localStorage.getItem('tid'),
            createdBy: localStorage.getItem('usertype'),
            InstructorDisplay: localStorage.getItem('username'),
            title: '',
            image: '',
            description: '',
            agegroup: '',
            language: '',
            category: 0,
            subcategory: 0,
            requirement: '',
            level: '',
            validity: '',
            validityIn: '',
            features: '',
            metaKeywords: '',
            faqs: [],
            SessionQuestions: [],
            SessionAnswers: [],
            reviewer: '',
          });
        }
      } catch (err) {
        console.log(err);
      }
    };
    FetchBlogDetails(TheCourseID);
    // get Blog Details

    // for login check here
    const FetchProfile = async () => {
      try {
        const res = await fetch(`${BASEAPIURL}/admin/instructure/myprofile`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic ${localStorage.getItem('jwttoken')}`,
          },
          body: JSON.stringify({
            token: localStorage.getItem('token'),
          }),
        });
        const resJson = await res.json();
        if (res.status === 200) {
          if (resJson.data !== null) {
            if (
              Object.keys(resJson.data).length > 0 &&
              localStorage.getItem('usertype') === 'instructor'
            ) {
              setisLogin(true);
              localStorage.setItem('tid', resJson.data.tid);
            } else {
              setisLogin(false);
            }
          } else {
            setisLogin(false);
          }
        } else {
          setisLogin(false);
          console.log(resJson.message);
        }
      } catch (err) {
        setisLogin(false);
        console.log(err);
      }
    };
    const isAdmin = localStorage.getItem('usertype');
    const isAdmintoken = localStorage.getItem('token');
    if (isAdmin && isAdmin === 'admin' && isAdmintoken) {
      setisLogin(true);
    } else {
      FetchProfile();
    }
    const interval = setInterval(() => {
      if (isAdmin && isAdmin === 'admin' && isAdmintoken) {
        setisLogin(true);
      } else {
        FetchProfile();
      }
    }, 5000);
    return () => clearInterval(interval);
    // for login check here
  }, [TheCourseID]);

  return (
    <>
      <Drawer
        drawer={drawer}
        action={drawerAction.toggle}
      />
      <HeaderInstructor
        drawer={drawer}
        action={drawerAction.toggle}
      />
      {IsLoader ? (
        <div
          className="ShowLoaderPage"
          style={{ background: '#ffffff87' }}
        >
          <img
            src={`${BASEURL}loader.gif`}
            alt=""
          />
        </div>
      ) : (
        ''
      )}
      <div style={{ display: isLogin ? 'none' : '' }}>
        <LoginPage setisLogin={setisLogin} />
      </div>
      <div style={{ display: isLogin ? '' : 'none' }}>
        <div
          className="ryPopUpBack"
          style={{ display: AddSessionAction ? '' : 'none' }}
        ></div>
        <div
          className="ryPopUp"
          style={{ display: AddSessionAction ? '' : 'none' }}
        >
          <anew
            href="#"
            className="ryPopUpAct"
            onClick={() => {
              SetAddSessionAction(false);
            }}
          >
            X
          </anew>
          <form
            style={{ margin: '30px 50px' }}
            onSubmit={(e) => {
              e.preventDefault();
              SetAddSessionAction(false);
            }}
          >
            <h3 className="text-center">Select Module to Add MCQs</h3>
            <div
              className="file_inp_cont text-center"
              style={{
                background: '#f7faff',
                border: '1px dashed #4f6a97',
              }}
            >
              <select
                required
                name="selectModule"
                className="form-control"
                value={CurrentSectionIndex}
                onChangeCapture={(e) => {
                  SetCurrentSectionIndex(e.target.value);
                }}
              >
                <option value="">Select Module</option>
                {ModuleList &&
                  ModuleList.length > 0 &&
                  ModuleList.map((s) => (
                    <option
                      key={s.index}
                      value={s.index}
                    >
                      {s.title}
                    </option>
                  ))}
              </select>
            </div>
            <button
              type="submit"
              className="btn btn-docsta-instructor"
              style={{
                width: '150px',
                margin: '20px auto',
              }}
            >
              Add MCQs
            </button>
          </form>
        </div>

        <div
          className="ryPopUpBack"
          style={{ display: ImageUpload ? '' : 'none' }}
        ></div>
        <div
          className="ryPopUp"
          style={{ display: ImageUpload ? '' : 'none' }}
        >
          <anew
            href="#"
            className="ryPopUpAct"
            onClick={() => {
              setImageUpload(false);
            }}
          >
            X
          </anew>
          <div style={{ margin: '30px 50px' }}>
            <h3 className="text-center">Upload Audio</h3>
            <div
              className="file_inp_cont text-center"
              style={{
                background: '#f7faff',
                border: '1px dashed #4f6a97',
              }}
            >
              <input
                type="file"
                name="cvfile"
                className="ryfile"
                accept="audio/*"
                onChange={(e) => {
                  saveFile(e);
                }}
                style={{
                  width: 'max-content',
                  margin: '0px auto',
                  display: 'block',
                }}
              />
              <p>Please upload a high quality noice free Audio.</p>
            </div>
            <anew
              className="btn btn-docsta-instructor"
              onClick={() => {
                setImageUpload(false);
              }}
              style={{
                width: '150px',
                margin: '20px auto',
              }}
            >
              Upload
            </anew>
          </div>
        </div>

        <div className="container pt-4 pb-4">
          <div
            className="p-3 text-center"
            style={{
              background: `url(${IMGBASEAPIURL}instructor/headerbg.jpg)`,
              backgroundRepeat: 'repeat',
              backgroundSize: '100%',
            }}
          >
            <h2 className="m-3 text-white">Submit A New Course</h2>
          </div>
          <div className="row">
            {/* <div className="col-md-1"></div> */}

            <div className="col-md-12">
              <div className="mt-4 mb-4 ry_instr_shadow">
                <div className="instr_signup_head d-none d-md-block">
                  <div className="row">
                    <div className="col-md-1 text-center"></div>
                    <div className="col-md-2 text-center">
                      <h2 className={`mb-0 instr_signup_head_step ${step1}`}>
                        1
                      </h2>
                      <b className="mb-2 d-block">Course Details</b>
                    </div>
                    <div className="col-md-2 text-center">
                      <h2 className={`mb-0 instr_signup_head_step ${step2}`}>
                        2
                      </h2>
                      <b className="mb-2 d-block">Create Tests</b>
                    </div>
                    <div className="col-md-2 text-center">
                      <h2 className={`mb-0 instr_signup_head_step ${step3}`}>
                        3
                      </h2>
                      <b className="mb-2 d-block">Add MCQs</b>
                    </div>
                    <div className="col-md-2 text-center">
                      <h2 className={`mb-0 instr_signup_head_step ${step4}`}>
                        4
                      </h2>
                      <b className="mb-2 d-block">Additional Details</b>
                    </div>
                    <div className="col-md-2 text-center">
                      <h2 className={`mb-0 instr_signup_head_step ${step5}`}>
                        5
                      </h2>
                      <b className="mb-2 d-block">Submit for Review</b>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className={classforform}>
                    <form
                      id="Inst-Regi-Form"
                      encType="multipart/form-data"
                      className="m-4 p-4"
                      style={{ display: 'block' }}
                      onSubmit={(e) => {
                        e.preventDefault();
                        RegistrerInstructor();
                      }}
                    >
                      <div className="For_Form_title"></div>

                      <div style={{ display: ConfirmationDiv }}>
                        <img
                          src={`${IMGBASEAPIURL}instructor/inst_reg_done.jpg`}
                          alt="Docsta Learn Insinstructor"
                          style={{ maxWidth: '100%' }}
                          className="mt-1 mb-4"
                        />
                        <h3 className="mt-3">
                          Your Course has been submitted successfully.
                        </h3>
                        <div
                          className="message mt-3"
                          style={{ color: Msgcolor }}
                        >
                          {APIMessage ? <span>{APIMessage}</span> : ''}
                        </div>
                        <Link
                          to="instructor/page/myquestionbank"
                          className="btn btn-docsta-instructor d-inline-block m-3"
                          style={{ width: '190px' }}
                        >
                          Back to My Question Bank
                        </Link>
                      </div>

                      <div style={{ display: PerDetDiv }}>
                        <h4 className="mb-2">Course Details</h4>
                        <div className="row m-2">
                          <div className="col-md-6"></div>
                          <div className="col-md-6"></div>
                          <div style={{ clear: 'both' }}></div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="ry_label">Course Title</label>
                              <input
                                value={regformdata.title}
                                type="text"
                                name="title"
                                className="form-control"
                                placeholder="Enter"
                                onChange={(e) => {
                                  handleForm(e.target.name, e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="ry_label">
                                Course Display Title
                                <i
                                  className="fa fa-question"
                                  title="Display on Search Results"
                                />
                              </label>
                              <input
                                value={regformdata.titleShow}
                                type="text"
                                name="titleShow"
                                className="form-control"
                                placeholder="Enter"
                                onChange={(e) => {
                                  handleForm(e.target.name, e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="ry_label">Course URL</label>
                              <input
                                value={regformdata.slug}
                                type="text"
                                maxLength={160}
                                name="slug"
                                className="form-control"
                                placeholder="Enter"
                                onClick={(e) => {
                                  if (e.target.value === '') {
                                    handleForm(
                                      e.target.name,
                                      CleanUrlWork(regformdata.title)
                                    );
                                  }
                                }}
                                onChange={(e) => {
                                  handleForm(e.target.name, e.target.value);
                                }}
                              />
                              <small>
                                {BASEURL}questionBank/{regformdata.slug}
                              </small>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="ry_label">
                                Before they join
                              </label>
                              <Editor
                                PageContent={RequirementContent}
                                setPageContent={setRequirementContent}
                                HandleForm={handleForm}
                                ForAttribute="requirement"
                              />
                              {/* <input
                                                                value={regformdata.requirement}
                                                                type="text"
                                                                name="requirement"
                                                                className="form-control"
                                                                placeholder="Enter"
                                                                onChange={(e) => {
                                                                    handleForm(
                                                                        e.target.name,
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            /> */}
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="ry_label">
                                About this Course
                              </label>
                              <Editor
                                PageContent={PageContent}
                                setPageContent={setPageContent}
                                HandleForm={handleForm}
                                ForAttribute="description"
                              />
                              {/* <input
                                                                value={regformdata.description}
                                                                type="text"
                                                                name="description"
                                                                maxLength={200}
                                                                className="form-control"
                                                                placeholder="Enter"
                                                                onChange={(e) => {
                                                                    handleForm(
                                                                        e.target.name,
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            /> */}
                            </div>
                          </div>

                          <div style={{ clear: 'both' }}></div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="ry_label">Category</label>
                              <select
                                type="text"
                                name="category"
                                className="form-control"
                                value={regformdata.category}
                                onChange={(e) => {
                                  handleForm(e.target.name, e.target.value);
                                }}
                                onBlur={(e) => {
                                  FetchSubCategory(e, e.target.value);
                                }}
                              >
                                <option
                                  key="0"
                                  value="0"
                                >
                                  Select Category
                                </option>
                                {Category.map((singi) => (
                                  <option
                                    key={singi.catid}
                                    value={singi.catid}
                                  >
                                    {singi.title}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="ry_label">Sub Category</label>
                              <select
                                value={regformdata.subcategory}
                                type="text"
                                name="subcategory"
                                className="form-control"
                                onChangeCapture={(e) => {
                                  handleForm(e.target.name, e.target.value);
                                }}
                              >
                                <option
                                  key="0"
                                  value="0"
                                >
                                  {SubCategory.length > 0
                                    ? 'Select Sub Category'
                                    : 'No Sub Category'}
                                </option>
                                {SubCategory.map((singi) => (
                                  <option
                                    key={singi.catid}
                                    value={singi.catid}
                                  >
                                    {singi.title}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div style={{ clear: 'both' }}></div>
                        </div>

                        <div className="row mt-2">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="ry_label">Level</label>
                              <select
                                name="level"
                                className="form-control"
                                value={regformdata.level}
                                onChangeCapture={(e) => {
                                  handleForm(e.target.name, e.target.value);
                                }}
                              >
                                <option value="">Select Level</option>
                                <option value="Beginner">Beginner</option>
                                <option value="Intermediate">
                                  Intermediate
                                </option>
                                <option value="Expert">Expert</option>
                                <option value="All Level">All Level</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="ry_label">Language</label>
                              <select
                                value={regformdata.language}
                                type="text"
                                name="language"
                                className="form-control"
                                placeholder="Enter"
                                onChange={(e) => {
                                  handleForm(e.target.name, e.target.value);
                                }}
                              >
                                <option value="">Select Language</option>
                                {AllLanguages.map((L) => (
                                  <option
                                    value={L}
                                    key={L}
                                  >
                                    {L}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="row">
                              <div
                                className="col-md-6"
                                style={{
                                  display:
                                    regformdata.validityIn === 'Lifetime'
                                      ? 'none'
                                      : '',
                                }}
                              >
                                <div className="form-group">
                                  <label className="ry_label">Validity</label>
                                  <select
                                    name="validity"
                                    className="form-control"
                                    value={regformdata.validity}
                                    onChangeCapture={(e) => {
                                      handleForm(e.target.name, e.target.value);
                                    }}
                                  >
                                    <option value="">Select Validity</option>
                                    {validityIndex.map((s) => (
                                      <option
                                        value={s}
                                        key={s}
                                      >
                                        {s} {ValidityUOM}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="ry_label">
                                    Validity Type
                                  </label>
                                  <select
                                    name="validityIn"
                                    className="form-control"
                                    value={regformdata.validityIn}
                                    onChangeCapture={(e) => {
                                      handleForm(e.target.name, e.target.value);
                                      SetValidityUOM(e.target.value);
                                    }}
                                  >
                                    <option value="">
                                      Select Validity Type
                                    </option>
                                    <option value="Hour">Hours</option>
                                    <option value="Day">Day</option>
                                    <option value="Month">Month</option>
                                    <option value="Year">Year</option>
                                    <option value="Lifetime">Lifetime</option>
                                  </select>
                                </div>
                              </div>
                              <div style={{ clear: 'both' }}></div>
                            </div>
                          </div>

                          <div style={{ clear: 'both' }}></div>
                        </div>

                        <div className="row mt-2">
                          <div className="col-md-2">
                            <div className="form-group">
                              <label className="ry_label">Age Group</label>
                              <select
                                name="agegroup"
                                className="form-control"
                                value={regformdata.agegroup}
                                onChangeCapture={(e) => {
                                  handleForm(e.target.name, e.target.value);
                                }}
                              >
                                <option value="">Select Age group</option>
                                <option value="Below 18">Below 18</option>
                                <option value="18 Plus">18 Plus</option>
                                <option value="Open For All">
                                  Open For All
                                </option>
                              </select>
                            </div>
                          </div>

                          <div className="col-md-2">
                            <div className="form-group">
                              <label className="ry_label">Cost in $</label>
                              <input
                                value={regformdata.cost}
                                type="number"
                                name="cost"
                                className="form-control"
                                placeholder="Enter"
                                onChange={(e) => {
                                  handleForm(e.target.name, e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-2">
                            <div className="form-group">
                              <label className="ry_label">Cost in ₹</label>
                              <input
                                value={regformdata.costinr}
                                type="number"
                                name="costinr"
                                className="form-control"
                                placeholder="Enter"
                                onChange={(e) => {
                                  handleForm(e.target.name, e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-2">
                            <div className="form-group">
                              <label className="ry_label">
                                Product ID <small>(iOS Store) </small>
                                <a
                                  href="https://appstoreconnect.apple.com/apps/6475391570/distribution/iaps"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i className="fa fa-link"></i>
                                </a>
                              </label>
                              <input
                                value={regformdata.costIOS}
                                type="text"
                                name="costIOS"
                                className="form-control"
                                placeholder="Enter"
                                onChange={(e) => {
                                  handleForm(e.target.name, e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="ry_label">Run Campaign</label>
                              <select
                                name="campaign"
                                className="form-control"
                                value={regformdata.campaign}
                                onChangeCapture={(e) => {
                                  handleForm(e.target.name, e.target.value);
                                }}
                              >
                                <option value="">Select Validity</option>
                                {CampaignIndex.map((s) => (
                                  <option
                                    value={s}
                                    key={s}
                                  >
                                    {s} %
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4"></div>

                          <div style={{ clear: 'both' }}></div>
                        </div>

                        <div className="row mt-2">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="ry_label">
                                Main Key Features
                              </label>
                              <input
                                value={regformdata.features}
                                type="text"
                                name="features"
                                className="form-control"
                                placeholder="Enter Minimum 5 (Comma Seperated)"
                                onChange={(e) => {
                                  handleForm(e.target.name, e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="ry_label">Meta Keywords</label>
                              <input
                                value={regformdata.metaKeywords}
                                type="text"
                                name="metaKeywords"
                                className="form-control"
                                placeholder="Enter"
                                onChange={(e) => {
                                  handleForm(e.target.name, e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="ry_label">
                                Meta Description
                              </label>
                              <input
                                maxLength={250}
                                value={regformdata.metaDescription}
                                type="text"
                                name="metaDescription"
                                className="form-control"
                                placeholder="Enter"
                                onChange={(e) => {
                                  handleForm(e.target.name, e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-4"></div>
                          <div className="col-md-4"></div>
                          <div style={{ clear: 'both' }}></div>
                        </div>

                        <div className="row mt-2">
                          <div className="col-md-6">
                            <label>Banner Image</label>
                            <input
                              type="file"
                              className="form-control"
                              accept="image/*"
                              onChange={(e) => {
                                uploadBanner(
                                  e.target.files[0],
                                  e.target.files[0].name
                                );
                              }}
                            />
                            {regformdata.image !== '' ? (
                              <div>
                                <img
                                  src={`${UPLOADNODEURL}${regformdata.image}`}
                                  alt=""
                                />
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="col-md-6">
                            <div
                              className="message"
                              style={{ color: Msgcolor }}
                            >
                              {APIMessage ? (
                                <label>{APIMessage}</label>
                              ) : (
                                <label>&nbsp;</label>
                              )}
                            </div>
                            <button
                              type="button"
                              className="btn btn-docsta-instructor"
                              onClick={() => {
                                ShowFormDiv('addi1');
                              }}
                            >
                              Proceed
                            </button>
                            <div className="text-center mt-2">
                              Help
                              <Link
                                to="/instructor-dashboard-help"
                                target="_blank"
                                style={{
                                  color: '#fff',
                                  background: 'blue',
                                  borderRadius: '50%',
                                  textAlign: 'center',
                                  fontSize: '12px',
                                  width: '20px',
                                  height: '20px',
                                  lineHeight: '20px',
                                  marginLeft: '7px',
                                }}
                              >
                                <i className="fa fa-question"></i>
                              </Link>
                              <a
                                href="https://api.whatsapp.com/send?phone=+971543229664&text=Hello,%20Sending%20you%20message%20from%20https://docstalearn.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={`${BASEURL}images/whatsappus.png`}
                                  alt=""
                                  style={{
                                    width: '25px',
                                    height: '25px',
                                    marginLeft: '7px',
                                  }}
                                />
                              </a>
                            </div>
                          </div>
                          <div style={{ clear: 'both' }}></div>
                        </div>
                      </div>

                      <div style={{ display: AddiDet1Div }}>
                        {/* <h4 className="mb-2">Additional Details</h4> */}
                        <div className="row">
                          <div className="col-md-3">
                            {regformdata.image !== '' ? (
                              <div>
                                <img
                                  src={`${UPLOADNODEURL}${regformdata.image}`}
                                  alt=""
                                  style={{
                                    maxWidth: '100%',
                                    border: '1px solid #f5f5f5',
                                    borderRadius: '10px',
                                    marginBottom: '20px',
                                    lineHeight: '100px',
                                  }}
                                />
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="col-md-9">
                            <h3
                              style={{
                                textDecoration: 'underline',
                                lineHeight: '70px',
                              }}
                            >
                              Course Title: {regformdata.title}
                            </h3>
                          </div>
                        </div>
                        <div className="form-group">
                          <span
                            onClick={() => {
                              let t = Number(ModuleList.length);
                              t = ModuleList[t - 1]
                                ? ModuleList[t - 1].index
                                : 0;
                              handleAddModuleList(t + 1);
                            }}
                            className="ryuploadAct"
                            style={{
                              maxWidth: 'max-content',
                              background: '#e7effd',
                              color: '#000',
                              fontWeight: 700,
                              borderRadius: '10px',
                              padding: '10px 20px',
                              cursor: 'pointer',
                            }}
                          >
                            Add a Test &nbsp;
                            <i
                              className="fa fa-plus"
                              style={{
                                borderRadius: '50%',
                                background: '#000',
                                color: '#f5f5f5',
                                width: '25px',
                                height: '25px',
                                lineHeight: '25px',
                                marginLeft: '5px',
                              }}
                            ></i>
                          </span>
                        </div>

                        <h4
                          style={{
                            background: '#152569',
                            color: '#f5f5f5',
                            padding: '10px 20px',
                          }}
                        >
                          Test List
                        </h4>

                        <div
                          className="form-group p-3 mb-3"
                          style={{ border: '1px solid #152569' }}
                        >
                          {ModuleList.map((s, changeId) => (
                            <div
                              className="form-group p-3 mb-3"
                              key={s.index}
                              style={{ border: '3px solid #7a3694' }}
                            >
                              <div className="row mb-3">
                                <div className="col-md-2">
                                  <b>Test Sr. No.</b>
                                  <input
                                    type="text"
                                    name="testsrNo"
                                    className="form-control"
                                    value={s.srNo}
                                    onChange={(e) => {
                                      handleEditModuleList(
                                        changeId,
                                        'srNo',
                                        e.target.value
                                      );
                                      RefreshModuleList();
                                    }}
                                  />
                                </div>
                                <div className="col-md-9">
                                  <b>Test Title</b>
                                  <input
                                    type="text"
                                    name="testTitle"
                                    className="form-control"
                                    value={s.title}
                                    onClick={() => {
                                      handleEditModuleList(changeId, 'open', 0);
                                      RefreshModuleList();
                                    }}
                                    onChange={(e) => {
                                      handleEditModuleList(
                                        changeId,
                                        'title',
                                        e.target.value
                                      );
                                      RefreshModuleList();
                                    }}
                                  />
                                </div>
                                <div className="col-md-1">
                                  <b>&nbsp;</b>
                                  <br />
                                  <span
                                    value={s.title}
                                    style={{
                                      display: s.index === 1 ? '' : '',
                                    }}
                                    className="text-danger"
                                    onClick={() => {
                                      /* eslint-disable no-restricted-globals */
                                      if (
                                        confirm(
                                          'Are you sure to Delete Module ?'
                                        )
                                      ) {
                                        handleRemoveModuleList(s.index);
                                        if (s[PrimaryIndex]) {
                                          DeleteModuleList(s[PrimaryIndex]);
                                        }
                                      }
                                      /* eslint-enable no-restricted-globals */
                                    }}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </span>
                                </div>
                              </div>
                              <div className="text-center">
                                <a
                                  href="/"
                                  className="text-info"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleEditModuleList(
                                      changeId,
                                      'open',
                                      Number(s.open) === 1 ? 0 : 1
                                    );
                                    RefreshModuleList();
                                  }}
                                >
                                  <i
                                    className={`fa fa-chevron-${
                                      Number(s.open) === 1 ? 'down' : 'up'
                                    }`}
                                  ></i>
                                </a>
                              </div>
                              <div
                                className="row mb-3"
                                style={{
                                  display: Number(s.open) === 1 ? 'none' : '',
                                }}
                              >
                                <div className="col-md-4 mb-4">
                                  <b>
                                    <span className="text-warning">Type</span>
                                  </b>
                                  <select
                                    className="form-control"
                                    defaultValue={
                                      Number(s.posiMark) === 0 ? '0' : '1'
                                    }
                                    onChange={(e) => {
                                      SetAction(Action + 1);
                                      if (e.target.value === '0') {
                                        handleEditModuleList(
                                          changeId,
                                          'posiMark',
                                          0
                                        );
                                      } else {
                                        handleEditModuleList(
                                          changeId,
                                          'posiMark',
                                          ''
                                        );
                                      }
                                      RefreshModuleList();
                                    }}
                                  >
                                    <option value="1">Mock Test</option>
                                    <option value="0">Practise Test</option>
                                  </select>
                                </div>
                                <div className="col-md-4 mb-4">
                                  <b>
                                    <span className="text-warning">
                                      Attempt Allowed
                                    </span>
                                  </b>
                                  <input
                                    type="number"
                                    name="attempt"
                                    min={0}
                                    className="form-control"
                                    value={s.attempt}
                                    onChange={(e) => {
                                      handleEditModuleList(
                                        changeId,
                                        'attempt',
                                        e.target.value
                                      );
                                      RefreshModuleList();
                                    }}
                                  />
                                </div>
                                <div
                                  className="col-md-4 mb-4"
                                  style={{
                                    display:
                                      Action && s.posiMark === 0 ? 'none' : '',
                                  }}
                                >
                                  <b>
                                    <span className="text-success">
                                      Passing %
                                    </span>
                                  </b>
                                  <input
                                    type="text"
                                    name="parScore"
                                    className="form-control"
                                    value={s.parScore}
                                    onChange={(e) => {
                                      handleEditModuleList(
                                        changeId,
                                        'parScore',
                                        e.target.value
                                      );
                                      RefreshModuleList();
                                    }}
                                  />
                                </div>
                                {/* <div className="col-md-4 mb-3"></div> */}
                                <div
                                  className="col-md-4 mb-4"
                                  style={{
                                    display:
                                      Action && s.posiMark === 0 ? 'none' : '',
                                  }}
                                >
                                  <b>
                                    <span className="text-success">Mark</span>{' '}
                                    for Corrent Answer
                                  </b>
                                  <input
                                    type="text"
                                    name="posiMark"
                                    min={0}
                                    className="form-control"
                                    value={s.posiMark}
                                    onBlur={(e) => {
                                      let temp = e.target.value;
                                      if (Number.isInteger(temp)) {
                                        // temp = temp;
                                      } else {
                                        temp = Number(temp).toFixed(2);
                                      }
                                      handleEditModuleList(
                                        changeId,
                                        'posiMark',
                                        Number(temp)
                                      );
                                      RefreshModuleList();
                                    }}
                                    onChange={(e) => {
                                      handleEditModuleList(
                                        changeId,
                                        'posiMark',
                                        e.target.value
                                      );
                                      RefreshModuleList();
                                    }}
                                  />
                                </div>
                                <div
                                  className="col-md-4 mb-4"
                                  style={{
                                    display:
                                      Action && s.posiMark === 0 ? 'none' : '',
                                  }}
                                >
                                  <b>
                                    <span className="text-danger">
                                      Negative Mark
                                    </span>{' '}
                                    for Wrong Answer
                                  </b>
                                  <input
                                    type="number"
                                    name="negMark"
                                    min={0}
                                    className="form-control"
                                    value={s.negMark}
                                    onBlur={(e) => {
                                      let temp = e.target.value;
                                      if (Number.isInteger(temp)) {
                                        // temp = temp;
                                      } else {
                                        temp = Number(temp).toFixed(2);
                                      }
                                      handleEditModuleList(
                                        changeId,
                                        'negMark',
                                        Number(temp)
                                      );
                                      RefreshModuleList();
                                    }}
                                    onChange={(e) => {
                                      let temp = e.target.value;
                                      handleEditModuleList(
                                        changeId,
                                        'negMark',
                                        temp
                                      );
                                      RefreshModuleList();
                                    }}
                                  />
                                </div>
                                <div className="col-md-4">
                                  <b>
                                    <span className="text-info">
                                      Duration in Minutes
                                    </span>{' '}
                                    for This Test
                                  </b>
                                  <input
                                    type="number"
                                    name="duration"
                                    min={0}
                                    className="form-control"
                                    value={s.duration}
                                    onChange={(e) => {
                                      handleEditModuleList(
                                        changeId,
                                        'duration',
                                        e.target.value
                                      );
                                      RefreshModuleList();
                                    }}
                                  />
                                </div>
                              </div>
                              <div
                                className="row mb-3"
                                style={{
                                  display: Number(s.open) === 1 ? 'none' : '',
                                }}
                              >
                                <div className="col-md-6">
                                  <b>Syllabus of Test</b>
                                  <EditorQBModule
                                    PageContent={s.syllabus}
                                    changeId={changeId}
                                    handleEditModuleList={handleEditModuleList}
                                    RefreshModuleList={RefreshModuleList}
                                    ForAttribute="syllabus"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <b>Before Start Test</b>
                                  <EditorQBModule
                                    PageContent={s.instruction}
                                    changeId={changeId}
                                    handleEditModuleList={handleEditModuleList}
                                    RefreshModuleList={RefreshModuleList}
                                    ForAttribute="instruction"
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>

                        <div
                          className="message mt-3"
                          style={{ color: Msgcolor }}
                        >
                          {APIMessage ? <span>{APIMessage}</span> : ''}
                        </div>

                        <div style={{ textAlign: 'center' }}>
                          <anew
                            href="#"
                            className="btn btn-info mr-3"
                            onClick={() => {
                              ShowFormDiv('initial');
                            }}
                            style={{
                              margin: '20px auto',
                              maxWidth: '150px',
                            }}
                          >
                            <i className="fa fa-edit"></i>
                            &nbsp;Edit Details
                          </anew>
                          <anew
                            href="#"
                            className="btn btn-docsta-instructor d-inline-block"
                            onClick={() => {
                              ShowFormDiv('addi2');
                            }}
                            style={{
                              margin: '20px auto',
                              maxWidth: '150px',
                            }}
                          >
                            Proceed
                          </anew>
                        </div>
                      </div>

                      <div style={{ display: AddiDet2Div }}>
                        {ModuleList.map((s) => (
                          <div
                            key={s.index}
                            style={{ marginBottom: '20px' }}
                          >
                            <h4
                              style={{
                                background: '#152569',
                                color: '#f5f5f5',
                                padding: '10px 20px',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                if (s.index === Question2CoursesModule) {
                                  setQuestion2CoursesModule(0);
                                } else {
                                  setQuestion2CoursesModule(s.index);
                                }
                              }}
                            >
                              {s.title}
                              <small>
                                &nbsp; (
                                {
                                  SessionQuestions.filter(
                                    (item) =>
                                      Number(item.module) === Number(s.index) &&
                                      item.status === 1
                                  ).length
                                }{' '}
                                Questions )
                              </small>
                              <div style={{ float: 'right' }}>
                                <i
                                  className={`fa fa-chevron-${
                                    s.index === Question2CoursesModule
                                      ? 'down'
                                      : 'up'
                                  }`}
                                ></i>
                              </div>
                              <div style={{ clear: 'both' }}></div>
                            </h4>
                            <div
                              style={{
                                // display: s.open === 1 ? '' : 'none',
                                border: '1px solid rgb(21, 37, 105)',
                              }}
                            >
                              {s.index === Question2CoursesModule ? (
                                <Question2Courses
                                  setSessionQuestions={setSessionQuestions}
                                  setSessionAnswers={setSessionAnswers}
                                  EditQuestionSession={s.index}
                                  SessionTitle={s.title}
                                  handleForm={handleForm}
                                  SessionQuestions={SessionQuestions}
                                  SessionAnswers={SessionAnswers}
                                  regformdata={regformdata}
                                  setForm={setForm}
                                />
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        ))}
                        <div style={{ textAlign: 'center' }}>
                          <anew
                            href="#"
                            className="btn btn-info mr-3"
                            onClick={() => {
                              ShowFormDiv('addi1');
                            }}
                            style={{
                              margin: '20px auto',
                              width: '150px',
                            }}
                          >
                            <i className="fa fa-edit"></i>
                            &nbsp;Edit Modules
                          </anew>
                          <button
                            type="button"
                            className="btn btn-docsta-instructor d-inline-block"
                            onClick={() => {
                              ShowFormDiv('addi3');
                            }}
                            style={{
                              margin: '20px auto',
                              width: '150px',
                            }}
                          >
                            Proceed
                          </button>
                        </div>
                      </div>

                      <div style={{ display: AddiDet3Div }}>
                        <Faqs2Courses
                          faqs={regformdata.faqs}
                          handleForm={handleForm}
                        />
                        <h4
                          style={{
                            background: '#152569',
                            color: '#f5f5f5',
                            padding: '10px 20px',
                            margintop: '20px',
                          }}
                        >
                          Message to the Reviewer
                        </h4>

                        <div
                          className="form-group"
                          style={{
                            border: '1px solid #152569',
                            padding: '20px',
                            background: '#ededed',
                          }}
                        >
                          <textarea
                            required
                            value={regformdata.reviewer}
                            name="question"
                            className="form-control"
                            onChange={(e) => {
                              handleForm('reviewer', e.target.value);
                            }}
                          />
                        </div>

                        <div style={{ textAlign: 'center' }}>
                          <anew
                            href="#"
                            className="btn btn-info mr-3 mb-3"
                            onClick={() => {
                              ShowFormDiv('addi2');
                            }}
                          >
                            <i className="fa fa-edit"></i>
                            &nbsp;Edit MCQs
                          </anew>
                          <button
                            type="submit"
                            className="btn btn-warning d-inline-block mr-3 mb-3 text-dark"
                            onClick={() => {
                              regformdata.draft = 1;
                              setMessage('');
                              handleForm('SessionAnswers', SessionAnswers);
                              setIsLoader(true);
                            }}
                            style={{ width: 'auto' }}
                          >
                            Save as Draft
                          </button>
                          <button
                            type="submit"
                            className="btn btn-docsta-instructor d-inline-block mb-3"
                            onClick={() => {
                              regformdata.draft = 0;
                              setMessage('');
                              handleForm('SessionAnswers', SessionAnswers);
                              setIsLoader(true);
                            }}
                            style={{ width: 'auto' }}
                          >
                            Submit for Review
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-1"></div>
          </div>
        </div>
      </div>
      <Footer className="appie-footer-about-area" />
      <BackToTop />
    </>
  );
}

export default InstructorCreateQB;
