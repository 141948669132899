import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
import Parser from 'html-react-parser';
import { BASEAPIURL, BASEURL, IMGBASEAPIURL } from '../../../config';

function Information({ InfoTitle }) {
    const [PageTitle, setPageTitle] = useState('');
    const [Page, setPage] = useState({ pagecontent: '', pagetitle: '' });

    useEffect(() => {
        setPageTitle('');
        const FetchData = async () => {
            try {
                const res = await fetch(`${BASEAPIURL}/admin/master/getpage`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        pagename: InfoTitle,
                    }),
                });
                const resJson = await res.json();
                if (res.status === 200) {
                    // setInfoData(resJson.data);
                    setPage(resJson.data);
                    // alert(resJson.data.pagename);
                    if (resJson.data.pagename === 'termandconditions') {
                        document.title = 'Terms and Conditions';
                        setPageTitle('Terms and Conditions');
                    }
                    if (resJson.data.pagename === 'privacy') {
                        document.title = 'Privacy Policy';
                        setPageTitle('Privacy Policy');
                    }
                    if (resJson.data.pagename === 'RefundAndCancellation') {
                        document.title = 'Refund and Cancellation';
                        setPageTitle('Refund and Cancellation');
                    }
                } else {
                    console.log(resJson.message);
                }
            } catch (err) {
                console.log(err);
            }
        };
        FetchData();
    }, [InfoTitle, setPage, setPageTitle]);
    return (
        <>
            {PageTitle !== '' ? (
                <> 
                    <section
                        className="corporate-learning"
                        data-overlay="6"
                        style={{
                            backgroundImage: `url(${IMGBASEAPIURL}institute/contactus/about-us.jpg)`,
                            padding: '70px 10px',
                            backgroundPosition: 'center',
                        }}
                    >
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="headBlock">
                                        <h1 className="mt-5 mb-5 text-white">{PageTitle}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="infoBlock">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="InformationPage">{Parser(Page.pagecontent)}</div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            ) : (
                <div className="text-center mt-5 mb-5">
                    <img src={`${BASEURL}/loader.gif`} alt="" style={{ maxWidth: '70px' }} />
                </div>
            )}
        </>
    );
}

export default Information;
