import React from 'react';
import useToggle from '../../../Hooks/useToggle';
import BackToTop from '../../BackToTop';
import Drawer from '../../Mobile/DrawerInstitute';
import Header from '../../Helper/HeaderInstitute';
import Footer from '../../Helper/FooterInstitute';
import CareerCounselingBox from '../CareerCounselingBox';
import CareerCounseling from './CareerCounseling';
import CounsellingTeam from '../CounsellingTeam';
import HighlyPopular from './HighlyPopular';
import InstructorBox from '../InstructorBox';
import PopularCoursesBox from '../PopularCoursesBox';

function DocInstitute() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <CareerCounselingBox />
            <CareerCounseling />
            <CounsellingTeam />
            <HighlyPopular />
            <InstructorBox />
            <PopularCoursesBox className="pt-70" />
            <Footer />
            <BackToTop />
        </>
    );
}

export default DocInstitute;
