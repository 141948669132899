// https://stackoverflow.com/questions/68690135/how-to-get-the-html-content-from-react-draft-wysiwyg-from-the-child-component-to
import React, { useState, useEffect } from 'react';
import Parser from 'html-react-parser';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './RichTextEditor.css';
import '../../components/Learn/CourseDetail/coursedetail.css';

let i = 0;
const ShowHtmlEditor = ({ getContent, PageContent, isEditview, setisEditview, setPageContent }) => {
    const [editorState, setEditorState] = useState(() =>
        EditorState.createWithContent(
            ContentState.createFromBlockArray(convertFromHTML(PageContent))
        )
    );

    function EditThis() {
        // setisEditview(1);
        setEditorState(
            EditorState.createWithContent(
                ContentState.createFromBlockArray(convertFromHTML(PageContent))
            )
        );
    }

    const handleEditorChange = (state) => {
        setEditorState(state);
        sendContent();
    };

    const sendContent = () => {
        getContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    useEffect(() => {
        if (i === 0) { 
            setEditorState(
                EditorState.createWithContent(
                    ContentState.createFromBlockArray(convertFromHTML(PageContent))
                )
            );
        }
    }, [PageContent]);

    return (
        <div>    
            <div style={{ display: isEditview !== 0 ? 'none' : '' }}>
                <button
                    type="button"
                    style={{ display: isEditview === 1 ? 'none' : '' }}
                    name="keyword"
                    className="btn btn-outline-success mb-2 mt-2"
                    onClick={() => {
                        setisEditview(1);
                        EditThis();
                    }}
                >
                    Edit
                </button>
                <button
                    type="button"
                    style={{ display: isEditview === 1 ? 'none' : '' }}
                    name="keyword"
                    className="btn btn-outline-danger mb-2 mt-2 ml-2"
                    onClick={() => {
                        setisEditview(2);
                        EditThis();
                    }}
                >
                    Edit HTML
                </button>

                <div className="CourseDescription">
                    {Parser(PageContent)}
                </div>
            </div>
            <div style={{ display: isEditview === 2 ? '' : 'none' }}>
                <textarea
                    name="htmlcodecontent"
                    value={PageContent}
                    className="form-control mb-3"
                    style={{ minHeight: '250px' }}
                    onClick={() => {
                        i = 1;
                    }}
                    onChange={(e) => {
                        setPageContent(e.target.value);
                    }}
                ></textarea>
            </div>
            <div
                onClick={() => {
                    i = 1;
                }}
                style={{ display: isEditview === 1 ? '' : 'none' }}
            >
                <Editor
                    editorState={editorState}
                    onEditorStateChange={handleEditorChange}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                />
            </div>
            <div style={{ display: isEditview !== 0 ? '' : 'none' }}>
                <button
                    type="button"
                    name="back"
                    className="btn btn-outline-info btn-sm mb-2 mt-0 ml-2"
                    onClick={() => {
                        setisEditview(0);
                    }}
                >
                    Preview
                </button>
            </div>
        </div>
    );
};

export default ShowHtmlEditor;
