import React from 'react';
import { IMGBASEAPIURL } from '../../../config';

function CounselingTeamList({ className }) {
    return (
        <>
            <section
                className={`appie-features-area advisoryBoardList instructors ${className || ''}`}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Meet Our Counselling Team</h3>
                                <p>Meet the consultants who can reshape your career</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="advisoryBoardBox">
                                <div className="appie-features-content">
                                    <div className="imgBox">
                                        <img
                                            src={`${IMGBASEAPIURL}institute/instructor/img1.jpg`}
                                            alt=""
                                        />
                                    </div>
                                    <div className="contentBox">
                                        <h4>Dr. Danish Salim</h4>
                                        <b>Medical Doctor</b>
                                    </div>
                                </div>
                                <div className="appie-features-content">
                                    <div className="imgBox">
                                        <img
                                            src={`${IMGBASEAPIURL}institute/instructor/img2.jpg`}
                                            alt=""
                                        />
                                    </div>
                                    <div className="contentBox">
                                        <h4>Dr. Raiza Shukoor</h4>
                                        <b>Medical Doctor</b>
                                    </div>
                                </div>
                                <div className="appie-features-content">
                                    <div className="imgBox">
                                        <img
                                            src={`${IMGBASEAPIURL}institute/instructor/img3.jpg`}
                                            alt=""
                                        />
                                    </div>
                                    <div className="contentBox">
                                        <h4>John Willams</h4>
                                        <b>Medical Doctor</b>
                                    </div>
                                </div>
                                <div className="appie-features-content">
                                    <div className="imgBox">
                                        <img
                                            src={`${IMGBASEAPIURL}institute/instructor/img4.jpg`}
                                            alt=""
                                        />
                                    </div>
                                    <div className="contentBox">
                                        <h4>Daiana John</h4>
                                        <b>Medical Doctor</b>
                                    </div>
                                </div>
                                <div className="appie-features-content">
                                    <div className="imgBox">
                                        <img
                                            src={`${IMGBASEAPIURL}institute/instructor/img5.jpg`}
                                            alt=""
                                        />
                                    </div>
                                    <div className="contentBox">
                                        <h4>Frank Gordon</h4>
                                        <b>Medical Doctor</b>
                                    </div>
                                </div>
                                <div className="appie-features-content">
                                    <div className="imgBox">
                                        <img
                                            src={`${IMGBASEAPIURL}institute/instructor/img6.jpg`}
                                            alt=""
                                        />
                                    </div>
                                    <div className="contentBox">
                                        <h4>Anthony Wells</h4>
                                        <b>Medical Doctor</b>
                                    </div>
                                </div>
                                <div className="appie-features-content">
                                    <div className="imgBox">
                                        <img
                                            src={`${IMGBASEAPIURL}institute/instructor/img7.jpg`}
                                            alt=""
                                        />
                                    </div>
                                    <div className="contentBox">
                                        <h4>Carmen Fox</h4>
                                        <b>Medical Doctor</b>
                                    </div>
                                </div>
                                <div className="appie-features-content">
                                    <div className="imgBox">
                                        <img
                                            src={`${IMGBASEAPIURL}institute/instructor/img8.jpg`}
                                            alt=""
                                        />
                                    </div>
                                    <div className="contentBox">
                                        <h4>Carmen Fox</h4>
                                        <b>Medical Doctor</b>
                                    </div>
                                </div>
                            </div>
                            <div className="bisylms-pagination">
                                {/* <a className="next" href="#">
                                    <i className="fal fa-arrow-left"></i>
                                </a>
                                <a className="current">01</a>
                                <a href="#">02</a>
                                <a href="#">03</a>
                                <a className="next" href="#">
                                    <i className="fal fa-arrow-right"></i>
                                </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CounselingTeamList;
