import React, { Suspense } from 'react';
import useToggle from '../../../Hooks/useToggle';
import BackToTop from '../../BackToTop';
import Drawer from '../../Mobile/DrawerInstitute';
import Header from '../../Helper/HeaderInstitute';
import Footer from '../../Helper/FooterInstitute';

const WebinarDetail = React.lazy(() => import('./WebinarDetail'));

function DocInstitute() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <Suspense fallback={<div>Loading...</div>}>
                <WebinarDetail />
            </Suspense>
            <Footer />
            <BackToTop />
        </>
    );
}

export default DocInstitute;
