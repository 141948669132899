import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showKeywordSearch: 'block',
  showLangOpt: 'none',
  ActMainMenu: 'Home',
  ActProfileMenu: 'Dashboard',
};

export const commonData = createSlice({
  name: 'commonSlice',
  initialState,
  reducers: {
    UpdateCommonData: (state, newData) => {
      switch (newData.payload.forType) {
        case 'business':
          Object.keys(newData.payload).forEach((key) => {
            state.business[key] = newData.payload[key];
          });
          break;
        default:
          Object.keys(newData.payload).forEach((key) => {
            state[key] = newData.payload[key];
          });
          break;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { UpdateCommonData } = commonData.actions;

export default commonData.reducer;
