import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
// import Parser from 'html-react-parser';
import 'react-phone-number-input/style.css';
// import PhoneInput from 'react-phone-number-input';
import useToggle from '../../../../Hooks/useToggle';
import BackToTop from '../../../BackToTop';
import Footer from '../../../Helper/FooterLearn';
import Drawer from '../../../Mobile/DrawerLearn';
// import Header from '../Helper/Header';
import HeaderInstructor from '../../InstructorIndex_Insti';
import { IMGBASEAPIURL, BASEAPIURL } from '../../../../config';
import {
    RegFrmRateVidMkInstr,
    RegFrmExiAudiInstr,
    RegFrmLangInstr,
    RegFrmTeachDoneInstr,
    RegFrmValues2StdInstr,
    RegFrmCrsComInstr,
    RegFrmDocstaHelpInstr,
} from '../../../../masterdata';
import '../../instructor.css';

// const serialize = require('form-serialize');

function CreateWebinar() {
    // const [file, setFile] = useState();
    // const [fileName, setFileName] = useState('');
    const [step1, setStep1] = useState('active');
    const [step2, setStep2] = useState('');
    const [step3, setStep3] = useState('');
    const [Category, setCategory] = useState([]);
    const [SubCategory, setSubCategory] = useState([]);
    const [drawer, drawerAction] = useToggle(false);
    const [PerDetDiv, setPerDetDiv] = useState('block');
    const [AddiDet1Div, setAddiDet1Div] = useState('None');
    const [AddiDet2Div, setAddiDet2Div] = useState('None');
    const [ConfirmationDiv, setConfirmationDiv] = useState('None');
    const [CVfile, setCVfile] = useState();
    const [Msgcolor, setMsgcolor] = useState();
    // const [Email, setEmail] = useState();
    const [APIMessage, setMessage] = useState();
    const [classforform, setclassforform] = useState('col-md-12');
    const Discounts = [...Array(50 + 1).keys()].slice(1);
    const TotDuration = [...Array(100 + 1).keys()].slice(1);
    const SessDuration = [...Array(12 + 1).keys()].slice(1);
    // const form = document.querySelector('#Inst-Regi-Form');
    // const obj = serialize(form, { hash: true, empty: true });
    // const [regformdata, setForm] = useState({});
    // const handleForm = (name, value) => {
    //     setForm({ ...regformdata, [name]: value });
    // };
    const [UserFormData, setUserFormData] = useState({
        title: '',
        requirements: '',
        description: '',
        category: '',
        subcategory: '',
        addiitrainer: '',
        level: '',
        languauge: '',
        noofpartic: '',
        agegroup: '',
        cost: '',
        discount: '',
        fromdate: '',
        todate: '',
        fromtime: '',
        totime: '',
        before: '',
        todal_duration: '',
        session_duration: '',
        session_frequency: '',
        banner: '',
        pre_vid: '',
        test_vid: '',
        key_featu: '',
        meta_keyword: '',
        meta_description: '',
    });
    const UpdateUserFormData = (name, value) => {
        setUserFormData({ ...UserFormData, [name]: value });
    };
    function ShowFormDiv(type) {
        let AllOkay = 1;
        if (
            typeof UserFormData.category === 'undefined' ||
            UserFormData.category === 0 ||
            UserFormData.category === '0'
        ) {
            AllOkay = 0;
            setMsgcolor('red');
            setMessage('Please Select the Category.');
        }

        if (AllOkay === 1) {
            setPerDetDiv('none');
            setAddiDet1Div('none');
            setAddiDet2Div('none');
            setConfirmationDiv('none');
            setclassforform('col-md-12');
            setStep1(' ');
            setStep2(' ');
            setStep3(' ');
            switch (type) {
                case 'addi2':
                    setStep2('active');
                    setAddiDet2Div('block');
                    break;
                case 'addi1':
                    setStep2('active');
                    setclassforform('col-md-12 text-center');
                    setConfirmationDiv('block');
                    break;
                case 'confirm':
                    setStep3('active');
                    setclassforform('col-md-12 text-center');
                    setConfirmationDiv('block');
                    break;
                default:
                    setStep1('active');
                    setPerDetDiv('block');
                    break;
            }
        }
    }

    // for file uploade will be here
    async function uploadFile(file, fileName) {
        // e.preventDefault();
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', fileName);
        const url = `${BASEAPIURL}/admin/instructure/uploadcv`;
        // const url = 'https://ktuniexpo.com/women-directors/test.php';
        try {
            const res = await axios.post(url, formData);
            console.log(res);
            setCVfile(res.data.filename);
        } catch (ex) {
            console.log(ex);
        }
    }

    const saveFile = (e) => {
        setTimeout(() => {
            uploadFile(e.target.files[0], e.target.files[0].name);
        }, 100);
    };
    // for file uploade will be here

    const FetchCategory = async () => {
        // e.preventDefault();
        try {
            const res = await fetch(`${BASEAPIURL}/webicategory/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    token: 'hello',
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                setCategory(resJson.data);
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const FetchSubCategory = async (e, parentid) => {
        // e.preventDefault();
        try {
            const res = await fetch(`${BASEAPIURL}/webicategory/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    parent: parentid,
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                setSubCategory(resJson.data);
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    async function WebiCreateUpdate() {
        try {
            ShowFormDiv('addi1');
            setMsgcolor('green');
            const url = `${BASEAPIURL}/webi/create`;
            const formData = new FormData();
            formData.append('edit', 0);
            // get all the data and add there
            formData.append('CVfile', CVfile);
            Object.keys(UserFormData).map((index) => {
                formData.append(index, UserFormData[index]);
                return index;
            });
            const config = {
                headers: {
                    'content-type': 'application/json', // multipart/form-data
                },
            };
            axios.post(url, formData, config).then((response) => {
                console.log(response);
                setMessage(response.data.message);
            });
        } catch (err) {
            setMsgcolor('red');
            console.log(err);
            setMessage('Something went wrong, Please try again later.');
        }
    }

    useEffect(() => {
        FetchCategory();
    }, []);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderInstructor drawer={drawer} action={drawerAction.toggle} />
            <>
                <div className="container pt-4 pb-4">
                    <div
                        className="p-3 text-center"
                        style={{
                            background: `url(${IMGBASEAPIURL}instructor/headerbg.jpg)`,
                            backgroundRepeat: 'repeat',
                            backgroundSize: '100%',
                        }}
                    >
                        <h2 className="m-3 text-white">Submit A New Webinar</h2>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div
                                className="mt-4 mb-4 ry_instr_shadow"
                                style={{ borderRadius: '20px' }}
                            >
                                <div className="instr_signup_head d-none  pt-4 pb-4 d-md-block">
                                    <div className="row">
                                        <div className="col-md-3 text-center">
                                            <h2 className={`mb-0 instr_signup_head_step ${step1}`}>
                                                1
                                            </h2>
                                            <b className="mb-2 d-block">Webinar Details</b>
                                        </div>
                                        <div className="col-md-3 text-center">
                                            <h2 className={`mb-0 instr_signup_head_step ${step2}`}>
                                                2
                                            </h2>
                                            <b className="mb-2 d-block">Submit for Review</b>
                                        </div>
                                        <div className="col-md-3 text-center">
                                            <h2 className={`mb-0 instr_signup_head_step ${step3}`}>
                                                3
                                            </h2>
                                            <b className="mb-2 d-block">Additional Details</b>
                                        </div>
                                        <div className="col-md-3 text-center">
                                            <h2 className={`mb-0 instr_signup_head_step ${step3}`}>
                                                4
                                            </h2>
                                            <b className="mb-2 d-block">Launch Webinar</b>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={classforform}>
                                        <form
                                            id="Inst-Regi-Form"
                                            encType="multipart/form-data"
                                            className="m-4 p-4"
                                            style={{ display: 'block' }}
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                WebiCreateUpdate();
                                            }}
                                        >
                                            <input
                                                type="file"
                                                name="cvfile"
                                                className="d-none"
                                                placeholder="Enter Your Full Name here"
                                                onChange={(e) => {
                                                    saveFile(e);
                                                }}
                                            />

                                            <div className="For_Form_title"></div>

                                            <div style={{ display: ConfirmationDiv }}>
                                                <img
                                                    src={`${IMGBASEAPIURL}instructor/inst_reg_done.jpg`}
                                                    alt="Docsta Learn Insinstructor"
                                                    style={{ maxWidth: '100%' }}
                                                    className="mt-1 mb-4"
                                                />
                                                <h3 className="mt-3">
                                                    Your request has been submitted successfully.
                                                </h3>
                                                <div
                                                    className="message mt-3"
                                                    style={{ color: Msgcolor }}
                                                >
                                                    {APIMessage ? (
                                                        <span>{APIMessage}</span>
                                                    ) : (
                                                        'After reviewing your request, we will get back to you. You will receive an email about the status'
                                                    )}
                                                </div>
                                            </div>

                                            <div style={{ display: PerDetDiv }}>
                                                <h4 className="mb-3">Webinar Details</h4>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="ry_label">
                                                                Course Title
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="title"
                                                                required
                                                                className="form-control"
                                                                placeholder="Enter"
                                                                onChange={(e) => {
                                                                    UpdateUserFormData(
                                                                        e.target.name,
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="ry_label">
                                                                Course Requirements
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="requirements"
                                                                className="form-control"
                                                                placeholder="Enter"
                                                                onChange={(e) => {
                                                                    UpdateUserFormData(
                                                                        e.target.name,
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="ry_label">
                                                                Course Description
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="description"
                                                                required
                                                                className="form-control"
                                                                placeholder="Enter"
                                                                onChange={(e) => {
                                                                    UpdateUserFormData(
                                                                        e.target.name,
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="clear_both"></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="ry_label">
                                                                Category
                                                            </label>
                                                            <select
                                                                required
                                                                type="text"
                                                                name="category"
                                                                className="form-control"
                                                                onChange={(e) => {
                                                                    UpdateUserFormData(
                                                                        e.target.name,
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                onBlur={(e) => {
                                                                    FetchSubCategory(
                                                                        e,
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            >
                                                                <option key="0" value="">
                                                                    Select Category
                                                                </option>
                                                                {Category.map((singi) => (
                                                                    <option
                                                                        key={singi.catid}
                                                                        value={singi.catid}
                                                                    >
                                                                        {singi.title}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="ry_label">
                                                                Sub Category
                                                            </label>
                                                            <select
                                                                type="text"
                                                                name="subcategory"
                                                                className="form-control"
                                                                onChangeCapture={(e) => {
                                                                    UpdateUserFormData(
                                                                        e.target.name,
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            >
                                                                <option key="0" value="0">
                                                                    {SubCategory.length > 0
                                                                        ? 'Select Sub Category'
                                                                        : 'No Sub Category'}
                                                                </option>
                                                                {SubCategory.map((singi) => (
                                                                    <option
                                                                        key={singi.catid}
                                                                        value={singi.catid}
                                                                    >
                                                                        {singi.title}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="ry_label">
                                                                Additional Instructor Name (If Any)
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="addiitrainer"
                                                                className="form-control"
                                                                placeholder="Enter"
                                                                onChange={(e) => {
                                                                    UpdateUserFormData(
                                                                        e.target.name,
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="clear_both"></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="ry_label">
                                                                Level
                                                            </label>
                                                            <select
                                                                name="level"
                                                                className="form-control"
                                                                onChangeCapture={(e) => {
                                                                    UpdateUserFormData(
                                                                        e.target.name,
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            >
                                                                <option value="Easy">Easy</option>
                                                                <option value="Medium">
                                                                    Medium
                                                                </option>
                                                                <option value="Hard">Hard</option>
                                                                <option value="">
                                                                    Not Specify
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="ry_label">
                                                                Language
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="languauge"
                                                                className="form-control"
                                                                placeholder="Enter"
                                                                onChange={(e) => {
                                                                    UpdateUserFormData(
                                                                        e.target.name,
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="ry_label">
                                                                Number Of Participants
                                                            </label>
                                                            <select
                                                                name="noofpartic"
                                                                className="form-control"
                                                                onChangeCapture={(e) => {
                                                                    UpdateUserFormData(
                                                                        e.target.name,
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            >
                                                                <option value="50 to 100">
                                                                    1 to 10
                                                                </option>
                                                                <option value="50 to 100">
                                                                    10 to 50
                                                                </option>
                                                                <option value="50 to 100">
                                                                    50 to 100
                                                                </option>
                                                                <option value="100+">100+</option>
                                                                <option value="">
                                                                    Not Specify
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="clear_both"></div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="ry_label">
                                                                Age Group
                                                            </label>
                                                            <select
                                                                name="agegroup"
                                                                className="form-control"
                                                                onChangeCapture={(e) => {
                                                                    UpdateUserFormData(
                                                                        e.target.name,
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            >
                                                                <option value="Under 18 Years">
                                                                    Under 18 Years
                                                                </option>
                                                                <option value="18 to 30 Years">
                                                                    18 to 30 Years
                                                                </option>
                                                                <option value="30 to 45 Years">
                                                                    30 to 45 Years
                                                                </option>
                                                                <option value="45 to 60 Years">
                                                                    45 to 60
                                                                </option>
                                                                <option value="60+ Years">
                                                                    60+ Years
                                                                </option>
                                                                <option value="100+">100+</option>
                                                                <option value="0">
                                                                    Open for All Age
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="ry_label">Cost</label>
                                                            <input
                                                                required
                                                                type="number"
                                                                name="Cost"
                                                                className="form-control"
                                                                placeholder="Enter"
                                                                onChange={(e) => {
                                                                    UpdateUserFormData(
                                                                        e.target.name,
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="ry_label">
                                                                Run Campaign
                                                            </label>
                                                            <select
                                                                name="discount"
                                                                className="form-control"
                                                                placeholder="Enter"
                                                                onChangeCapture={(e) => {
                                                                    UpdateUserFormData(
                                                                        e.target.name,
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            >
                                                                <option value="0">
                                                                    Select % of Discount
                                                                </option>
                                                                {Discounts.map((singi) => (
                                                                    <option
                                                                        value={singi}
                                                                        key={singi}
                                                                    >
                                                                        {singi}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="clear_both"></div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="ry_label">
                                                                        From Date
                                                                    </label>
                                                                    <input
                                                                        required
                                                                        type="date"
                                                                        name="fromdate"
                                                                        className="form-control"
                                                                        placeholder="from"
                                                                        onChangeCapture={(e) => {
                                                                            UpdateUserFormData(
                                                                                e.target.name,
                                                                                e.target.value
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="ry_label">
                                                                        To Date
                                                                    </label>
                                                                    <input
                                                                        required
                                                                        type="date"
                                                                        name="todate"
                                                                        className="form-control"
                                                                        placeholder="from"
                                                                        onChangeCapture={(e) => {
                                                                            UpdateUserFormData(
                                                                                e.target.name,
                                                                                e.target.value
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="clear_both"></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="ry_label">
                                                                        From Date
                                                                    </label>
                                                                    <input
                                                                        type="time"
                                                                        name="fromtime"
                                                                        className="form-control"
                                                                        placeholder="from"
                                                                        onChangeCapture={(e) => {
                                                                            UpdateUserFormData(
                                                                                e.target.name,
                                                                                e.target.value
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="ry_label">
                                                                        To Date
                                                                    </label>
                                                                    <input
                                                                        type="time"
                                                                        name="totime"
                                                                        className="form-control"
                                                                        placeholder="To"
                                                                        onChangeCapture={(e) => {
                                                                            UpdateUserFormData(
                                                                                e.target.name,
                                                                                e.target.value
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="clear_both"></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="ry_label">
                                                                Have you done this Course before?
                                                            </label>
                                                            <select
                                                                name="before"
                                                                className="form-control"
                                                                placeholder="Enter"
                                                                onChangeCapture={(e) => {
                                                                    UpdateUserFormData(
                                                                        e.target.name,
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            >
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="clear_both"></div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="ry_label">
                                                                Total Duration
                                                            </label>
                                                            <select
                                                                name="todal_duration"
                                                                className="form-control"
                                                                placeholder="Enter"
                                                                onChangeCapture={(e) => {
                                                                    UpdateUserFormData(
                                                                        e.target.name,
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            >
                                                                {TotDuration.map((singi) => (
                                                                    <option
                                                                        value={singi}
                                                                        key={singi}
                                                                    >
                                                                        {singi} Hours
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="ry_label">
                                                                Session Duration
                                                            </label>
                                                            <select
                                                                name="session_duration"
                                                                className="form-control"
                                                                placeholder="Enter"
                                                                onChangeCapture={(e) => {
                                                                    UpdateUserFormData(
                                                                        e.target.name,
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            >
                                                                {SessDuration.map((singi) => (
                                                                    <option
                                                                        value={singi}
                                                                        key={singi}
                                                                    >
                                                                        {singi} Hours
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="ry_label">
                                                                Session Frequency
                                                            </label>
                                                            <select
                                                                name="session_duration"
                                                                className="form-control"
                                                                placeholder="Enter"
                                                                onChangeCapture={(e) => {
                                                                    UpdateUserFormData(
                                                                        e.target.name,
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            >
                                                                <option value="Daily">Daily</option>
                                                                <option value="Alternate Days">
                                                                    Alternate Days
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="clear_both"></div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="ry_label">
                                                                Upload Banner Images
                                                            </label>
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                name="banner"
                                                                className="form-control"
                                                                placeholder="Enter"
                                                                style={{ lineHeight: '18px' }}
                                                                onChangeCapture={(e) => {
                                                                    UpdateUserFormData(
                                                                        e.target.name,
                                                                        0
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="ry_label">
                                                                Upload Preview Video
                                                            </label>
                                                            <input
                                                                type="file"
                                                                accept="video/mp4"
                                                                name="pre_vid"
                                                                className="form-control"
                                                                placeholder="Enter"
                                                                style={{ lineHeight: '18px' }}
                                                                onChangeCapture={(e) => {
                                                                    UpdateUserFormData(
                                                                        e.target.name,
                                                                        0
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="ry_label">
                                                                Upload Test Video
                                                            </label>
                                                            <input
                                                                type="file"
                                                                accept="video/mp4"
                                                                name="test_vid"
                                                                className="form-control"
                                                                placeholder="Enter"
                                                                style={{ lineHeight: '18px' }}
                                                                onChangeCapture={(e) => {
                                                                    UpdateUserFormData(
                                                                        e.target.name,
                                                                        0
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="clear_both"></div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="ry_label">
                                                                Main Key Features
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="key_featu"
                                                                className="form-control"
                                                                placeholder="Enter"
                                                                style={{ lineHeight: '18px' }}
                                                                onChangeCapture={(e) => {
                                                                    UpdateUserFormData(
                                                                        e.target.name,
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="ry_label">
                                                                Meta Key Words
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="meta_keyword"
                                                                className="form-control"
                                                                placeholder="Enter"
                                                                style={{ lineHeight: '18px' }}
                                                                onChangeCapture={(e) => {
                                                                    UpdateUserFormData(
                                                                        e.target.name,
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="ry_label">
                                                                Meta Description
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="meta_description"
                                                                className="form-control"
                                                                placeholder="Enter"
                                                                style={{ lineHeight: '18px' }}
                                                                onChangeCapture={(e) => {
                                                                    UpdateUserFormData(
                                                                        e.target.name,
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="clear_both"></div>
                                                </div>

                                                <div
                                                    className="message mt-3"
                                                    style={{ color: Msgcolor }}
                                                >
                                                    {APIMessage ? <span>{APIMessage}</span> : ''}
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-4"></div>
                                                    <div className="col-md-4"></div>
                                                    <div className="col-md-4">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-docsta-instructor"
                                                            onClick={() => {
                                                                console.log('Submited');
                                                            }}
                                                            style={{ borderRadius: '12px' }}
                                                        >
                                                            Submit for Approval
                                                        </button>
                                                        <div className="instr_webi_help mt-3">
                                                            Help <i className="i1">?</i>
                                                            <a
                                                                href="https://api.whatsapp.com/send?phone=971503731520&text=Hello%20Need%20Help%20on%20the%20Course%20Creation%20on%20Docsta%20Webinar"
                                                                target="_WHATSAPP"
                                                            >
                                                                <img
                                                                    className="i2"
                                                                    src={`${IMGBASEAPIURL}favicon.ico`}
                                                                    alt=""
                                                                />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="clear_both"></div>
                                                </div>
                                            </div>

                                            <div style={{ display: AddiDet1Div }}>
                                                <h4 className="mb-2">Additional Details</h4>
                                                <div className="form-group">
                                                    <label className="ry_label">
                                                        1. What Language Is Your Course In?
                                                    </label>
                                                    <select
                                                        name="language"
                                                        className="form-control"
                                                    >
                                                        {RegFrmLangInstr.map((singi) => (
                                                            <option
                                                                value={Object.keys(singi)[0]}
                                                                key={Object.keys(singi)[0]}
                                                            >
                                                                {Object.values(singi)[0]}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label className="ry_label">
                                                        2. Which Form Of Teaching Have You Done
                                                        Before?
                                                    </label>
                                                    <select
                                                        name="teachdone"
                                                        className="form-control"
                                                    >
                                                        {RegFrmTeachDoneInstr.map((singi) => (
                                                            <option
                                                                value={Object.keys(singi)[0]}
                                                                key={Object.keys(singi)[0]}
                                                            >
                                                                {Object.values(singi)[0]}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label className="ry_label">
                                                        3. Where Do You Rate Yourself Regarding Your
                                                        Video-Making Skills?
                                                    </label>
                                                    <select
                                                        name="vidrating"
                                                        className="form-control"
                                                    >
                                                        {RegFrmRateVidMkInstr.map((singi) => (
                                                            <option
                                                                value={Object.keys(singi)[0]}
                                                                key={Object.keys(singi)[0]}
                                                            >
                                                                {Object.values(singi)[0]}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label className="ry_label">
                                                        4. Do You Have An Existing Audience Or
                                                        Students To Share Your Course With?
                                                    </label>
                                                    <select name="exiaudi" className="form-control">
                                                        {RegFrmExiAudiInstr.map((singi) => (
                                                            <option
                                                                value={Object.keys(singi)[0]}
                                                                key={Object.keys(singi)[0]}
                                                            >
                                                                {Object.values(singi)[0]}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label className="ry_label">
                                                        5. What Set Of Values Are You Hoping To Add
                                                        To Your Ideal Student's Life?
                                                    </label>
                                                    <select name="val2std" className="form-control">
                                                        {RegFrmValues2StdInstr.map((singi) => (
                                                            <option
                                                                value={Object.keys(singi)[0]}
                                                                key={Object.keys(singi)[0]}
                                                            >
                                                                {Object.values(singi)[0]}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="btn btn-docsta-instructor"
                                                    onClick={() => {
                                                        ShowFormDiv('addi2');
                                                    }}
                                                >
                                                    Proceed
                                                </button>
                                            </div>

                                            <div style={{ display: AddiDet2Div }}>
                                                <h4 className="mb-2">Additional Details</h4>
                                                <div className="form-group">
                                                    <label className="ry_label">
                                                        6. What Would You Like to Name Your Course?
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="namecourse"
                                                        className="form-control"
                                                        placeholder="Type Here/  Eg: Data Analyst, Learn Photoshow Etc."
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="ry_label">
                                                        7. Who are Your Target Students?
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="tarstd"
                                                        className="form-control"
                                                        placeholder="Type Here/  Eg: School Students, College Students Etc."
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="ry_label">
                                                        8. Are There Any Requirements or
                                                        Pre-Requisities For Taking Your Course?
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="prereq"
                                                        className="form-control"
                                                        placeholder="No/ If Yes Type Here"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="ry_label">
                                                        9. What Would You Like Our Help With?
                                                    </label>
                                                    <select
                                                        name="docstahelp"
                                                        className="form-control"
                                                    >
                                                        {RegFrmDocstaHelpInstr.map((singi) => (
                                                            <option
                                                                value={Object.keys(singi)[0]}
                                                                key={Object.keys(singi)[0]}
                                                            >
                                                                {Object.values(singi)[0]}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label className="ry_label">
                                                        10. How Much Of Your Course Is Currently
                                                        Ready?
                                                    </label>
                                                    <select
                                                        name="crsready"
                                                        className="form-control"
                                                    >
                                                        {RegFrmCrsComInstr.map((singi) => (
                                                            <option
                                                                value={Object.keys(singi)[0]}
                                                                key={Object.keys(singi)[0]}
                                                            >
                                                                {Object.values(singi)[0]}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <button
                                                    type="submit"
                                                    className="btn btn-docsta-instructor"
                                                    onClick={() => {
                                                        ShowFormDiv('confirm');
                                                    }}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-md-5 p-4 d-none text-center">
                                        <img
                                            src={`${IMGBASEAPIURL}instructor/InstrSignStep01.png`}
                                            alt="Docsta Learn Insinstructor Login"
                                            style={{ display: PerDetDiv }}
                                            className="step_1_img"
                                        />
                                        <img
                                            src={`${IMGBASEAPIURL}instructor/InstrSignStep02.png`}
                                            alt="Docsta Learn Insinstructor Login"
                                            style={{ display: AddiDet1Div }}
                                            className="step_2_img"
                                        />
                                        <img
                                            src={`${IMGBASEAPIURL}instructor/InstrSignStep03.png`}
                                            alt="Docsta Learn Insinstructor Login"
                                            style={{ display: AddiDet2Div }}
                                            className="step_3_img"
                                        />
                                    </div>
                                </div>
                                <p className="text-center mt-1 d-none">
                                    Already have an Account ?
                                    <br />
                                    <Link to="/instructor/login" className="mb-4  mt-3">
                                        <button type="submit" className="btn btn-info">
                                            Login as Instructor
                                        </button>
                                    </Link>
                                </p>
                            </div>
                        </div>

                        <div className="col-md-1"></div>
                    </div>
                </div>
            </>
            <Footer className="appie-footer-about-area" />
            <BackToTop />
        </>
    );
}

export default CreateWebinar;
