import React from 'react';
// import { Helmet } from 'react-helmet';
import useToggle from '../../../Hooks/useToggle';
import BackToTop from '../../BackToTop';
import Drawer from '../../Mobile/DrawerInstitute';
import Header from '../../Helper/HeaderInstitute';
import Footer from '../../Helper/FooterInstitute';
import AdvisoryBoardList from './AdvisoryBoardList';

function AdvisoryBoard() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <AdvisoryBoardList />
            <Footer />
            <BackToTop />
        </>
    );
}

export default AdvisoryBoard;
