import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import errorImg from '../../assets/images/error.jpg';

function Error() {
    const navigate = useNavigate();
    const goBack = (e) => {
        e.preventDefault();
        // history.goBack();
        navigate(-1);
    };
    return (
        <>
            <div className="appie-error-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-8">
                            <div className="appie-error-content">
                                <img src={errorImg} alt="Error 404" />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="appie-error-content">
                                <a onClick={(e) => goBack(e)} href="/" className="main-btn d-block">
                                    Go Back
                                </a>
                                <br />
                                <Link to="/" className="btn btn-success d-block">
                                    Take Me To Home Page
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Error;
