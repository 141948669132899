import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { logoutaLL } from '../../../../config';

function AdminProfileMenu({ forpage, isDrawer = 0 }) {
  // const history = useHistory();
  // const [drawer, drawerAction] = useToggle(false);
  // const [lfVisibility, setlfVisibility] = useState('block');
  const [MenuUsers, setMenuUsers] = useState(0);
  const [MenuMaster, setMenuMaster] = useState(0);
  const [MenuContent, setMenuContent] = useState(0);
  const [MenuRequest, setMenuRequest] = useState(0);
  const [MenuPages, setMenuPages] = useState(0);
  function toggleMenu(formenu, value) {
    setMenuUsers(0);
    setMenuMaster(0);
    setMenuContent(0);
    setMenuRequest(0);
    setMenuPages(0);
    switch (formenu) {
      case 'MenuDashboard':
        break;
      case 'MenuPages':
        setMenuPages(value);
        break;
      case 'MenuRequest':
        setMenuRequest(value);
        break;
      case 'MenuContent':
        setMenuContent(value);
        break;
      case 'MenuMaster':
        setMenuMaster(value);
        break;
      case 'MenuUsers':
      default:
        setMenuUsers(value);
        break;
    }
  }

  function closedrawer() {
    if (isDrawer === 1) {
      const get = document.getElementById('closedrawer');
      get.click();
    }
  }

  return (
    <>
      <div
        className="ry_instr_shadow pt-3 pb-3 p-3"
        forpage={forpage}
      >
        <ul className="offcanvas_main_menu">
          <li className="">
            <Link
              to="/admin-dashboard"
              className="ry_admin_menu"
              onClick={() => {
                toggleMenu('MenuDashboard', 1);
                closedrawer();
              }}
            >
              <i className="fal fa-star" /> Dashboard
            </Link>
          </li>

          <li className="menu-item-has-children ry_admin_menu">
            <span className="menu-expand">
              <i
                className="fa fa-angle-right"
                style={{ display: MenuUsers === 1 ? 'none' : 'block' }}
                onClick={() => {
                  toggleMenu('MenuUsers', 1);
                }}
              ></i>
              <i
                className="fa fa-angle-down"
                style={{ display: MenuUsers === 1 ? 'block' : 'none' }}
                onClick={() => {
                  toggleMenu('MenuUsers', 0);
                }}
              ></i>
            </span>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                toggleMenu('MenuUsers', 1);
              }}
            >
              <i className="fal fa-users" /> Users
            </a>
            <ul
              className="sub-menu mt-3"
              style={{ display: MenuUsers === 1 ? 'block' : 'none' }}
            >
              <li>
                <Link
                  to="/admin/page/admins-manage"
                  onClick={() => {
                    closedrawer();
                  }}
                >
                  <i className="fal fa-users" /> Admin
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/page/instructors-manage"
                  onClick={() => {
                    closedrawer();
                  }}
                >
                  <i className="fal fa-users" /> Instructor
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/page/students-manage"
                  onClick={() => {
                    closedrawer();
                  }}
                >
                  <i className="fal fa-users" /> Students
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/page/students-videoCourses"
                  onClick={() => {
                    closedrawer();
                  }}
                >
                  <i className="fal fa-users" /> Video Courses's Students
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/page/students-questionBanks"
                  onClick={() => {
                    closedrawer();
                  }}
                >
                  <i className="fal fa-users" /> Question Bank's Students
                </Link>
              </li>
            </ul>
          </li>

          <li className="menu-item-has-children ry_admin_menu">
            <span className="menu-expand">
              <i
                className="fa fa-angle-right"
                style={{ display: MenuContent === 1 ? 'none' : 'block' }}
                onClick={() => {
                  toggleMenu('MenuContent', 1);
                }}
              ></i>
              <i
                className="fa fa-angle-down"
                style={{ display: MenuContent === 1 ? 'block' : 'none' }}
                onClick={() => {
                  toggleMenu('MenuContent', 0);
                }}
              ></i>
            </span>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                toggleMenu('MenuContent', 1);
              }}
            >
              <i className="fal fa-users" /> Contents
            </a>
            <ul
              className="sub-menu mt-3"
              style={{ display: MenuContent === 1 ? 'block' : 'none' }}
            >
              <li>
                <Link
                  to="/admin/page/maincourse-manage"
                  onClick={() => {
                    closedrawer();
                  }}
                >
                  <i className="fal fa-books" /> Video Courses
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/page/questionBanks-manage"
                  onClick={() => {
                    closedrawer();
                  }}
                >
                  <i className="fal fa-question" /> Question Banks
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/page/subscription-manage"
                  onClick={() => {
                    closedrawer();
                  }}
                >
                  <i className="fal fa-truck" /> Clubbed Courses
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/page/myblog"
                  onClick={() => {
                    closedrawer();
                  }}
                >
                  <i className="fal fa-folder" /> Blogs
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/orders"
                  onClick={() => {
                    closedrawer();
                  }}
                >
                  <i className="fal fa-file-invoice" /> Orders
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/page/freetips-manage"
                  onClick={() => {
                    closedrawer();
                  }}
                >
                  <i className="fal fa-users" /> Free Tips
                </Link>
              </li>

              {/* <li>
                                <Link
                                    to="/admin/page/list-videoCourse"
                                    onClick={() => {
                                        closedrawer();
                                    }}
                                >
                                    <i className="fal fa-users" /> Video Courses
                                </Link>
                            </li> */}
              {/* <li>
                                <Link
                                    to="/admin/page/list-webinar"
                                    onClick={() => {
                                        closedrawer();
                                    }}
                                >
                                    <i className="fal fa-users" /> Webinars
                                </Link>
                            </li> */}
              {/* <li>
                                <Link
                                    to="/admin/page/list-QuiBank"
                                    onClick={() => {
                                        closedrawer();
                                    }}
                                >
                                    <i className="fal fa-users" /> Questions Banks
                                </Link>
                            </li> */}
              {/* <li>
                                <Link
                                    to="/admin/page/list-Questions"
                                    onClick={() => {
                                        closedrawer();
                                    }}
                                >
                                    <i className="fal fa-users" /> Questions
                                </Link>
                            </li> */}
            </ul>
          </li>

          <li className="menu-item-has-children ry_admin_menu">
            <span className="menu-expand">
              <i
                className="fa fa-angle-right"
                style={{ display: MenuRequest === 1 ? 'none' : 'block' }}
                onClick={() => {
                  toggleMenu('MenuRequest', 1);
                }}
              ></i>
              <i
                className="fa fa-angle-down"
                style={{ display: MenuRequest === 1 ? 'block' : 'none' }}
                onClick={() => {
                  toggleMenu('MenuRequest', 0);
                }}
              ></i>
            </span>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                toggleMenu('MenuRequest', 1);
              }}
            >
              <i className="fal fa-users" /> Request
            </a>
            <ul
              className="sub-menu mt-3"
              style={{ display: MenuRequest === 1 ? 'block' : 'none' }}
            >
              <li>
                <Link
                  to="/admin/page/email-subscribers"
                  onClick={() => {
                    closedrawer();
                  }}
                >
                  <i className="fal fa-envelope" /> Email Subscriber
                </Link>
              </li>
              {/* <li>
                                <Link
                                    to="/admin/page/courses-enquiries"
                                    onClick={() => {
                                        closedrawer();
                                    }}
                                >
                                    <i className="fal fa-users" /> Course Enquiry
                                </Link>
                            </li> */}
              {/* <li>
                                <Link
                                    to="/admin/page/request-individual"
                                    onClick={() => {
                                        closedrawer();
                                    }}
                                >
                                    <i className="fal fa-users" /> Individual
                                </Link>
                            </li> */}
              <li>
                <Link
                  to="/admin/page/request-team"
                  onClick={() => {
                    closedrawer();
                  }}
                >
                  <i className="fal fa-users" /> Team
                </Link>
              </li>
              {/* <li>
                                <Link
                                    to="/admin/page/blogs-manage"
                                    onClick={() => {
                                        closedrawer();
                                    }}
                                >
                                    <i className="fal fa-users" /> Corporate Learning
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/admin/page/freetips-manage"
                                    onClick={() => {
                                        closedrawer();
                                    }}
                                >
                                    <i className="fal fa-users" /> Career Counselling
                                </Link>
                            </li> */}
              <li>
                <Link
                  to="/admin/page/contact-enquiry"
                  onClick={() => {
                    closedrawer();
                  }}
                >
                  <i className="fal fa-users" /> Contact Enquiries
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/page/reported-bugs"
                  onClick={() => {
                    closedrawer();
                  }}
                >
                  <i className="fal fa-bug" /> Bug Reported
                </Link>
              </li>
            </ul>
          </li>

          <li className="menu-item-has-children ry_admin_menu">
            <span className="menu-expand">
              <i
                className="fa fa-angle-right"
                style={{ display: MenuMaster === 1 ? 'none' : 'block' }}
                onClick={() => {
                  toggleMenu('MenuMaster', 1);
                }}
              ></i>
              <i
                className="fa fa-angle-down"
                style={{ display: MenuMaster === 1 ? 'block' : 'none' }}
                onClick={() => {
                  toggleMenu('MenuMaster', 0);
                }}
              ></i>
            </span>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                toggleMenu('MenuMaster', 1);
              }}
            >
              <i className="fal fa-cog" /> Master Settings
            </a>
            <ul
              className="sub-menu mt-3"
              style={{ display: MenuMaster === 1 ? 'block' : 'none' }}
            >
              <li
                onClick={() => {
                  closedrawer();
                }}
              >
                <Link
                  to="/admin/page/manage-topmessage"
                  className=""
                >
                  <i className="fal fa-image" /> Top Message
                </Link>
              </li>
              <li
                onClick={() => {
                  closedrawer();
                }}
              >
                <Link
                  to="/admin/page/homeslider-manage"
                  className=""
                >
                  <i className="fal fa-image" /> Manage Slider
                </Link>
              </li>
              <li
                onClick={() => {
                  closedrawer();
                }}
              >
                <Link to="/admin/page/counselling-team">
                  <i className="fal fa-user" /> Counselling Team
                </Link>
              </li>
              <li
                onClick={() => {
                  closedrawer();
                }}
              >
                <Link to="/admin/page/advisory-board">
                  <i className="fal fa-user" /> Advisory Board
                </Link>
              </li>
              <li
                onClick={() => {
                  closedrawer();
                }}
              >
                <Link
                  to="/admin/page/categories-manage"
                  className=""
                >
                  <i className="fal fa-file" /> Courses Categories
                </Link>
              </li>
              <li
                onClick={() => {
                  closedrawer();
                }}
              >
                <Link
                  to="/admin/page/blogcategories-manage"
                  className=""
                >
                  <i className="fal fa-file" /> Blog Categories
                </Link>
              </li>
              <li
                onClick={() => {
                  closedrawer();
                }}
              >
                <Link
                  to="/admin/page/freetipscategory-manage"
                  className=""
                >
                  <i className="fal fa-file" /> Free Tips Categories
                </Link>
              </li>
              <li
                onClick={() => {
                  closedrawer();
                }}
              >
                <Link
                  to="/admin/page/testimonials-manage"
                  className=""
                >
                  <i className="fal fa-users" /> Testimonials
                </Link>
              </li>
              <li
                onClick={() => {
                  closedrawer();
                }}
              >
                <Link
                  to="/admin/page/faqs-manage"
                  className=""
                >
                  <i className="fal fa-image" /> Faqs
                </Link>
              </li>

              <li
                onClick={() => {
                  closedrawer();
                }}
              >
                <Link
                  to="/admin/page/homeslider-manage"
                  className=""
                >
                  <i className="fal fa-image" /> Home Page Slider
                </Link>
              </li>
              <li
                onClick={() => {
                  closedrawer();
                }}
              >
                <Link
                  to="/admin/page/gallery-manage"
                  className=""
                >
                  <i className="fal fa-image" /> Gallery
                </Link>
              </li>
              <li
                onClick={() => {
                  closedrawer();
                }}
              >
                <Link
                  to="/admin/page/accreditations-manage"
                  className=""
                >
                  <i className="fal fa-image" />
                  Accreditations & Affiliations
                </Link>
              </li>
              <li
                onClick={() => {
                  closedrawer();
                }}
              >
                <Link
                  to="/admin/page/renownedclients-manage"
                  className=""
                >
                  <i className="fal fa-image" />
                  Renowned Clients
                </Link>
              </li>
            </ul>
          </li>

          <li className="menu-item-has-children ry_admin_menu">
            <span className="menu-expand">
              <i
                className="fa fa-angle-right"
                style={{ display: MenuPages === 1 ? 'none' : 'block' }}
                onClick={() => {
                  toggleMenu('MenuPages', 1);
                }}
              ></i>
              <i
                className="fa fa-angle-down"
                style={{ display: MenuPages === 1 ? 'block' : 'none' }}
                onClick={() => {
                  toggleMenu('MenuPages', 0);
                }}
              ></i>
            </span>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                toggleMenu('MenuPages', 1);
              }}
            >
              <i className="fal fa-folder" /> Pages Settings
            </a>
            <ul
              className="sub-menu mt-3"
              style={{ display: MenuPages === 1 ? 'block' : 'none' }}
            >
              {/* <li
                                onClick={() => {
                                    closedrawer();
                                }}
                            >
                                <Link to="/admin/page/category-seo-settings">
                                    <i className="fal fa-file" /> Category Seo Settings
                                </Link>
                            </li>
                            <li
                                onClick={() => {
                                    closedrawer();
                                }}
                            >
                                <Link to="/admin/page/courses-seo-settings">
                                    <i className="fal fa-file" /> Course Seo Settings
                                </Link>
                            </li> */}
              {/* <li
                                onClick={() => {
                                    closedrawer();
                                }}
                            >
                                <Link to="/admin/page/seo-settings">
                                    <i className="fal fa-file" /> Seo Settings
                                </Link>
                            </li> */}
              <li
                onClick={() => {
                  closedrawer();
                }}
              >
                <Link to="/admin/page/set-term">
                  <i className="fal fa-file" /> Terms and Conditions
                </Link>
              </li>
              <li
                onClick={() => {
                  closedrawer();
                }}
              >
                <Link to="/admin/page/set-privacy">
                  <i className="fal fa-file" /> Privacy Policy
                </Link>
              </li>
              <li
                onClick={() => {
                  closedrawer();
                }}
              >
                <Link to="/admin/page/set-RefundAndCancellation">
                  <i className="fal fa-file" /> Refund and Cancellation
                </Link>
              </li>
            </ul>
          </li>

          <li className="">
            <Link
              to="/admin-dashboard"
              className="ry_admin_menu"
              onClick={() => {
                logoutaLL('admin');
              }}
            >
              <i className="fal text-danger fa-sign-out" /> Logout
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default AdminProfileMenu;
