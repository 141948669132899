import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { BASEAPIURL, UPLOADNODEURL } from '../../config';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

function FeaturedCategoriesSlider() {
    const IndexName = '_id';
    const [CatiData, setCatiData] = useState([]);
    const [FiltCategoryData, setFiltCategoryData] = useState([]);
    const sliderRef = useRef();
    const settings = {
        autoplay: false,
        arrows: true,
        dots: false,
        slidesToShow: 6,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
        ],
    };

    const FetchCatiData = async () => {
        try {
            const res = await fetch(`${BASEAPIURL}/admin/coursecategory/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    SortStatus: 1,
                    recordsPerPage: 100,
                    sortby: 'a2z',
                    parent: 'all',
                    featured: 1,
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                if (Object.keys(resJson.data).length > 0) {
                    setCatiData(resJson.data);
                }
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const FetchFilterData = async () => {
        try {
            const res = await fetch(`${BASEAPIURL}/courseQB/getFilterOptions`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    SortStatus: 1,
                    recordsPerPage: 100,
                    sortby: 'a2z',
                    // SortMode: TypeofCourse,
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                if (Object.keys(resJson.category).length > 0) {
                    setFiltCategoryData(resJson.category);
                }
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        FetchFilterData();
        FetchCatiData();
    }, []);

    function getCatLink(catData) {
        let returnValue = '';
        let page = '';
        let categotySort = '';
        if (catData.maincatid === 'skill') {
            page = 'courses/skills-based-courses';
        }
        if (catData.maincatid === 'exam') {
            page = 'courses/exam-oriented-courses';
        }
        if (catData.maincatid === 'qb') {
            page = 'questionBanks';
        }
        if (Number(catData.parent) === 0) {
            categotySort = `?category=${catData.catid}`;
        } else {
            categotySort = `?category=${catData.parent}&subcategory=${catData.catid}`;
        }
        returnValue = `/${page}${categotySort}`;
        return returnValue;
    }

    return (
        <>
            <section className="appie-features-area FeaturedCategoriesSlider pb-0">
                <div className="container">
                    <div className="appie-section-title text-center">
                        <h4
                            className="mt-4 mb-3 text-center"
                            style={{
                                borderBottom: '1px solid #7a3694',
                                display: 'inline-block',
                                paddingBottom: '5px',
                            }}
                        >
                            Featured Categories
                        </h4>
                        {/* <p>Enrich your knowledge with our empowering catalog</p> */}
                    </div>
                    <div className="appie-showcase-slider align-items-center">
                        <div className="CategoryChooseFilter">
                            <Slider ref={sliderRef} {...settings}>
                                {CatiData.map((cti) => (
                                    <div key={cti.catid} title={cti.title}>
                                        <div className="appie-features-content">
                                            <Link
                                                to={getCatLink(cti)}
                                                className="imgBox text-center"
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <label
                                                    htmlFor={`catiid${cti.catid}`}
                                                    className="mb-0"
                                                >
                                                    <img
                                                        src={`${UPLOADNODEURL}${cti.image}`}
                                                        alt={cti.image}
                                                    />
                                                    <div className="cti_titl">
                                                        <b style={{ color: '#000' }}>{cti.title}</b>
                                                        {FiltCategoryData.map((singi) => (
                                                            <span
                                                                key={`${singi[IndexName]}${cti.catid}`}
                                                                style={{
                                                                    display:
                                                                        singi[IndexName] !==
                                                                        cti.catid
                                                                            ? 'none'
                                                                            : 'none',
                                                                }}
                                                            >
                                                                ({singi.count} Courses)
                                                            </span>
                                                        ))}
                                                    </div>
                                                </label>
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FeaturedCategoriesSlider;
