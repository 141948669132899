import React, { useEffect, useState } from 'react';
import Routes from './Routes';
import TagManager from 'react-gtm-module';
import { ToastContainer } from 'react-toastify';
import SocialLoginDetails from './components/Students/SocialLoginDetails';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const tagManagerArgs = {
    gtmId: 'GTM-NFCGQM34',
  };
  const [updateData, setUpdateData] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(0);
  useEffect(() => {
    const token = localStorage.getItem('userdata');
    if (token) {
      const userdata = JSON.parse(token);
      if (userdata.mobile == '' || userdata.name == '') {
        setUpdateData(true);
      } else {
        setUpdateData(false);
      }
    } else {
      setUpdateData(false);
    }
  }, []);
  TagManager.initialize(tagManagerArgs);
  return (
    <>
      {updateData && <SocialLoginDetails />}
      <ToastContainer style={{ zIndex: 9999999999 }} />
      <Routes />
    </>
  );
}

export default App;
