import React, { useState, useRef, useEffect, Suspense } from 'react';
import Slider from 'react-slick';
// import { InlineShareButtons } from 'sharethis-reactjs';
import { useParams } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { IMGBASEAPIURL, BASEAPIURL, UPLOADNODEURL, CleanUrlWork, BASEURL } from '../../config';
import '../../assets/css/courselist.css';

const CourseListBlock = React.lazy(() => import('../Helper/CourseListBlock'));
const Sharethis = React.lazy(() => import('../Helper/Sharethis'));

function CoursesStarted({ SortMode }) {
    // for share this
    const [ShareThisURL, setShareThisURL] = useState(BASEURL);
    const [showShare, setshowShare] = useState(false);
    function closeshowShare() {
        setshowShare(false);
    }
    // for share this
    const { CatID } = useParams();
    // const [showShare, setshowShare] = useState(false);
    const [CourseCount, setCourseCount] = useState('none');
    const [CourseData, setCourseData] = useState([]);
    const [tab, setTab] = useState('popular');
    const handleClick = (e, value) => {
        e.preventDefault();
        setTab(value);
    };

    const sliderRef = useRef();
    const settings = {
        autoplay: false,
        arrows: true,
        dots: false,
        slidesToShow: 4,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    useEffect(() => {
        const FetchCatiCourseData = async () => {
            try {
                setCourseData([]);
                const res = await fetch(`${BASEAPIURL}/course/getAll`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        category: CatID,
                        recordsPerPage: 100,
                        status: 1,
                        SortMode,
                        sortby: SortMode === 'offline' ? 'popular' : 'highrated',
                    }),
                });
                const resJson = await res.json();
                if (res.status === 200) {
                    if (Object.keys(resJson.data).length > 0) {
                        setCourseData(resJson.data);
                        setCourseCount('none');
                    } else {
                        setCourseCount('block');
                    }
                } else {
                    console.log(resJson.message);
                }
            } catch (err) {
                console.log(err);
            }
        };
        FetchCatiCourseData();
    }, [CatID, SortMode]);
    return (
        <>
            {showShare && (
                <div>
                    <div className="mfp-bg mfp-ready"></div>
                    <div
                        className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
                        tabIndex="-1"
                        style={{ overflow: ' hidden auto' }}
                    >
                        <div className="mfp-container mfp-s-ready mfp-iframe-holder">
                            <div className="mfp-content" style={{ maxWidth: 'max-content' }}>
                                <div className="mfp-iframe-scaler-old">
                                    <button
                                        onClick={closeshowShare}
                                        title="Close (Esc)"
                                        type="button"
                                        className="mfp-close"
                                    >
                                        ×
                                    </button>
                                </div>
                                <div
                                    className="mfp-iframe p-4 text-center"
                                    style={{ background: '#fff' }}
                                >
                                    <Sharethis ShareThisURL={ShareThisURL} />
                                </div>
                            </div>
                            <div className="mfp-preloader">Loading...</div>
                        </div>
                    </div>
                </div>
            )}
            <section className="appie-features-area coursesStarted">
                <div className="container-fluid ">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title">
                                <h3>Courses to get you Started</h3>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: CourseCount }}>
                        <div className="alert alert-info text-center mt-5">
                            There is no courses on the Category, Please come back later or Browse
                            the Other Category from below.
                        </div>
                    </div>
                    <div className="row">
                        {CourseData.map((singi) => (
                            <div className="listin5crsstarted" key={singi.courseid}>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <CourseListBlock
                                        IMGBASEAPIURL={IMGBASEAPIURL}
                                        CleanUrlWork={CleanUrlWork}
                                        singi={singi}
                                        UPLOADNODEURL={UPLOADNODEURL}
                                        setShareThisURL={setShareThisURL}
                                        setshowShare={setshowShare}
                                        BASEURL={BASEURL}
                                    />
                                </Suspense>
                            </div>
                        ))}
                    </div>
                    <div className="row d-none">
                        <div className="col-lg-12">
                            <div className="appie-features-tabs-btn">
                                <div
                                    className="nav nav-pills mb-0"
                                    id="v-pills-tab"
                                    role="tablist"
                                    aria-orientation="vertical"
                                >
                                    <a
                                        onClick={(e) => handleClick(e, 'popular')}
                                        className={`nav-link ${tab === 'popular' ? 'active' : ''}`}
                                        id="v-pills-popular-tab"
                                        data-toggle="pill"
                                        href="#v-pills-popular"
                                        role="tab"
                                        aria-controls="v-pills-popular"
                                        aria-selected="true"
                                    >
                                        Popular Courses
                                    </a>
                                    <a
                                        onClick={(e) => handleClick(e, 'business')}
                                        className={`nav-link ${tab === 'business' ? 'active' : ''}`}
                                        id="v-pills-business-tab"
                                        data-toggle="pill"
                                        href="#v-pills-business"
                                        role="tab"
                                        aria-controls="v-pills-business"
                                        aria-selected="false"
                                    >
                                        Webinars
                                    </a>
                                    <a
                                        onClick={(e) => handleClick(e, 'it')}
                                        className={`nav-link ${tab === 'it' ? 'active' : ''}`}
                                        id="v-pills-it-tab"
                                        data-toggle="pill"
                                        href="#v-pills-it"
                                        role="tab"
                                        aria-controls="v-pills-it"
                                        aria-selected="false"
                                    >
                                        Question Bank
                                    </a>
                                    <a
                                        onClick={(e) => handleClick(e, 'software')}
                                        className={`nav-link ${tab === 'software' ? 'active' : ''}`}
                                        id="v-pills-software-tab"
                                        data-toggle="pill"
                                        href="#v-pills-software"
                                        role="tab"
                                        aria-controls="v-pills-software"
                                        aria-selected="false"
                                    >
                                        Live Classes
                                    </a>
                                    <a
                                        onClick={(e) => handleClick(e, 'market')}
                                        className={`nav-link ${tab === 'market' ? 'active' : ''}`}
                                        id="v-pills-market-tab"
                                        data-toggle="pill"
                                        href="#v-pills-market"
                                        role="tab"
                                        aria-controls="v-pills-market"
                                        aria-selected="false"
                                    >
                                        All
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <tabContent /> */}
                    <div className="row d-none">
                        <div className="col-lg-12">
                            <div className="tab-content" id="v-pills-tabContent">
                                {/* <tabPopular /> */}
                                <div
                                    className={`${tab === 'popular' ? 'show active' : ''}
                                    tab-pane fade`}
                                    id="v-pills-popular"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-popular-tab"
                                >
                                    <div className="row appie-showcase-slider">
                                        <div className="col-lg-12">
                                            <Slider ref={sliderRef} {...settings}>
                                                {/* <slide1 /> */}
                                                <div>
                                                    <div className="appie-features-content">
                                                        <div className="imgBox">
                                                            <img
                                                                src={`${IMGBASEAPIURL}institute/courses/img17.jpg`}
                                                                alt=""
                                                            />
                                                            <span>
                                                                <i className="fa fa-eye" /> 1388
                                                            </span>
                                                        </div>
                                                        <div className="contentBox">
                                                            <span>Beginner</span>
                                                            <h6>
                                                                <a href="/">MRCP Made Easy</a>
                                                            </h6>
                                                            <div className="instructorBox">
                                                                <img
                                                                    src={`${IMGBASEAPIURL}institute/instructor/img1.jpg`}
                                                                    alt=""
                                                                />
                                                                <a href="/">
                                                                    <b>Dr. Danish Salim</b>
                                                                </a>
                                                            </div>
                                                            <div className="ratingBox">
                                                                <i className="fas fa-star" />
                                                                <i className="fas fa-star" />
                                                                <i className="fas fa-star" />
                                                                <i className="fas fa-star-half-alt" />
                                                                <i className="far fa-star" />
                                                                <b>3.5/5.0</b>
                                                            </div>
                                                            <h3 className="rateBox">
                                                                $29 <i>$39</i>
                                                            </h3>
                                                            <div className="tagsBox">
                                                                <b>Best Seller</b>
                                                                <div className="shareBox">
                                                                    <a href="/" className="mr-10">
                                                                        <i className="far fa-heart" />
                                                                    </a>
                                                                    <a
                                                                        href="/"
                                                                        className="text-dark"
                                                                    >
                                                                        <i className="far fa-share-alt" />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="hLine">
                                                                <hr />
                                                            </div>
                                                            <div className="courseDetails">
                                                                <div className="durationBox">
                                                                    <i className="far fa-clock" />
                                                                    <b>5hr 30mins</b>
                                                                </div>
                                                                <div className="sessionsBox">
                                                                    <i className="far fa-play-circle" />
                                                                    <b>100 Sessions</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CoursesStarted;
