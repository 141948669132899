import React, { Suspense, useEffect } from 'react';
// import { Helmet } from 'react-helmet';
import useToggle from '../../../Hooks/useToggle';
import BackToTop from '../../BackToTop';
import Drawer from '../../Mobile/DrawerInstitute';
import Header from '../../Helper/HeaderInstitute';
import Footer from '../../Helper/FooterInstitute';

const AboutUs = React.lazy(() => import('./AboutUs'));
// const ApprovalsBox = React.lazy(() => import('../ApprovalsBox'));
const AdvisoryBoard = React.lazy(() => import('../AdvisoryBoard'));
const OurClientsBox = React.lazy(() => import('../OurClientsBox'));
// import AwardsStuff from './AwardsStuff';

function DocInstitute() {
    const [drawer, drawerAction] = useToggle(false);

    useEffect(() => {
        document.title = 'About Us';
    }, []);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <Suspense fallback={<div className="text-center">Loading...</div>}>
                <AboutUs />
            </Suspense>
            {/* <Suspense fallback={<div className="text-center">Loading...</div>}>
                <ApprovalsBox className="mt-5 mb-4" />
            </Suspense> */}
            <div style={{ background: '#f5f5f5' }}>
                <div className="container">
                    <Suspense fallback={<div className="text-center">Loading...</div>}>
                        <AdvisoryBoard />
                    </Suspense>
                </div>
            </div>
            <Suspense fallback={<div>Loading...</div>}>
                <OurClientsBox />
            </Suspense>
            {/* <AwardsStuff /> */}
            <Footer />
            <BackToTop />
        </>
    );
}

export default DocInstitute;
