import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  BASEAPIURL,
  UPLOADNODEURL,
  showfulldatetime,
} from '../../../../config';

function MyInvoices({ forpage }) {
  return (
    <>
      <div
        className="pb-3"
        forpage={forpage}
      >
        <div className="row">
          <div className="col-md-7">
            <div className="ry_instr_shadow p-3">
              <h6 className="std_header_on_page">
              </h6>
              <div style={{ clear: 'both' }}></div>
              <hr />
              <div className="row">
                <div className="col-md-4">Gender</div>
                <div className="col-md-8">
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">City</div>
                <div className="col-md-8">
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">Address</div>
                <div className="col-md-8">
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">Phone Number</div>
                <div className="col-md-8">
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">Email</div>
                <div className="col-md-8">
                </div>
              </div>
              <div className="text-center mt-2">
                <Link
                  to="/user/page/updateprofilesetting"
                  className="btn btn-secondary p-1 pl-4 pr-4 mt-4"
                >
                  Edit Profile
                </Link>
                <Link
                  to="/user/page/changePassword"
                  className="btn btn-danger ml-2 p-1 pl-4 pr-4 mt-4"
                >
                  Change Password
                </Link>
                <small className="text-info d-none">
                  You are logged in on
                </small>
              </div>
              <div className="text-left mt-4">
                <h6 className="std_header_on_page mt-4">Email Notifications</h6>
                <Link
                  to="/user/page/EmailNotificationSetting"
                  className="mt-2"
                >
                  Turn Promotional Email Notifications from Docsta Learn on or
                  off
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyInvoices;
