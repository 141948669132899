import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BASEAPIURL, BASEURL, UPLOADNODEURL } from '../../../../config';

function MyPurchasePage() {
    const [isLoded, SetisLoded] = useState(false);
    const [ShowType, SetShowType] = useState('MainCourse');
    const [FavCourse, SetFavCourse] = useState([]);
    const GerFavorites = async (targetType) => {
        try {
            const res = await fetch(`${BASEAPIURL}/student/PurchasedCourseList`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
                body: JSON.stringify({
                    targetType,
                    usertype: localStorage.getItem('usertype'),
                }),
            });
            const resJson = await res.json();
            SetisLoded(true);
            if (resJson.data) {
                switch (targetType) {
                    case 'MainCourse':
                        SetFavCourse(resJson.data);
                        break;
                    default:
                        break;
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        GerFavorites('MainCourse');
    }, []);
    return (
        <>
            {/* {ShowType} */}
            <div className="ry_instr_shadow p-4" style={{ display: isLoded ? '' : 'none' }}>
                <h4 className="std_header_on_page">My Purchase</h4>
                <div className="row">
                    <div className="col-md-12">
                        <div
                            className="col-md-12 mt-4"
                            style={{
                                backgroundColor: 'lightgrey',
                                borderRadius: '10px',
                            }}
                        >
                            <ul className="nav" style={{ text: 'black' }}>
                                <li className="col-md-1"></li>
                                <li className="nav-item col-md-2">
                                    <span
                                        className="nav-link"
                                        href="#"
                                        onClick={() => {
                                            SetShowType('MainCourse');
                                        }}
                                    >
                                        Courses
                                    </span>
                                </li>

                                <li className="nav-item col-md-2">
                                    <span
                                        className="nav-link"
                                        href="#"
                                        onClick={() => {
                                            SetShowType('webinar');
                                        }}
                                    >
                                        Webinars
                                    </span>
                                </li>

                                <li className="nav-item col-md-2">
                                    <span
                                        className="nav-link"
                                        href="#"
                                        onClick={() => {
                                            SetShowType('product');
                                        }}
                                    >
                                        Products
                                    </span>
                                </li>
                                <li className="col-md-1"></li>
                            </ul>
                        </div>
                        {/* For Favarate product */}
                        <div style={{ display: ShowType === 'product' ? '' : 'none' }}>
                            <div
                                className="col-md-12 std_header_on_page"
                                style={{
                                    backgroundColor: '#f0eeee',
                                    marginTop: '50px',
                                    borderRadius: '5px',
                                    padding: '20px',
                                    textAlign: 'center',
                                    // display: FavCourse && FavCourse.length === 0 ? '' : 'none',
                                }}
                            >
                                <p>No Products Bought yet.</p>
                            </div>
                        </div>
                        {/* For Favarate product */}

                        {/* For Favarate webinar */}
                        <div style={{ display: ShowType === 'webinar' ? '' : 'none' }}>
                            <div
                                className="col-md-12 std_header_on_page"
                                style={{
                                    backgroundColor: '#f0eeee',
                                    marginTop: '50px',
                                    borderRadius: '5px',
                                    padding: '20px',
                                    textAlign: 'center',
                                    // display: FavCourse && FavCourse.length === 0 ? '' : 'none',
                                }}
                            >
                                <p>No Webinars Bought yet.</p>
                                <br />
                                <Link
                                    to="/webinars"
                                    target="_BLANK"
                                    className="btn btn-primary dsghk"
                                >
                                    Browse Webinars now
                                </Link>
                            </div>
                        </div>
                        {/* For Favarate webinar */}

                        {/* For Favarate course */}
                        <div style={{ display: ShowType === 'MainCourse' ? '' : 'none' }}>
                            <div
                                className="col-md-12 std_header_on_page"
                                style={{
                                    backgroundColor: '#f0eeee',
                                    marginTop: '50px',
                                    borderRadius: '5px',
                                    padding: '20px',
                                    textAlign: 'center',
                                    display: FavCourse && FavCourse.length === 0 ? '' : 'none',
                                }}
                            >
                                <p>No Courses Bought yet.</p>
                                <br />
                                <Link
                                    to="/courses-all"
                                    target="_BLANK"
                                    className="btn btn-primary dsghk"
                                >
                                    Browse Courses now
                                </Link>
                            </div>

                            <div className="mt-3">
                                <div className="row">
                                    {FavCourse.map((s) => (
                                        <div className="col-md-4" key={s.image}>
                                            <div
                                                className="text-center"
                                                style={{
                                                    border: '1px solid #f5f5f5',
                                                    background: '#fff',
                                                    marginBottom: '10px',
                                                    paddingBottom: '10px',
                                                }}
                                            >
                                                <a
                                                    href={`${BASEURL}courseStudent/${s.slug}`}
                                                    target="_MainCourse"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        src={`${UPLOADNODEURL}${s.image}`}
                                                        alt=""
                                                        style={{
                                                            objectFit: 'cover',
                                                            height: '180px',
                                                            width: '100%',
                                                        }}
                                                    />
                                                    <b title={s.title} className="CLB_course_title">
                                                        {s.title}
                                                    </b>
                                                </a>
                                                <a
                                                    href={`${BASEURL}courseStudent/${s.slug}`}
                                                    target="_MainCourse"
                                                    rel="noreferrer"
                                                    className="btn text-left p-0 pl-2 pr-2 buynow-btn"
                                                    style={{ borderRadius: '0px' }}
                                                >
                                                    Resume Course
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {/* For Favarate course */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MyPurchasePage;
