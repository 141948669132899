import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { IMGBASEAPIURL, logoutaLL } from '../../config';
import StickyMenu from '../../lib/StickyMenu';

function AdminHeader({ action }) {
  const UserName = localStorage.getItem('username');
  useEffect(() => {
    document.title = 'Admin Panel';
    StickyMenu();
  }, []);
  return (
    <header className="appie-header-area appie-sticky">
      <div
        className="headSection"
        style={{ background: '#570078' }}
      >
        <div className="container-fluid">
          <div className="header-nav-box">
            <div className="row align-items-center">
              <div className="col-lg-2 col-md-6 col-12">
                <div className="appie-logo-box">
                  <Link to="/institute">
                    <img
                      src={`${IMGBASEAPIURL}logo/logo-learn.png`}
                      alt="Docsta Learn"
                      style={{ filter: 'brightness(0) invert(1)' }}
                    />
                  </Link>
                  <div
                    onClick={(e) => action(e)}
                    className="toggle-btn ml-30 canvas_open d-lg-none d-block"
                  >
                    <i className="fa fa-bars" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="appie-search-box d-none">
                  <span>Categories</span>
                  <form action="#">
                    <div className="input-box">
                      <input
                        type="text"
                        placeholder="Search anything"
                      />
                      <button type="button">
                        <i className="fal fa-search" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="appie-btn-box">
                  <div>
                    {UserName !== null ? (
                      <span className="text-dark">
                        <small className="text-danger text-white">
                          Admin Panel
                        </small>
                        <br />
                        <a
                          href="/"
                          className="text-white"
                          onClick={(e) => {
                            e.preventDefault();
                            logoutaLL('admin');
                          }}
                        >
                          &nbsp;(Logout)
                        </a>
                      </span>
                    ) : (
                      <>
                        <Link
                          to="/"
                          className="signup-btn"
                        >
                          Front Panel
                        </Link>
                      </>
                    )}
                  </div>
                  <div className="appie-icon-box">
                    <a href="/">
                      <i className="fal fa-bell" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default AdminHeader;
