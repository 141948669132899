import React, { useEffect } from 'react';
import { BASEURL } from '../../config';

function questionBanksRed() {
    useEffect(() => {
        window.location.href = `${BASEURL}question-bank`;
    }, []);

    return <></>;
}

export default questionBanksRed;
