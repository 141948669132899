import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import axios from 'axios';
import parse from 'html-react-parser';
import { BASEAPIURL, UPLOADNODEURL } from '../../../../config';
import Editor from '../../../htmleditor/EditorTinyMCE';
import { ValidURLSlug } from '../../../../MasterRegEmp';

// showdatetimein

function CreateBlog({ ForMainID, FetchAdmins, setForMainID }) {
    const [Category, setCategory] = useState({
        blogid: 0,
        title: '',
        slug: '',
        metadescription: '',
        description: '',
        image: '',
    });
    const HandleForm = (name, value) => {
        setCategory({ ...Category, [name]: value });
    };
    // for the Editors
    const [AlertMsg, setAlertMsg] = useState('');
    const [BlogCategory, setBlogCategory] = useState([]);
    const [PageContent, setPageContent] = useState('');
    // for the Editors

    const UpdateStatus = async (e) => {
        e.preventDefault();
        try {
            const res = await fetch(`${BASEAPIURL}/admin/blog/create`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(Category),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                if (resJson.status === 1) {
                    FetchAdmins();
                    setForMainID(0);
                    setAlertMsg(`<div class='alert alert-succerss'>${resJson.message}</div>`);
                } else {
                    setAlertMsg(`<div class='alert alert-danger'>${resJson.message}</div>`);
                }
                console.log(resJson);
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    // for file uploade will be here
    async function uploadFile(e, file, fileName) {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', fileName);
        const url = `${BASEAPIURL}/admin/instructure/uploadcv`;
        try {
            const res = await axios.post(url, formData);
            console.log(res);
            HandleForm('image', res.data.filename);
        } catch (ex) {
            console.log(ex);
        }
    }

    // for file uploade will be here
    const FetchBlogCategory = async () => {
        try {
            const res = await fetch(`${BASEAPIURL}/admin/blogcategory/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    recordsPerPage: 100,
                    SortStatus: 1,
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                setBlogCategory(resJson.data);
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        const FetchCategoryDetails = async () => {
            try {
                const res = await fetch(`${BASEAPIURL}/admin/blog/GetBlogDetail`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        blogid: ForMainID,
                    }),
                });
                const resJson = await res.json();
                if (res.status === 200) {
                    if (Object.keys(resJson.data).length > 0) {
                        setCategory(resJson.data);
                        setPageContent(resJson.data.description);
                    }
                } else {
                    console.log(resJson.message);
                }
            } catch (err) {
                console.log(err);
            }
        };
        if (ForMainID !== 'NEW') {
            FetchCategoryDetails();
        }
        FetchBlogCategory();
        // document.getElementById('blog_filter_frm').submit();
    }, [ForMainID]);

    return (
        <>
            <div className="ry_instr_shadow p-4 mb-4" forpage="CreateCategory">
                <h4>{ForMainID === 'NEW' ? 'Create ' : 'Update '} Blog</h4>
                <form
                    id="blog_filter_frm"
                    className="row mt-3"
                    onSubmit={(e) => {
                        UpdateStatus(e);
                    }}
                >
                    <div className="col-md-8">
                        <div className="form-group">
                            <b>Category</b>
                            <select
                                required
                                name="category"
                                className="form-control"
                                value={Category.category}
                                onChangeCapture={(e) => {
                                    HandleForm(e.target.name, e.target.value);
                                }}
                            >
                                <option value="">Select Blog Category</option>
                                {BlogCategory.map((singoCati) => (
                                    <option key={singoCati.blogcatid} value={singoCati.blogcatid}>
                                        {singoCati.title}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="form-group">
                            <b>Title</b>
                            <input
                                required
                                name="title"
                                type="text"
                                className="form-control"
                                value={Category.title}
                                placeholder="Title"
                                onChange={(e) => {
                                    HandleForm(e.target.name, e.target.value);
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <b>Slug</b>
                            <input
                                required
                                name="slug"
                                type="text"
                                className="form-control"
                                value={Category.slug}
                                placeholder="slug"
                                onFocus={(e) => {
                                    if (e.target.value.trim() === '') {
                                        HandleForm(
                                            e.target.name,
                                            Category.title.replace(ValidURLSlug, '-')
                                        );
                                    }
                                    HandleForm(
                                        e.target.name,
                                        e.target.value.replace(ValidURLSlug, '-')
                                    );
                                }}
                                onChange={(e) => {
                                    HandleForm(
                                        e.target.name,
                                        e.target.value.replace(ValidURLSlug, '-')
                                    );
                                }}
                            />
                        </div>

                        <div className="form-group">
                            <b>Short Description</b>
                            <input
                                required
                                name="metadescription"
                                type="text"
                                className="form-control"
                                value={Category.metadescription}
                                placeholder="Used for SEO and List Category Page"
                                onChange={(e) => {
                                    HandleForm(e.target.name, e.target.value);
                                }}
                            />
                        </div>
                        <b>Description</b>
                        <Editor
                            PageContent={PageContent}
                            setPageContent={setPageContent}
                            HandleForm={HandleForm}
                            ForAttribute="description"
                        />
                    </div>
                    <div className="col-md-4">
                        <b>Thumbnail</b>
                        <br />
                        <img src={`${UPLOADNODEURL}${Category.image}`} alt={Category.title} />
                        <input
                            accept="image/*"
                            /* eslint-disable no-unneeded-ternary */
                            required={ForMainID === 'NEW' ? true : false}
                            /* eslint-enable no-unneeded-ternary */
                            type="file"
                            name="image"
                            onChange={(e) => {
                                uploadFile(e, e.target.files[0], e.target.files[0].name);
                            }}
                        />
                    </div>
                    <div className="col-md-12">{parse(AlertMsg)}</div>
                    <div className="col-md-12 mt-4 text-center">
                        <button
                            className="btn btn-outline-danger mr-3"
                            type="button"
                            onClick={() => {
                                setForMainID(0);
                            }}
                            style={{ borderRadius: '10px' }}
                        >
                            Cancel
                        </button>

                        <button
                            className="btn btn-success"
                            type="submit"
                            style={{ borderRadius: '10px' }}
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
}

export default CreateBlog;
