import React from 'react';
import HeaderInstitute from './HeaderInstitute';

function Header({ action, type, pageName, HideBottomMenu }) {
    return (
        <>
            <HeaderInstitute
                action={action}
                type={type}
                pageName={pageName}
                HideBottomMenu={HideBottomMenu}
            />
        </>
    );
}

export default Header;
