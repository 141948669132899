import React from 'react';
import { IMGBASEAPIURL } from '../../../config';

function CategorySingle({ className }) {
    return (
        <>
            <section className={`course-page categoryDetail ${className || ''}`}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="secHead">
                                <h1>First Aid - Lorem Ipsum Dolor</h1>
                                <div className="ratingBox">
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star-half-alt" />
                                    <i className="far fa-star" />
                                    <b>3.5/5.0</b>
                                </div>
                                <p>
                                    Ace national and international professional exams with our
                                    extensive subjected question banks
                                </p>
                                <div className="detail">
                                    <span>
                                        <i className="fas fa-file-video" />
                                        120 Courses
                                    </span>
                                    <span>
                                        <i className="fas fa-graduation-cap" />
                                        25K+ Learners
                                    </span>
                                    <span>
                                        <i className="fas fa-chalkboard-teacher" />
                                        32.5K+ Followers
                                    </span>
                                    <span>
                                        <i className="fas fa-badge-check" />
                                        1,500 Reviews
                                    </span>
                                </div>
                                <div className="btnBox">
                                    <a href="/#" className="btnTwo">
                                        Subscribe
                                    </a>
                                    <a href="/#" className="btnShare">
                                        <i className="far fa-share-alt" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-hero-thumb">
                                <div>
                                    <img
                                        src={`${IMGBASEAPIURL}institute/category/category-single/bg-img1.jpg`}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CategorySingle;
