import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASEAPIURL, BASEURL, UPLOADNODEURL } from '../../../../config';
import './createQB.css';
import * as XLSX from 'xlsx';

const PrimaryIndex = '_id';

function Question2Courses({
    handleForm,
    EditQuestionSession,
    SessionQuestions,
    SessionAnswers,
    setSessionQuestions,
    setSessionAnswers,
    SessionTitle,
    setForm,
    regformdata,
}) {
    const [Running, setRunning] = useState(1);
    const reader = new FileReader();
    const [EditQuestionInd, setEditQuestionInd] = useState('');
    const [UploadType, SetUploadType] = useState('manual');
    const [EditQuestionNo, setEditQuestionNo] = useState(1);
    const [QuestionsList, setQuestionsList] = useState(SessionQuestions);
    const handleAddQuestionsList = (Index, module) => {
        setQuestionsList((temp) => [
            ...temp,
            {
                Index,
                question: '',
                status: 1,
                module,
                questionImage: '',
                level: '',
                explanationAnswer: '',
                explanationImage: '',
                answers: [],
            },
        ]);
    };

    function nextQuestionIndex() {
        let maxIndex = 1;
        QuestionsList.forEach((item) => {
            if (item.Index > maxIndex) {
              maxIndex = item.Index;
            }
          });
        return maxIndex;
    }

    function nextAnswerIndex() {
        let maxIndex = 1;
        AnswersList.forEach((item) => {
            if (item.Index > maxIndex) {
              maxIndex = item.Index;
            }
          });
        return maxIndex;
    }

    const handleEditQuestionsList = (changeId, ind, val) => {
        QuestionsList[changeId][ind] = val;
    };
    const handleRemoveQuestionsList = (ind) => {
        if (ind !== 0) {
            setQuestionsList(QuestionsList.filter((item) => Number(item.Index) !== Number(ind)));
        }
    };
    const RefreshQuestionsList = () => {
        const temp = 999999;
        handleAddQuestionsList(temp, 0);
        handleRemoveQuestionsList(temp);
        handleForm('SessionQuestions', QuestionsList);
        setSessionQuestions(QuestionsList);
    };
    // for questions
    // for Answers
    const [AnswersList, setAnswersList] = useState(SessionAnswers);
    const handleAddAnswersList = (Index) => {
        setAnswersList((temp) => [
            ...temp,
            {
                Index,
                status: 1,
                module: EditQuestionSession,
                QuestionIndex: EditQuestionNo,
                answer: '',
                isCorrect: 0,
            },
        ]);
    };
    const handleEditAnswersList = (changeId, ind, val) => {
        AnswersList[changeId][ind] = val;
    };
    const handleRemoveAnswersList = (ind) => {
        if (ind !== 1) {
            setAnswersList(AnswersList.filter((item) => item.Index !== ind));
        }
    };
    const RefreshAnswersList = () => {
        const temp = 999999;
        handleAddAnswersList(temp);
        handleRemoveAnswersList(temp);
        handleForm('SessionAnswers', AnswersList);
        setSessionAnswers(AnswersList);
    };
    // for Amswers
    // for Multiple Question List

    // for the File Uploads will be here
    async function uploadFile(file, fileName, index) {
        // e.preventDefault();
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', fileName);
        const url = `${BASEAPIURL}/admin/instructure/uploadcv`;
        try {
            const res = await axios.post(url, formData);
            handleEditQuestionsList(EditQuestionInd, index, res.data.filename);
        } catch (ex) {
            console.log(ex);
        }
    }
    const saveFile = (e, indexName) => {
        if (e.target.files && e.target.files.length > 0) {
            uploadFile(e.target.files[0], e.target.files[0].name, indexName);
        }
    };
    // for the File Uploads will be here

    // Process the CSV File here
    const [csvmsq, setcsvmsq] = useState('');

    const ProcessExcel = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const fileName = file.name;
            if (fileName.match(/\.xlsx$/) || fileName.match(/\.xls$/)) {
                // actual Read file will be here
                setcsvmsq('Reading the Excel File.');
                reader.onload = (event) => {
                    try {
                      const data = event.target.result;
                      const workbook = XLSX.read(data, { type: 'binary' });
                      const sheetName = workbook.SheetNames[0]; // Assuming the data is in the first sheet
                      const sheet = workbook.Sheets[sheetName];
                      const CSVData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                      
                      let QuestionCSVData = [];
                      let AnswerCSVData = [];
                      let t = nextQuestionIndex();
                      let tAns = nextAnswerIndex();

                      if (CSVData.length > 0) {
                            CSVData.forEach((item, i) => {
                                if (i > 0 && item[1] && item[1].trim() !== "") {
                                    // console.log(item);
                                    t = t + 1;
                                    QuestionCSVData.push({
                                        Index: t,
                                        question: item[1],
                                        srno: item[0],
                                        status: 1,
                                        module: EditQuestionSession,
                                        questionImage: '',
                                        level: '',
                                        explanationAnswer: item[10],
                                        explanationImage: '',
                                        answers: [],
                                    });
                                    let tempOption = {};
                                    if (item[2] && item[2] !== '') {
                                        tAns = tAns + 1;
                                        tempOption = {
                                            Index: tAns,
                                            module: EditQuestionSession,
                                            QuestionIndex: t,
                                            status: 1,
                                            answer: item[2],
                                            isCorrect: Number(item[6]),
                                        };
                                        AnswerCSVData.push(tempOption);  
                                    }
                                    if (item[3] && item[3] !== '') {
                                        tAns = tAns + 1;
                                        tempOption = {
                                            Index: tAns,
                                            module: EditQuestionSession,
                                            QuestionIndex: t,
                                            status: 1,
                                            answer: item[3],
                                            isCorrect: Number(item[7]),
                                        };
                                        AnswerCSVData.push(tempOption);  
                                    }
                                    if (item[4] && item[4] !== '') {
                                        tAns = tAns + 1;
                                        tempOption = {
                                            Index: tAns,
                                            status: 1,
                                            module: EditQuestionSession,
                                            QuestionIndex: t,
                                            answer: item[4],
                                            isCorrect: Number(item[8]),
                                        };
                                        AnswerCSVData.push(tempOption);  
                                    }
                                    if (item[5] && item[5] !== '') {
                                        tAns = tAns + 1;
                                        tempOption = {
                                            Index: tAns,
                                            status: 1,
                                            module: EditQuestionSession,
                                            QuestionIndex: t,
                                            answer: item[5],
                                            isCorrect: Number(item[9]),
                                        };
                                        AnswerCSVData.push(tempOption);  
                                    }
                                }
                            });
                            setRunning(Running + 1);
                            QuestionCSVData = QuestionsList.concat(QuestionCSVData);
                            AnswerCSVData = AnswersList.concat(AnswerCSVData);
                            console.log(QuestionCSVData);
                            setQuestionsList(QuestionCSVData);
                            setSessionQuestions(QuestionCSVData);
                            setAnswersList(AnswerCSVData);
                            setSessionAnswers(AnswerCSVData);
                            setForm({...regformdata, SessionQuestions: QuestionCSVData, AnswersList: AnswerCSVData});
                            setTimeout(() => {
                                SetUploadType('manual');
                                setcsvmsq("");
                            }, 786);
                        }

                      // Do something with the parsed data
                    } catch (error) {
                        setcsvmsq('Error reading the Excel file, Check console log and send it to Developer.');
                        console.error(error);
                    }
                  };
                  reader.readAsBinaryString(file);
                // actual Read file will be here
            } else {
                setcsvmsq('Please select a Excel file only.');
                e.target.value = null;
            }
        }
    };

    // const ProcessCSV = async (e) => {
    //     if (e.target.files && e.target.files.length > 0) {
    //         const file = e.target.files[0];
    //         const fileName = file.name;
    //         if (!fileName.match(/\.csv$/)) {
    //             setcsvmsq('Please select a CSV file only.');
    //             e.target.value = null;
    //         } else {
    //             // actual Read file will be here
    //             try {
    //                 // 1. create url from the file
    //                 const fileUrl = URL.createObjectURL(file);
            
    //                 // 2. use fetch API to read the file
    //                 const response = await fetch(fileUrl);
            
    //                 // 3. get the text from the response
    //                 const text = await response.text();
            
    //                 // 4. split the text by newline
    //                 const lines = text.split("\n");
            
    //                 // 5. map through all the lines and split each line by comma.
    //                 const CSVData = lines.map((line) => line.split(","));
    //                 let QuestionCSVData = [];
    //                 let AnswerCSVData = [];
    //                 // let t = Number(QuestionsList.length); t = QuestionsList[t - 1] ? QuestionsList[t - 1].Index : 0;
    //                 let t = nextQuestionIndex();
    //                 let tAns = nextAnswerIndex();
    //                 if (CSVData.length > 0) {
    //                     CSVData.forEach((item, i) => {
    //                         if (i > 0 && item[1] && item[1].trim() !== "") {
    //                             // console.log(item);
    //                             t = t + 1;
    //                             QuestionCSVData.push({
    //                                 Index: t,
    //                                 question: item[1],
    //                                 srno: item[0],
    //                                 status: 1,
    //                                 module: EditQuestionSession,
    //                                 questionImage: '',
    //                                 level: '',
    //                                 explanationAnswer: item[10],
    //                                 explanationImage: '',
    //                                 answers: [],
    //                             });
    //                             let tempOption = {};
    //                             if (item[2] && item[2].trim() !== '') {
    //                                 tAns = tAns + 1;
    //                                 tempOption = {
    //                                     Index: tAns,
    //                                     module: EditQuestionSession,
    //                                     QuestionIndex: t,
    //                                     status: 1,
    //                                     answer: item[2],
    //                                     isCorrect: Number(item[6]),
    //                                 };
    //                                 AnswerCSVData.push(tempOption);  
    //                             }
    //                             if (item[3] && item[3].trim() !== '') {
    //                                 tAns = tAns + 1;
    //                                 tempOption = {
    //                                     Index: tAns,
    //                                     module: EditQuestionSession,
    //                                     QuestionIndex: t,
    //                                     status: 1,
    //                                     answer: item[3],
    //                                     isCorrect: Number(item[7]),
    //                                 };
    //                                 AnswerCSVData.push(tempOption);  
    //                             }
    //                             if (item[4] && item[4].trim() !== '') {
    //                                 tAns = tAns + 1;
    //                                 tempOption = {
    //                                     Index: tAns,
    //                                     status: 1,
    //                                     module: EditQuestionSession,
    //                                     QuestionIndex: t,
    //                                     answer: item[4],
    //                                     isCorrect: Number(item[8]),
    //                                 };
    //                                 AnswerCSVData.push(tempOption);  
    //                             }
    //                             if (item[5] && item[5].trim() !== '') {
    //                                 tAns = tAns + 1;
    //                                 tempOption = {
    //                                     Index: tAns,
    //                                     status: 1,
    //                                     module: EditQuestionSession,
    //                                     QuestionIndex: t,
    //                                     answer: item[5],
    //                                     isCorrect: Number(item[9]),
    //                                 };
    //                                 AnswerCSVData.push(tempOption);  
    //                             }
    //                         }
    //                     });
    //                     setRunning(Running + 1);
    //                     QuestionCSVData = QuestionsList.concat(QuestionCSVData);
    //                     AnswerCSVData = AnswersList.concat(AnswerCSVData);
    //                     console.log(QuestionCSVData);
    //                     setQuestionsList(QuestionCSVData);
    //                     setSessionQuestions(QuestionCSVData);
    //                     setAnswersList(AnswerCSVData);
    //                     setSessionAnswers(AnswerCSVData);
    //                     setForm({...regformdata, SessionQuestions: QuestionCSVData, AnswersList: AnswerCSVData});
    //                     setTimeout(() => {
    //                         SetUploadType('manual');
    //                         setcsvmsq("");
    //                     }, 786);
    //                 }
    //               } catch (error) {
    //                 console.error(error);
    //               }
    //             // actual Read file will be here
    //             setcsvmsq("Reading the CSV File, Please wait...");
    //         }
    //     } else {
    //         setcsvmsq("Unable to Read the CSV FIle, Please try again.");
    //     }
    // };
    // Process the CSV File Here

    useEffect(() => {
        // setAnswersList(Answers);
        // console.log('#########');
        // console.log(SessionQuestions);
        // console.log(SessionAnswers);
        // console.log('#########');
        // SetUploadType(0);
        // console.log(EditQuestionSession);
    }, [SessionQuestions, SessionAnswers]);

    function returnValue(EditQuestionInd, attribute) {
        return QuestionsList && QuestionsList[EditQuestionInd] && QuestionsList[EditQuestionInd][attribute] ? QuestionsList[EditQuestionInd][attribute] : ''
    }

    return (
        <div style={{ minWidth: '300px' }}>
            {/* {csvData.map((s) => )} */}
            <div className="p-3">
                <button
                    type="button"
                    className={UploadType === 'manual' ? 'btn btn-sm btn-success mr-3' : 'btn btn-sm btn-outline-success mr-3'}
                    onClick={() => {
                        SetUploadType('manual');
                    }}
                >
                    Typing Here
                </button>
                <button
                    type="button"
                    className={UploadType === 'csv' ? 'btn btn-sm btn-success' : 'btn btn-sm btn-outline-success'}
                    onClick={() => {
                        SetUploadType('csv');
                    }}
                >
                    Excel Upload
                </button>
            </div>
            <div className="QBCSVUpload ml-3 mr-3 mb-3 p-3" style={{ display: UploadType === 'csv' ? '' : 'none' }}>
                <h6 className="text-dark mt-2 uploadCSVQB">
                    <b>Step 01:</b> Please Download the Template for Import from the below Button:
                    <br />
                    <a className="btn btn-purple btn-sm mt-3 uploadCSVQB" href={`${BASEURL}assets/qb/importQuestions.xlsx`} download>
                        Download Template
                    </a>
                </h6>
                <h6 className="text-dark mt-4 uploadCSVQB">
                    <b>Step 01:</b> Fill the Template File Correclty and save the the File as Excel and Import on Step 03.
                </h6>
                <h6 className="text-dark mt-4 uploadCSVQB">
                    <b>Step 03:</b>
                    <div className="form-group mt-3">
                        <input
                            type="file"
                            className="form-control"
                            // accept=".csv"
                            accept=".xlsx, .xls"
                            style={{ lineHeight: '100%' }}
                            onChange={(e) => {
                                setcsvmsq('');
                                ProcessExcel(e);
                            }}
                            // onSubmit={(e) => {
                            //     ProcessCSV(e);
                            // }}
                        />
                        <div className="hideempty text-danger">{csvmsq}</div>
                    </div>
                </h6>
            </div>
            <div className="QBManualUpload ml-3 mr-3 mb-3"  style={{ display: UploadType === 'manual' ? '' : 'none' }}>
                {EditQuestionInd !== '' ? (
                    <>
                        <h4
                            className="d-none"
                            style={{
                                background: '#152569',
                                color: '#f5f5f5',
                                padding: '10px 20px',
                            }}
                        >
                            Question
                        </h4>
                        <div
                            className="form-group"
                            style={{
                                border: '1px solid #152569',
                                padding: '20px',
                                background: '#ededed',
                            }}
                        >
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-md-1">
                                        <div className="form-group">
                                            <label>Sr. No.</label>
                                            <input
                                                type="text"
                                                name="srno"
                                                className="form-control"
                                                placeholder="Enter Question Sr. No."
                                                value={returnValue(EditQuestionInd, 'srno')}
                                                onChange={(e) => {
                                                    handleEditQuestionsList(
                                                        EditQuestionInd,
                                                        'srno',
                                                        e.target.value
                                                    );
                                                    RefreshQuestionsList(e);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <label>Question</label>
                                        <textarea
                                            name="question"
                                            className="form-control"
                                            value={returnValue(EditQuestionInd, 'question')}
                                            // value={QuestionsList[EditQuestionInd].question}
                                            onClick={() => {
                                                let OptionCount = 0;
                                                AnswersList.map((s) => {
                                                    if (
                                                        Number(s.QuestionIndex) ===
                                                        Number(EditQuestionNo)
                                                    ) {
                                                        OptionCount += 1;
                                                    }
                                                    return '';
                                                });
                                                if (OptionCount === 0) {
                                                    document.getElementById('AddMoreOptions').click();
                                                }
                                            }}
                                            onChange={(e) => {
                                                handleEditQuestionsList(
                                                    EditQuestionInd,
                                                    'question',
                                                    e.target.value
                                                );
                                                RefreshQuestionsList(e);
                                            }}
                                        />
                                    </div>
                                    <div
                                        className="col-md-3"
                                        style={{
                                            background: `url('${UPLOADNODEURL}/${QuestionsList[EditQuestionInd].questionImage}')`,
                                            backgroundSize: '100%',
                                        }}
                                    >
                                        <label style={{ background: '#f5f5f5d1' }}>
                                            Question Image
                                            {QuestionsList[EditQuestionInd].questionImage && QuestionsList[EditQuestionInd].questionImage !=='' ? (
                                                <a
                                                    href="/"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        // eslint-disable-next-line no-restricted-globals
                                                        if (confirm("Are you sue to remove image from the Question?")) {
                                                            // txt = "You pressed OK!";
                                                            handleEditQuestionsList(
                                                                EditQuestionInd,
                                                                'questionImage',
                                                                ''
                                                            );
                                                            RefreshQuestionsList(e);
                                                          }
                                                    }}
                                                >
                                                    &nbsp;
                                                    <i className='fa fa-trash text-danger'></i>
                                                </a>
                                            ) : (
                                                ''
                                            ) }
                                        </label>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) => {
                                                saveFile(e, 'questionImage');
                                            }}
                                            style={{ background: 'transparent' }}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className=""
                                onBlur={() => {
                                    // handleForm('SessionAnswers', AnswersList);
                                    // setSessionAnswers(AnswersList);
                                }}
                            >
                                <label>Options</label>
                                {AnswersList.map((s, AnsRowID) => (
                                    <div
                                        className="row"
                                        key={`${EditQuestionInd}_${s.Index}`}
                                        style={{ display: Running && Number(s.status) === 1 ? '' : 'none' }}
                                    >
                                        {Number(s.QuestionIndex) === Number(EditQuestionNo) ? (
                                            <>
                                                <div className="col-md-1">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            name="srno"
                                                            className="form-control"
                                                            // eslint-disable-next-line no-plusplus
                                                            placeholder="Enter Answer Sr. No."
                                                            value={s.srno}
                                                            onChange={(e) => {
                                                                handleEditAnswersList(
                                                                    AnsRowID,
                                                                    e.target.name,
                                                                    e.target.value
                                                                );
                                                                RefreshAnswersList();
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-7">
                                                    {/* {`EditQuestionNo : ${EditQuestionNo}`}
                                                    {`s.QuestionIndex : ${s.QuestionIndex}`} */}
                                                    <div className="form-group">
                                                        {/* <label>Answer {Number(AnsRowID) + 1}</label> */}
                                                        <textarea
                                                            name="answer"
                                                            className="form-control"
                                                            // eslint-disable-next-line no-plusplus
                                                            placeholder="Enter Option"
                                                            value={s.answer}
                                                            onChange={(e) => {
                                                                handleEditAnswersList(
                                                                    AnsRowID,
                                                                    e.target.name,
                                                                    e.target.value
                                                                );
                                                                RefreshAnswersList();
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-1 text-center">
                                                    {s[PrimaryIndex] && s[PrimaryIndex] !== null ? (
                                                        <i
                                                            className="fas fa-trash text-danger"
                                                            onClick={() => {
                                                                if ( window.confirm( ' Do you really want to Remove this Option ?' ) ) {
                                                                    handleEditAnswersList(AnsRowID, 'status', 0);
                                                                    setRunning(Running + 1);
                                                                }
                                                            }}
                                                        ></i>
                                                    ) : (
                                                        <i
                                                            className="far fa-trash text-danger"
                                                            onClick={() => {
                                                                handleRemoveAnswersList(s.Index);
                                                                // RefreshAnswersList();
                                                            }}
                                                        ></i>
                                                    )}
                                                    {/* {s.Index} */}
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <input
                                                            type="checkbox"
                                                            name="isCorrect"
                                                            checked={s.isCorrect === 1}
                                                            className="form-control1"
                                                            value={s.isCorrect}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    handleEditAnswersList(
                                                                        AnsRowID,
                                                                        e.target.name,
                                                                        1
                                                                    );
                                                                } else {
                                                                    handleEditAnswersList(
                                                                        AnsRowID,
                                                                        e.target.name,
                                                                        0
                                                                    );
                                                                }
                                                                RefreshAnswersList();
                                                            }}
                                                        />
                                                        &nbsp;Correct Answer
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-1"></div>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-right">
                                    <span
                                        id="AddMoreOptions"
                                        onClick={() => {
                                            // let t = Number(AnswersList.length); t = AnswersList[t - 1] ? AnswersList[t - 1].Index : 0;
                                            const t = nextAnswerIndex();
                                            handleAddAnswersList(t + 1);
                                        }}
                                        className="ryuploadAct"
                                        style={{
                                            maxWidth: 'max-content',
                                            background: '#7f13ab',
                                            color: '#f5f5f5',
                                            fontWeight: 800,
                                            borderRadius: '10px',
                                            padding: '5px 20px',
                                            cursor: 'pointer',
                                            margin: '0px auto',
                                            marginBottom: '10px',
                                            float: 'right',
                                        }}
                                    >
                                        <i className="fa fa-plus"></i> Option
                                    </span>
                                </div>
                                <div className="col-md-3"></div>
                            </div>

                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-md-9">
                                        <label>Explanation</label>
                                        <textarea
                                            name="explanationAnswer"
                                            className="form-control"
                                            value={QuestionsList[EditQuestionInd].explanationAnswer}
                                            onChange={(e) => {
                                                handleEditQuestionsList(
                                                    EditQuestionInd,
                                                    'explanationAnswer',
                                                    e.target.value
                                                );
                                                RefreshQuestionsList(e);
                                            }}
                                        />
                                    </div>
                                    <div
                                        className="col-md-3"
                                        style={{
                                            background: `url('${UPLOADNODEURL}/${QuestionsList[EditQuestionInd].explanationImage}')`,
                                            backgroundSize: '100%',
                                        }}
                                    >
                                        <label style={{ background: '#f5f5f5d1' }}>
                                            Explanation Image
                                        </label>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) => {
                                                saveFile(e, 'explanationImage');
                                            }}
                                            style={{ background: 'transparent' }}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 text-center">
                                <a
                                    href="/"
                                    name="saveQuestion"
                                    className="btn btn-success text-white"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setEditQuestionInd('');
                                        handleForm('SessionQuestions', QuestionsList);
                                        setSessionQuestions(QuestionsList);
                                        // handleForm('SessionAnswers', AnswersList);
                                        setSessionAnswers(AnswersList);
                                        // setEditQuestionForm('NA');
                                    }}
                                >
                                    Submit
                                </a>
                            </div>
                        </div>
                    </>
                ) : (
                    ''
                )}
                {/* list of the quesytions */}
                
                <div className="text-left">
                        <h4
                            className="d-none"
                            style={{
                                background: '#152569',
                                color: '#f5f5f5',
                                padding: '5px 10px',
                            }}
                        >
                            {SessionTitle}
                        </h4>
                        <div className="ListQuestionContainerAdmin">
                            {Running && QuestionsList.map((Que, QuestionInd) => (
                                <div
                                    key={`${EditQuestionSession}_${Que.Index}`}
                                    style={{ display: Running && Number(Que.status) === 1 ? '' : 'none' }}
                                >
                                    {Number(EditQuestionSession) === Number(Que.module) ? (
                                        <>
                                            <div title={Que.Index}>
                                                {Que.srno}
                                                &nbsp;
                                                {Que.question}
                                            </div>
                                            <a
                                                href="/"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setEditQuestionInd(QuestionInd);
                                                    setEditQuestionNo(Que.Index);
                                                }}
                                                className="mr-2"
                                            >
                                                <i className="fa fa-edit"></i>
                                            </a>
                                            {Que[PrimaryIndex] && Que[PrimaryIndex] !== null ? (
                                                <span
                                                    onClick={() => {
                                                        if ( window.confirm(`Do you really want to Remove this Question (${Que.question}) ?`)) {
                                                            handleEditQuestionsList(QuestionInd, 'status', 0);
                                                            setRunning(Running + 1);
                                                        }
                                                    }}
                                                    className="text-danger"
                                                >
                                                    <i className="fas fa-trash"></i>
                                                </span>
                                            ) : (
                                                <span
                                                    onClick={() => {
                                                        if ( window.confirm('Do you really want to Remove this Question ?')) {
                                                            handleRemoveQuestionsList(Que.Index);
                                                        }
                                                    }}
                                                    className="text-danger"
                                                >
                                                    <i className="far fa-trash"></i>
                                                </span>  
                                            )}
                                            <hr />
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            ))}
                        </div>
                        <div className="text-center mb-3">
                            <a
                                href="/"
                                name="saveQuestion"
                                className="btn btn-success"
                                onClick={(e) => {
                                    e.preventDefault();
                                    // let t = Number(QuestionsList.length);
                                    // t = QuestionsList[t - 1] ? QuestionsList[t - 1].Index : 0;
                                    const t = nextQuestionIndex();
                                    setEditQuestionInd(QuestionsList.length);
                                    setEditQuestionNo(t + 1);
                                    handleAddQuestionsList(t + 1, EditQuestionSession);
                                }}
                            >
                                Add MCQ
                            </a>
                        </div>
                    </div>
                {/* list of the quesytions */}
            </div>
        </div>
    );
}

export default Question2Courses;
