import React, { useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { BASEURL, BASEAPIURL } from '../../config';
import { BuyThisCourseStdAfterLoginAuto } from '../Students/functions';

function Header({ action, ShowData, setshowShare, setshowReviewForm, courseType }) {
    const [ShowProgress, SetShowProgress] = useState(false);
    const [DoneSessions, SetDoneSessions] = useState([]);
    const FetchDoneSessions = async (CourseMongoID, courseType) => {
        try {
            const res = await fetch(`${BASEAPIURL}/student/FetchDoneSession`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
                body: JSON.stringify({
                    loggedToken: localStorage.getItem('loggedToken'),
                    usertype: localStorage.getItem('usertype'),
                    courseID: CourseMongoID,
                    courseType: courseType ? courseType : 'MainCourse',
                    completed: 1,
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                if (resJson.status === 1 && resJson.data.length > 0) {
                    // play the first video Running
                    SetDoneSessions(resJson.data);
                } else {
                    SetDoneSessions([]);
                }
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        FetchDoneSessions(ShowData._id, courseType);
        // for the Enrolled courses
        if (localStorage.getItem('Enroll_targetID') && localStorage.getItem('Enroll_targetType') && localStorage.getItem('Enroll_targetID') !== '' && localStorage.getItem('Enroll_targetType') !== '') {
            setTimeout(() => {
                BuyThisCourseStdAfterLoginAuto(localStorage.getItem('loggedToken'), localStorage.getItem('Enroll_targetID'), localStorage.getItem('Enroll_targetType'), localStorage.getItem('usertype'));
            }, 786);
        }
        // for the Enrolled courses
    }, [ShowData, courseType]);
    return (
        <>
            <header className="appie-header-area appie-sticky" style={{ display: 'block' }}>
                <div className="headSection">
                    <div className="container-fluid">
                        <div className="header-nav-box">
                            <div className="row">
                                <div className="col-12">
                                    <div className="headFull">
                                        <div
                                            className="appie-logo-box"
                                            style={{ flexBasis: 'auto' }}
                                        >
                                            <a href="/institute">
                                                <img
                                                    src={`${BASEURL}logo.png`}
                                                    alt=""
                                                    style={{ maxWidth: '155px' }}
                                                />
                                            </a>
                                            <div
                                                onClick={(e) => action(e)}
                                                className="toggle-btn ml-30 canvas_open d-lg-none d-block"
                                            >
                                                <i className="fa fa-bars" />
                                            </div>
                                        </div>
                                        <div className="appie-search-box">
                                            <b>{ShowData.title}</b>
                                        </div>
                                        <div className="appie-btn-box">
                                            <span
                                                className="text-dark"
                                                onClick={() => {
                                                    setshowReviewForm(true);
                                                }}
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <i className="far fa-star mr-2"></i>
                                                Review this {courseType ? 'Course' : 'Course'}
                                            </span>
                                            <span
                                                style={{
                                                    width: 50,
                                                    position: 'relative',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    SetShowProgress(!ShowProgress);
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: ShowProgress ? '' : 'none',
                                                        position: 'absolute',
                                                        top: '50px',
                                                        zIndex: '2147483647',
                                                        background: 'rgb(255, 255, 255)',
                                                        padding: '10px',
                                                        minWidth: '190px',
                                                        left: '-80px',
                                                        border: '1px solid #ccc',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {`${DoneSessions.length > ShowData.sessionCount ? ShowData.sessionCount : DoneSessions.length} ${courseType ? 'Test' : 'Session'} completed out of ${ShowData.sessionCount}`}
                                                </div>
                                                <CircularProgressbar
                                                    value={Number((Number(DoneSessions.length > ShowData.sessionCount ? ShowData.sessionCount : DoneSessions.length) / Number(ShowData.sessionCount ? ShowData.sessionCount : 100)) * 100).toFixed(0)}
                                                    text=""
                                                />
                                                <small
                                                    style={{
                                                        position: 'absolute',
                                                        top: '9px',
                                                        left: '17px',
                                                    }}
                                                >
                                                    <b>{Number((Number(DoneSessions.length > ShowData.sessionCount ? ShowData.sessionCount : DoneSessions.length) / Number(ShowData.sessionCount ? ShowData.sessionCount : 100)) * 100).toFixed(0)}%</b>
                                                </small>
                                                <i
                                                    className="fas fa-graduation-cap"
                                                    style={{
                                                        position: 'absolute',
                                                        top: '25px',
                                                        left: '17px',
                                                    }}
                                                ></i>
                                            </span>
                                            {/* Course Status */}
                                            <span
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                                className="text-dark"
                                                onClick={() => {
                                                    setshowShare(true);
                                                    SetShowProgress(false);
                                                }}
                                            >
                                                <i className="far fa-share mr-2"></i>
                                                Share
                                            </span>
                                            <div className="appie-icon-box"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;
