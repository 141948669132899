import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BASEAPIURL, BASEURL } from '../../../../config';

function EmailNotificationSetting() {
    const navigate = useNavigate();
    const [NotificationSetting, setNotificationSetting] = useState({
        promotion: 1,
        announcements: 1,
        nopromotional: 0,
        usertype: localStorage.getItem('usertype'),
    });

    const [MsgClass, setMsgClass] = useState('alert-danger');
    const [APIMessage, setMessage] = useState();
    const [IsAPIAction, setIsAPIAction] = useState(false);

    const UpdateFormsData = (name, value) => {
        setNotificationSetting({ ...NotificationSetting, [name]: value });
    };

    async function UpdateEmailNotification() {
        try {
            setMsgClass('alert-danger');
            setIsAPIAction(true);
            NotificationSetting.usertype = localStorage.getItem('usertype');
            const url = `${BASEAPIURL}/student/UpdateEmailNotificationSetting`;
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
            };
            axios
                .post(url, NotificationSetting, config)
                .then((response) => {
                    setIsAPIAction(false);
                    if (response.data.status === 1) {
                        setMsgClass('alert-success');
                        setTimeout(() => {
                            setMessage('');
                            navigate('/user/page/profilesetting');
                        }, 2786);
                    }
                    setMessage(response.data.message);
                })
                .catch((er) => {
                    setIsAPIAction(false);
                    console.log(er);
                    setMessage('Something went wrong, Please try again later.');
                });
        } catch (err) {
            setIsAPIAction(false);
            setMsgClass('alert-danger');
            setTimeout(() => {
                setMessage('');
            }, 2786);
            console.log(err);
            setMessage('Something went wrong, Please try again later.');
        }
    }

    useEffect(() => {
        const FetchProfile = async () => {
            try {
                const res = await fetch(`${BASEAPIURL}/student/myprofile`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                    },
                    body: JSON.stringify({
                        loggedToken: localStorage.getItem('loggedToken'),
                        usertype: localStorage.getItem('usertype'),
                    }),
                });
                const resJson = await res.json();
                if (res.status === 200) {
                    if (resJson.userdata && resJson.userdata !== null) {
                        setNotificationSetting(resJson.userdata);
                    }
                } else {
                    console.log(resJson.message);
                }
            } catch (err) {
                console.log(err);
            }
        };
        FetchProfile();
    }, []);

    return (
        <>
            <form
                id="Inst-Regi-Form"
                encType="multipart/form-data"
                className="p-1"
                style={{ display: 'block' }}
                onSubmit={(e) => {
                    e.preventDefault();
                    if (!IsAPIAction) {
                        UpdateEmailNotification();
                    }
                }}
            >
                <div
                    style={{
                        background: '#f2f5ff',
                        margin: 'auto',
                        padding: '25px',
                    }}
                >
                    <h4 className="std_header_on_page text-center">Email Notifications</h4>
                    <h4 className="text-center mt-2" style={{ color: '#5c5a5a' }}>
                        Turn Promotional Email Notifications from Docsta Learn on or off
                    </h4>
                </div>
                <h4 className="std_header_on_page text-left mt-4">I want to Receive</h4>
                <div
                    className="ry_instr_shadow mt-3 p-3"
                    style={{ display: NotificationSetting.nopromotional === 1 ? 'none' : '' }}
                >
                    <div style={{ display: 'flex' }}>
                        <div>
                            <input
                                type="checkbox"
                                className="mr-2"
                                checked={
                                    // eslint-disable-next-line no-unneeded-ternary
                                    NotificationSetting.promotion === 1 ? true : false
                                    // eslint-enable-next-line no-unneeded-ternary
                                }
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        UpdateFormsData('promotion', 1);
                                    } else {
                                        UpdateFormsData('promotion', 0);
                                    }
                                }}
                            />
                        </div>
                        <div>
                            <b>
                                Promotions, course recommendations. and helpful resources from
                                <span className="std_header_on_page">&nbsp;Docsta Learn</span>
                            </b>
                        </div>
                    </div>
                </div>

                <div
                    className="ry_instr_shadow mt-3 p-3"
                    style={{ display: NotificationSetting.nopromotional === 1 ? 'none' : '' }}
                >
                    <div style={{ display: 'flex' }}>
                        <div>
                            <input
                                type="checkbox"
                                className="mr-2"
                                name=""
                                checked={
                                    // eslint-disable-next-line no-unneeded-ternary
                                    NotificationSetting.announcements === 1 ? true : false
                                    // eslint-enable-next-line no-unneeded-ternary
                                }
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        UpdateFormsData('announcements', 1);
                                    } else {
                                        UpdateFormsData('announcements', 0);
                                    }
                                }}
                            />
                        </div>
                        <div>
                            <b>Announcements from Instructors whose course(s) I'm enrolled In.</b>
                            <p>
                                To adjust the prelercrse by course, leave this box checked and go to
                                the course dashboard and click on Option to opt out of specific out
                                of specific Announcements.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="ry_instr_shadow mt-3 p-3">
                    <div style={{ display: 'flex' }}>
                        <div>
                            <input
                                type="checkbox"
                                className="mr-2"
                                name=""
                                checked={
                                    // eslint-disable-next-line no-unneeded-ternary
                                    NotificationSetting.nopromotional === 1 ? true : false
                                    // eslint-enable-next-line no-unneeded-ternary
                                }
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        UpdateFormsData('nopromotional', 1);
                                    } else {
                                        UpdateFormsData('nopromotional', 0);
                                    }
                                }}
                            />
                        </div>
                        <div>
                            <b>Don't send me any Promotional Emails.</b>
                            <p>
                                If this box is checked, Please note that you will continue to
                                receive Important Transaction Email like Purchase rreceipts.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="text-center">
                    <div className={`message alert hide_empty_div mb-3 ${MsgClass}`}>
                        {APIMessage ? <span>{APIMessage}</span> : ''}
                    </div>
                    <div className={`mt-3 text-center ${IsAPIAction ? '' : 'd-none'}`}>
                        <img style={{ maxWidth: '32px' }} src={`${BASEURL}loader.gif`} alt="" />
                    </div>
                    <button
                        type="submit"
                        className={`btn btn-lg btn-docsta-instructor ${
                            IsAPIAction ? 'd-none' : ''
                        }`}
                        style={{ width: 'auto', margin: '20px auto' }}
                    >
                        Save
                    </button>
                </div>
            </form>
        </>
    );
}

export default EmailNotificationSetting;
