import React, { useState, useEffect } from 'react';
import { BASEAPIURL, UPLOADNODEURL, getCourseURL } from '../../../../config';
import Loader from '../../../Helper/Loader';

function GrabGift() {
    // for the Gift Parameter
    // Extracting parameters from the query string
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    // const action = params.get('action');
    // for the Gift Parameter token=${data._id}&action=gift
    const [Message, SetMessage] = useState({ status: 0, v: '' });
    const [Gift, SetGift] = useState([]);
    const [CourseData, SetCourseData] = useState({});
    const [isLoded, SetisLoded] = useState(false);

    const GetDeals = async (token) => {
        try {
            const res = await fetch(`${BASEAPIURL}/student/getGifts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
                body: JSON.stringify({ token, usertype: localStorage.getItem('usertype') }),
            });
            const resJson = await res.json();
            SetisLoded(true);
            if (resJson.courseData && resJson.courseData.length > 0) {
                SetCourseData(resJson.courseData[0]);
            }
            if (resJson.data && resJson.data.length > 0) {
                SetGift(resJson.data);
            }
        } catch (err) {
            console.log(err);
            SetisLoded(false);
        }
    };

    const AcceptGift = async (token, rediect2Course) => {
        try {
            const res = await fetch(`${BASEAPIURL}/student/grabGifts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
                body: JSON.stringify({ token, usertype: localStorage.getItem('usertype') }),
            });
            const resJson = await res.json();
            SetisLoded(true);
            SetMessage({ status: resJson.status, v: resJson.message });
            if (resJson.status === 1) {
                setTimeout(() => {
                    window.location.href = rediect2Course; 
                }, 786);
            }
        } catch (err) {
            console.log(err);
            SetisLoded(false);
        }
    };

    useEffect(() => {
        SetCourseData({});
        SetGift([]);
        GetDeals(token);
    }, [token]);

    return (
        <>
            {!isLoded ? (
                <Loader />
            ) : (
                <>
                    <div className="ry_instr_shadow p-4">
                        <h4 className="std_header_on_page">Gift</h4>
                        <div
                            style={{
                                background: '#f2f5ff',
                                margin: '20px auto',
                                padding: '35px',
                                display: Gift.length > 0 ? 'none' : '',
                            }}
                        >
                            <h4 className="text-center" style={{ color: '#5c5a5a' }}>
                                Something went wrong, Please open the link again you received.
                            </h4>
                        </div>
                        <div className="mt-3">
                            {Message.v && Message.v !== "" && <div className={`alert alert-${Message.status === 1 ? 'success' : 'danger'}`}>{Message.v}</div>}
                            <div className="">
                                {Gift.map((s) => (
                                    <div
                                        key={s._id}
                                        style={{
                                            border: '1px solid #f5f5f5',
                                            background: '#fff',
                                            marginBottom: '10px',
                                            padding: '10px',
                                        }}
                                    >
                                        <h6 className='alert alert-success'>
                                            Congratulations!
                                            <br />
                                            {s.name} have sent you the below Gift Course.
                                        </h6>
                                        <div className="row">
                                            <div className="col-md-3 text-center">
                                                <a
                                                    href={getCourseURL(s.targetType, CourseData.slug)}
                                                    target="_MainCourse"
                                                    rel="noreferrer"
                                                    style={{ position: 'relative' }}
                                                >
                                                    <img src={`${UPLOADNODEURL}${CourseData.image}`} alt="" />
                                                </a>
                                            </div>
                                            <div className="col-md-6 text-left">
                                                <a
                                                    href={getCourseURL(s.targetType, CourseData.slug)}
                                                    target="_MainCourse"
                                                    rel="noreferrer"
                                                >
                                                    <h5 className="mb-2">{CourseData.title}</h5>
                                                </a>
                                                <p>{CourseData.metaDescription}</p>
                                            </div>
                                            <div className="col-md-3 pt-4 text-center">
                                                <a
                                                    href={getCourseURL(s.targetType, CourseData.slug)}
                                                    rel="noreferrer"
                                                    onClick={(e) => {
                                                        SetisLoded(false);
                                                        e.preventDefault();
                                                        AcceptGift(token, '/user/dashboard');
                                                    }}
                                                    className="btn btn-docsta-instructor"
                                                >
                                                    Accept Gift
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default GrabGift;
