import React from 'react';
import Home from './HomeMain';

function HomeMain() {
    return (
        <>
            <Home />
        </>
    );
}

export default HomeMain;
