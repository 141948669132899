import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CoursesMainCategory } from '../config';
import { UpdateCommonData } from '../reduxFunction/commonSlice';
import { useDispatch, useSelector } from 'react-redux';

let countryName = localStorage.getItem('country_name');
function Navigation({
    setShowCatNavigation,
    setMainCatId,
    setCatId,
    setSubCatId,
    FetchCatiData,
    setCourseListLink,
    FetchCoursesData,
}) {
    const CommonData = useSelector((state) => state.commonData);
    const dispatch = useDispatch();
    useEffect(() => {
        // dispatch(UpdateCommonData({ActMainMenu: ''}));
        countryName = localStorage.getItem('country_name');
    }, []);
    return (
        <>
            <ul
                style={{
                    maxWidth: '1024px',
                    marginRight: 'auto',
                    marginLeft: 'auto',
                }}
            >
                <li
                    className="d-none"
                    style={{
                        cursor: 'pointer',
                        background: '#7a3694',
                        color: '#f5f5f5',
                        padding: '10px 30px',
                    }}
                >
                    <b
                        onMouseEnter={() => {
                            setShowCatNavigation('block');
                        }}
                        onClick={() => {
                            setShowCatNavigation('block');
                        }}
                    >
                        <i className="fa fa-bars"></i>
                        &nbsp;All Categories
                    </b>
                </li>
                <li
                    onMouseEnter={() => {
                        setShowCatNavigation('none');
                    }}
                    onClick={() => {
                        localStorage.setItem('NVRunning', '/');
                    }}
                >
                    <Link
                        className={CommonData.ActMainMenu === '/' ? 'NavigationActive' : ''}
                        to="/"
                    >
                        <i className="fa fa-home"></i>
                    </Link>
                </li>
                {CoursesMainCategory().map((s) => (
                    <li key={s.id} className="NVCatiLi" style={{ position: 'relative', display: s.id === 'qb' ? '' : '' }}>
                        <Link
                            to={s.slug}
                            onMouseEnter={() => {
                                dispatch(UpdateCommonData({ActMainMenu: s.id}));
                                setShowCatNavigation('block');
                                setMainCatId(s.id);
                                setCatId(0);
                                setSubCatId(0);
                                FetchCatiData(s.id, 0);
                                setCourseListLink(s.slug);
                                FetchCoursesData(s.id, 0, 0);
                            }}
                            className={CommonData.ActMainMenu === s.id ? 'NavigationActive' : ''}
                        >
                            {s.title}
                        </Link>
                        {CommonData.ActMainMenu === s.id ? <span></span> : ''}
                    </li>
                ))}

                <li
                    className='d-none'
                    onMouseEnter={() => {
                        setShowCatNavigation('none');
                    }}
                    onClick={() => {
                        // localStorage.setItem('NVRunning', 'free-audio-tips');
                        dispatch(UpdateCommonData({ActMainMenu: 'free-audio-tips'}));
                    }}
                >
                    <Link
                        className={CommonData.ActMainMenu === 'free-audio-tips' ? 'NavigationActive' : ''}
                        to="/free-audio-tips"
                    >
                        Free Audio Tips
                    </Link>
                </li>
                {/* <li>
                    <Link to="/premium-plans">Premium Plans</Link>
                </li> */}
                <li
                    onMouseEnter={() => {
                        setShowCatNavigation('none');
                    }}
                    onClick={() => {
                        dispatch(UpdateCommonData({ActMainMenu: 'request-as-team'}));
                    }}
                >
                    <Link
                        className={CommonData.ActMainMenu === 'request-as-team' ? 'NavigationActive' : ''}
                        to="/request-as-team"
                    >
                        Team Learning
                    </Link>
                </li>
                <li
                    onMouseEnter={() => {
                        setShowCatNavigation('none');
                    }}
                    onClick={() => {
                        dispatch(UpdateCommonData({ActMainMenu: 'user/page/MyFavourite'}));
                    }}
                >
                    <Link
                        className={CommonData.ActMainMenu === 'user/page/MyFavourite' ? 'NavigationActive' : ''}
                        to="/user/page/MyFavourite"
                    >
                        My favourites
                    </Link>
                </li>
                {/* <li
                    onMouseEnter={() => {
                        setShowCatNavigation('none');
                    }}
                    onClick={() => {
                        dispatch(UpdateCommonData({ActMainMenu: 'gallery'}));
                    }}
                >
                    <Link
                        className={CommonData.ActMainMenu === 'gallery' ? 'NavigationActive' : ''}
                        to="/gallery"
                    >
                        Gallery
                    </Link>
                </li> */}
                {/* <li
                    onMouseEnter={() => {
                        setShowCatNavigation('none');
                    }}
                    onClick={() => {
                        dispatch(UpdateCommonData({ActMainMenu: 'mentors'}));
                    }}
                >
                    <Link
                        className={CommonData.ActMainMenu === 'mentors' ? 'NavigationActive' : ''}
                        to="/mentors"
                    >
                        Mentors
                    </Link>
                </li> */}
                {/* <li
                    onMouseEnter={() => {
                        setShowCatNavigation('none');
                    }}
                    onClick={() => {
                        dispatch(UpdateCommonData({ActMainMenu: 'contact-us'}));
                    }}
                >
                    <Link
                        className={CommonData.ActMainMenu === 'contact-us' ? 'NavigationActive' : ''}
                        to="/contact-us"
                    >
                        Contact Us
                    </Link>
                </li> */}
                <li
                    className=""
                    onMouseEnter={() => {
                        setShowCatNavigation('none');
                    }}
                    onClick={() => {
                        dispatch(UpdateCommonData({ActMainMenu: 'user/page/MyFavourite'}));
                    }}
                >
                    <span>
                        <i className="fal fa-angle-down" />
                    </span>
                    <ul className="sub-menu">
                        <li>
                            <Link to="/about-us">About Us</Link>
                        </li>
                        <li>
                            <Link to="/mentors">Mentors</Link>
                        </li>
                        <li>
                            <Link to="/gallery">Gallery</Link>
                        </li>
                        <li>
                            <Link to="/contact-us">Contact Us</Link>
                        </li>
                    </ul>
                </li>
                <li
                    onMouseEnter={() => {
                        setShowCatNavigation('none');
                    }}
                    style={{ display: countryName === 'United Arab Emirates' ? '' : 'none' }}
                >
                    <a
                        href="https://docstainstitute.com"
                        target="_BLANK"
                        rel="noopener noreferrer"
                        // className="btn-docsta-institute"
                    >
                        Docsta Institute
                    </a>
                </li>
            </ul>
        </>
    );
}

export default Navigation;
