import React, { Suspense } from 'react';
import useToggle from '../../../Hooks/useToggle';
import BackToTop from '../../BackToTop';
import Drawer from '../../Mobile/DrawerInstitute';
import Header from '../../Helper/HeaderInstitute';
import Footer from '../../Helper/FooterInstitute';

const CourseDetail = React.lazy(() => import('./CourseDetail'));

function CourseDetailNew() {
  const [drawer, drawerAction] = useToggle(false);

  return (
    <>
      <Drawer
        drawer={drawer}
        action={drawerAction.toggle}
      />
      <Header
        action={drawerAction.toggle}
        pageName="courseDetail"
      />
      <Suspense fallback={<div></div>}>
        <CourseDetail />
      </Suspense>
      <Footer />
      <BackToTop />
    </>
  );
}

export default CourseDetailNew;
