import React, { useState, useEffect } from 'react';

function Faqs2Courses({ faqs, handleForm }) {
    const [UploadFaq, setUploadFaq] = useState(true);
    const [EditIndex, setEditIndex] = useState('NA');
    const [Error, setError] = useState('');
    const [Question, setQuestion] = useState('');
    const [Answer, setAnswer] = useState('');
    const [AddModeFaqs, setAddModeFaqs] = useState(false);
    // for for Multiple Video List
    // console.log(faqs);
    const [FaqsList, setFaqsList] = useState(faqs);
    const handleAddFaqsList = (index) => {
        // ExpensesTypeSelected.push(1);
        setFaqsList((temp) => [
            ...temp,
            {
                index,
                question: '',
                answer: '',
            },
        ]);
    };
    const handleEditFaqsList = (changeVidId, ind, val) => {
        FaqsList[changeVidId][ind] = val;
    };
    const handleRemoveFaqsList = (ind) => {
        if (ind !== 1) {
            setFaqsList(FaqsList.filter((item) => item.index !== ind));
        }
    };
    const RefreshFaqsList = () => {
        const temp = 999999;
        handleAddFaqsList(temp);
        handleRemoveFaqsList(temp);
        handleForm('faqs', FaqsList);
    };
    // for Multiple Video List
    useEffect(() => {
        setFaqsList(faqs);
    }, [faqs]);
    function addFaq() {
        if (Question !== '' && Answer !== '') {
            if (EditIndex === 'NA') {
                setFaqsList((temp) => [
                    ...temp,
                    {
                        index: Number(FaqsList.length) + 1,
                        question: Question,
                        answer: Answer,
                    },
                ]);
            }
            setError('');
            setAddModeFaqs(false);
        } else {
            setError('Both fields are required.');
        }
    }
    return (
        <div
            style={{ minWidth: '300px' }}
            onBlur={() => {
                handleForm('faqs', FaqsList);
                console.log(1);
            }}
            // handleForm('faqs', FaqsList);
        >
            <div className="ryPopUpBack" style={{ display: AddModeFaqs ? '' : 'none' }}></div>
            <div
                className="ryPopUp"
                style={{
                    minWidth: '30%',
                    top: '20px',
                    bottom: '20px',
                    display: AddModeFaqs ? '' : 'none',
                    maxHeight: '100%',
                    position: 'fixed',
                    overflow: 'auto',
                }}
            >
                <aNew
                    href="#"
                    className="ryPopUpAct"
                    onClick={() => {
                        handleForm('faqs', FaqsList);
                        setAddModeFaqs(false);
                    }}
                >
                    X
                </aNew>
                <div style={{ margin: '30px 10px' }}>
                    <h3>Add FAQ</h3>
                    <div className="form-group">
                        <label>Question</label>
                        <input
                            type="text"
                            value={Question}
                            name="question"
                            className="form-control"
                            onChange={(e) => {
                                setQuestion(e.target.value);
                                if (EditIndex !== 'NA') {
                                    handleEditFaqsList(EditIndex, 'question', e.target.value);
                                    RefreshFaqsList();
                                }
                            }}
                        />
                    </div>

                    <div className="form-group">
                        <label>Answer</label>
                        <textarea
                            value={Answer}
                            name="question"
                            className="form-control"
                            onChange={(e) => {
                                setAnswer(e.target.value);
                                if (EditIndex !== 'NA') {
                                    handleEditFaqsList(EditIndex, 'answer', e.target.value);
                                    RefreshFaqsList();
                                }
                            }}
                        />
                    </div>

                    <div className="text-danger">{Error}</div>

                    <div className="row">
                        <div className="col-md-6">
                            <button
                                type="button"
                                className="btn btn-outline-secondary"
                                onClick={() => {
                                    setAddModeFaqs(false);
                                }}
                                style={{
                                    width: '150px',
                                    margin: '20px auto',
                                }}
                            >
                                Close
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button
                                type="button"
                                className="btn btn-docsta-instructor"
                                onClick={() => {
                                    addFaq();
                                }}
                                style={{
                                    width: '150px',
                                    margin: '20px auto',
                                }}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <h4
                style={{
                    background: '#152569',
                    color: '#f5f5f5',
                    padding: '10px 20px',
                }}
            >
                Upload FAQs
                <div style={{ float: 'right' }}>
                    <i
                        className="fa fa-arrow-down"
                        style={{ display: UploadFaq ? '' : 'none' }}
                        onClick={() => {
                            setUploadFaq(false);
                        }}
                    ></i>
                    <i
                        className="fa fa-arrow-up"
                        style={{ display: UploadFaq ? 'none' : '' }}
                        onClick={() => {
                            setUploadFaq(true);
                        }}
                    ></i>
                </div>
                <div style={{ clear: 'both' }}></div>
            </h4>
            <div
                className="form-group"
                style={{
                    border: '1px solid #152569',
                    padding: '20px',
                    background: '#ededed',
                    display: UploadFaq ? '' : 'none',
                }}
            >
                <div className="text-right">
                    <span
                        onClick={() => {
                            setAddModeFaqs(true);
                            setEditIndex('NA');
                            setQuestion('');
                            setAnswer('');
                        }}
                        className="ryuploadAct"
                        style={{
                            maxWidth: 'max-content',
                            background: '#7f13ab',
                            color: '#f5f5f5',
                            fontWeight: 800,
                            borderRadius: '10px',
                            padding: '5px 20px',
                            cursor: 'pointer',
                            margin: '0px auto',
                            marginBottom: '10px',
                            float: 'right',
                        }}
                    >
                        Add Faqs
                    </span>
                    <div style={{ clear: 'both' }}></div>
                </div>
                {FaqsList.map((s, changeId) => (
                    <div className="form-group mb-3 p-3 bg-white" key={s.index}>
                        <h4>
                            {s.index}. {s.question}
                            <div style={{ float: 'right' }}>
                                <i
                                    onClick={() => {
                                        setAddModeFaqs(true);
                                        setEditIndex(changeId);
                                        setQuestion(s.question);
                                        setAnswer(s.answer);
                                    }}
                                    className="fa fa-edit text-info"
                                ></i>
                            </div>
                            <div style={{ clear: 'both' }}></div>
                        </h4>
                        <p>{s.answer}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Faqs2Courses;
