import React, { useEffect, Suspense, useState } from 'react';

import { useParams } from 'react-router-dom';
import '../../administrator.css';
import useToggle from '../../../../Hooks/useToggle';
import BackToTop from '../../../BackToTop';
import Footer from '../../../Helper/FooterLearn';
import Drawer from '../../../Mobile/DrawerAdmin';

import HeaderAdmin from '../../HeaderAdmin';
import LoginPage from '../Login/index';
import { BASEAPIURL } from '../../../../config';
import axios from 'axios';

const ProfileMenu = React.lazy(() => import('./ProfileMenu'));
const Dashboard = React.lazy(() => import('./Dashboard'));

// const MyLifeTips = React.lazy(() => import('./MyLifeTips'));
const ListBlogs = React.lazy(() => import('./ListBlogs'));
const ListOrders = React.lazy(() => import('./orders/listing'));
const ViewOrder = React.lazy(() => import('./orders/view'));

const EmailSubscribers = React.lazy(() => import('./emailSubscribers/list'));

const AdminList = React.lazy(() => import('./ListAdmin'));
const StdsList = React.lazy(() => import('./ListStds'));
const CreateEnrollment = React.lazy(() => import('./enrollments/create'));

const StudentQuestionBank = React.lazy(() =>
  import('./reports/StudentQuestionBank')
);
const StudentVideoCourse = React.lazy(() =>
  import('./reports/StudentVideoCourse')
);

const InstructorsList = React.lazy(() => import('./ListInstructors'));
const CounsellingTeam = React.lazy(() => import('./ListCounsellingTeam'));
const AdvisoryBoard = React.lazy(() => import('./ListAdvisoryBoard'));

const Listcategories = React.lazy(() => import('./Listcategories'));
const ListTestimonials = React.lazy(() => import('./ListTestimonials'));
const ListGalleries = React.lazy(() => import('./ListGalleries'));
const ListHomeSlider = React.lazy(() => import('./ListHomeSlider'));
const ListTopMessage = React.lazy(() => import('./ListTopMessage'));
const ListFaqs = React.lazy(() => import('./ListFaqs'));

const ListAccreditations = React.lazy(() => import('./ListAccreditations'));
const ListRenownedClients = React.lazy(() => import('./ListRenownedClients'));

const ListBlogCategories = React.lazy(() => import('./ListBlogCategories'));
const ListFreeTipsCategories = React.lazy(() =>
  import('./ListFreeTipsCategories')
);
const Listfreetip = React.lazy(() => import('./Listfreetip'));
const SubscriptionManage = React.lazy(() => import('../subscription/list'));
const ListMainCourses = React.lazy(() => import('./ListMainCourses'));
const ListQBCourses = React.lazy(() => import('./ListQBCourses'));
const ListCoursesEnquiries = React.lazy(() => import('./ListCoursesEnquiries'));
const ListRequestIndividual = React.lazy(() =>
  import('./ListRequestIndividual')
);
const ListContactEnquiry = React.lazy(() => import('./ListContactEnquiry'));
const ListReportedBugs = React.lazy(() => import('./reports/ListReportedBugs'));
const ListRequestTeam = React.lazy(() => import('./ListRequestTeam'));

const RefundAndCancellation = React.lazy(() =>
  import('./RefundAndCancellation')
);
const InfoPrivacy = React.lazy(() => import('./InfoPrivacy'));
const InfoTerms = React.lazy(() => import('./InfoTerms'));

// admin menu will be here
// create Master Links
const CreateCategories = React.lazy(() => import('./CreateCategories'));
// create Master Links

function AdminDashboard() {
  const [IsLoaded, setIsLoaded] = useState(false);
  const [IsLogin, setIsLogin] = useState(false);
  const noPermission =
    "<h3 class='alert text-center pt-5 pb-5 alert-danger'>You don't have permission for this Page.</h3>";
  const { PageName } = useParams();
  const [drawer, drawerAction] = useToggle(false);
  const [AMissionPer, setAMissionPer] = useState(
    localStorage.getItem('amissionper')
  );

  const FetchProfile = async () => {
    try {
      const res = await axios(`${BASEAPIURL}/admin/account/CheckLogin`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json', // multipart/form-dat
          'ry-token1': localStorage.getItem('aid'),
          'jwt-token': localStorage.getItem('token'),
          'ry-token2': localStorage.getItem('usertype'),
        },
        body: JSON.stringify({ token: localStorage.getItem('token') }),
      });
      const resJson = res.data;
      setIsLoaded(true);
      if (res.status === 200) {
        if (resJson.status === 1) {
          setIsLogin(true);
          if (resJson.data) {
            // console.error(resJson.data.permission);
            localStorage.setItem('amissionper', resJson.data.permission);
            // console.error(localStorage.getItem('amissionper'));
            setTimeout(() => {
              setAMissionPer(resJson.data.permission);
            }, 786);
          }
        }
      }
    } catch (err) {
      setIsLoaded(true);
      console.error(err);
      setIsLogin(false);
    }
  };

  useEffect(() => {
    setIsLogin(false);
    FetchProfile();
    // checked
    localStorage.setItem('RYSJAIN', Date());
    const interval = setInterval(() => {
      FetchProfile();
    }, 60000);
    return () => clearInterval(interval);
    // checked
  }, []);

  return (
    <div style={{ display: IsLoaded ? '' : 'none' }}>
      {IsLogin ? (
        <>
          <Drawer
            drawer={drawer}
            action={drawerAction.toggle}
          />
          <HeaderAdmin
            drawer={drawer}
            action={drawerAction.toggle}
          />
          <>
            <div className="container-fluid pt-4 pb-4">
              <div className="row">
                <div className="hideonmobile col-md-3">
                  <Suspense fallback={<div>Loading...</div>}>
                    <ProfileMenu
                      isDrawer={0}
                      forpage={PageName}
                    />
                  </Suspense>
                </div>

                <div className="col-md-9">
                  {PageName === undefined ? (
                    <Suspense fallback={<div>Loading...</div>}>
                      <Dashboard forpage={PageName} />
                    </Suspense>
                  ) : (
                    ''
                  )}
                  {PageName === 'index' ? (
                    <Suspense fallback={<div>Loading...</div>}>
                      <Dashboard forpage={PageName} />
                    </Suspense>
                  ) : (
                    ''
                  )}

                  {PageName === 'set-term' && (
                    <>
                      {AMissionPer &&
                      AMissionPer.includes('Manage Page Settings') ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <InfoTerms forpage={PageName} />
                        </Suspense>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{ __html: noPermission }}
                        />
                      )}
                    </>
                  )}

                  {PageName === 'set-privacy' && (
                    <>
                      {AMissionPer &&
                      AMissionPer.includes('Manage Page Settings') ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <InfoPrivacy forpage={PageName} />
                        </Suspense>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{ __html: noPermission }}
                        />
                      )}
                    </>
                  )}

                  {PageName === 'set-RefundAndCancellation' && (
                    <>
                      {AMissionPer &&
                      AMissionPer.includes('Manage Page Settings') ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <RefundAndCancellation forpage={PageName} />
                        </Suspense>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{ __html: noPermission }}
                        />
                      )}
                    </>
                  )}

                  {PageName === 'subscription-manage' && (
                    <>
                      {AMissionPer &&
                      AMissionPer.includes('Manage Clubbed Courses') ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <SubscriptionManage forpage={PageName} />
                        </Suspense>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{ __html: noPermission }}
                        />
                      )}
                    </>
                  )}

                  {PageName === 'freetips-manage' && (
                    <>
                      {AMissionPer &&
                      AMissionPer.includes('Manage Free Tips') ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <Listfreetip forpage={PageName} />
                        </Suspense>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{ __html: noPermission }}
                        />
                      )}
                    </>
                  )}

                  {PageName === 'maincourse-manage' && (
                    <>
                      {AMissionPer &&
                      AMissionPer.includes('Manage Video Course') ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <ListMainCourses forpage={PageName} />
                        </Suspense>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{ __html: noPermission }}
                        />
                      )}
                    </>
                  )}

                  {PageName === 'questionBanks-manage' && (
                    <>
                      {AMissionPer &&
                      AMissionPer.includes('Manage Question Banks') ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <ListQBCourses forpage={PageName} />
                        </Suspense>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{ __html: noPermission }}
                        />
                      )}
                    </>
                  )}

                  {PageName === 'myblog' && (
                    <>
                      {AMissionPer && AMissionPer.includes('Manage Blogs') ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <ListBlogs forpage={PageName} />
                        </Suspense>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{ __html: noPermission }}
                        />
                      )}
                    </>
                  )}

                  {PageName === 'orders' && (
                    <>
                      {AMissionPer && AMissionPer.includes('Manage Orders') ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <ListOrders forpage={PageName} />
                        </Suspense>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{ __html: noPermission }}
                        />
                      )}
                    </>
                  )}
                  {PageName === 'orders/:id' && (
                    <>
                      {AMissionPer && AMissionPer.includes('View Orders') ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <ViewOrder forpage={PageName} />
                        </Suspense>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{ __html: noPermission }}
                        />
                      )}
                    </>
                  )}

                  {PageName === 'admins-manage' && (
                    <>
                      {AMissionPer && AMissionPer.includes('Manage Admin') ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <AdminList forpage={PageName} />
                        </Suspense>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{ __html: noPermission }}
                        />
                      )}
                    </>
                  )}

                  {PageName === 'students-manage' && (
                    <>
                      {AMissionPer && AMissionPer.includes('Manage Student') ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <StdsList
                            forpage={PageName}
                            AMissionPer={AMissionPer}
                          />
                        </Suspense>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{ __html: noPermission }}
                        />
                      )}
                    </>
                  )}

                  {PageName === 'create-enrollment' && (
                    <>
                      {AMissionPer &&
                      AMissionPer.includes('Create Enrollment') ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <CreateEnrollment AMissionPer={AMissionPer} />
                        </Suspense>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{ __html: noPermission }}
                        />
                      )}
                    </>
                  )}

                  {PageName === 'students-videoCourses' && (
                    <>
                      {AMissionPer &&
                      AMissionPer.includes(
                        'Manage Student on Video Courses'
                      ) ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <StudentVideoCourse forpage={PageName} />
                        </Suspense>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{ __html: noPermission }}
                        />
                      )}
                    </>
                  )}

                  {PageName === 'students-questionBanks' && (
                    <>
                      {AMissionPer &&
                      AMissionPer.includes(
                        'Manage Student on Question Banks'
                      ) ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <StudentQuestionBank forpage={PageName} />
                        </Suspense>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{ __html: noPermission }}
                        />
                      )}
                    </>
                  )}

                  {PageName === 'instructors-manage' && (
                    <>
                      {AMissionPer &&
                      AMissionPer.includes('Manage Instructor') ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <InstructorsList
                            forpage={PageName}
                            AMissionPer={AMissionPer}
                          />
                        </Suspense>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{ __html: noPermission }}
                        />
                      )}
                    </>
                  )}

                  {AMissionPer && AMissionPer.includes('Manage Settings') ? (
                    <>
                      {PageName === 'categories-create' ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <CreateCategories forpage={PageName} />
                        </Suspense>
                      ) : (
                        ''
                      )}

                      {PageName === 'counselling-team' && (
                        <Suspense fallback={<div>Loading...</div>}>
                          <CounsellingTeam forpage={PageName} />
                        </Suspense>
                      )}

                      {PageName === 'advisory-board' && (
                        <Suspense fallback={<div>Loading...</div>}>
                          <AdvisoryBoard forpage={PageName} />
                        </Suspense>
                      )}

                      {PageName === 'categories-manage' ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <Listcategories forpage={PageName} />
                        </Suspense>
                      ) : (
                        ''
                      )}

                      {PageName === 'testimonials-manage' ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <ListTestimonials forpage={PageName} />
                        </Suspense>
                      ) : (
                        ''
                      )}

                      {PageName === 'gallery-manage' ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <ListGalleries forpage={PageName} />
                        </Suspense>
                      ) : (
                        ''
                      )}

                      {PageName === 'homeslider-manage' ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <ListHomeSlider forpage={PageName} />
                        </Suspense>
                      ) : (
                        ''
                      )}

                      {PageName === 'manage-topmessage' ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <ListTopMessage forpage={PageName} />
                        </Suspense>
                      ) : (
                        ''
                      )}

                      {PageName === 'faqs-manage' ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <ListFaqs forpage={PageName} />
                        </Suspense>
                      ) : (
                        ''
                      )}

                      {PageName === 'accreditations-manage' ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <ListAccreditations forpage={PageName} />
                        </Suspense>
                      ) : (
                        ''
                      )}

                      {PageName === 'renownedclients-manage' ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <ListRenownedClients forpage={PageName} />
                        </Suspense>
                      ) : (
                        ''
                      )}

                      {PageName === 'blogcategories-manage' ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <ListBlogCategories forpage={PageName} />
                        </Suspense>
                      ) : (
                        ''
                      )}

                      {PageName === 'freetipscategory-manage' ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <ListFreeTipsCategories forpage={PageName} />
                        </Suspense>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    <>
                      {'categories-create, counselling-team, advisory-board, categories-manage, testimonials-manage, gallery-manage, homeslider-manage, manage-topmessage, faqs-manage, accreditations-manage, renownedclients-manage, blogcategories-manage, freetipscategory-manage'.includes(
                        PageName
                      ) && (
                        <div
                          dangerouslySetInnerHTML={{ __html: noPermission }}
                        />
                      )}
                    </>
                  )}

                  {AMissionPer && AMissionPer.includes('Manage Request') ? (
                    <>
                      {PageName === 'request-individual' ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <ListRequestIndividual forpage={PageName} />
                        </Suspense>
                      ) : (
                        ''
                      )}
                      {PageName === 'email-subscribers' ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <EmailSubscribers forpage={PageName} />
                        </Suspense>
                      ) : (
                        ''
                      )}

                      {PageName === 'courses-enquiries' ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <ListCoursesEnquiries forpage={PageName} />
                        </Suspense>
                      ) : (
                        ''
                      )}

                      {PageName === 'reported-bugs' ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <ListReportedBugs forpage={PageName} />
                        </Suspense>
                      ) : (
                        ''
                      )}

                      {PageName === 'contact-enquiry' ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <ListContactEnquiry forpage={PageName} />
                        </Suspense>
                      ) : (
                        ''
                      )}
                      {PageName === 'request-team' ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <ListRequestTeam forpage={PageName} />
                        </Suspense>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    <>
                      {'request-individual, email-subscribers, courses-enquiries, contact-enquiry, request-team'.includes(
                        PageName
                      ) && (
                        <div
                          dangerouslySetInnerHTML={{ __html: noPermission }}
                        />
                      )}
                    </>
                  )}
                </div>

                <div className="col-md-1"></div>
              </div>
            </div>
          </>
          <Footer className="appie-footer-about-area" />
          <BackToTop />
        </>
      ) : (
        <LoginPage />
      )}
    </div>
  );
}

export default AdminDashboard;
