import React from 'react';
import CounterUpCom from '../../lib/CounterUpCom';

function CounterArea() {
    return (
        <>
            <section className="appie-counter-area" id="counter">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="appie-single-counter mt-30">
                                <div className="counter-content">
                                    <div className="icon">
                                        <i className="fal fa-chalkboard-teacher" />
                                    </div>
                                    <h3 className="title">
                                        <span className="counter-item">
                                            <CounterUpCom endValue={100} sectionSelect="counter" />
                                        </span>
                                        +
                                    </h3>
                                    <p>Career Courses</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="appie-single-counter mt-30 item-2">
                                <div className="counter-content">
                                    <div className="icon">
                                        <i className="fal fa-user-tie" />
                                    </div>
                                    <h3 className="title">
                                        <span className="counter-item">
                                            <CounterUpCom endValue={1200} sectionSelect="counter" />
                                        </span>
                                        +
                                    </h3>
                                    <p>Expert Instructors</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="appie-single-counter mt-30 item-3">
                                <div className="counter-content">
                                    <div className="icon">
                                        <i className="fal fa-user-graduate" />
                                    </div>
                                    <h3 className="title">
                                        <span className="counter-item">
                                            <CounterUpCom endValue={120} sectionSelect="counter" />
                                        </span>
                                        K+
                                    </h3>
                                    <p>Satisfied Students</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="appie-single-counter mt-30 item-4">
                                <div className="counter-content">
                                    <div className="icon">
                                        <i className="fal fa-badge-check" />
                                    </div>
                                    <h3 className="title">
                                        <span className="counter-item">
                                            <CounterUpCom endValue={100} sectionSelect="counter" />
                                        </span>
                                        +
                                    </h3>
                                    <p>Certified Courses</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CounterArea;
