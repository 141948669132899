import React from 'react';
import { IMGBASEAPIURL } from '../../../config';

function HowUseCalendar() {
    return (
        <>
            <section className="calendar-area useCalendar">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="calendarBox">
                                <div className="calendarText">
                                    <h3>How to use the Course Calendar?</h3>
                                    <ul>
                                        <li>
                                            <i className="fas fa-check-circle" />
                                            Choose the Location
                                        </li>
                                        <li>
                                            <i className="fas fa-check-circle" />
                                            Select the Month
                                        </li>
                                        <li>
                                            <i className="fas fa-check-circle" />
                                            Pick the Week
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <i className="fas fa-check-circle" />
                                            Pick the Category of the course
                                        </li>
                                        <li>
                                            <i className="fas fa-check-circle" />
                                            Click on the program of choice
                                        </li>
                                        <li>
                                            <i className="fas fa-check-circle" />
                                            Select your suitable Date on the calendar
                                        </li>
                                    </ul>
                                </div>
                                <div className="calendarImg">
                                    <img
                                        src={`${IMGBASEAPIURL}institute/calendar/calendar-img1.png`}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HowUseCalendar;
