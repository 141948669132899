import React, { useState } from 'react';
import { IMGBASEAPIURL } from '../../config';

function CompleteCourseList({ className }) {
    const [showQues, setQues] = useState(1);
    const openQuestion = (value) => {
        setQues(value);
    };
    return (
        <>
            <section className={`appie-features-area completeCourselist ${className || ''}`}>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-9">
                            <div className="appie-section-title">
                                <h3>Complete Course List</h3>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="appie-section-title">
                                <div className="input-box">
                                    <input type="text" placeholder="Search" />
                                    <button type="button">
                                        <i className="fal fa-search" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="appie-section-title">
                                <div className="btnBox">
                                    <a className="main-btn" href="/">
                                        <i className="far fa-filter" /> Filter
                                    </a>
                                    <select>
                                        <option value="0">Sort By</option>
                                        <option value="0">Sort 1</option>
                                        <option value="0">Sort 2</option>
                                        <option value="0">Sort 3</option>
                                    </select>
                                </div>
                            </div>
                            <div className="filter-accrodion">
                                <div
                                    onClick={() => openQuestion(1)}
                                    className={`accrodion ${showQues === 1 ? 'active' : ''}`}
                                >
                                    <div className="accrodion-inner">
                                        <div className="accrodion-title">Rating</div>
                                        <div
                                            className="accrodion-content"
                                            style={{
                                                display: showQues === 1 ? 'block' : 'none',
                                            }}
                                        >
                                            <div className="inner">
                                                <div className="ratingBox">
                                                    <i className="fas fa-star" />
                                                    <i className="fas fa-star" />
                                                    <i className="fas fa-star" />
                                                    <i className="fas fa-star-half-alt" />
                                                    <i className="far fa-star" />
                                                    <b>(4700)</b>
                                                </div>
                                                <div className="ratingBox">
                                                    <i className="fas fa-star" />
                                                    <i className="fas fa-star" />
                                                    <i className="fas fa-star" />
                                                    <i className="far fa-star" />
                                                    <i className="far fa-star" />
                                                    <b>(4700)</b>
                                                </div>
                                                <div className="ratingBox">
                                                    <i className="fas fa-star" />
                                                    <i className="fas fa-star" />
                                                    <i className="fas fa-star-half-alt" />
                                                    <i className="far fa-star" />
                                                    <i className="far fa-star" />
                                                    <b>(4700)</b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    onClick={() => openQuestion(2)}
                                    className={`accrodion ${showQues === 2 ? 'active' : ''}`}
                                >
                                    <div className="accrodion-inner">
                                        <div className="accrodion-title">Video Duration</div>
                                        <div
                                            className="accrodion-content"
                                            style={{
                                                display: showQues === 2 ? 'block' : 'none',
                                            }}
                                        >
                                            <div className="inner">
                                                <p>
                                                    <input type="checkbox" /> 30 min
                                                </p>
                                                <p>
                                                    <input type="checkbox" defaultChecked /> 45 min
                                                </p>
                                                <p>
                                                    <input type="checkbox" /> 60 min
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    onClick={() => openQuestion(3)}
                                    className={`accrodion ${showQues === 3 ? 'active' : ''}`}
                                >
                                    <div className="accrodion-inner">
                                        <div className="accrodion-title">Topic</div>
                                        <div
                                            className="accrodion-content"
                                            style={{
                                                display: showQues === 3 ? 'block' : 'none',
                                            }}
                                        >
                                            <div className="inner">
                                                <p>
                                                    <input type="checkbox" defaultChecked /> Topic 1
                                                </p>
                                                <p>
                                                    <input type="checkbox" /> Topic 2
                                                </p>
                                                <p>
                                                    <input type="checkbox" /> Topic 3
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    onClick={() => openQuestion(4)}
                                    className={`accrodion ${showQues === 4 ? 'active' : ''}`}
                                >
                                    <div className="accrodion-inner">
                                        <div className="accrodion-title">Level</div>
                                        <div
                                            className="accrodion-content"
                                            style={{
                                                display: showQues === 4 ? 'block' : 'none',
                                            }}
                                        >
                                            <div className="inner">
                                                <p>
                                                    <input type="checkbox" defaultChecked /> All
                                                    Levels (5,200)
                                                </p>
                                                <p>
                                                    <input type="checkbox" /> Beginner (2,200)
                                                </p>
                                                <p>
                                                    <input type="checkbox" /> Intermediate (4,200)
                                                </p>
                                                <p>
                                                    <input type="checkbox" /> Expert (1,200)
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    onClick={() => openQuestion(5)}
                                    className={`accrodion ${showQues === 5 ? 'active' : ''}`}
                                >
                                    <div className="accrodion-inner">
                                        <div className="accrodion-title">Price</div>
                                        <div
                                            className="accrodion-content"
                                            style={{
                                                display: showQues === 5 ? 'block' : 'none',
                                            }}
                                        >
                                            <div className="inner">
                                                <p>
                                                    <input type="checkbox" defaultChecked /> $29.99
                                                </p>
                                                <p>
                                                    <input type="checkbox" /> $39.99
                                                </p>
                                                <p>
                                                    <input type="checkbox" /> $69.99
                                                </p>
                                                <p>
                                                    <input type="checkbox" /> $99.99
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    onClick={() => openQuestion(6)}
                                    className={`accrodion ${showQues === 6 ? 'active' : ''}`}
                                >
                                    <div className="accrodion-inner">
                                        <div className="accrodion-title">Language</div>
                                        <div
                                            className="accrodion-content"
                                            style={{
                                                display: showQues === 6 ? 'block' : 'none',
                                            }}
                                        >
                                            <div className="inner">
                                                <p>
                                                    <input type="checkbox" defaultChecked /> English
                                                    (6,200)
                                                </p>
                                                <p>
                                                    <input type="checkbox" /> Hindi (5,300)
                                                </p>
                                                <p>
                                                    <input type="checkbox" /> Telugu (4,500)
                                                </p>
                                                <p>
                                                    <input type="checkbox" /> Tamil (3,700)
                                                </p>
                                                <p>
                                                    <input type="checkbox" /> Kannada (2,800)
                                                </p>
                                                <p>
                                                    <input type="checkbox" /> Malayalam (1,500)
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="completeCourselistBox">
                                <div className="appie-features-content">
                                    <div className="imgBox">
                                        <img
                                            src={`${IMGBASEAPIURL}institute/courses/img1.jpg`}
                                            alt=""
                                        />
                                        <span>
                                            <i className="fa fa-eye" /> 1388
                                        </span>
                                    </div>
                                    <div className="contentBox">
                                        <span>Beginner</span>
                                        <h6>
                                            <a href="/">
                                                CPR Adult - Rescuing an Adult Who is Unconscious
                                            </a>
                                        </h6>
                                        <div className="instructorBox">
                                            <img
                                                src={`${IMGBASEAPIURL}institute/instructor/img1.jpg`}
                                                alt=""
                                            />
                                            <a href="/">
                                                <b>Dr. Danish Salim</b>
                                            </a>
                                        </div>
                                        <div className="ratingBox">
                                            <i className="fas fa-star" />
                                            <i className="fas fa-star" />
                                            <i className="fas fa-star" />
                                            <i className="fas fa-star-half-alt" />
                                            <i className="far fa-star" />
                                            <b>3.5/5.0</b>
                                        </div>
                                        <h3 className="rateBox">
                                            $29 <i>$39</i>
                                        </h3>
                                        <div className="tagsBox">
                                            <b>Best Seller</b>
                                            <div className="shareBox">
                                                <a href="/" className="mr-10">
                                                    <i className="far fa-heart" />
                                                </a>
                                                <a href="/" className="text-dark">
                                                    <i className="far fa-share-alt" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="hLine">
                                            <hr />
                                        </div>
                                        <div className="courseDetails">
                                            <div className="durationBox">
                                                <i className="far fa-clock" />
                                                <b>5hr 30mins</b>
                                            </div>
                                            <div className="sessionsBox">
                                                <i className="far fa-play-circle" />
                                                <b>100 Sessions</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="appie-features-content">
                                    <div className="imgBox">
                                        <img
                                            src={`${IMGBASEAPIURL}institute/courses/img2.jpg`}
                                            alt=""
                                        />
                                        <span>
                                            <i className="fa fa-eye" /> 1388
                                        </span>
                                    </div>
                                    <div className="contentBox">
                                        <span className="bgOne">Intermediate</span>
                                        <h6>
                                            <a href="/">
                                                Electric Shock - Dealing With Someone Who Had An
                                                Electric Shock
                                            </a>
                                        </h6>
                                        <div className="instructorBox">
                                            <img
                                                src={`${IMGBASEAPIURL}institute/instructor/img2.jpg`}
                                                alt=""
                                            />
                                            <a href="/">
                                                <b>Dr. Raiza Shukoor</b>
                                            </a>
                                        </div>
                                        <div className="ratingBox">
                                            <i className="fas fa-star" />
                                            <i className="fas fa-star" />
                                            <i className="fas fa-star" />
                                            <i className="fas fa-star-half-alt" />
                                            <i className="far fa-star" />
                                            <b>3.5/5.0</b>
                                        </div>
                                        <h3 className="rateBox">
                                            $29 <i>$39</i>
                                        </h3>
                                        <div className="tagsBox">
                                            <b>Best Seller</b>
                                            <div className="shareBox">
                                                <a href="/#" className="mr-10">
                                                    <i className="far fa-heart" />
                                                </a>
                                                <a href="/#" className="text-dark">
                                                    <i className="far fa-share-alt" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="hLine">
                                            <hr />
                                        </div>
                                        <div className="courseDetails">
                                            <div className="durationBox">
                                                <i className="far fa-clock" />
                                                <b>5hr 30mins</b>
                                            </div>
                                            <div className="sessionsBox">
                                                <i className="far fa-play-circle" />
                                                <b>100 Sessions</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="appie-section-title text-lg-right text-center mt-30 pb-0">
                                <a className="main-btn" href="/">
                                    View All <i className="far fa-arrow-right" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CompleteCourseList;
