import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  BASEAPIURL,
  UPLOADNODEURL,
  showfulldatetime,
} from '../../../../config';

function ProfileSetting({ forpage }) {
  const [defaultProfilePicture] = useState('defaultProfilePicture.png');
  const [Profile, setProfile] = useState({
    name: '',
    email: '',
    mobile: '',
    lastLogin: '',
    photo: defaultProfilePicture,
  });

  useEffect(() => {
    const FetchProfile = async () => {
      try {
        const res = await fetch(`${BASEAPIURL}/student/myprofile`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
          },
          body: JSON.stringify({
            loggedToken: localStorage.getItem('loggedToken'),
            usertype: localStorage.getItem('usertype'),
          }),
        });
        const resJson = await res.json();
        if (res.status === 200) {
          if (resJson.userdata && resJson.userdata !== null) {
            setProfile(resJson.userdata);
          }
        } else {
          console.log(resJson.message);
        }
      } catch (err) {
        console.log(err);
      }
    };
    FetchProfile();
  }, []);

  function GetShowData(forData, Index, defaultValue) {
    let returnValue = defaultValue;
    if (
      forData &&
      forData[Index] &&
      forData[Index] !== null &&
      forData[Index] !== ''
    ) {
      returnValue = forData[Index];
    }
    return returnValue;
  }

  return (
    <>
      <div
        className="pb-3"
        forpage={forpage}
      >
        <div className="row">
          <div className="col-md-7">
            <div className="ry_instr_shadow p-3">
              <h6 className="std_header_on_page">
                {GetShowData(Profile, 'name', 'NA')}
              </h6>
              <div style={{ clear: 'both' }}></div>
              <hr />
              <div className="row">
                <div className="col-md-4">Gender</div>
                <div className="col-md-8">
                  {GetShowData(Profile, 'gender', 'NA')}
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">City</div>
                <div className="col-md-8">
                  {GetShowData(Profile, 'city', 'NA')}
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">Address</div>
                <div className="col-md-8">
                  {GetShowData(Profile, 'address', 'NA')}
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">Phone Number</div>
                <div className="col-md-8">
                  <a href={`tel:${GetShowData(Profile, 'mobile', '')}`}>
                    {GetShowData(Profile, 'mobile', 'NA')}
                  </a>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">Email</div>
                <div className="col-md-8">
                  <a href={`mailto:${GetShowData(Profile, 'email', '')}`}>
                    {GetShowData(Profile, 'email', 'NA')}
                  </a>
                </div>
              </div>
              <div className="text-center mt-2">
                <Link
                  to="/user/page/updateprofilesetting"
                  className="btn btn-secondary p-1 pl-4 pr-4 mt-4"
                >
                  Edit Profile
                </Link>
                <Link
                  to="/user/page/changePassword"
                  className="btn btn-danger ml-2 p-1 pl-4 pr-4 mt-4"
                >
                  Change Password
                </Link>
                <small className="text-info d-none">
                  You are logged in on
                  {showfulldatetime(
                    Number(GetShowData(Profile, 'lastLogin', 0))
                  )}
                </small>
              </div>
              <div className="text-left mt-4">
                <h6 className="std_header_on_page mt-4">Email Notifications</h6>
                <Link
                  to="/user/page/EmailNotificationSetting"
                  className="mt-2"
                >
                  Turn Promotional Email Notifications from Docsta Learn on or
                  off
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-5">
            <div className="ry_instr_shadow p-3">
              <img
                src={`${UPLOADNODEURL}${GetShowData(
                  Profile,
                  'photo',
                  defaultProfilePicture
                )}`}
                alt={GetShowData(Profile, 'name', 'NA')}
                className="p-3"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileSetting;
