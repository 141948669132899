import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BASEAPIURL, BASEURL } from '../../../../config';
import Pagination from './AnyPagination';
import '../../../Helper/rypopup.css';

function showdatetimein(datetime) {
    const tmp = new Date(datetime);
    return `${tmp.getDate()}/${tmp.getMonth()}/${tmp.getUTCFullYear()}`;
}

function MyCoursesList({ forpage }) {
    const [NoBlog, setNoBlog] = useState(1);
    const [ShowAlert, setShowAlert] = useState(false);
    // for pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(5);
    const [TotalRecords, setTotalRecords] = useState(5);
    const nPages = Math.ceil(TotalRecords / recordsPerPage);
    // for pagination
    const [Blogs, setBlogs] = useState([]);
    const [BlogsCati, setBlogsCati] = useState([]);
    const [Keyword, setKeyword] = useState('');
    const [SortBy, setSortBy] = useState('');

    // const [drawer, drawerAction] = useToggle(false);
    // const [lfVisibility, setlfVisibility] = useState('block');

    const FetchCourses = async () => {
        try {
            const res = await fetch(`${BASEAPIURL}/mainCourse/getAll`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('jwttoken')}`,
                },
                body: JSON.stringify({
                    createdID: localStorage.getItem('tid'),
                    createdBy: 'instructor',
                    recordsPerPage: 5,
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                setBlogs(resJson.data);
                setNoBlog(resJson.data.length);
                setBlogsCati(resJson.category);
                setTotalRecords(resJson.count);
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };
    const FilterFetchCourses = async (e, keywordhere, sortbyhere, PageNumber) => {
        // Keyword SortBy
        e.preventDefault();
        try {
            const res = await fetch(`${BASEAPIURL}/mainCourse/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    keyword: keywordhere,
                    sortby: sortbyhere,
                    PageNumber,
                    recordsPerPage: 5,
                    createdID: localStorage.getItem('tid'),
                    createdBy: 'instructor',
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                setBlogs(resJson.data);
                setBlogsCati(resJson.category);
                setTotalRecords(resJson.count);
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
        return false;
    };
    useEffect(() => {
        FetchCourses();
    }, []);

    return (
        <>
            <div
                className="ryPopUpBack"
                style={{ display: ShowAlert ? '' : 'none' }}
                onClick={() => {
                    setShowAlert(false);
                }}
            ></div>
            <div
                className="ryPopUp"
                style={{ display: ShowAlert ? '' : 'none', position: 'fixed' }}
            >
                <a
                    href="/"
                    className="ryPopUpAct"
                    onClick={(e) => {
                        e.preventDefault();
                        setShowAlert(false);
                    }}
                >
                    X
                </a>
                <div style={{ margin: '30px 50px' }}>
                    <h3>Are you sure, You want to udpate the Course? </h3>
                    <div className="mt-4 mb-4">
                        <h6 className="text-danger">
                            The Course is already live, If you edit it again, It will be again goes
                            back to on Review Status.
                        </h6>
                    </div>
                    <Link
                        to="/create-course"
                        target="_CREATE"
                        className="btn btn-docsta-instructor"
                        onClick={() => {
                            setShowAlert(false);
                        }}
                        style={{
                            width: '150px',
                            margin: '20px auto',
                        }}
                    >
                        Confirm Edit
                    </Link>
                </div>
            </div>
            <div className="ry_instr_shadow p-4" forpage={forpage}>
                <h4>
                    My Courses
                    <div style={{ clear: 'both' }}></div>
                </h4>
                <form
                    id="blog_filter_frm"
                    className="row mt-3"
                    style={{
                        display: NoBlog === 0 ? 'none' : '',
                    }}
                >
                    <div className="col-md-6">
                        <input
                            name="keyword"
                            type="text"
                            className="form-control"
                            placeholder="Search Anything"
                            value={Keyword}
                            onChange={(e) => {
                                setKeyword(e.target.value);
                                FilterFetchCourses(e, e.target.value, SortBy, 1);
                            }}
                        />
                    </div>
                    <div className="col-md-6">
                        <select
                            name="sortby"
                            defaultValue={SortBy}
                            className="form-control"
                            onChangeCapture={(e) => {
                                setSortBy(e.target.value);
                                FilterFetchCourses(e, Keyword, e.target.value, 1);
                            }}
                        >
                            <option value="0">Sort By</option>
                            <option value="a2z">Name A2Z</option>
                            <option value="z2a">Name Z2A</option>
                            <option value="latest">Latest</option>
                            <option value="old">Oldest</option>
                        </select>
                    </div>
                </form>
                <div
                    style={{
                        display: NoBlog === 0 ? '' : 'none',
                        background: '#f5f5f5',
                        margin: '20px auto',
                        padding: '35px',
                        textAlign: 'center',
                    }}
                >
                    <div>
                        <h4 style={{ color: '#5c5a5a' }}>No Courses yet...</h4>
                        <h6 style={{ color: '#5c5a5a' }}>
                            Once you publish your Course, come here to view them.
                        </h6>
                    </div>
                </div>
                <div
                    style={{
                        display: NoBlog === 0 ? '' : 'none',
                    }}
                >
                    <h3 className="mt-5 mb-1">Why conduct a Course?</h3>
                    <h4 className="mb-3" style={{ color: '#7f13ab' }}>
                        You can be your guiding star
                    </h4>
                    <div className="row">
                        <div className="col-md-4 text-centaer">
                            <img
                                alt=""
                                src={`${BASEURL}/images/courses/create01.jpg`}
                                style={{ marginTop: '50px' }}
                            />
                            <h4 className="mt-3">Creating an impact</h4>
                            <p>
                                If you have a skill that thousands would love to learn, get on this
                                global stage to show the world your unique potential.
                            </p>
                        </div>

                        <div className="col-md-4 text-ceanter">
                            <img
                                alt=""
                                src={`${BASEURL}/images/courses/create02.jpg`}
                                style={{ marginTop: '50px' }}
                            />
                            <h4 className="mt-3">Creating an impact</h4>
                            <p>
                                Teaching only expands your knowledge, a few hours of your research
                                could become a powerful lesson for another individual.
                            </p>
                        </div>

                        <div className="col-md-4 text-centaer">
                            <img
                                alt=""
                                src={`${BASEURL}/images/courses/create03.jpg`}
                                style={{ marginTop: '50px' }}
                            />
                            <h4 className="mt-4">Creating an impact</h4>
                            <p>
                                Make an attractive course once and from that single effort - earn
                                again and again each time your course is bought.
                            </p>
                        </div>

                        <div className="m-1" style={{ clear: 'both' }}></div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 text-center"></div>
                        <div className="col-md-4 text-center">
                            <Link
                                to="/create-course"
                                target="_CREATE"
                                className="btn btn-docsta-instructor ry-pull-left mt-3"
                                style={{ borderRadius: '10px' }}
                                onClick={() => {
                                    localStorage.setItem('edit_course_id', 0);
                                }}
                            >
                                Create a Course
                            </Link>
                        </div>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                </div>
                <div
                    className="mt-3 table-responsive"
                    style={{
                        display: NoBlog === 0 ? 'none' : '',
                    }}
                >
                    <table className="table table-hover table-borddered table-stripped">
                        <thead className="ry-table-header">
                            <tr>
                                <th>Title</th>
                                <th>Listner</th>
                                <th>Status</th>
                                <th>Created on</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Blogs.map((singi) => (
                                <tr key={singi.blogid}>
                                    <td>
                                        <div style={{ display: 'inline-block' }} className="">
                                            <b>{singi.title}</b>
                                            <br />
                                            <small style={{ fontSize: '70%' }}>
                                                {BlogsCati.map((cti) => (
                                                    <div key={cti.TheCourseID}>
                                                        {singi.category === cti.TheCourseID ? (
                                                            <div className="text-info">
                                                                <b>Category:</b> {cti.title}
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                ))}
                                            </small>
                                        </div>
                                    </td>
                                    <td>{singi.listner}</td>
                                    <td>
                                        {singi.status === 1 ? (
                                            <button
                                                type="button"
                                                className="alert p-1 alert-success"
                                            >
                                                Live
                                            </button>
                                        ) : (
                                            ''
                                        )}
                                        {singi.status === 2 ? (
                                            <button
                                                type="button"
                                                className="alert p-1 alert-warning"
                                                title="After reviewing your request, we will get back to you."
                                            >
                                                On Review
                                            </button>
                                        ) : (
                                            ''
                                        )}

                                        {singi.status === 0 ? (
                                            <button
                                                type="button"
                                                className="alert p-1 alert-danger"
                                                title="Your Entry rejected to Publish."
                                            >
                                                Rejected
                                            </button>
                                        ) : (
                                            ''
                                        )}
                                    </td>
                                    <td>
                                        {showdatetimein(singi.createdAt)}
                                        {singi.status === 1 ? (
                                            <Link
                                                to="#"
                                                className="btn btn-docsta-instructor ry-pull-right"
                                                style={{ width: 'auto', borderRadius: '10px' }}
                                                onClick={() => {
                                                    localStorage.setItem(
                                                        'edit_course_id',
                                                        singi.TheCourseID
                                                    );
                                                    setShowAlert(true);
                                                }}
                                            >
                                                <i className="fa fa-edit"></i>
                                            </Link>
                                        ) : (
                                            <Link
                                                to="/create-course"
                                                target="_CREATE"
                                                className="btn btn-docsta-instructor ry-pull-right"
                                                style={{ width: 'auto', borderRadius: '10px' }}
                                                onClick={() => {
                                                    localStorage.setItem(
                                                        'edit_course_id',
                                                        singi.TheCourseID
                                                    );
                                                }}
                                            >
                                                <i className="fa fa-edit"></i>
                                            </Link>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div
                    className="mt-3 table-responsive"
                    style={{
                        display: NoBlog === 0 ? 'none' : '',
                    }}
                >
                    <small className="text-center d-block mb-2">
                        Showing Page {currentPage} of Total page {nPages} from {TotalRecords}
                        &nbsp;Records
                    </small>
                    <Pagination
                        nPages={nPages}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        FetchFilterBlogs={FilterFetchCourses}
                        SortBy={SortBy}
                        Keyword={Keyword}
                    />
                </div>
            </div>
        </>
    );
}

export default MyCoursesList;
