import React from 'react';
import { Link } from 'react-router-dom';
import Parser from 'html-react-parser';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
// import Header from '../Helper/Header';
import Footer from '../Helper/FooterInstitute';
import Drawer from '../Mobile/DrawerInstitute';
import HeaderInstructor from './InstructorIndex_Insti';
import { IMGBASEAPIURL } from '../../config';
import './instructor.css';

function InstructorIndex() {
    const InstHeadTitle = 'Become an Instructor with Us';
    const InstHeadContent =
        'If teaching is your passion, we are your solution. If you have the knowledge that will benefit others, then we are here to enrich your potential and support you to create world-class courses and make your world wide impact.';
    const JoinTitle =
        'Your dream to leave a legacy in this world is just a click away...<br />Join our instructor team! ';
    const StartTeachTitle = 'Start teaching the world today';
    const JoinContent1 =
        'Our platform is designed to guide you from step 1 upto creation of a perfect, valuable program that will leave your mark on each of your students. Our expertise is not just in training you to create the best courses, but also in amplifying your reach exponentially so that your knowledge reaches the global population. ';
    const JoinContent2 =
        'Over time, we have touched the lives of more than a million students with multiple life enhancing courses and wants each of our instructors to do the same and reap the abundant rewards with maximum protection of their intellectual rights. ';
    const StartTeachContent =
        'If teaching is your passion, we are your solution. If you have the knowledge that will benefit others, then we are here to enrich your potential and support you to create world-class courses and make your world wide impact.<br /><br />Our platform is designed to guide you from step 1 upto creation of a perfect, valuable program that will leave your mark on each of your students. Our expertise is not just in training you to create the best courses but also in amplifying your reach exponentially so that your knowledge reaches the global population.<br /><br /> Over time, we have touched the lives of more than a million students with multiple life enhancing courses and wants each of our instructors to do the same and reap the abundant rewards with maximum protection of their intellectual rights.';
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} type="instructor" />
            <HeaderInstructor drawer={drawer} action={drawerAction.toggle} />
            <>
                <div style={{ background: '#f2e7e7' }}>
                    <div
                        className="mb-3 pt-4 pb-4 container hideonmobile"
                        style={{
                            background: `url(${IMGBASEAPIURL}instructor/insr_banner.png)`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    >
                        <div className="row m-0">
                            <div
                                className="col-md-6 pt-4 pb-4 mb-4  mt-4 pl-4 pr-4"
                                style={{ paddingLeft1: '70px' }}
                            >
                                <h3 className="mt-4 mb-3">{Parser(InstHeadTitle)}</h3>
                                <p style={{ textAlign: 'left' }}>{Parser(InstHeadContent)}</p>
                                <Link to="/instructor-register">
                                    <a className="main-btn mt-3 mb-4" href="/">
                                        Register Now
                                    </a>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ background: '#f2e7e7' }}>
                    <div
                        className="mb-3 pt-4 pb-4 container hideondesktop"
                        style={{
                            background: `url(${IMGBASEAPIURL}instructor/insr_banner.png)`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    >
                        <div className="row m-0">
                            <div className="col-md-6">
                                <img
                                    alt=""
                                    src={`${IMGBASEAPIURL}instructor/insr_banner_mob.png?v=20230123`}
                                    style={{
                                        float: 'right',
                                        maxWidth: '120px',
                                        marginTop: '30px',
                                        marginLeft: '10px',
                                    }}
                                />
                                <h5 className="mt-4 mb-3">{Parser(InstHeadTitle)}</h5>
                                <p style={{ textAlign: 'left' }}>{Parser(InstHeadContent)}</p>
                                <Link to="/instructor-register">
                                    <a className="main-btn mt-3 mb-4" href="/">
                                        Register Now
                                    </a>
                                </Link>
                                <div style={{ clear: 'both' }}></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="mb-3 mt-4">
                        <h3 className="mb-3 mt-3 text-center">{Parser(JoinTitle)}</h3>
                        <div className="row">
                            <div className="col-md-2 mt-3 text-center"></div>
                            <div className="col-md-4 mt-3 text-center">
                                <img
                                    src={`${IMGBASEAPIURL}instructor/joinimg1.jpg`}
                                    alt="Docsta Learn Insinstructor"
                                />
                                <div className="join_desc">{Parser(JoinContent1)}</div>
                            </div>
                            <div className="col-md-4 mt-3 text-center">
                                <img
                                    src={`${IMGBASEAPIURL}instructor/joinimg2.jpg`}
                                    alt="Docsta Learn Insinstructor"
                                />
                                <div className="join_desc">{Parser(JoinContent2)}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ background: '#f5f5f5' }}>
                    <div className="container">
                        <div
                            className="hideonmobile"
                            style={{
                                background: `url(${IMGBASEAPIURL}instructor/startteach_banner.png?v=1)`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: '100%',
                                '@media (max-width: 500px)': {
                                    display: 'none',
                                },
                            }}
                        >
                            <div className="row">
                                <div
                                    className="col-md-6  mb-4  mt-4 pl-5 pr-5"
                                    style={{ paddingLeft1: '70px' }}
                                >
                                    <h3 className="mt-4 mb-3">{Parser(StartTeachTitle)}</h3>
                                    <p style={{ textAlign: 'left' }}>{Parser(StartTeachContent)}</p>
                                    <Link to="/instructor-register">
                                        <a className="main-btn mt-3 mb-4" href="/">
                                            Register Now
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="hideondesktop">
                            <div className="row">
                                <div className="col-md-6">
                                    <h3 className="mt-4 mb-3">{Parser(StartTeachTitle)}</h3>
                                    <img
                                        alt=""
                                        src={`${IMGBASEAPIURL}instructor/startteach_banner_mob.png?v=20230123`}
                                        style={{
                                            float: 'right',
                                            maxWidth: '190px',
                                            marginBottom: '30px',
                                            marginLeft: '10px',
                                        }}
                                    />
                                    <p style={{ textAlign: 'left' }}>{Parser(StartTeachContent)}</p>
                                    <Link to="/instructor-register">
                                        <a className="main-btn mt-3 mb-4" href="/">
                                            Register Now
                                        </a>
                                    </Link>
                                    <div style={{ clear: 'both' }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            <Footer />
            <BackToTop />
        </>
    );
}

export default InstructorIndex;
