import React from 'react';
import { Link } from 'react-router-dom';
import { IMGBASEAPIURL } from '../../config';

function CareerCounselingBox() {
    return (
        <>
            <section
                className="appie-career-counseling d-none"
                style={{
                    backgroundImage: `url(${IMGBASEAPIURL}institute/bg/career-counsel-bg.jpg)`,
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="appie-instructor-content">
                                <h3>Career Counseling</h3>
                                <h4>Discover Endless Possibilities</h4>
                                <p>
                                    Never compromise on your career. Experience World renowned
                                    career guidance from our consultants
                                </p>
                                <Link className="main-btn" to="/career-counselling">
                                    Get a Call Back
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CareerCounselingBox;
